import React from "react";
import BreadcrumbComponent from "../../common/components/breadcrumb-component/breadcrumb-component";

const Orders:React.FC =()=> {
    let crumbMapping = [
        { label: 'Orders', link: '/orders' },
    ];
    return(
        <div>
            <BreadcrumbComponent breadcrumbLink={crumbMapping}/>
        </div>
    )
}
export default Orders;