import { splitPhoneNumber } from "../../utils";

export const initialValues =(editData: any, mode: any)=>{
    return  editData &&  mode === 'edit'? {
        name: editData?.name || "",
        authorizedRepresentative: editData?.authorized_representative || "",
        mailingAddress: {
          id: editData?.mailingAddress?.id,
          street: editData?.mailingAddress?.street || "",
          city: editData?.mailingAddress?.City?.name || "",
          state: editData?.mailingAddress?.State?.name || "",
          zipcode: editData?.mailingAddress?.zipcode || "",
          selectedMailingAddressCity: editData.mailingAddress.City, 
          selectedMailingAddressState: editData.mailingAddress.State,
          mailingAddressCity: null,
          mailingAddressState: null,
        },
        agencyOwners: editData?.agency_owners?.length ? editData?.agency_owners?.map((data: any)=> {
          return {
            id:  data?.id,
            name: data?.name,
            bankName: data?.bank_name,
            bankRoutingNumber: data?.bank_routing_number,
            bankAccountNumber: data?.bank_account_number,
            commissionPaymentFrequency: data?.commission_payment_frequency ? {label: data?.commission_payment_frequency, value: data?.commission_payment_frequency} : null,
            socialSecurityNumber: data?.social_security_number,
            ein: data?.ein,
            dateOfBirth: data?.date_of_birth,
            companiesContractedWith: {value: 1, label : 'NGL'},
            accountType: data?.account_type,
            isPrimaryOwner: data?.is_primary_owner        
          }
        }) : [],
        agencyLicenseDetails: editData?.agency_licenses?.length ? editData?.agency_licenses?.map((data: any)=> {
          return {
            id:  data?.id,
            stateLicensedIn: data?.states_licensed?.id ? data?.states_licensed : null,
            licenseExpiration:  data?.license_expiration || null,
            amlCertification: data?.aml_certification || null,
            reserveBalance: data?.reserve_balance || 0,
            agencyNumber: data?.agency_number ||  null,
            stateOptions: [],
            selectedState:  data?.states_licensed?.id ? data?.states_licensed : null,
            focusedField: null,
          }
        }) : [],
        agencyStatesAppointedIn: editData?.agency_states_appointed?.length ? editData?.agency_states_appointed?.map((data: any)=>{return {...data?.states_appointed_in, editID:  data?.id}}) :[],
        deletedAgencyStatesAppointedIn: [],
        stateAppointedFocusedField: null,
        stateAppointedSelectedState: null,
        stateAppointedOptions: [],
        workPhone: splitPhoneNumber(editData?.work_phone),
        cellPhone:  editData?.cell_phone ? splitPhoneNumber(editData?.cell_phone) :  {
          countryCode: "+1", 
          phoneNumber: "",  
        },
        emailAddress: editData?.email_address || "",
        specialInstructions: editData?.special_instructions || "",
        commissionLevel: editData?.commission_level || null,
        commissionLevelOptions:[],
        commissionLevelField: null,
        commissionLevelSelected: editData?.commissionLevel || null,
        regionalRepId: editData?.regional_rep_id || null,
        regionalRepIdFocusedField: null,
        regionalRepIdSelectedState: editData?.agency_regional_rep || null,
        regionalRepIdOptions: [],
        note: editData?.note || "",
    }:
    {
        name: "",
        authorizedRepresentative: "",
        mailingAddress: {
            street: "",
            city: "",
            state: "",
            zipcode: "",
            selectedMailingAddressCity: null,
            selectedMailingAddressState: null,
            mailingAddressCity: null,
            mailingAddressState: null,
        },
      agencyOwners: [{
          name: "",
          bankName: null,
          bankRoutingNumber: null,
          bankAccountNumber: null,
          accountType: null,
          commissionPaymentFrequency: null,
          socialSecurityNumber: null,
          ein: null,
          dateOfBirth: null,
          isPrimaryOwner: null,
          companiesContractedWith: null
      }],
      agencyLicenseDetails: [
        {
            stateOptions: [],
            selectedState: null,
            focusedField: null,
            stateLicensedIn: null,
            licenseExpiration: null,
            amlCertification: null,
            reserveBalance: 0,
            agencyNumber: null
        },
      ],
      agencyStatesAppointedIn: [],
      deletedAgencyStatesAppointedIn: [],
      stateAppointedFocusedField: null,
      stateAppointedSelectedState: null,
      stateAppointedOptions: [],
      workPhone:  {
        countryCode: "+1", 
        phoneNumber: "",  
      },
      cellPhone:  {
        countryCode: "+1", 
        phoneNumber: "",  
      },
      emailAddress: "",
      specialInstructions: "",
      commissionLevel: "",
      commissionLevelOptions:[],
      commissionLevelField: null,
      commissionLevelSelected: null,
      regionalRepId: null,
      regionalRepIdFocusedField: null,
      regionalRepIdSelectedState: null,
      regionalRepIdOptions: [],
    }
}