import React from 'react';
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import closeIcon from '../../../assets/icons/closeIcon.svg';
import { ViewComponentProps } from '../types/view-companies-props';
import './view-companies.scss';

const ViewCompanies: React.FC<ViewComponentProps> = ({isOpen, viewData, toggleView}) => {
    const formatAddress = (address: any) => {
      const street = address?.street || "";
      const city = address?.City?.name || "";
      const state = address?.State?.name || "";
      const zipcode = address?.zipcode || "";
  
      return `${street}, ${city}, ${state}, ${zipcode}`;
    };
  return (
    <div className="add-or-edit-offcanvas">
    <Offcanvas isOpen={isOpen} direction="end" className="offcanvas">
        <OffcanvasHeader className="offcanvas-header">
          <div className="header-div">
            <h2 className="mb-0 scroll-text">
            {viewData?.name}
            </h2>
            <button onClick={toggleView}>
              <img src={closeIcon} alt="Close" />
            </button>
          </div>
        </OffcanvasHeader>
        <OffcanvasBody className="offcanvas-body">
        <div className="view-form">
          <div className="form-group">
            <label>Physical Address</label>
            <div className="data-value">{formatAddress(viewData.physicalAddress)}</div>
          </div>
          <div className="form-group">
            <label>Mailing Address</label>
            <div className="data-value">{formatAddress(viewData.mailingAddress)}</div>
          </div>
          <div className="form-group">
            <label>Contact Number Claims</label>
            <div className="data-value">{viewData?.contact_numbers_claims?.trim() ? viewData.contact_numbers_claims : "-"}</div>
          </div>
          <div className="form-group">
            <label>Contact Number Agents</label>
            <div className="data-value">{viewData?.contact_numbers_agents?.trim() ? viewData.contact_numbers_agents : "-"}</div>
          </div>
          <div className="form-group">
            <label>Contact Names</label>
            <div className="data-value">{viewData?.contact_names}</div>
          </div>
          <div className="form-group">
            <label>Contact Departments</label>
            <div className="data-value">{viewData?.contact_departments?.trim() ? viewData.contact_departments : "-"}</div>
          </div>
        </div>
        </OffcanvasBody>
      </Offcanvas>
    </div>
  )
}

export default ViewCompanies