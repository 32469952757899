import axios from "..";
export const getSavedReportsDetails = (searchText:string, sortBy?: string, sortDirection?: string) => {
    return axios({
      method: "GET",
      url: "/reports/saved-reports",
      params: {
        searchText:searchText,
        sortBy: sortBy,
        sortDirection: sortDirection
      }
  });
};