import React, { useState, useMemo } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styles from '../welcome/welcome.module.scss';
import { useSetLoading } from '../../common/hooks/appearance';
import './forgot-password.scss'
import * as resetPasswordAPI from '../../api/resetpassword';
import { emailValidationRegex } from '../../constants/regex-string';

interface ForgotPasswordProps {
  onBackToLogin?: () => void;
  setPassword?: boolean;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ onBackToLogin, setPassword = false }) => {
  const [message, setMessage] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const emailInitialValues = { email: '' };

  const emailValidationSchema = Yup.object({
    email: Yup.string().matches(emailValidationRegex, { message: "Invalid email address. Please provide a valid email."})
    .required('Email is required'),
  });

  const handleRequestSubmit = async (values: typeof emailInitialValues) => {
    setIsLoading(true);
    try {
      const response: any= await resetPasswordAPI?.forgotpassword(values);
      if(response?.data?.message){
        setMessage({message: response?.data?.message, types: 'success'
        });
      }
      setIsLoading(false);
    } catch (error:any) {
      setMessage({message: error?.response?.data?.message, types: 'error'});
      setIsLoading(false);
    }
  };

  const showLoading = useMemo(() => isLoading, [isLoading]);
  useSetLoading(showLoading);
  
  return (
    <div className={`${setPassword ? styles.middleSection : styles.rightSection} forgot-password`}>
        <Formik
          initialValues={emailInitialValues}
          validationSchema={emailValidationSchema}
          onSubmit={handleRequestSubmit}
        >
          <Form className={styles.loginForm}>
            <h2 className="h-heading">Forgot Password</h2>
            <div className={styles.field}>
              <Field 
                type="email" 
                name="email" 
                onBlur={()=>{
                  setMessage('')
                }}
                placeholder="Enter your email" 
              />
              <ErrorMessage name="email" component="div" className={`${styles.errorMessage} forgot-password-email-error`} />
            </div>
            <button type="submit" className={styles.submitButton} >
              Send Reset Link
            </button>
            {message?.message ? <div className={`${message?.types === 'error' ? `forgot-password-error-message` : `forgot-password-success-message`}`}>{message?.message}</div>:""}
              <div className={`helperLinks`}>
                <div className={`helperLinksLabel`}>
                In case of any issues, please{' '}
                <a
                  href={`${process.env.REACT_APP_WP_WEBSITE_BASE_URL}/contact-us/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  contact us
                </a>
                </div>
            { !setPassword && (
              <a href="/#" onClick={(e)=>{e.preventDefault(); onBackToLogin?.();}}>Login</a>
            )}
            </div>
          </Form>
        </Formik>
    </div>
  );
};

export default ForgotPassword;
