import React from 'react';
import { Collapse, NavItem, NavLink } from 'reactstrap';
import { SubMenuProps } from '../../types/sub-menu-props';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import collapseArrow from '../../../../assets/icons/side-bar/collapseArrow.svg';
import expandArrow from '../../../../assets/icons/side-bar/expandArrow.svg';
import activeFuneralHomesIcon from '../../../../assets/icons/side-bar/activeFuneralHomesIcon.svg';
import activeAgenciesIcon from '../../../../assets/icons/side-bar/activeAgenciesIcon.svg';
import activeAgentsIcon from '../../../../assets/icons/side-bar/activeAgentsIcon.svg';
import activeRegionalRepsIcon from '../../../../assets/icons/side-bar/activeRegionalRepsIcon.svg';
import activeCompaniesIcon from '../../../../assets/icons/side-bar/activeCompaniesIcon.svg';
import activePoliciesIcon from '../../../../assets/icons/side-bar/activePoliciesIcon.svg';
import activeUserIcon from '../../../../assets/icons/side-bar/activeUserIcon.svg';
import activeRolesIcon from '../../../../assets/icons/side-bar/activeRolesIcon.svg';
import activeViewContractsIcon from '../../../../assets/icons/side-bar/activeViewContractsIcon.svg';
import activeContractsTemplatesIcon from '../../../../assets/icons/side-bar/activeContractsTemplatesIcon.svg';
import activeContractsSignatureIcon from '../../../../assets/icons/side-bar/activeContractsSignatureIcon.svg';
import activeSavedReportsIcon from '../../../../assets/icons/side-bar/activeSavedReportsIcon.svg';
import activeGenerateReportsIcon from '../../../../assets/icons/side-bar/activeGenerateReportsIcon.svg';
import activeTrackUploadIcon from '../../../../assets/icons/side-bar/activeTrackUploadIcon.svg'
const SubMenu: React.FC<SubMenuProps> = ({navItem, navItems, isCollapsed, setNavItems, activeItem, setActiveItem}) => {
    const collapsed= navItem?.isOpen;
    const iconMapping = {
        "Funeral Homes": activeFuneralHomesIcon,
        "Agencies": activeAgenciesIcon,
        "Agents": activeAgentsIcon,
        "Regional Reps": activeRegionalRepsIcon,
        "Companies": activeCompaniesIcon,
        "Policies": activePoliciesIcon,
        "Users": activeUserIcon,
        "Roles": activeRolesIcon,
        "View Contracts": activeViewContractsIcon,
        "Contracts Templates": activeContractsTemplatesIcon,
        "Contracts Signature": activeContractsSignatureIcon,
        "Saved Reports": activeSavedReportsIcon,
        "Generate Reports": activeGenerateReportsIcon,
        "Track Uploads": activeTrackUploadIcon
      };
    const toggleItem = () => {
       const updateNavItem= navItems?.map((data: any, index: any)=>{
            if(navItem.label === data.label){
                return {
                    ...data,
                    isOpen: !data?.isOpen
                }
            }else{
                return {
                    ...data,
                    isOpen: true
                }
            }
       })
       
       setNavItems(updateNavItem);
    }
    return (
        <div className={classNames('sub-menu',{ 'menu-open': !collapsed })}>
            <NavItem onClick={toggleItem}
            className={classNames( navItem?.classNames,{
                'menu-open': !collapsed,
                'collapsed-item': isCollapsed,
              })}>
                <NavLink title={isCollapsed ? navItem.label : ''}>
                <div className="side-bar-icon-text-arrow">
                    <div className="side-bar-icon-text">
                        <div className="side-bar-icon">
                            <img src={navItem.icon} alt={`${navItem.label}-icon`}/>
                        </div>
                        <div className="side-bar-text">
                            <span>{navItem.label}</span>
                        </div>
                    </div>
                    <div className="side-bar-arrow" 
                    onClick={(e) => {
                            e.preventDefault(); 
                    }}>
                        {!collapsed? <img src={collapseArrow} alt="collapseArrow"/> : <img src={expandArrow} alt="collapseArrow"/>} 
                    </div>
                </div>
                </NavLink>
            </NavItem>
            <Collapse isOpen={!collapsed} navbar className={classNames("items-menu", { "mb-1": !collapsed, "collapse-hidden": collapsed })}>
                {navItem.subItems?.map((item,index)=>(
                    <NavItem key={index}  className={classNames(`pl-4`,{
                        'collapsed-item': isCollapsed,
                        'disabledClassName': item?.disabled,
                        "active-item": activeItem === item.path
                      })}>
                        <NavLink  disabled={item?.disabled} tag={Link} to={item.path} onClick={()=>{
                            setTimeout(() => {
                                localStorage.setItem('currentUrl',  window.location.pathname);
                            }, 100)
                            setActiveItem(item.path)
                        }} title={isCollapsed ? item.label : ''}>
                        <div className="icon"><img src={activeItem === item.path ? iconMapping[item.label as keyof typeof iconMapping] : item.icon}  alt={`${item.label}-icon`}/> <span>{!isCollapsed && item.label}</span></div>
                        </NavLink>
                    </NavItem>
                ))}
            </Collapse>
        </div>
    )
}
export default SubMenu;