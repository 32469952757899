import * as Yup from "yup";
import phone from 'phone';
import { emailValidationRegex } from "../../../../constants/regex-string";

export const validationSchema = Yup.object({
    name: Yup.string()
        .matches(/^[A-Za-z ´',-.()!&Åë]+$/, 'Name can only contain alphabets, spaces, and special characters')
        .max(200, "Name can't be longer than 200 characters.")
        .required('Mandatory Field*'),
    ownerName: Yup.string()
        .matches(/^[A-Za-z ´',-.()!&Åë]+$/, 'Name can only contain alphabets, spaces, and special characters')
        .max(200, "Name can't be longer than 200 characters.")
        .required('Mandatory Field*'),
    phoneNumber: Yup.object({
            phoneNumber: Yup.string()
            .matches(/^\d{10}$/, `Number format is invalid`)
            .test('is-valid-phone', `Invalid number`, (value, allValue) => {
              if (!value) return true;
              const { isValid } = phone(`${allValue?.parent?.countryCode || ''}-${value}`);
              return isValid;
            })
            .required('Mandatory Field*'),
    }),
    emailAddress: Yup.string()
      .matches(emailValidationRegex, { message: "Invalid email address. Please provide a valid email."})
      .required('Mandatory Field*'),
    faxNumber: Yup.string()
        .required('Mandatory Field*'),
    taxId: Yup.string()
      .required('Mandatory Field*'),
    physicalAddress: Yup.object({
        street: Yup.string()
            .required("Mandatory Field*")
            .min(3, "Street name must be at least 3 characters.")
            .max(200, "Street name can't be longer than 200 characters."),
        selectedPhysicalAddressCity:  Yup.object().required ("Mandatory Field*"),
        selectedPhysicalAddressState: Yup.object().required ("Mandatory Field*"),
        zipcode: Yup.string()
            .trim()
            .matches(/^\d+$/, 'ZIP Code must contain only digits')
            .required('Mandatory Field*'),
    }),
    mailingAddress: Yup.object({
        street: Yup.string()
            .required("Mandatory Field*")
            .min(3, "Street name must be at least 3 characters.")
            .max(200, "Street name can't be longer than 200 characters."),
        selectedMailingAddressCity:  Yup.object().required ("Mandatory Field*"),
        selectedMailingAddressState: Yup.object().required ("Mandatory Field*"),
        zipcode: Yup.string()
            .trim()
            .matches(/^\d+$/, 'ZIP Code must contain only digits')
            .required('Mandatory Field*'),
    }),
  });