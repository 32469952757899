import React, { useState } from "react";
import { Container } from "reactstrap";
import BreadcrumbComponent from "../../../common/components/breadcrumb-component/breadcrumb-component";
import SearchBar from "../../../common/components/search/search-bar";
import TableComponent from "../../../common/components/table-component/table-component";
import AddOrEditPolicies from "./add-or-edit-policies";
const Policies: React.FC = () => {
  const [addOrEditOpen, setEditOpen] = useState<boolean>(false);
  const [addOrEditOpenMode, setEditOpenMode] = useState<'add' | 'edit' | null>(null);


  const toggleAddEdit = (modeType: any) => {
    setEditOpen(!addOrEditOpen);
    setEditOpenMode(addOrEditOpen ? modeType : "");
  };

  const columnNames = [
    "Edit",
    "Policy Holder Name",
    "Policy Number",
    "Age Of Insured",
    "Effective Date",
    "Status",
    "Termination Date",
    "Issued Face",
    "Current Face Amount",
    "State",
    "Funeral Assigned To",
    "Writing Agent Name",
    "Agent Number",
    "Commission Payment Frequency",
    "Product",
    "Plan Code",
    "Payment Period",
    "Plan Description",
    "Company Insurer",
    "Growth Rate",
    "Last 4 of SSN/Full SSN",
    "Paid To Date",
    "Last Payment Date",
    "Commission Paid to Writing Agent/Override Agents",
  ];
  const rowsData=[
    {
      "Policy Holder Name": "John Doe",
      "Policy Number": "123456789",
      "Age Of Insured": 45,
      "Effective Date": "2022-01-01",
      "Status": "Active",
      "Termination Date": "None",
      "Issued Face": "$100,000",
      "Current Face Amount": "$95,000",
      "State": "California",
      "Funeral Assigned To": "Peaceful Rest Funeral Home",
      "Writing Agent Name": "Jane Smith",
      "Agent Number": "A98765",
      "Commission Payment Frequency": "Monthly",
      "Product": "Life Insurance",
      "Plan Code": "L123",
      "Payment Period": "20 Years",
      "Plan Description": "Comprehensive Life Coverage",
      "Company Insurer": "ABC Insurance Co.",
      "Growth Rate": "5%",
      "Last 4 of SSN/Full SSN": "6789",
      "Paid To Date": "2024-12-01",
      "Last Payment Date": "2024-11-30",
      "Commission Paid to Writing Agent/Override Agents": "$1,500"
    },
    {
      "Policy Holder Name": "Alice Brown",
      "Policy Number": "987654321",
      "Age Of Insured": 50,
      "Effective Date": "2023-05-15",
      "Status": "Active",
      "Termination Date": "None",
      "Issued Face": "$150,000",
      "Current Face Amount": "$140,000",
      "State": "New York",
      "Funeral Assigned To": "Eternal Peace Services",
      "Writing Agent Name": "Michael Johnson",
      "Agent Number": "B12345",
      "Commission Payment Frequency": "Quarterly",
      "Product": "Term Life Insurance",
      "Plan Code": "T456",
      "Payment Period": "30 Years",
      "Plan Description": "Extended Coverage",
      "Company Insurer": "XYZ Insurance Co.",
      "Growth Rate": "4%",
      "Last 4 of SSN/Full SSN": "1234",
      "Paid To Date": "2024-12-15",
      "Last Payment Date": "2024-11-15",
      "Commission Paid to Writing Agent/Override Agents": "$2,000"
    },
    {
      "Policy Holder Name": "Robert Green",
      "Policy Number": "456789123",
      "Age Of Insured": 60,
      "Effective Date": "2021-03-10",
      "Status": "Inactive",
      "Termination Date": "2023-07-01",
      "Issued Face": "$200,000",
      "Current Face Amount": "$180,000",
      "State": "Florida",
      "Funeral Assigned To": "Everlasting Peace Funeral Home",
      "Writing Agent Name": "Sarah Lee",
      "Agent Number": "C98765",
      "Commission Payment Frequency": "Monthly",
      "Product": "Whole Life Insurance",
      "Plan Code": "W789",
      "Payment Period": "Lifetime",
      "Plan Description": "Premium Whole Life",
      "Company Insurer": "LifeGuard Co.",
      "Growth Rate": "6%",
      "Last 4 of SSN/Full SSN": "5678",
      "Paid To Date": "2023-06-01",
      "Last Payment Date": "2023-05-30",
      "Commission Paid to Writing Agent/Override Agents": "$1,800"
    },
    {
      "Policy Holder Name": "Linda White",
      "Policy Number": "789123456",
      "Age Of Insured": 35,
      "Effective Date": "2020-09-25",
      "Status": "Active",
      "Termination Date": "None",
      "Issued Face": "$80,000",
      "Current Face Amount": "$78,000",
      "State": "Texas",
      "Funeral Assigned To": "Heavenly Rest Home",
      "Writing Agent Name": "David Wilson",
      "Agent Number": "D54321",
      "Commission Payment Frequency": "Semi-Annual",
      "Product": "Universal Life Insurance",
      "Plan Code": "U123",
      "Payment Period": "25 Years",
      "Plan Description": "Flexible Life Plan",
      "Company Insurer": "SafeLife Insurance",
      "Growth Rate": "3%",
      "Last 4 of SSN/Full SSN": "4321",
      "Paid To Date": "2024-10-01",
      "Last Payment Date": "2024-09-30",
      "Commission Paid to Writing Agent/Override Agents": "$1,200"
    },
    {
      "Policy Holder Name": "James Black",
      "Policy Number": "321654987",
      "Age Of Insured": 55,
      "Effective Date": "2019-12-31",
      "Status": "Active",
      "Termination Date": "None",
      "Issued Face": "$300,000",
      "Current Face Amount": "$290,000",
      "State": "Illinois",
      "Funeral Assigned To": "Tranquil Times Home",
      "Writing Agent Name": "Emily Carter",
      "Agent Number": "E65432",
      "Commission Payment Frequency": "Monthly",
      "Product": "Final Expense Insurance",
      "Plan Code": "F321",
      "Payment Period": "20 Years",
      "Plan Description": "Senior Protection",
      "Company Insurer": "ElderCare Co.",
      "Growth Rate": "2%",
      "Last 4 of SSN/Full SSN": "9876",
      "Paid To Date": "2024-11-15",
      "Last Payment Date": "2024-10-15",
      "Commission Paid to Writing Agent/Override Agents": "$2,500"
    },
    {
      "Policy Holder Name": "Sophia Miller",
      "Policy Number": "852741963",
      "Age Of Insured": 40,
      "Effective Date": "2021-06-15",
      "Status": "Active",
      "Termination Date": "None",
      "Issued Face": "$120,000",
      "Current Face Amount": "$118,000",
      "State": "Nevada",
      "Funeral Assigned To": "Graceful Memorial Services",
      "Writing Agent Name": "Nathan Adams",
      "Agent Number": "F12345",
      "Commission Payment Frequency": "Quarterly",
      "Product": "Whole Life Insurance",
      "Plan Code": "WL567",
      "Payment Period": "30 Years",
      "Plan Description": "Whole Life Protection Plan",
      "Company Insurer": "Guardian Life",
      "Growth Rate": "4%",
      "Last 4 of SSN/Full SSN": "2345",
      "Paid To Date": "2024-12-01",
      "Last Payment Date": "2024-11-28",
      "Commission Paid to Writing Agent/Override Agents": "$1,700"
    },
    {
      "Policy Holder Name": "Liam Davis",
      "Policy Number": "963258741",
      "Age Of Insured": 52,
      "Effective Date": "2018-03-01",
      "Status": "Inactive",
      "Termination Date": "2023-05-10",
      "Issued Face": "$250,000",
      "Current Face Amount": "$240,000",
      "State": "Arizona",
      "Funeral Assigned To": "Serenity Funeral Home",
      "Writing Agent Name": "Hannah Brown",
      "Agent Number": "G67890",
      "Commission Payment Frequency": "Monthly",
      "Product": "Universal Life Insurance",
      "Plan Code": "UL890",
      "Payment Period": "Lifetime",
      "Plan Description": "Flexible Universal Coverage",
      "Company Insurer": "LifeSecure Co.",
      "Growth Rate": "3%",
      "Last 4 of SSN/Full SSN": "6789",
      "Paid To Date": "2023-05-01",
      "Last Payment Date": "2023-04-30",
      "Commission Paid to Writing Agent/Override Agents": "$2,300"
    },
    {
      "Policy Holder Name": "Emma Wilson",
      "Policy Number": "741963852",
      "Age Of Insured": 48,
      "Effective Date": "2020-11-25",
      "Status": "Active",
      "Termination Date": "None",
      "Issued Face": "$80,000",
      "Current Face Amount": "$78,500",
      "State": "Oregon",
      "Funeral Assigned To": "Peace Haven Funeral Services",
      "Writing Agent Name": "Olivia Martinez",
      "Agent Number": "H54321",
      "Commission Payment Frequency": "Semi-Annual",
      "Product": "Term Life Insurance",
      "Plan Code": "TL321",
      "Payment Period": "20 Years",
      "Plan Description": "Basic Term Life",
      "Company Insurer": "SafeGuard Insurance",
      "Growth Rate": "5%",
      "Last 4 of SSN/Full SSN": "1234",
      "Paid To Date": "2024-12-15",
      "Last Payment Date": "2024-12-10",
      "Commission Paid to Writing Agent/Override Agents": "$1,100"
    },
    {
      "Policy Holder Name": "Oliver Johnson",
      "Policy Number": "159753486",
      "Age Of Insured": 33,
      "Effective Date": "2023-01-10",
      "Status": "Active",
      "Termination Date": "None",
      "Issued Face": "$50,000",
      "Current Face Amount": "$49,500",
      "State": "Colorado",
      "Funeral Assigned To": "Heavenly Memories",
      "Writing Agent Name": "William Scott",
      "Agent Number": "I98765",
      "Commission Payment Frequency": "Monthly",
      "Product": "Final Expense Insurance",
      "Plan Code": "FE654",
      "Payment Period": "15 Years",
      "Plan Description": "Affordable Final Expense Plan",
      "Company Insurer": "Heritage Life Co.",
      "Growth Rate": "2%",
      "Last 4 of SSN/Full SSN": "4567",
      "Paid To Date": "2024-11-30",
      "Last Payment Date": "2024-11-29",
      "Commission Paid to Writing Agent/Override Agents": "$900"
    },
    {
      "Policy Holder Name": "Isabella Clark",
      "Policy Number": "357951486",
      "Age Of Insured": 65,
      "Effective Date": "2017-08-20",
      "Status": "Active",
      "Termination Date": "None",
      "Issued Face": "$300,000",
      "Current Face Amount": "$295,000",
      "State": "Georgia",
      "Funeral Assigned To": "Tranquility Funeral Home",
      "Writing Agent Name": "Charlotte Harris",
      "Agent Number": "J12345",
      "Commission Payment Frequency": "Quarterly",
      "Product": "Whole Life Insurance",
      "Plan Code": "WL678",
      "Payment Period": "Lifetime",
      "Plan Description": "Secure Lifetime Coverage",
      "Company Insurer": "Legacy Life",
      "Growth Rate": "4%",
      "Last 4 of SSN/Full SSN": "8901",
      "Paid To Date": "2024-12-05",
      "Last Payment Date": "2024-12-01",
      "Commission Paid to Writing Agent/Override Agents": "$3,000"
    }      
  ]
  let crumbMapping = [
    { label: "Directory", link: "/home/directory/funeral-homes" },
    { label: "Policies", link: "/home/directory/policies" },
  ];
  return (
    <Container>
      <BreadcrumbComponent breadcrumbLink={crumbMapping} />
      <SearchBar
        placeholder={`Search by funeral home, physical address, contact number etc..`}
        toggleAddEdit={toggleAddEdit}
      />
      <div className={"funeral-homes-table"}>
        <TableComponent
          columnNames={columnNames}
          toggleAddEdit={toggleAddEdit}
          rowsData={rowsData}
        />
      </div>
      {/* <PaginationComponent/> */}
      {addOrEditOpen && (
        <AddOrEditPolicies
          isOpen={addOrEditOpen}
          toggle={() => {
            toggleAddEdit("");
          }}
          mode={addOrEditOpenMode}
        />
      )}
    </Container>
  );
};
export default Policies;
