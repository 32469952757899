/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useSetLoading } from '../../common/hooks/appearance';
import './guides.scss';
import { Button } from 'reactstrap';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import Card from '@mui/joy/Card';
import Divider from '@mui/material/Divider';
import CardCover from '@mui/joy/CardCover';
import { getVideoList } from '../../api/guiders';
import checkIcons from '../../assets/icons/checkIcons.svg';
import playIcons from '../../assets/icons/playIcons.svg';
import ViewGuidesVideo from './view-guides-video/view-guides-video';
import UploadGuidesVideo from './upload-guides-video/upload-guides-video';
import AlertComponent from '../../common/components/Alert/Alert';
import BreadcrumbComponent from '../../common/components/breadcrumb-component/breadcrumb-component';

const Guides: React.FC = () => {
  const crumbMapping = [{ label: 'Guides', link: '/guides' }];
  const categoriesListAll = [
    {
      name: 'All Videos',
      selected: true,
      videoCount: 0,
      isDisabled: false,
    },
    {
      name: 'Contracts',
      selected: false,
      videoCount: 0,
      isDisabled: false,
    },
    {
      name: 'Dashboard',
      selected: false,
      videoCount: 0,
      isDisabled: false,
    },
    {
      name: 'Directory',
      selected: false,
      videoCount: 0,
      isDisabled: false,
    },
    {
      name: 'Leads',
      selected: false,
      videoCount: 0,
      isDisabled: false,
    },
    {
      name: 'Miscellaneous',
      selected: false,
      videoCount: 0,
      isDisabled: false,
    },
    {
      name: 'Reports',
      selected: false,
      videoCount: 0,
      isDisabled: false,
    },
    {
      name: 'Search & Filter',
      selected: false,
      videoCount: 0,
      isDisabled: false,
    },
    {
      name: 'Users',
      selected: false,
      videoCount: 0,
      isDisabled: false,
    },
    {
      name: 'Funeral Services',
      selected: false,
      videoCount: 0,
      isDisabled: true,
    },
    {
      name: 'Orders',
      selected: false,
      videoCount: 0,
      isDisabled: true,
    },
    {
      name: 'Packages',
      selected: false,
      videoCount: 0,
      isDisabled: true,
    },
    {
      name: 'Payments',
      selected: false,
      videoCount: 0,
      isDisabled: true,
    },
  ];

  const [categoriesSelected, setCategoriesSelected] = useState<any>(categoriesListAll[0]);
  const [categoriesList, setCategoriesList] = useState<any[]>(categoriesListAll);
  const [uploadVideo, setUploadVideo] = useState<any>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<any>('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const [showVideo, setShowVideo] = useState<any>({
    isShowVideo: false,
    label: '',
    url: '',
    thumbnail: '',
  });

  const [categoriesSelectedVideos, setCategoriesSelectedVideos] = useState<any[]>([]);

  const fetchVideoList = async (searchText = '', categoryName: any, filters?: any) => {
    setIsLoading(true);
    try {
      const response = await getVideoList(searchText, categoryName === 'All Videos' ? '' : categoryName, filters);
      setCategoriesSelected({
        name: categoryName,
        selected: true,
        videoCount: response?.data?.data?.totalRecords,
        isDisabled: false,
      });
      const formatData = response?.data?.data?.data?.map((data: any) => {
        return {
          categoriesName: data?.name,
          categoriesType: data?.category,
          url: `${process.env.REACT_APP_S3_BULK_UPLOAD_BUCKET_DOMAIN}/${data?.video_key}`,
          thumbnail: `${process.env.REACT_APP_S3_BULK_UPLOAD_BUCKET_DOMAIN}/${data?.thumbnail_key}`,
        };
      });
      setCategoriesSelectedVideos(formatData);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchVideoList(searchText, categoriesSelected?.name);
  }, []);

  const handleSearch = useCallback(() => {
    if (searchText?.trim() === '!') {
      setAlertMessage('Search cannot be performed with just "!"');
      setAlertType('info');
      setAlertOpen(true);
      return;
    }

    const value = searchText.trim();
    if (value) {
      handleUpdateTabSelect('All Videos');
      fetchVideoList(value, 'All Videos');
    }
  }, [searchText]);

  const handleSearchChange = (event: any) => {
    const value = event.target.value || '';
    if (value?.trim()?.length > 0 || value === '') {
      setSearchText(value);
    }
    if (!value?.trim()) {
      handleUpdateTabSelect('All Videos');
      fetchVideoList(value, 'All Videos');
    }
  };

  const handleSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && searchText?.trim()) {
      handleSearch();
    }
  };

  const handleUpdateTabSelect = (tabName: any) => {
    const updatedTab = categoriesList?.map((data: any) => {
      return {
        ...data,
        selected: data?.name === tabName,
      };
    });
    setCategoriesList(updatedTab);
  };

  const handleListSelected = (data: any, index: number) => {
    setSearchText('');
    const updatedData = categoriesList?.map((d, i) => (i === index ? { ...d, selected: true } : { ...d, selected: false }));
    setCategoriesList(updatedData);
    setCategoriesSelected(data);
    const categoriesName: any = `${data?.name}`;
    fetchVideoList(searchText, categoriesName);
  };

  const handleUploadPopup = () => {
    setUploadVideo(!uploadVideo);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const showLoading = useMemo(() => isLoading, [isLoading]);
  useSetLoading(showLoading);

  return (
    <div>
      <AlertComponent message={alertMessage} type={alertType} open={alertOpen} onClose={handleAlertClose} />
      <BreadcrumbComponent breadcrumbLink={crumbMapping} />
      <div className="guides">
        <div className="guides-search-videos">
          <div className={'search-bar-input-text'}>
            {/* <InputLabel shrink htmlFor="bootstrap-input">
                        </InputLabel> */}
            <FormControl variant="outlined">
              <OutlinedInput
                id="outlined-adornment-password"
                type={'search'}
                placeholder={'Search Videos'}
                onChange={handleSearchChange}
                onKeyDown={handleSearchKeyDown}
                value={searchText}
                endAdornment={
                  <InputAdornment position="end">
                    <Divider orientation="vertical" flexItem className={'search-icon-divider'} />
                    <SearchIcon onClick={handleSearch} className={'search-icon'} titleAccess="Click to Search" />
                  </InputAdornment>
                }
                label={''}
              />
            </FormControl>
          </div>
          <div className="add-new-video-btn">
            <Button className={'btn'} onClick={handleUploadPopup}>
              Add New Video
            </Button>
          </div>
        </div>
        <div className="guides-video-section">
          <div className="guides-video-section-right">
            <div className="guides-video-section-right-container">
              <div className="guides-video-section-right-container-heading">Categories</div>
              <List className="guides-video-section-right-container-list">
                {categoriesList?.map((data, index) => (
                  <ListItem
                    endAction={
                      data?.selected ? (
                        <div className="guides-video-section-right-container-list-check-icons">
                          {' '}
                          <img src={checkIcons} alt="Close" />{' '}
                        </div>
                      ) : (
                        <></>
                      )
                    }
                  >
                    <ListItemButton disabled={data?.isDisabled} selected={data?.selected} onClick={() => handleListSelected(data, index)}>
                      {data?.name}
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
          <div className="guides-video-section-left">
            <div className="guides-video-section-left-container-heading">
              <div className="guides-video-section-left-container-heading-label">{categoriesSelected?.name}</div>
              <div className="guides-video-section-left-container-heading-count">{categoriesSelected?.videoCount}</div>
            </div>
            <div className="guides-video-section-right-container-videos">
              {categoriesSelectedVideos?.map((data: any, index: any) => (
                <div className="guides-video-section-right-container-videos-cards">
                  <div className="">
                    <Card className="guides-video-section-right-container-videos-cards-video">
                      <CardCover>
                        <video loop width="620" height="640" controls id={index} src={data?.url} poster={data?.thumbnail} />
                        {/* <source
                                                        
                                                        type="video/mp4"
                                                        id={index}
                                                    /> */}
                        {/* </video> */}
                      </CardCover>
                    </Card>
                  </div>
                  <div className="guides-video-section-right-container-videos-cards-vide-info">
                    <div className="guides-video-section-right-container-videos-cards-vide-info-label-duration">
                      <div className="guides-video-section-right-container-videos-cards-vide-info-label">{data?.categoriesName}</div>
                      <div className="guides-video-section-right-container-videos-cards-vide-info-duration">{data?.categoriesType}</div>
                    </div>
                    <div className="guides-video-section-right-container-videos-cards-vide-info-icon">
                      <img
                        src={playIcons}
                        alt="Close"
                        onClick={() => {
                          setShowVideo({
                            isShowVideo: true,
                            label: data?.categoriesName,
                            url: data?.url,
                            thumbnail: data?.thumbnail,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {showVideo?.isShowVideo ? (
        <ViewGuidesVideo
          onClose={() =>
            setShowVideo({
              isShowVideo: !showVideo?.isShowVideo,
              label: '',
              url: '',
              thumbnail: '',
            })
          }
          url={showVideo?.url}
          label={showVideo?.label}
          thumbnail={showVideo?.thumbnail}
        />
      ) : (
        ''
      )}
      {uploadVideo ? (
        <UploadGuidesVideo
          onClose={handleUploadPopup}
          setIsLoading={setIsLoading}
          refreshData={() => {
            fetchVideoList(searchText, categoriesSelected?.name);
          }}
        />
      ) : (
        ''
      )}
    </div>
  );
};
export default Guides;
