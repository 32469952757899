import React from "react";
import { Table } from "reactstrap";
import "./dynamic-table.scss";
import TableHeaderArrow from "../../../assets/icons/table-header-arrow.svg";
import SorterAsc from "../../../assets/icons/sorterasc.svg";
import SorterDesc from "../../../assets/icons/sorterdesc.svg";
import RenderCellContent from "../table-component/render-cell-content";
import DownloadIcon from "../../../assets/icons/download.svg";

interface Column<T> {
  header: string;
  accessor: (row: T) => React.ReactNode;
}

interface DynamicTableProps<T> {
  columns: Array<Column<T>>;
  data: T[];
  handleSort?: (columnName: string) => void;
  sortedColumn?: string;
  sortDirection?: string;
  setIsFilterApplied: React.Dispatch<React.SetStateAction<boolean>>;
}

const DynamicTable = <T,>({
  columns,
  data,
  handleSort,
  sortedColumn,
  sortDirection,
  setIsFilterApplied
}: DynamicTableProps<T>) => {
  const getSortArrowIcon = (columnName: string) => {
    if (columnName === sortedColumn) {
      setIsFilterApplied(true);
      return sortDirection === "asc" ? SorterAsc : SorterDesc;
    }
    return TableHeaderArrow;
  };

  const renderFileDownloadLink = (value: string) => {
    return value ? (
      <a
        href={`${process.env.REACT_APP_S3_BULK_UPLOAD_BUCKET_DOMAIN}/${value}`}
        rel="noopener noreferrer"
        download
      >
        <img src={DownloadIcon} alt="Download" className="download-icon" />
      </a>
    ) : (
      <span>-</span>
    );
  };
  return (
    <Table className="table-container" responsive>
      <thead className="table-header-row">
        <tr>
          <th>S.No</th>
          {columns.map((column, index) => (
            <th key={index}>
              <div className="table-header">
                <div>{column.header}</div>
                <div style={{ cursor: "pointer" }}>
                  {column.header !== "Original File" &&
                    column.header !== "Output File" && (
                      <img
                        src={getSortArrowIcon(column.header)}
                        alt="Sort Icon"
                        onClick={() => handleSort?.(column.header)}
                      />
                    )}
                </div>
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="table-body">
        {data.length > 0 ? (
          data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td>{rowIndex + 1}</td>
              {columns.map((column, colIndex) => (
                <td key={colIndex}>
                  {column.header === "Original File" ||
                  column.header === "Output File" ? (
                    renderFileDownloadLink(column.accessor(row) as string)
                  ) : (
                    <RenderCellContent text={column.accessor(row)} />
                  )}
                </td>
              ))}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={columns.length + 1} className="no-records">
              No Records Found
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default DynamicTable;
