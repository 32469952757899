import React from 'react';
import { Row, Table } from 'reactstrap';
import editIcon from '../../../assets/icons/edit.svg';
import download from '../../../assets/icons/download.svg';
import menuIcon from '../../../assets/icons/menuIcon.svg';
import { TableComponentProps } from './table-component-props';
import TableHeaderArrow from '../../../assets/icons/table-header-arrow.svg';
import SorterAsc from '../../../assets/icons/sorterasc.svg';
import SorterDesc from '../../../assets/icons/sorterdesc.svg';
import styles from '../table-component/table-component.module.scss';
import RenderCellContent from './render-cell-content';
import viewIcon from '../../../assets/icons/viewIcon.svg';
import { saveAs } from 'file-saver';

const TableComponent: React.FC<TableComponentProps> = ({
  columnNames,
  rowsData = [],
  toggleAddEdit,
  handleEditClick,
  handleViewClick,
  handleSort,
  sortedColumn,
  sortDirection,
  contracts,
  onMenuClick,
  onImageClick,
}) => {
  const getSortArrowIcon = (columnName: string) => {
    if (columnName === sortedColumn) {
      return sortDirection === 'asc' ? SorterAsc : SorterDesc;
    }
    return TableHeaderArrow;
  };

  const getStatusClass = (status: string) => {
    switch (status) {
      case 'New':
        return 'status-new';
      case 'Active':
        return 'status-active';
      case 'Pending':
        return 'status-pending';
      case 'Expired':
        return 'status-expired';
      default:
        return '';
    }
  };
  return (
    <>
      <Row className={styles.row}>
        <div className={styles['directory-table-container']}>
          <Table hover className={styles['table-container']} bordered borderless={true} responsive>
            <thead>
              <tr>
                {columnNames.map((columnName, index) => (
                  <th key={index}>
                    <div className={styles['header-column-names-arrow']}>
                      {index !== 0 ? (
                        columnName === 'Download' ? (
                          <div>{columnName}</div>
                        ) : (
                          <>
                            <div>{columnName}</div>
                            <div style={{ cursor: 'pointer' }}>
                              <img src={getSortArrowIcon(columnName)} alt="Table Header Arrow" onClick={() => handleSort?.(columnName)} />
                            </div>
                          </>
                        )
                      ) : (
                        <div className={styles['header-first-column']}>{columnName}</div>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {(rowsData ?? []).map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {columnNames.map((columnName, colIndex) => {
                    const statusClass = columnName === 'Status' && contracts ? `contracts-status ${getStatusClass(row[columnName])}` : '';
                    return (
                      <td
                        key={colIndex}
                        className={`${colIndex < 2 ? styles['sticky-column'] : ''} ${
                          colIndex === 1 && columnName !== 'Signature Name' && columnName !== 'Template ID'
                            ? styles['sticky-column-hover']
                            : ''
                        } ${statusClass}`}
                        onClick={() => {
                          if (colIndex === 1) {
                            handleViewClick?.(row.id, row['Funeral Home']);
                          }
                        }}
                        rel="noopener noreferrer"
                        // download
                      >
                        {colIndex === 0 ? (
                          contracts ? (
                            <img
                              className={`${styles['edit-icon']} edit-icon-hover`}
                              src={menuIcon}
                              alt="menu edit icon"
                              aria-controls={'positioned-menu'}
                              aria-haspopup="true"
                              aria-expanded={'true'}
                              aria-hidden
                              role={'button'}
                              onClick={(event) => onMenuClick?.(event, row.id)}
                            />
                          ) : (
                            <img
                              className={`${styles['edit-icon']} edit-icon-hover`}
                              src={editIcon}
                              alt="Edit Icon"
                              onClick={() => {
                                toggleAddEdit?.('edit', row.id);
                                handleEditClick?.(row.id, row['Funeral Home']);
                              }}
                            />
                          )
                        ) : columnName === 'Download' ? (
                          <a
                            href={row['file_url'] ? row['file_url'] : '#'}
                            onClick={(e) => {
                              if (!row['file_url']) {
                                e.preventDefault();
                                alert('File URL is not available.');
                              } else {
                                e.preventDefault();
                                saveAs(row['file_url']);
                              }
                            }}
                            rel="noopener noreferrer"
                            download
                          >
                            <img className={`${styles['download-icon']}`} src={download} alt="Download Icon" />
                          </a>
                        ) : columnName === 'Signature Image' ? (
                          <img
                            src={viewIcon}
                            alt="View"
                            aria-controls={'positioned-image'}
                            aria-haspopup="true"
                            aria-expanded={'true'}
                            role={'button'}
                            className={`${styles['contract-signature']} edit-icon-hover`}
                            onClick={(event) => onImageClick?.(event, row['Signature Image'])}
                          />
                        ) : columnName === 'Website Url' ? (
                          row[columnName] ? (
                            <a href={row[columnName]} target="_blank" rel="noopener noreferrer">
                              {row[columnName]}
                            </a>
                          ) : (
                            <span>-</span>
                          )
                        ) : (
                          <RenderCellContent text={row[columnName]} />
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Row>
    </>
  );
};

export default TableComponent;
