import React, { useState } from "react";
import {
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Button,
} from "reactstrap";
import phone from 'phone';
import * as Yup from "yup";
import { Formik, Form } from "formik";
import closeIcon from "../../../assets/icons/closeIcon.svg";
import "../add-or-edit-directories.scss";
import { AddOrEditComponentProps } from "../types/add-or-edit-props";
import InputComponent from "../../../common/components/input-component/input-component";
import DeleteConfirmationPopup from "../delete-confirmation-popup/delete-confirmation-popup";
import { createRegionalRep, deleteRegionalRep, editRegionalRep } from "../../../api/directories/regionalRep";
import PhoneNumberComponent from "../../../common/components/phone-number-component/phone-number-component";
import { concatenatePhoneNumber, splitPhoneNumber } from "../utils";
import AlertComponent from "../../../common/components/Alert/Alert";
import DirectoryAddressComponent from "../../../common/components/directory-address-component/directory-address-component";
import { useShouldDisplayField } from "../../../common/hooks/useShouldDisplayField";
import { emailValidationRegex } from "../../../constants/regex-string";

const AddOrEditRegionalReps: React.FC<AddOrEditComponentProps> = ({
  isOpen,
  toggle,
  mode,
  editData,
  refreshData,
}) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<
    "success" | "error" | "info" | "warning"
  >("success");
  const [isDeletePopupOpen, setDeletePopupOpen] = useState<boolean>(false);
  const [deleteRegionalRepId, setDeleteRegionalRepId] = useState<number | null>(null);
  const shouldDisplayField = useShouldDisplayField();

  const handleDelete = async (deleteRegionalRepId: number | null) => {
    try {
      if (deleteRegionalRepId === null) {
        return;
      }
      const response = await deleteRegionalRep(deleteRegionalRepId);
      if (response.data.success && refreshData) {
        refreshData()
      }
      setDeletePopupOpen(false);
      toggle()
    } catch (error) {
      setAlertMessage("Failed to delete RegionalRep.");
      setAlertType("error");
      setAlertOpen(true);
    }
  };
  const initialValues = editData && mode === "edit"
    ? {
      name: editData.name,
      address: {
        id: editData.address.id,
        street: editData.address.street || "",
        zipcode: editData.address.zipcode || "",
        city: editData?.address?.City?.name || "",
        state: editData?.address?.State?.name || "",
        selectedAddressCity: editData?.address?.City,
        selectedAddressState: editData?.address?.State,
        addressCity: null,
        addressState: null,
      },
      email: editData.email,
      phoneNumber: splitPhoneNumber(editData.phone_number),
      region: editData.region,
      note: editData?.note || "",
    }
    : {
      name: "",
      address: {
        street: "",
        city: "",
        state: "",
        zipcode: "",
        selectedAddressCity: null,
        selectedAddressState: null,
        addressCity: null,
        addressState: null,
      },
      email: "",
      phoneNumber: { countryCode: "+1", phoneNumber: "" },
      region: "",
    };
  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Mandatory Field*")
      .matches(/^[A-Za-z ´',-.()!&Åë]+$/, 'Name can only contain alphabets, spaces, and special characters')
      .max(200, "Name can't be longer than 200 characters.")  
      .min(2, "Name must be at least 2 characters long"),
    address: Yup.object({
      street: Yup.string().required("Mandatory Field*"),
      zipcode: Yup.string()
        .required("Mandatory Field*")
        .matches(/^\d{5}$/, "Invalid zipcode format"),
      selectedAddressCity: Yup.object().required("Mandatory Field*"),
      selectedAddressState: Yup.object().required("Mandatory Field*"),
    }),
    email: Yup.string()
      .required("Mandatory Field*")
      .max(200, "Email must be less than 200 characters")
      .matches(emailValidationRegex, { message: "Invalid email address. Please provide a valid email."}),
    phoneNumber: Yup.object({
      countryCode: Yup.string().matches(/^\+\d{1,4}$/),
      phoneNumber:  Yup.string()
        .matches(/^\d{10}$/, `Number format is invalid`)
        .test('is-valid-phone', `Invalid number`, (value, allValue) => {
          if (!value) return true;
          const { isValid } = phone(`${allValue?.parent?.countryCode || ''}-${value}`);
          return isValid;
        }).required("Mandatory Field*"),
    }),
    region: Yup.string()
      .matches(/^[A-Za-z ´',-.()!&Åë]+$/, 'Region can only contain alphabets, spaces, and special characters')
      .max(200, "Name can't be longer than 200 characters.")
      .nullable(),
  });

  const onSubmit = async (
    values: typeof initialValues,
    { resetForm }: { resetForm: () => void }
  ) => {
    try{
    const regionalRepData = {
      name: values.name,
      address: {
        id: mode === "edit" ? editData?.address.id : undefined,
        street: values.address.street,
        city: {
          id: values?.address?.selectedAddressCity?.id || editData?.address.City.id,
          name: values?.address?.selectedAddressCity?.name || editData?.address.City.name
        },
        state: {
          id: values?.address?.selectedAddressState?.id || editData?.address.State.id,
          name: values?.address?.selectedAddressState?.name || editData?.address.State.name,
        },
        zipcode: values.address.zipcode,
      },
      email: values.email,
      phoneNumber: concatenatePhoneNumber(values.phoneNumber),
      region: values.region||null,
      note: values?.note || null,
    };
      if (editData && mode === "edit") {
        const response = await editRegionalRep(editData.id, regionalRepData);
        if (response.data.success && refreshData) {
          refreshData()
        }
      } else {
        const response = await createRegionalRep(regionalRepData);
        if (response.data.success && refreshData) {
          refreshData()
        }
      }
      resetForm();
      toggle();
    } catch (error: unknown) {
      console.error("Error submitting form:", error);
      let errorMessage = "An unexpected error occurred."; 
      if (typeof error === "object" && error !== null) {
        if ("response" in error && (error as any).response?.data) {
          const responseData = (error as any).response.data;
          errorMessage = responseData?.message || errorMessage;
        } else if ("message" in error) {
          errorMessage = (error as Error).message;
        }
      }
      setAlertMessage(errorMessage);
      setAlertType("error");
      setAlertOpen(true);
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  return (
    <>
      <AlertComponent
        message={alertMessage}
        type={alertType}
        open={alertOpen}
        onClose={handleAlertClose}
      />
      <div className="add-or-edit-offcanvas">
        <Offcanvas isOpen={isOpen} direction="end" className="offcanvas">
          <OffcanvasHeader className="offcanvas-header">
            <div className="header-div">
              <h2 className="mb-0"> {mode === "edit" ? "Edit" : "Add"} Regional Rep</h2>
              <button onClick={toggle}>
                <img src={closeIcon} alt="Close" />
              </button>
            </div>
          </OffcanvasHeader>
          <OffcanvasBody className="offcanvas-body">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form className="add-or-edit-form">
                  <InputComponent
                    fieldName="name"
                    label="Name"
                    placeholder="Enter name"
                    optional={false}
                    isAlpha
                  />
                  <DirectoryAddressComponent
                    addressLabel="Address"
                    streetFieldName="address.street"
                    zipCodeFieldName="address.zipcode"
                    setCity={(city)=>setFieldValue('address.addressCity', city)}   
                    setState={(state)=>setFieldValue('address.addressState', state)} 
                    selectedState={values?.address?.selectedAddressState}
                    selectedCity={values?.address?.selectedAddressCity}
                    setSelectedCity = {(city)=>setFieldValue('address.selectedAddressCity', city)} 
                    setSelectedState = {(state)=>setFieldValue('address.selectedAddressState', state)}
                    cityFieldName="address.selectedAddressCity"
                    stateFieldName="address.selectedAddressState"
                    optional={false}
                    // mode={mode}
                  />

                      <InputComponent
                        fieldName="email"
                        label="Email Address"
                        placeholder="Enter email address"
                        optional={false}
                        isEmail
                      />
                      <PhoneNumberComponent
                        label="Cell Phone Number"
                        fieldName="phoneNumber.phoneNumber"
                        countryCodeFieldName="phoneNumber.countryCode"
                        placeholder="Enter cell phone number"
                        optional={false}
                      />
                      <InputComponent
                        fieldName="region"
                        label="Region/State/Agencies Assigned"
                        placeholder="Enter region/state/agencies assigned"
                        isAlpha
                      />
                      {shouldDisplayField &&
                        <InputComponent
                          fieldName="note"
                          label="Note(admins only)"
                          placeholder="Enter Note"
                          inputIsTextArea={true}
                          optional={true}
                        />}
                      <div className="form-button-container">
                        {editData && (
                          <Button
                            type="button"
                            className="delete-button"
                            onClick={() => {
                              setDeleteRegionalRepId(editData.id);
                              setDeletePopupOpen(true);
                            }}
                          >
                            Delete
                          </Button>
                        )}
                        <button
                          type="button"
                          className={`cancel-button${mode === "edit" ? "-in-edit" : ""}`}
                          onClick={toggle}
                        >
                          Cancel
                        </button>
                        <button
                          className={mode === "edit" ? "save-button-changes" : "save-button"}
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {mode === "edit" ? "Save Changes" : "Save"}
                        </button>
                      </div>
                  </Form>
                  )}
              </Formik>
            </OffcanvasBody>
        </Offcanvas>
      </div>
      <DeleteConfirmationPopup
        isOpen={isDeletePopupOpen}
        onClose={() => setDeletePopupOpen(!isDeletePopupOpen)}
        handleDelete={handleDelete}
        deleteId={deleteRegionalRepId}
        label="Regional Rep"
      />
    </>
  );
};
export default AddOrEditRegionalReps;
