/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import AuthorizedRepresentativeTable from './authorized-representative-table/authorized-representative-table';
import { getAllAuthorizedRepresentativeDetails, removeAuthorizedRepresentativeDetails } from '../../../../../../../api/funeralhomes';
import { splitPhoneNumber } from '../../../../../utils';
import { columnNames } from './authorized-representative-table.constant';
import DeleteConfirmationPopup from '../delete-confirmation-popup/delete-confirmation-popup';
import AlertComponent from '../../../../../../../common/components/Alert/Alert';
import './authorized-representative.scss';

const AuthorizedRepresentative: any = (props: any) => {
  const { funeralHomeId, setLoading } = props;
  const [rowsData, setRowsData] = useState<any[]>([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const [isDeletePopupOpen, setDeletePopupOpen] = useState<boolean>(false);
  const [deleteAdditionalLocationId, setDeleteAdditionalLocationId] = useState<number | null>(null);

  const handleEditClick = (id: any) => {
    const updatedrowsData = rowsData
      ?.map?.((data: any) => {
        return {
          ...data,
          isEdit: data?.id === id ? !data?.isEdit : false,
        };
      })
      ?.filter((data) => !data?.isNewAdded);

    setRowsData(updatedrowsData);
  };

  const handleAddMoreAuthorisedRepresentatives = (id: any) => {
    const updatedrowsData = [
      ...rowsData
        ?.map?.((data: any) => {
          return {
            ...data,
            isEdit: data?.id === id ? !data?.isEdit : false,
          };
        })
        ?.filter((data) => !data?.isNewAdded),
      {
        Edit: '',
        name: '',
        contactNumber: {
          countryCode: '+1',
          phoneNumber: '',
        },
        faxNumber: '',
        email: '',
        isEdit: true,
        isNewAdded: true,
      },
    ];
    setRowsData(updatedrowsData);
  };

  const handleGetAuthorisedRepresentative = async () => {
    try {
      setLoading(true);
      const getAPIDetails = await getAllAuthorizedRepresentativeDetails(funeralHomeId);
      setRowsData(
        getAPIDetails?.data?.data?.rows?.map((data: any) => {
          return {
            Edit: '',
            id: data?.id,
            name: data?.name,
            phoneNumber: data?.phone_number || '-',
            contactNumber: splitPhoneNumber(data?.phone_number),
            faxNumber: data?.fax_number,
            email: data?.email_address,
            isEdit: false,
          };
        }),
      );
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const handleDeleteClick = async (id: any) => {
    setDeleteAdditionalLocationId(id);
    setDeletePopupOpen(true);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await removeAuthorizedRepresentativeDetails(funeralHomeId, deleteAdditionalLocationId);
      setLoading(false);
      setDeleteAdditionalLocationId(null);
      setDeletePopupOpen(false);
      handleGetAuthorisedRepresentative();
    } catch {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetAuthorisedRepresentative();
  }, [funeralHomeId]);

  return (
    <div className="view-funeral-homes-right-container">
      <AuthorizedRepresentativeTable
        columnNames={columnNames}
        rowsData={rowsData}
        handleEditClick={handleEditClick}
        handleDeleteClick={handleDeleteClick}
        handleAddMoreAuthorisedRepresentatives={handleAddMoreAuthorisedRepresentatives}
        setLoading={setLoading}
        funeralHomeId={funeralHomeId}
        handleGetAuthorisedRepresentative={handleGetAuthorisedRepresentative}
        setAlertOpen={setAlertOpen}
        setAlertType={setAlertType}
        setAlertMessage={setAlertMessage}
      />
      <DeleteConfirmationPopup
        isOpen={isDeletePopupOpen}
        onClose={() => setDeletePopupOpen(!isDeletePopupOpen)}
        handleDelete={handleDelete}
        label="Authorised Representative"
      />
      <AlertComponent message={alertMessage} type={alertType} open={alertOpen} onClose={() => setAlertOpen(false)} />
    </div>
  );
};

export default AuthorizedRepresentative;
