import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import styles from './positioned-menu.module.scss';

interface MenuOption {
  label: string;
  onClick: () => void;
  icon?: string; 
}

interface PositionedMenuProps {
  anchorEl: HTMLElement | null;
  menuOptions: MenuOption[];
  anchorOrigin?: { vertical: 'top' | 'bottom'; horizontal: 'left' | 'right' };
  transformOrigin?: { vertical: 'top' | 'bottom'; horizontal: 'left' | 'right' };
  onClose: () => void;
}

const PositionedMenu: React.FC<PositionedMenuProps> = ({
  anchorEl,
  menuOptions,
  anchorOrigin = { vertical: 'top', horizontal: 'left' },
  transformOrigin = { vertical: 'top', horizontal: 'left' },
  onClose,
}) => {
  const open = Boolean(anchorEl);

  return (
    <div>
      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-menu-button"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        className={styles["menu"]}
      >
       {menuOptions.map((option, index) => {
          const iconClassName =
            option.label === 'Delete Signature' || 'Delete Template' ? styles.deleteIcon : option.label === 'View Signature' || 'View Template' ? styles.viewIcon : styles.defaultIcon;

          return (
            <MenuItem
              key={index}
              onClick={() => {
                option.onClick();
              }}
            >
              <div className={styles["menu-option"]}>
              {option.icon && (
                <img
                  src={option.icon}
                  alt={option.label}
                  className={iconClassName}
                />
              )}
              <div className={styles["option-label"]}>{option.label}</div>
              </div>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default PositionedMenu;