/* eslint-disable  @typescript-eslint/no-explicit-any */
export const dropDowncustomStyles = {
  control: (provided: any) => ({
    ...provided,
    width: '100%',
    height: '51px',
    borderColor: '#ffffff',
    backgroundColor: '#F7F7F7',
    boxShadow: '#ffffff',
    '&:hover': {
      borderColor: '#ffffff',
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    width: '100%',
  }),
  option: (styles: any, state: any) => ({
    ...styles,
    backgroundColor: state.isSelected ? '#887952' : 'white',
    ':hover': {
      backgroundColor: state.isSelected ? '#887952' : '#EEEE',
    },
  }),
};

export const columnNames: any = [
  {
    header: 'Edit',
    data: 'edit',
  },
  {
    header: 'Email',
    data: 'email',
  },
  {
    header: 'First Name',
    data: 'firstName',
  },
  {
    header: 'Last Name',
    data: 'lastName',
  },
  {
    header: 'Role',
    data: 'role',
  },
];
