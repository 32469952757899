import axios from '.'; 

export const uploadFile = (formData: FormData) => {
    return axios({
        method: 'POST',
        url: '/s3/upload',
        data: formData, 
        headers: {
            'Content-Type': 'multipart/form-data', 
        },
    });
};
