import axios from '.';

export const addContractTemplate = (data: any) => {
  return axios({
    method: 'POST',
    url: 'contracts/template',
    data
  });
};

export const getContractTemplates = (
  page: number,
  limit: number,
  sortBy?: string,
  sortDirection?: string,
  searchText?: string
) => {
  return axios({
    url: `contracts/template`,
    method: "GET",
    params: {
      offset: (page - 1) * limit,
      limit: limit,
      sortBy: sortBy,
      sortDirection: sortDirection,
      searchText: searchText,
    },
  });
};

export const instantFileUpdate = (id:number, fileData: any) =>{
  return axios({
    method: "POST",
    url: `contracts/template/upload/${id}`,
    data:fileData,
  });
}

export const updateContractTemplate = (id: number, data: any) => {
    return axios({
      method: "PUT",
      url: `/contracts/template/${id}`,
      data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

export const removeContractTemplate = ( id: number) => {
    return axios({
      url: `/contracts/template/${id}`,
      method: 'DELETE',
    });
  };


export const getCategories = () => {
  return axios({
    url: `/contracts/directoryCategories`,
    method: "GET",
  });
};

