import React, { useEffect, useState, useMemo } from "react";
import { FormGroup, Label } from "reactstrap";
import { SelectDropdownComponent } from "../../common/components";
import { selectDropDowncustomStyles } from "./agents/add-or-edit-agents.utils";
import { getAutoFillLocation } from "../../api/profile";
import { useSetLoading } from "../../common/hooks/appearance";

interface LocationFiltersProps {
  module?: string;
  selectedCountries?: any[];
  selectedStates?: any[];
  selectedCities?: any[];
  setFieldValue?: (field: string, value: any) => void;
  resetLocationFilters?: any;
  appliedFiltersFlag?: boolean;
}

const LocationFilters: React.FC<LocationFiltersProps> = ({
  module,
  selectedCountries = [],
  selectedStates = [],
  selectedCities = [],
  setFieldValue,
  resetLocationFilters,
  appliedFiltersFlag = false,
}) => {
  const [countryOptions, setCountryOptions] = useState<any[]>([]);
  const [stateOptions, setStateOptions] = useState<any[]>([]);
  const [cityOptions, setCityOptions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await getAutoFillLocation(
          undefined,
          undefined,
          module
        );
        const data = response.data.data;
        const countries = data.map((item: any) => ({
          label: item.name,
          value: item.id,
          states: item.States || [],
        }));
        setCountryOptions(countries);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [module]);

  useEffect(() => {
    if (selectedCountries && selectedCountries.length > 0) {
      const selectedCountryData = countryOptions.filter((country) =>
        selectedCountries.includes(country.value)
      );
      const states = selectedCountryData.flatMap((item: any) => {
        if (item.states && Array.isArray(item.states)) {
          return item.states.map((state: any) => ({
            label: state.name,
            value: state.id,
            cities: state.Cities || [],
          }));
        }
        return [];
      });
      
      setStateOptions(states);
    } else {
      setStateOptions([]);
      setCityOptions([]);
    }
  }, [selectedCountries, countryOptions]);

  useEffect(() => {
    if (selectedStates && selectedStates.length > 0) {
      const selectedStatesData = stateOptions.filter((state) =>
        selectedStates.includes(state.value)
      );
      const cities = selectedStatesData.flatMap((item: any) => {
        if (item.cities && Array.isArray(item.cities)) {
          return item.cities.map((city: any) => ({
            label: city.name,
            value: city.id,
          }));
        }
        return [];
      });
      setCityOptions(cities);
    } else {
      setCityOptions([]);
    }
  }, [selectedStates, stateOptions]);

  useEffect(() => {
    setStateOptions([]);
    setCityOptions([]);
  }, [resetLocationFilters]);

  const handleCountryChange = (selected: any) => {
    setFieldValue &&
      setFieldValue(
        "countryIds",
        selected.map((item: any) => item.value)
      );
    if (selected && selected.length > 0) {
      const newStates = selected.flatMap((item: any) => {
        if (item.states && Array.isArray(item.states)) {
          return item.states.map((state: any) => ({
            label: state.name,
            value: state.id,
            cities: state.Cities || [],
          }));
        }
        return [];
      });
      setStateOptions((prevState) => [...prevState, ...newStates]);
      setCityOptions([]);
    } else {
      setStateOptions([]);
      setCityOptions([]);
    }
  };

  const handleStateChange = (selected: any) => {
    setFieldValue &&
      setFieldValue(
        "stateIds",
        selected.map((item: any) => item.value)
      );
    if (selected && selected.length > 0) {
      const newCities = selected.flatMap((item: any) => {
        if (item.cities && Array.isArray(item.cities)) {
          return item.cities.map((city: any) => ({
            label: city.name,
            value: city.id,
          }));
        }
        return [];
      });
      setCityOptions((prevState) => [...prevState, ...newCities]);
    } else {
      setCityOptions([]);
    }
  };

  const handleCityChange = (selected: any) => {
    setFieldValue &&
      setFieldValue(
        "cityIds",
        selected.map((item: any) => item.value)
      );
  };

  const showLoading = useMemo(() => isLoading, [isLoading]);
  useSetLoading(showLoading);

  return (
    <>
      <FormGroup className="filter-form-group">
        <Label className="custom-form-label">Country</Label>
        <SelectDropdownComponent
          placeholder="Select Countries"
          options={countryOptions}
          customStyles={selectDropDowncustomStyles}
          isMulti={true}
          isSearchable={true}
          isClearable={true}
          value={
            selectedCountries?.map((id: string) => ({
              label:
                countryOptions.find((country) => country.value === id)?.label ||
                "",
              value: id,
            })) || []
          }
          onChange={handleCountryChange}
        />
      </FormGroup>
      {stateOptions.length > 0 ? (
        <FormGroup className="filter-form-group">
          <Label className="custom-form-label">State</Label>
          <SelectDropdownComponent
            placeholder="Select States"
            options={stateOptions}
            customStyles={selectDropDowncustomStyles}
            isMulti={true}
            isSearchable={true}
            isClearable={true}
            value={
              selectedStates?.map((id: string) => ({
                label:
                  stateOptions.find((state) => state.value === id)?.label || "",
                value: id,
              })) || []
            }
            onChange={handleStateChange}
          />
        </FormGroup>
      ) : null}
      {cityOptions.length > 0 && (
        <FormGroup className="filter-form-group">
          <Label className="custom-form-label">City</Label>
          <SelectDropdownComponent
            placeholder="Select Cities"
            options={cityOptions}
            customStyles={selectDropDowncustomStyles}
            isMulti={true}
            isSearchable={true}
            isClearable={true}
            value={
              selectedCities?.map((id: string) => ({
                label:
                  cityOptions.find((city) => city.value === id)?.label || "",
                value: id,
              })) || []
            }
            onChange={handleCityChange}
          />
        </FormGroup>
      )}
    </>
  );
};

export default LocationFilters;
