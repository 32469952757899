/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { Row, Col } from 'reactstrap';
import { DirectoryAddressComponent, InputComponent, PhoneNumberComponent } from '../../../../../../common/components/index';
import './funeral-home-details.scss';

const FuneralHomeDetails: any = (props: any) => {
  const { setFieldValue, validateForm, values } = props;

  return (
    <div className="">
      <div className="funeral-home-detail">
        <div className="funeral-home-detail-container">
          <div className="funeral-home-detail-form">
            <Row xs={12} className="multi-line-row-form-input heading-name-label">
              <Col className="multi-line-row-form-input-single">
                <InputComponent fieldName="name" label="Name" placeholder="Enter Name" optional={false} isAlpha />
              </Col>
              <Col className="multi-line-row-form-input-single">
                <InputComponent fieldName="ownerName" label="Owner Name" placeholder="Enter Owner Name" optional={false} isAlpha />
              </Col>
            </Row>
            <Row xs={12} className="single-line-row-form-input sub-name-label">
              <DirectoryAddressComponent
                addressLabel="physicalAddress"
                streetFieldName="physicalAddress.street"
                zipCodeFieldName="physicalAddress.zipcode"
                setCity={(city: any) => {
                  setFieldValue('physicalAddress.physicalAddressCity', city);
                  setTimeout(() => {
                    validateForm();
                  }, 100);
                }}
                setState={(state) => {
                  setFieldValue('physicalAddress.physicalAddressState', state);
                  setTimeout(() => {
                    validateForm();
                  }, 100);
                }}
                selectedState={values?.physicalAddress?.selectedPhysicalAddressState}
                selectedCity={values?.physicalAddress?.selectedPhysicalAddressCity}
                setSelectedCity={(city) => {
                  setFieldValue('physicalAddress.selectedPhysicalAddressCity', city);
                  setTimeout(() => {
                    validateForm();
                  }, 100);
                }}
                setSelectedState={(state) => {
                  setFieldValue('physicalAddress.selectedPhysicalAddressState', state);
                  setTimeout(() => {
                    validateForm();
                  }, 100);
                }}
                cityFieldName="physicalAddress.selectedPhysicalAddressCity"
                stateFieldName="physicalAddress.selectedPhysicalAddressState"
                optional={false}
              />
            </Row>
            <Row xs={12} className="single-line-row-form-input sub-name-label">
              <DirectoryAddressComponent
                addressLabel="mailingAddress"
                streetFieldName="mailingAddress.street"
                zipCodeFieldName="mailingAddress.zipcode"
                setCity={(city) => {
                  setFieldValue('mailingAddress.mailingAddressCity', city);
                  setTimeout(() => {
                    validateForm();
                  }, 100);
                }}
                setState={(state) => {
                  setFieldValue('mailingAddress.mailingAddressState', state);
                  setTimeout(() => {
                    validateForm();
                  }, 100);
                }}
                selectedState={values?.mailingAddress?.selectedMailingAddressState}
                selectedCity={values?.mailingAddress?.selectedMailingAddressCity}
                setSelectedCity={(city) => {
                  setFieldValue('mailingAddress.selectedMailingAddressCity', city);
                  setTimeout(() => {
                    validateForm();
                  }, 100);
                }}
                setSelectedState={(state) => {
                  setFieldValue('mailingAddress.selectedMailingAddressState', state);
                  setTimeout(() => {
                    validateForm();
                  }, 100);
                }}
                cityFieldName="mailingAddress.selectedMailingAddressCity"
                stateFieldName="mailingAddress.selectedMailingAddressState"
                optional={false}
              />
            </Row>
            <Row xs={12} className="multi-line-row-form-input sub-name-label">
              <Col className="multi-line-row-form-input-single">
                <PhoneNumberComponent
                  label="Phone Number"
                  fieldName={`phoneNumber.phoneNumber`}
                  countryCodeFieldName={`phoneNumber.countryCode`}
                  placeholder="Enter Phone Number"
                  optional={false}
                  menuPosition={'fixed'}
                />
              </Col>
              <Col className="multi-line-row-form-input-single">
                <InputComponent fieldName="faxNumber" label="Fax Number" isFax={true} placeholder="Enter Fax Number" optional={false} />
              </Col>
            </Row>
            <Row xs={12} className="multi-line-row-form-input sub-name-label">
              <Col className="multi-line-row-form-input-single">
                <InputComponent
                  fieldName={`emailAddress`}
                  label="Email Address"
                  placeholder="Enter Email Address"
                  isEmail
                  optional={false}
                />
              </Col>
              <Col className="multi-line-row-form-input-single">
                <InputComponent fieldName={`taxId`} label="Tax Id" placeholder="Enter Tax Id" optional={false} />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FuneralHomeDetails;
