import * as Yup from "yup";
import phone from 'phone';
import { emailValidationRegex } from "../../../constants/regex-string";

export const validationSchema = Yup.object({
    name: Yup.string()
    .matches(/^[A-Za-z ´',-.()!&Åë]+$/, 'Name can only contain alphabets, spaces, and special characters')
    .max(200, "Name can't be longer than 200 characters.")
    .required("Mandatory Field*"),
    entityName: Yup.string()
    .matches(/^[A-Za-z ´',-.()!&Åë]+$/, 'Entity name can only contain alphabets, spaces, and special characters')
      .max(200, "Entity name can't be longer than 200 characters.")
      .nullable(),
    mailingAddress: Yup.object({
      street: Yup.string()
        .min(3, "Street name must be at least 3 characters.")
        .max(200, "Street name can't be longer than 200 characters.")
        .required("Mandatory Field*"),
      zipcode: Yup.string()
        .matches(/^\d{5}$/, "Zipcode must be 5 digits")
        .required("Mandatory Field*"),
      selectedMailingAddressCity: Yup.object().required("Mandatory Field*"),
      selectedMailingAddressState: Yup.object().required("Mandatory Field*"),
    }),
    homeAddress: Yup.object({
      street: Yup.string()
        .min(3, "Street name must be at least 3 characters.")
        .max(200, "Street name can't be longer than 200 characters.")
        .required("Mandatory Field*"),
      zipcode: Yup.string()
        .matches(/^\d{5}$/, "Zipcode must be 5 digits")
        .required("Mandatory Field*"),
      selectedHomeAddressCity: Yup.object().required("Mandatory Field*"),
      selectedHomeAddressState: Yup.object().required("Mandatory Field*"),
  
    }),
    homePhone: Yup.object({
      countryCode: Yup.string(),
      phoneNumber: Yup.string()
      .matches(/^\d{10}$/, `Number format is invalid`)
      .test('is-valid-phone', `Invalid number`, (value, allValue) => {
        if (!value) return true;
        const { isValid } = phone(`${allValue?.parent?.countryCode || ''}-${value}`);
        return isValid;
      }),
    }).nullable(),
    workPhone: Yup.object({
      countryCode: Yup.string(),
      phoneNumber: Yup.string()
        .matches(/^\d{10}$/, `Number format is invalid`)
        .test('is-valid-phone', `Invalid number`, (value, allValue) => {
          if (!value) return true;
          const { isValid } = phone(`${allValue?.parent?.countryCode || ''}-${value}`);
          return isValid;
        }).required('Mandatory Field*'),
    }),
    cellPhone: Yup.object({
      countryCode: Yup.string(),
      phoneNumber: Yup.string()
      .matches(/^\d{10}$/, `Number format is invalid`)
      .test('is-valid-phone', `Invalid number`, (value, allValue) => {
        if (!value) return true;
        const { isValid } = phone(`${allValue?.parent?.countryCode || ''}-${value}`);
        return isValid;
      }),
    })
    .nullable().optional(),
    emailAddress: Yup.string()
      .matches(emailValidationRegex, { message: "Invalid email address. Please provide a valid email."})
      .required("Mandatory Field*"),
    gender: Yup.string().optional().nullable(),
    agency: Yup.string().nullable(),
    companiesContractedWith: Yup.string().oneOf(['NGL','GWIC','Global Atlantic','FDLIC','Travel plans by Inman','APSI','Other']).nullable(),
    bankRoutingNumber: Yup.string().optional()
      .matches(/^[A-Za-z0-9]+$/, 'Bank Routing Number can only contain alphabets and numbers')
      .max(200, 'Bank routing number cannot exceed 200 characters'),
    bankAccountNumber: Yup.string().optional()
      .matches(/^[0-9]+$/, 'Bank Account Number can only contain numbers')
      .max(200, 'Bank account number cannot exceed 200 characters'),
    accountType: Yup.string().oneOf(['Checking', 'Savings']),
    commissionPaymentFrequency: Yup.string().oneOf(['Daily', 'Weekly', 'Monthly', 'Quarterly','Half-yearly', 'Yearly']),
    bankName: Yup.string()
      .matches(/^[A-Za-z ´',-.()!&Åë]+$/, 'Bank Name can only contain alphabets, spaces, and special characters')
      .max(200, 'Bank Name cannot exceed 200 characters')
      .nullable(),
    socialSecurityNumber: Yup.string().matches(/^\d{4}$/, 'Invalid Social Security Number'),
    ein: Yup.string().matches(/^\d{2}-\d{7}$/, 'Invalid EIN').optional(),
    dateOfBirth: Yup.date().typeError('Invalid Date').optional(),
    stateLicensedIn: Yup.number().required('Mandatory Field*'),
    licenseExpiration: Yup.date().typeError("Enter a valid date").nullable(),
    amlCertification: Yup.string()
      .max(200, 'AML Certification cannot exceed 200 characters')
      .nullable(),
    reserveBalance: Yup.number()
      .nullable()
      .typeError('Reserve balance must be a valid number')
    .test(
      'is-decimal',
      'Reserve balance must have up to 8 digits before the decimal and 2 digits after the decimal',
      (value) => {
        if (value === null || value === undefined) return true;
        const regex = /^\d{1,8}(\.\d{1,2})?$/;
        return regex.test(value.toString());
      }
    )
    .max(99999999.99, 'Reserve balance cannot exceed 99,999,999.99')
    .min(0, 'Reserve balance cannot be negative'),
    agencyNumber: Yup.string()
      .max(200, 'Reserve Balance cannot exceed 200 characters')
      .nullable(),
    specialInstructions: Yup.string(),
    commissionLevel: Yup.string()
    .required("Mandatory Field*"),
    regionalRepId: Yup.number(),
  });