/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Row, Table } from 'reactstrap';
import editIcon from '../../../../../../../../assets/icons/edit.svg';
import deleteIcon from '../../../../../../../../assets/icons/deleteIcon.svg';
import './authorized-representative-table.scss';
import AuthorisedRepresentativeTableCellContent from './authorized-representative-table-cell-content';
import AuthorisedRepresentativeTableCellForm from './authorized-representative-table-cell-form';
import { TableComponentProps } from './authorized-representative-table-props';

const AuthorizedRepresentativeTable: React.FC<TableComponentProps> = ({
  columnNames = [],
  rowsData = [],
  handleEditClick,
  handleDeleteClick,
  handleAddMoreAuthorisedRepresentatives,
  setLoading,
  funeralHomeId,
  handleGetAuthorisedRepresentative,
  setAlertOpen,
  setAlertType,
  setAlertMessage,
}) => {
  const [isEditProgress, setIsEditProgress] = useState<any>(' ');

  return (
    <>
      <Row className={'authorized-representative-table'}>
        <div className={'authorized-representative-table-container'}>
          <Table hover className={`authorized-representative-table-box ${isEditProgress}`} bordered={true} striped>
            <thead>
              <tr>
                {columnNames.map((columnName: any, index: any) => (
                  <th key={index}>
                    <div className={'authorized-representative-table-header-column-names'}>
                      <div className={'authorized-representative-table-header-column'}>{columnName?.header}</div>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rowsData?.length ? (
                (rowsData ?? []).map((row: any, rowIndex: any) =>
                  !row.isEdit ? (
                    <tr key={rowIndex}>
                      {columnNames.map((columnName: any, colIndex: any) => {
                        return (
                          <td
                            key={colIndex}
                            className={`${
                              colIndex < 2 ? 'authorized-representative-table-sticky-column' : ''
                            } ${'authorized-representative-table-sticky-column-hover'}`}
                          >
                            {colIndex === 0 ? (
                              <div>
                                <img
                                  className={`edit-icon edit-icon-hover`}
                                  src={editIcon}
                                  alt="Edit Icon"
                                  onClick={() => {
                                    setIsEditProgress('authorized-representative-table-box-active');
                                    handleEditClick?.(row.id);
                                  }}
                                />
                                <img
                                  className={`edit-icon edit-icon-hover`}
                                  src={deleteIcon}
                                  alt="Edit Icon"
                                  onClick={() => {
                                    handleDeleteClick?.(row.id);
                                  }}
                                />
                              </div>
                            ) : (
                              <AuthorisedRepresentativeTableCellContent text={row[columnName?.data]} />
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  ) : (
                    <AuthorisedRepresentativeTableCellForm
                      initialValues={row}
                      colIndex={rowIndex}
                      handleEditClick={handleEditClick}
                      setIsEditProgress={setIsEditProgress}
                      setLoading={setLoading}
                      funeralHomeId={funeralHomeId}
                      handleGetAuthorisedRepresentative={handleGetAuthorisedRepresentative}
                      setAlertOpen={setAlertOpen}
                      setAlertType={setAlertType}
                      setAlertMessage={setAlertMessage}
                    />
                  ),
                )
              ) : (
                <tr className="authorized-representative-table-no-record">
                  {' '}
                  <td colSpan={9}>No Records found </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Row>
      <div className="authorized-representative-table-add-btn-container">
        <div
          className="authorized-representative-table-add-btn"
          onClick={() => {
            setIsEditProgress('authorized-representative-table-box-active');
            handleAddMoreAuthorisedRepresentatives();
          }}
        >
          + Add Authorised Representative
        </div>
      </div>
    </>
  );
};

export default AuthorizedRepresentativeTable;
