import { createNamespacer, createReducer } from '../utils/reducers';

const initialState = {
  active: {rows:[], count:0},
  archive: {rows:[], count:0},
};

const namespacer = createNamespacer('NOTIFICATIONS');

const handlers = {
  [namespacer('SET_NOTIFICATIONS')]: (state:any, action:any) => {
    return {
      ...state,
      active: action.payload.value.active,
      archive: action.payload.value.archive
    };
  }
};

const reducer = createReducer(initialState, handlers, ['NOTIFICATIONS']);

export default reducer;
