import { splitPhoneNumber } from "../../utils";

export const getInitialValues =(editData?: any, mode?: any)=>{
    return  editData &&  mode === 'edit'? {
    }:
    {
        name: "",
        physicalAddress: {
            street: "",
            city: "",
            state: "",
            zipcode: "",
            selectedPhysicalAddressCity: null,
            selectedPhysicalAddressState: null,
            physicalAddressCity: null,
            physicalAddressState: null,
        },
        mailingAddress: {
            street: "",
            city: "",
            state: "",
            zipcode: "",
            selectedMailingAddressCity: null,
            selectedMailingAddressState: null,
            mailingAddressCity: null,
            mailingAddressState: null,
        },
        ownerName:"",
        phoneNumber:{
          countryCode: "+1", 
          phoneNumber: "",  
        },
        emailAddress: "",
        faxNumber: "",
        taxId: "",
    }
}