import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import phone from 'phone';
import * as Yup from "yup";
import {
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Label,
  FormGroup,
  Button,
} from "reactstrap";
import closeIcon from "../../assets/icons/closeIcon.svg";
import {
  AddOrEditLeadComponentProps,
  AddressLocation,
  DropdownOption,
  LeadSource,
} from "./leads-item-props";
import InputComponent from "../../common/components/input-component/input-component";
import PhoneNumberComponent from "../../common/components/phone-number-component/phone-number-component";
import {
  DirectoryAddressComponent,
  SelectDropdownComponent,
} from "../../common/components";
import "../../common/components/input-component/input-component.scss";
import { addLead, editLead, deleteLead, getLeadSources } from "../../api/leads";
import { splitPhoneNumber, concatenatePhoneNumber } from "../directory/utils";
import AlertComponent from "../../common/components/Alert/Alert";
import DeleteConfirmationPopup from "../directory/delete-confirmation-popup/delete-confirmation-popup";
import { emailValidationRegex } from "../../constants/regex-string";

const AddOrEditLeads: React.FC<AddOrEditLeadComponentProps> = ({
  isOpen,
  toggle,
  mode,
  editData,
  fetchLeads,
  page,
}) => {
  const [addressCity, setAddressCity] = useState<AddressLocation | null>(null);
  const [addressState, setAddressState] = useState<AddressLocation | null>(
    null
  );
  const [selectedAddressCity, setSelectedAddressCity] =
    useState<AddressLocation | null>(null);
  const [selectedAddressState, setSelectedAddressState] =
    useState<AddressLocation | null>(null);
  const [deletePopupState, setDeletePopupState] = useState<{
    isOpen: boolean;
    leadId: number | null;
  }>({
    isOpen: false,
    leadId: null,
  });
  const [alertState, setAlertState] = useState<{
    isOpen: boolean;
    message: string;
    type: "success" | "error" | "info" | "warning";
  }>({
    isOpen: false,
    message: "",
    type: "success",
  });
  const [leadSources, setLeadSources] = useState<LeadSource[]>([]);

  const showAlert = (
    message: string,
    type: "success" | "error" | "info" | "warning"
  ) => {
    setAlertState({
      isOpen: true,
      message,
      type,
    });
  };

  const closeAlert = () => {
    setAlertState((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  };

  const handleDelete = async (deleteLeadId: number | null) => {
    try {
      if (deleteLeadId === null) {
        return;
      }
      const response = await deleteLead(deleteLeadId);
      if (response && response.data.success) {
        fetchLeads(page);
      }
      setDeletePopupState((prevState) => ({ ...prevState, isOpen: false }));
      toggle();
    } catch (error) {
      showAlert("Failed to delete", "error");
    }
  };

  const initialValues =
    editData && mode === "edit"
      ? {
        fullName: editData.full_name,
        emailId: editData.email,
        phoneNumberDetails: editData.phone_no
          ? splitPhoneNumber(editData.phone_no)
          : {
            countryCode: "+1",
            phoneNumber: "",
          },
        address: {
          id: editData.Address?.id,
          street: editData.Address?.street || "",
          zipcode: editData.Address?.zipcode || "",
        },
        leadSource: editData.LeadSource?.source,
        leadType: editData?.lead_type,
        status: editData.status,
        company:editData.company||"",
        websiteUrl:editData.website_url||"",
      }
      : {
        fullName: "",
        emailId: "",
        phoneNumberDetails: {
          countryCode: "+1",
          phoneNumber: "",
        },
        address: {
          street: "",
          zipcode: "",
        },
        leadSource: "",
        leadType: "Internal",
        status: "New",
        company:"",
        websiteUrl:"",
      };

  const validationSchema = Yup.object({
    fullName: Yup.string().required("Full name is required"),
    emailId: Yup.string()
      .matches(emailValidationRegex, {
        message: "Invalid email address. Please provide a valid email.",
      })
      .required("Email Id is required"),
    address: Yup.object({
      street: Yup.string()
        .min(3, "Street name must be at least 3 characters.")
        .max(200, "Street name can't be longer than 200 characters."),
      zipcode: Yup.string(),
    }),
    phoneNumberDetails: Yup.object({
      countryCode: Yup.string().matches(/^\+\d{1,4}$/, "Invalid country code"),
      phoneNumber: Yup.string()
        .matches(/^\d{10}$/, `Number format is invalid`)
        .test('is-valid-phone', `Invalid number`, (value, allValue) => {
          if (!value) return true;
          const { isValid } = phone(`${allValue?.parent?.countryCode || ''}-${value}`);
          return isValid;
        })
    }),
    leadSource: Yup.string().required("Lead Source is Required"),
    leadType: Yup.string().required("Lead Type is required"),
    status: Yup.string(),
    company:Yup.string().matches(/^[A-Za-z ´',-.()!&Åë]+$/, 'Company can only contain alphabets, spaces, and special characters')
    .max(200, "Company can't be longer than 200 characters."),
    websiteUrl:Yup.string().url("Invalid URL. Please enter a valid website URL.").nullable().notRequired(),
  });

  useEffect(() => {
    if (editData) {
      setSelectedAddressCity(editData.Address ? editData.Address.City : null);
      setSelectedAddressState(editData.Address ? editData.Address.State : null);
    }
  }, [editData]);
  const selectDropDowncustomStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderColor: state.isFocused ? "#887952" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px #887952" : provided.boxShadow,
      "&:hover": {
        borderColor: "#887952",
      },
    }),
    option: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: state.isSelected ? "#887952" : "white",
      ":hover": {
        backgroundColor: state.isSelected ? "#887952" : "#EEEE",
      },
    }),
  };

  const onSubmit = async (values: typeof initialValues, { resetForm }: any) => {
    // Check if the address or any value has changed
    const hasChanges =
      editData &&
      ((editData.Address?.street ?? "") !== values.address?.street ||
        editData.Address?.city?.id !== addressCity?.id ||
        editData.Address?.state?.id !== addressState?.id ||
        (editData.Address?.zipcode ?? "") !== values.address?.zipcode ||
        (editData.phone_no || "").trim() !==
        (concatenatePhoneNumber(values.phoneNumberDetails) || "").trim() ||
        editData.full_name !== values.fullName ||
        editData.email !== values.emailId ||
        editData.status !== values.status ||
        editData.lead_type !== values.leadType ||
        editData.company!==values.company||
        editData.website_url!==values.websiteUrl||
        editData.LeadSource.source !== values.leadSource);

    // If no changes are detected in "edit" mode, skip API call
    if (mode === "edit" && !hasChanges) {
      resetForm();
      toggle();
      return;
    }
    const isAddressProvided =
      values.address?.street ||
      addressCity?.id ||
      addressState?.id ||
      values.address?.zipcode;
    const updatedAddress =
      isAddressProvided ||
        (mode === "edit" &&
          (values.address?.street ||
            selectedAddressCity?.id ||
            selectedAddressState?.id ||
            values.address?.zipcode))
        ? {
          id: mode === "edit" ? editData?.Address?.id : undefined,
          street: values.address?.street,
          city: {
            id: selectedAddressCity?.id || null,
            name: selectedAddressCity?.name || "",
          },
          state: {
            id: selectedAddressState?.id || null,
            name: selectedAddressState?.name || "",
          },
          zipcode: values.address?.zipcode,
        }
        : null;

    const phoneNumber =
      values?.phoneNumberDetails?.phoneNumber &&
        values?.phoneNumberDetails?.countryCode
        ? concatenatePhoneNumber(values.phoneNumberDetails)
        : null;
    const { phoneNumberDetails, address, ...updatedValues } = values;
    const finalValues = {
      ...updatedValues,
      phoneNumber,
      address: updatedAddress,
    };
    try {
      let response;
      if (mode === "add") {
        response = await addLead(finalValues);
      } else if (mode === "edit") {
        response = await editLead(editData?.id, finalValues);
      }
      if (response?.data.success && fetchLeads) {
        fetchLeads(page);
      } else if (response?.data.errors) {
      }
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.errors) {
      } else {
        console.error("Unexpected error:", error);
      }
    }
    resetForm();
    toggle();
  };

  const fetchLeadSources = async () => {
    try {
      const response = await getLeadSources();
      setLeadSources(response?.data?.data?.data);
    } catch (error: any) {
    } finally {
    }
  };

  useEffect(() => {
    fetchLeadSources();
  }, []);

  return (
    <>
      <AlertComponent
        open={alertState.isOpen}
        message={alertState.message}
        type={alertState.type}
        onClose={closeAlert}
      />
      <div className="add-or-edit-offcanvas">
        <Offcanvas isOpen={isOpen} direction="end" className="offcanvas">
          <OffcanvasHeader className="offcanvas-header">
            <div className="header-div">
              <h2 className="mb-0">{mode === "edit" ? "Edit" : "Add"} Lead</h2>
              <button onClick={toggle}>
                <img src={closeIcon} alt="Close" className="header-div-img" />
              </button>
            </div>
          </OffcanvasHeader>
          <OffcanvasBody className="offcanvas-body">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, errors, touched, values, setFieldValue }) => (
                <Form className="add-or-edit-form">
                  <InputComponent
                    fieldName="fullName"
                    label="Full Name"
                    placeholder="Enter Full name"
                    optional={false}
                    isAlpha
                  />
                  <InputComponent
                    fieldName="emailId"
                    label="Email ID"
                    placeholder="Enter Email ID"
                    optional={false}
                    isEmail
                  />
                  <PhoneNumberComponent
                    label="Phone Number"
                    fieldName="phoneNumberDetails.phoneNumber"
                    countryCodeFieldName="phoneNumberDetails.countryCode"
                    placeholder="Enter Phone Number"
                  />
                  <DirectoryAddressComponent
                    addressLabel="Address"
                    streetFieldName="address.street"
                    zipCodeFieldName="address.zipcode"
                    setCity={setAddressCity}
                    setState={setAddressState}
                    selectedState={selectedAddressState}
                    selectedCity={selectedAddressCity}
                    setSelectedCity={setSelectedAddressCity}
                    setSelectedState={setSelectedAddressState}
                    mode={mode}
                  />
                  <FormGroup className="add-or-edit-form-group">
                    <Label htmlFor="leadSource" className="custom-form-label">
                      Lead Source <span>*</span>
                    </Label>
                    <SelectDropdownComponent
                      placeholder="Select Lead Source"
                      options={leadSources.map((source) => ({
                        label: source.source,
                        value: source.source,
                      }))}
                      customStyles={selectDropDowncustomStyles}
                      value={
                        values.leadSource
                          ? {
                            label: values.leadSource,
                            value: values.leadSource,
                          }
                          : null
                      }
                      onChange={(selected) => {
                        const selectedOption = selected as DropdownOption;
                        setFieldValue("leadSource", selectedOption?.value);
                      }}
                    />
                    <ErrorMessage
                      name="leadSource"
                      component="div"
                      className="error-message"
                    />
                  </FormGroup>
                  <FormGroup className="add-or-edit-form-group">
                    <Label htmlFor="leadType" className="custom-form-label">
                      Lead Type <span>*</span>
                    </Label>
                    <SelectDropdownComponent
                      placeholder="Select Lead Type"
                      options={[
                        { label: "Internal", value: "Internal" },
                        { label: "External", value: "External" },
                      ]}
                      customStyles={selectDropDowncustomStyles}
                      value={
                        values.leadType
                          ? { label: values.leadType, value: values.leadType }
                          : { label: "Internal", value: "Internal" }
                      }
                      onChange={(selected) => {
                        const selectedOption = selected as DropdownOption;
                        setFieldValue("leadType", selectedOption?.value);
                      }}
                    />
                    <ErrorMessage
                      name="leadType"
                      component="div"
                      className="error-message"
                    />
                  </FormGroup>
                  {mode === "edit" ? (
                    <FormGroup className="add-or-edit-form-group">
                      <Label htmlFor="status" className="custom-form-label">
                        Status
                      </Label>
                      <SelectDropdownComponent
                        placeholder="Select Status"
                        options={[
                          { label: "New", value: "New" },
                          { label: "Viewed", value: "Viewed" },
                        ]}
                        value={{ label: values.status, value: values.status }}
                        onChange={(selected) => {
                          const selectedOption = selected as DropdownOption;
                          if (mode === "edit") {
                            setFieldValue("status", selectedOption?.value);
                          }
                        }}
                        customStyles={selectDropDowncustomStyles}
                      />
                      <ErrorMessage
                        name="status"
                        component="div"
                        className="error-message"
                      />
                    </FormGroup>
                  ) : (
                    <InputComponent
                      fieldName="status"
                      label="Status"
                      placeholder="Enter Status"
                      disabled={true}
                    />

                  )}
                  <InputComponent
                    fieldName="company"
                    label="Company"
                    placeholder="Enter Company Name"
                  />

                  <InputComponent
                    fieldName="websiteUrl"
                    label="Website URL"
                    placeholder="Enter Website URL"
                  />
                  <div className="form-button-container">
                    {mode === "edit" && (
                      <Button
                        type="button"
                        className="delete-button"
                        onClick={() => {
                          setDeletePopupState({
                            isOpen: true,
                            leadId: editData?.id,
                          });
                        }}
                      >
                        Delete
                      </Button>
                    )}
                    <button
                      className={`cancel-button${mode === "edit" ? "-in-edit" : ""
                        }`}
                      onClick={toggle}
                      type="button"
                    >
                      Cancel
                    </button>
                    <button
                      className={
                        mode === "edit" ? "save-button-changes" : "save-button"
                      }
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </OffcanvasBody>
        </Offcanvas>
      </div>
      <DeleteConfirmationPopup
        isOpen={deletePopupState.isOpen}
        deleteId={deletePopupState.leadId}
        onClose={() =>
          setDeletePopupState((prevState) => ({
            ...prevState,
            isOpen: false,
          }))
        }
        handleDelete={handleDelete}
        label="Lead"
      />
    </>
  );
};

export default AddOrEditLeads;
