/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import './view-funeral-homes-right-section.scss';
import quickLinksIcon from '../../../../../assets/icons/quickLinksIcons/quickLinksIcon.svg';
import closeIcon from '../../../../../assets/icons/closeIcon.svg';
import addOwnerIcon from '../../../../../assets/icons/quickLinksIcons/addOwnerIcon.svg';
import additionalLocationIcon from '../../../../../assets/icons/quickLinksIcons/additionalLocationIcon.svg';
import notesIcon from '../../../../../assets/icons/quickLinksIcons/notesIcon.svg';
import specialInstructionsIcon from '../../../../../assets/icons/quickLinksIcons/specialInstructionsIcon.svg';
import moment from 'moment';
// import addContractsIcon from '../../../../../assets/icons/quickLinksIcons/addContractsIcon.svg';
// import viewContractsIcon from '../../../../../assets/icons/quickLinksIcons/viewContractsIcon.svg';
// import sendContractsIcon from '../../../../../assets/icons/quickLinksIcons/sendContractsIcon.svg';
import { Tooltip } from '@mui/material';
import { getAllFuneralHomeNotes } from '../../../../../api/funeralhomes';

interface ViewFuneralHomesRightSectionProps {
  handleQuickLinksTab: () => void;
  quickLinksCollapsed: boolean;
  handleQuickLinkTabInBottomSection: (label: string) => void;
  shouldDisplayTab: boolean;
  funeralHomeId: number;
  notesData: any;
  setNotesData: any;
}
const ViewFuneralHomesRightSection: React.FC<ViewFuneralHomesRightSectionProps> = ({
  handleQuickLinksTab,
  quickLinksCollapsed,
  handleQuickLinkTabInBottomSection,
  shouldDisplayTab,
  funeralHomeId,
  notesData,
  setNotesData,
}) => {
  interface quickLinksInterface {
    label: string;
    icon: any;
  }
  const quickLinks: quickLinksInterface[] = [
    { label: 'Add more owner', icon: addOwnerIcon },
    { label: 'Add Additional Location', icon: additionalLocationIcon },
    ...(shouldDisplayTab ? [{ label: 'Notes', icon: notesIcon }] : []),
    { label: 'Special Instructions', icon: specialInstructionsIcon },
    // { label: "Add Contracts", icon: addContractsIcon },
    // { label: "View Contracts", icon: viewContractsIcon },
    // { label: "Send Contracts", icon: sendContractsIcon },
  ];
  // const [notesData, setNotesData] = useState<any>([]); // Store the first note
  const [showNotes, setShowNotes] = useState(false);
  const fetchNotes = async () => {
    try {
      const response = await getAllFuneralHomeNotes(funeralHomeId);
      setNotesData(response.data.data.rows);
      setShowNotes(true);
    } catch (error) {
      console.error('Error fetching notes:', error);
    }
  };

  return (
    <div className="view-funeral-homes-right-container">
      {/* Show Notes View when clicked */}
      {showNotes ? (
        <div className="notes-view">
          <div className="notes-header">
            <span className="back-arrow" onClick={() => setShowNotes(false)}>
              &larr;<span className="back-arrow-text"> Notes </span>
            </span>
          </div>
          <div className="notes-content">
            {notesData?.length ? (
              notesData?.map((data: any) => (
                <div className="note-box">
                  <strong>{data?.createdBy?.first_name}</strong>
                  <p className="notes-text">{data?.notes}</p>
                  <p className="notes-text-date">{moment(data?.created_at).format('MM-DD-YYYY h:mm A')}</p>
                </div>
              ))
            ) : (
              <p>No Records</p>
            )}
          </div>
        </div>
      ) : (
        // Show Quick Links when Notes is not clicked
        <div className="quick-links-section">
          <div className="quick-links-header-section">
            <div className="quick-links-icon">
              <img
                src={quickLinksIcon}
                alt="Quick Links"
                onClick={() => handleQuickLinksTab()}
                className={`${quickLinksCollapsed ? 'quick-links-icon-expanded' : ''}`}
              />
              {!quickLinksCollapsed && (
                <div className="quick-links-header">
                  <h2>Quick Links</h2>
                </div>
              )}
            </div>
            {!quickLinksCollapsed && (
              <>
                <div className="quick-links-closeIcon">
                  <img src={closeIcon} alt="quick links close icon" onClick={() => handleQuickLinksTab()} />
                </div>
              </>
            )}
          </div>

          <div className="quick-links-list-section">
            {quickLinks.map((link) => (
              <Tooltip title={quickLinksCollapsed ? link.label : ''} key={link.label}>
                <div
                  className={`quick-link-div ${quickLinksCollapsed ? 'quickLinksCollapsed' : ''}`}
                  onClick={() => {
                    if (link.label === 'Notes') {
                      fetchNotes();
                      setShowNotes(true); // Hide Quick Links and show Notes
                    } else {
                      handleQuickLinkTabInBottomSection(link.label);
                    }
                  }}
                >
                  <div className="each-quick-link-icon">
                    <img src={link.icon} />
                  </div>
                  {!quickLinksCollapsed && <div>{link.label}</div>}
                </div>
              </Tooltip>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewFuneralHomesRightSection;
