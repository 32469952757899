import React, { useState, useEffect, useMemo, useCallback } from "react";
import { getSavedReportsDetails } from "../../../api/reports/saved-reports";
import AlertComponent from "../../../common/components/Alert/Alert";
import BreadcrumbComponent from "../../../common/components/breadcrumb-component/breadcrumb-component";
import SavedReportsTable from "./saved-reports-table";
import { exportFuneralHomeData } from "../../../api/funeralhomes";
import { exportAgentData} from "../../../api/agents";
import { exportLeadsData } from "../../../api/leads";
import "./saved-reports-table.scss";
import { useSetLoading } from "../../../common/hooks/appearance";

const SavedReports: React.FC = () => {
  //const [searchText, setSearchText] = useState<string>("");
  const [columnName, setColumnName] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("created_at");
  const [sortDirection, setSortDirection] = useState<string>("desc");
  //const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertState, setAlertState] = useState<{
    isOpen: boolean;
    message: string;
    type: "success" | "error" | "info" | "warning";
  }>({
    isOpen: false,
    message: "",
    type: "success",
  });
  const [savedReportsData, setSavedReportsData] = useState<any>([]);

  let crumbMapping = [
    { label: "Reports", link: "/home/reports/saved-reports" },
    { label: "Saved Reports", link: "home/reports/saved-reports" },
  ];
  const columnNames: string[] = [
    "Name Of Reports",
    "Last Generated",
    "Download Report",
  ];

  const mapColumnNameToField = (columnName: string): string => {
    const mapping: Record<string, string> = {
      "Name Of Reports": "name",
      "Last Generated": "updated_at",
    };

    return mapping[columnName] || columnName;
  };

  // const handleSearch = _.debounce((text: string) => {
  //   setSearchText(text);
  // }, 500);

  const handleSort = (columnName: string) => {
    const mappedField = mapColumnNameToField(columnName);
    if (sortBy === mappedField) {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortBy(mappedField);
      setColumnName(columnName);
      setSortDirection("asc");
    }
  };
  const showAlert = (
    message: string,
    type: "success" | "error" | "info" | "warning"
  ) => {
    setAlertState({
      isOpen: true,
      message,
      type,
    });
  };
  const closeAlert = () => {
    setAlertState((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  };


  const fetchSavedReports =useCallback( async (search = "") => {
    setIsLoading(true);
    try {
      const response = await getSavedReportsDetails(
        search,
        sortBy,
        sortDirection
      );
      setSavedReportsData(response.data.data);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  }, [sortBy, sortDirection]);
  useEffect(() => {
    fetchSavedReports();
  }, [ sortBy, sortDirection,fetchSavedReports]);
  const formateDate = (lastGeneratedDate: Date) => {
    const dateStr = lastGeneratedDate;
    const date = new Date(dateStr);
    return `${date.getDate()} ${date.toLocaleString("en-US", {
      month: "long",
    })}, ${date.getFullYear()}`;
  };
  const formattedData = useMemo(() => {
    if (Array.isArray(savedReportsData)) {
      return savedReportsData.map((item) => ({
        id: item.id,
        "Name Of Reports": item.name || "",
        "Last Generated": formateDate(item.updated_at),
        "Download Report": true,
      }));
    } else {
      return [];
    }
  }, [savedReportsData]);

  const handleDownload = async (reportName: string) => {
    try {
      if (reportName === "New Funeral Homes in Current Quarter") {
        await exportFuneralHomeData({savedReport: reportName});
        //response = await exportFuneralHomeList(reportName);
      } else if (reportName === "New Agents in Current Quarter") {
        await exportAgentData({savedReport: reportName});
      } else if (reportName === "New Leads in Current Quarter") {
        await exportLeadsData({savedReport: reportName});
      }
      // const blob = new Blob([response?.data], {
      //   type: "application/vnd.ms-excel",
      // });
      // const link = document.createElement("a");
      // link.href = URL.createObjectURL(blob);
      // link.download = `${reportName}.xlsx`;
      // link.click();
      showAlert("Sent Downloadable link to Email , Please check", "success");
      fetchSavedReports();
    } catch (error) {
      showAlert("Failed to download the report", "error");
    }
  };

  const showLoading = useMemo(() => isLoading, [isLoading]);
  useSetLoading(showLoading);

  return (
    <>
      <AlertComponent
        message={alertState.message}
        type={alertState.type}
        onClose={closeAlert}
        open={alertState.isOpen}
      />
      <BreadcrumbComponent breadcrumbLink={crumbMapping} />
      {/* <SearchBar
        placeholder="Search Report"
        onSearch={handleSearch}
        module="SavedReports"
      /> */}
      <div className={"saved-reports-table"}>
        <SavedReportsTable
          columnNames={columnNames}
          rowsData={formattedData}
          handleSort={handleSort}
          sortedColumn={columnName}
          sortDirection={sortDirection}
          handleDownload={handleDownload}
        />
      </div>
    </>
  );
};
export default SavedReports;
