import axios from '.';

export const addContractSignature = (data: any) => {
  return axios({
    method: 'POST',
    url: 'contracts/signature',
    data
  });
};

export const getContractSignatures = (offset: number, limit: number, searchText?: string, sortBy?: string, sortDirection?: string) => {
    return axios({
      url: `contracts/signature`,
      method: 'GET',
      params: {
          offset: offset, 
          limit: limit, 
          sortBy: sortBy,
          sortDirection: sortDirection,
          searchText: searchText,
        },
    });
  };

export const updateContractSignature = (id: number, data: any) => {
    return axios({
      method: 'PUT',
      url: `/contracts/signature/${id}`,
      data
    });
  };

export const removeContractSignature = ( id: number) => {
    return axios({
      url: `/contracts/signature/${id}`,
      method: 'DELETE',
    });
  };

export const uploadContractSignatureFile = (id: number, data: any) => {
    return axios({
      method: 'POST',
      url: `/contracts/signature/upload/${id}`,
      data
    });
  };


