import React from "react";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import closeIcon from "../../../assets/icons/closeIcon.svg";
import "./view-regional-reps.scss";
import { ViewComponentProps } from "../types/view-regional-reps-props";

const ViewRegionalReps: React.FC<ViewComponentProps> = ({
  isOpen,
  viewData,
  toggleView,
}) =>{
  const formatAddress = (address: any) => {
    const street = address?.street || "-";
    const city = address?.City?.name || "-";
    const state = address?.State?.name || "-";
    const zipcode = address?.zipcode || "-";
    return `${street}, ${city}, ${state}, ${zipcode}`;
  };
  return (
    <div className="add-or-edit-offcanvas">
    <Offcanvas isOpen={isOpen} direction="end" className="offcanvas">
       <OffcanvasHeader className="offcanvas-header">
          <div className="header-div">
            <h2 className="mb-0 scroll-text">{viewData?.name ?? "-"}</h2>
            <button onClick={toggleView}>
              <img src={closeIcon} alt="Close" />
            </button>
          </div>
        </OffcanvasHeader>
      <OffcanvasBody className="offcanvas-body">
        <div className="view-form">
          <div className="form-group">
            <label>Email</label>
            <div className="data-value scrollable-text">{viewData?.email }</div>
          </div>
          <div className="form-group">
            <label>Phone Number</label>
            <div className="data-value">{viewData?.phone_number }</div>
          </div>
          <div className="form-group">
            <label>Address</label>
            <div className="data-value scrollable-text"> {viewData?.address ? formatAddress(viewData.address) : "_"}</div>
          </div>
          <div className="form-group">
            <label>Region/State/Agencies Assigned</label>
            <div className="data-value scrollable-text">{viewData?.region?.trim() ? viewData.region : "-"}</div>
          </div>
        </div>
      </OffcanvasBody>
    </Offcanvas>
    </div>
  );
};

export default ViewRegionalReps;
