import React from "react";
import closeIcon from "../../../assets/icons/closeIcon.svg";
import "./import-modal.scss";
import {
  S3_IMPORT_TEMPLATE_FOLDER,
  IMPORT_TEMPLATES,
} from "../../../constants/s3UploadTemplates";

interface ImportModalProps {
  openModal: boolean;
  toggleModal: () => void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  module?: string;
}

const ImportModal: React.FC<ImportModalProps> = ({
  openModal,
  toggleModal,
  handleFileChange,
  module,
}) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  if (!openModal) return null;
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <p>Import Bulk Records</p>
          <button className="close-button" onClick={toggleModal}>
            <img src={closeIcon} alt="Close" />
          </button>
        </div>
        <div className="modal-body">
          <div className="template-section">
            <p>
              Upload a XLS file with the correct template in order to
              successfully import records in bulk.
            </p>
            <a
              href={`${
                process.env.REACT_APP_S3_BULK_UPLOAD_BUCKET_DOMAIN
              }/${S3_IMPORT_TEMPLATE_FOLDER}/${
                IMPORT_TEMPLATES[module as keyof typeof IMPORT_TEMPLATES]
              }`}
              className="download-link"
            >
              Download sample template file
            </a>
          </div>
          <div className="upload-section">
            <button className="upload-button" onClick={handleButtonClick}>
              Select Upload File
            </button>
            <input
              type="file"
              ref={fileInputRef}
              className="file-input"
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept=".xls,.xlsx"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportModal;
