import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadcrumbComponent from "../../../common/components/breadcrumb-component/breadcrumb-component";
import { Container } from "reactstrap";
import _ from "lodash";
import SearchBar from "../../../common/components/search/search-bar";
import TableComponent from "../../../common/components/table-component/table-component";
import "./contracts-signature.scss";
import AddOrEditContractSignature from "./add-or-edit-contracts-signature";
import {
  getContractSignatures,
  removeContractSignature,
} from "../../../api/contractSignature";
import PaginationComponent from "../../../common/components/pagination-component/pagination-component";
import DeleteConfirmationPopup from "../../directory/delete-confirmation-popup/delete-confirmation-popup";
import trash from "../../../assets/icons/trashIcon.svg";
import edit from "../../../assets/icons/edit.svg";
import view from "../../../assets/icons/viewIcon.svg";
import PositionedMenu from "../../../common/components/positioned-menu/positioned-menu";
import { useSetLoading } from "../../../common/hooks/appearance";
import ViewContractSignature from "./view-contract-signature";
import PositionedImage from "./positioned-image";

const ContractsSignatures: React.FC = () => {
  const [data, setData] = useState<any[]>([]);
  const [searchText, setSearchText] = useState<any>(null);
  const [isReset, setIsReset] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [addEditState, setAddEditState] = useState<{
    isOpen: boolean;
    mode: "add" | "edit" | null;
    selectedId: number | null;
  }>({
    isOpen: false,
    mode: null,
    selectedId: null,
  });
  const [error, setError] = useState<string | null>(null);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize] = useState<number>(15);
  const [sortBy, setSortBy] = useState<string>("created_at");
  const [sortDirection, setSortDirection] = useState<string>("desc");
  const [columnName, setCloumnName] = useState<string>("");
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOptions, setMenuOptions] = useState<any[]>([]);
  const [isDeletePopupOpen, setDeletePopupOpen] = useState<boolean>(false);
  const [deleteSignatureId, setDeleteSignatureId] = useState<number | null>(
    null
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [imageUrl, setImageUrl] = useState<string>("");

  let crumbMapping = [
    { label: "Contracts", link: "/home/contracts/contracts-signature" },
    {
      label: "Contracts Signature",
      link: "/home/contracts/contracts-signature",
    },
  ];

  const columnNames: string[] = [
    "Actions",
    "Signature Name",
    "First Name",
    "Last Name",
    "Designation",
    "Signature Image",
  ];

  const handleSearch = _.debounce((text: string) => {
    setSearchText(text);
  }, 500);

  const toggleAddEdit = (mode: "add" | "edit" | null, id?: number) => {
    setAddEditState((prevState) => ({
      isOpen: !prevState.isOpen,
      mode: !prevState.isOpen ? mode : null,
      selectedId: !prevState.isOpen ? id ?? null : null,
    }));
  };
  const [viewState, setViewState] = useState<{
    viewOpen: boolean;
    selectedId: number | null;
  }>({
    viewOpen: false,
    selectedId: null,
  });

  const toggleView = (id: number) => {
    setViewState((prevState) => ({
      viewOpen: !prevState.viewOpen,
      selectedId: id,
    }));
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const fetchContractSignatures =useCallback( async (page = 1, search = "") => {
    setLoading(true);
    const offset = (page - 1) * pageSize;
    try {
      const response = await getContractSignatures(
        offset,
        pageSize,
        search,
        sortBy,
        sortDirection
      );
      setData(response.data.data.data);
      setTotalRecords(response.data.data.totalRecords);
      setTimeout(() => setLoading(false), 1000);
    } catch (error) {
      setError("Failed to fetch contract Signatures");
      setLoading(false);
    }
  },[pageSize, sortBy, sortDirection] );

  useEffect(() => {
    if(searchText !== null){
      setCurrentPage(1);
      fetchContractSignatures(1, searchText);
    }
  }, [searchText,fetchContractSignatures]);

  useEffect(() => {
    fetchContractSignatures(currentPage, searchText);
  }, [currentPage, searchText,sortBy, sortDirection,fetchContractSignatures]);

  const formattedData = useMemo(() => {
    if (Array.isArray(data)) {
      return data.map((item) => ({
        id: item.id,
        "Signature Name": item.signature_name,
        "First Name": item.first_name,
        "Last Name": item.last_name,
        Designation: item.designation,
        "Signature Image": item.signature_image_url,
      }));
    } else {
      return [];
    }
  }, [data]);

  const mapColumnNameToField = (columnName: string): string => {
    const mapping: Record<string, string> = {
      "Signature Name": "signature_name",
      "First Name": "first_name",
      "Last Name": "last_name",
      Designation: "designation",
      "Signature Image": "signature_image_url",
    };

    return mapping[columnName] || columnName;
  };

  const handleSort = (columnName: string) => {
    const mappedField = mapColumnNameToField(columnName);
    if (sortBy === mappedField) {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortBy(mappedField);
      setCloumnName(columnName);
      setSortDirection("asc");
    }
  };

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    rowId: number
  ) => {
    setMenuOptions([
      { label: "Edit Signature", onClick: () => handleEdit(rowId), icon: edit },
      { label: "View Signature", onClick: () => handleView(rowId), icon: view },
      {
        label: "Delete Signature",
        onClick: () => {
          setDeleteSignatureId(rowId);
          setDeletePopupOpen(true);
          handleMenuClose();
        },
        icon: trash,
      },
    ]);
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleEdit = (rowId: number) => {
    toggleAddEdit("edit", rowId);
    handleMenuClose();
  };
  const handleView = (rowId: number) => {
    toggleView(rowId);
    handleMenuClose();
  };

  const handleDelete = async (deleteSignatureId: number | null) => {
    try {
      if (deleteSignatureId === null) {
        console.error("No Siganture ID provided for deletion");
        return;
      }
      const response = await removeContractSignature(deleteSignatureId);
      if (response.data.success) {
        fetchContractSignatures();
      }
      setDeletePopupOpen(false);
    } catch (error) {
      console.error("Error while deleting Contract Signature:", error);
    }
  };

  const handleViewImage = (event: React.MouseEvent<HTMLElement>,imageSrc: string) => {
    setImageUrl(imageSrc);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setImageUrl("");
  };


  const showLoading = useMemo(() => loading, [loading]);
  useSetLoading(showLoading);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Container>
      <BreadcrumbComponent breadcrumbLink={crumbMapping} />
      <SearchBar
        placeholder={`Search by Signature name, first name, last name, designation`}
        toggleAddEdit={toggleAddEdit}
        onSearch={handleSearch}
        module="ContractSignatures"
        isReset={isReset}
      />
      {formattedData.length > 0 ? (
        <>
          <div className={"contracts-signature-table"}>
            <TableComponent
              columnNames={columnNames}
              contracts={true}
              rowsData={formattedData}
              handleSort={handleSort}
              sortedColumn={columnName}
              sortDirection={sortDirection}
              onMenuClick={handleMenuOpen}
              onImageClick={handleViewImage}
            />
          </div>
          {totalRecords > 0 && (
            <PaginationComponent
              totalRecords={totalRecords}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          )}
        </>
      ) : (
        !loading && (
          <div className="no-results">
            <p>No results found</p>
          </div>
        )
      )}
      {addEditState?.isOpen && (
        <AddOrEditContractSignature
          isOpen={addEditState?.isOpen}
          toggle={() => {
            toggleAddEdit(null);
          }}
          mode={addEditState?.mode}
          editData={data.find((item) => item.id === addEditState.selectedId)}
          refreshData={()=>{
            setSearchText(null);
            setCurrentPage(1);
            setIsReset(!isReset);
            fetchContractSignatures();
          }}
        />
      )}

      <PositionedMenu
        menuOptions={menuOptions}
        anchorEl={menuAnchorEl}
        onClose={handleMenuClose}
      />

      <PositionedImage anchorEl={anchorEl} imageUrl={imageUrl} onClose={handleClose} />

      <DeleteConfirmationPopup
        isOpen={isDeletePopupOpen}
        onClose={() => setDeletePopupOpen(!isDeletePopupOpen)}
        handleDelete={handleDelete}
        deleteId={deleteSignatureId}
        label="Signature"
      />
      {viewState.viewOpen && (
        <ViewContractSignature
          isOpen={viewState.viewOpen}
          viewData={data.find((item) => item.id === viewState.selectedId)}
          toggleView={toggleView}
        />
      )}
    </Container>
  );
};
export default ContractsSignatures;
