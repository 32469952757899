export const routes = {
  LOGIN: '/',
  HOME: '/home/*',
  NO_ROLE: '/no-role',
  DASHBOARD: '/home/dashboard',
  EMAILVERIFICATION: '/verify-email',
  RESETPASSWORD: '/reset-password/',
  SETPASSWORD: '/set-password',
  FORGOTPASSWORD: '/forgot-password'
};
