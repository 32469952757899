import axios from '.';

export const getUser = () => {
  return axios({
    method: 'GET',
    url: '/profile',
  });
};

export const updateUser = (userId: number | null, userData: any) => {
  return axios({
    method: 'PUT',
    url: `/profile/${userId}`,
    data: userData,
  });
};

export const getAutoFillLocation = (
  key?: string,
  value?: string,
  module?: string
) => {
  const params: Record<string, string> = {};

  if (key) params.key = key;
  if (value) params.value = value;
  if (module) params.module = module;

  return axios({
    method: "GET",
    url: "/search",
    params,
  });
};

export const uploadProfileImageApi = (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  return axios({
    method: 'POST',
    url: '/profile/upload-image',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

