import React from "react";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import closeIcon from "../../../assets/icons/closeIcon.svg";
import "./view-agents.scss";
import { ViewComponentProps } from "../types/view-regional-reps-props";
import { FuneralHomeAssociated, StateAppointed } from "./agents";

const ViewAgents: React.FC<ViewComponentProps> = ({
  isOpen,
  viewData,
  toggleView,
}) =>{
  const formatAddress = (address: any) => {
    const street = address?.street || "-";
    const city = address?.City?.name || "-";
    const state = address?.State?.name || "-";
    const zipcode = address?.zipcode || "-";
    return `${street}, ${city}, ${state}, ${zipcode}`;
  };

  return (
    <div className="add-or-edit-offcanvas">
        <Offcanvas isOpen={isOpen} direction="end" className="view-funeral-homes">
            <OffcanvasHeader className="offcanvas-header">
                <div className="header-div">
                    <h2 className="mb-0">{viewData?.name ?? "-"}</h2>
                    <button onClick={toggleView}>
                    <img src={closeIcon} alt="Close" />
                    </button>
                </div>
            </OffcanvasHeader>
            <OffcanvasBody className="offcanvas-body">
                <div className="view-form">
                <div className="form-group">
                    <label>Entity Name</label>
                    <div className="data-value">{viewData?.entity_name ||"-"}</div>
                </div>
                <div className="form-group">
                    <label> Mailing Address</label>
                    <div className="data-value">{viewData?.mailingAddress ? formatAddress(viewData.mailingAddress):"-"}</div>
                </div>
                <div className="form-group">
                    <label>Home Address</label>
                    <div className="data-value">{viewData?.homeAddress ? formatAddress(viewData.homeAddress):"-"}</div>
                </div>
                <div className="form-group">
                    <label>Home Phone</label>
                    <div className="data-value">{viewData?.home_phone || "_" }</div>
                </div>
                <div className="form-group">
                    <label>Work Phone</label>
                    <div className="data-value">{viewData?.work_phone || "_" }</div>
                </div>
                <div className="form-group">
                    <label>Cell Phone</label>
                    <div className="data-value">{viewData?.cell_phone || "-"}</div>
                </div>
                <div className="form-group">
                    <label>Email Address</label>
                    <div className="data-value">{viewData?.email_address || "-"}</div>
                </div>
                <div className="form-group">
                    <label>Special Instructions</label>
                    <div className="data-value">{viewData?.special_instruction || "-"}</div>
                </div>
                <div className="form-group">
                    <label>Gender</label>
                    <div className="data-value">{viewData?.gender || "-"}</div>
                </div>
                <div className="form-group">
                    <label>Agency</label>
                    <div className="data-value">{viewData?.agency || "-"}</div>
                </div>
                <div className="form-group">
                    <label>	Tax ID</label>
                    <div className="data-value">{viewData?.companies_contracted_with || "-"}</div>
                </div>
                <div className="form-group">
                    <label>Bank Routing Number</label>
                    <div className="data-value">{viewData?.bank_routing_number || "-"}</div>
                </div>
                <div className="form-group">
                    <label>Bank Account Number</label>
                    <div className="data-value">{viewData?.bank_account_number || "-"}</div>
                </div>
                <div className="form-group">
                    <label>Checking or Savings</label>
                    <div className="data-value">{viewData?.account_type || "-"}</div>
                </div>
                <div className="form-group">
                    <label>EIN</label>
                    <div className="data-value">{viewData?.ein || "-"}</div>
                </div>
                <div className="form-group">
                    <label>Date Of Birth</label>
                    <div className="data-value">{viewData?.date_of_birth || "-"}</div>
                </div>
                <div className="form-group">
                    <label>States Licensed In</label>
                    <div className="data-value">{viewData?.states_licensed?.name || "-"}</div>
                </div>
                <div className="form-group">
                    <label>License Expiration (each state)</label>
                    <div className="data-value">{viewData?.license_expiration || "-"}</div>
                </div>
                <div className="form-group">
                    <label>AML Certification (each company)</label>
                    <div className="data-value">{viewData?.aml_certification || "-"}</div>
                </div>
                <div className="form-group">
                    <label>Reserve Balance (each company)</label>
                    <div className="data-value">{viewData?.reserve_balance || "-"}</div>
                </div>
                <div className="form-group">
                    <label>Agency Number (each company)</label>
                    <div className="data-value">{viewData?.agency_number || "-"}</div>
                </div>
                <div className="form-group">
                    <label>Commission Level</label>
                    <div className="data-value">{viewData?.commissionLevel?.commission_name || "-"}</div>
                </div>
                <div className="form-group">
                    <label>Regional Rep</label>
                    <div className="data-value">{viewData?.regional_rep_id || "-"}</div>
                </div>
                <div className="form-group">
                    <label>States Appointed</label>
                    <div className="data-value">{viewData?.agent_states_appointed.map((state: StateAppointed) => state.states_appointed_in.name).join(", ")||"-"}</div>
                </div>
                <div className="form-group">
                    <label>Agent Number</label>
                    <div className="data-value">{viewData?.agent_number||"-"}</div>
                </div>
                <div className="form-group">
                    <label>Upline Agents</label>
                    <div className="data-value">{viewData?.uplineAgent?.name || "-"}</div>
                </div>
                <div className="form-group">
                    <label>Funeral Home Associated With</label>
                    <div className="data-value">{viewData?.agent_funeral_home_associated.map((state: FuneralHomeAssociated) => state.funeral_home_associated.name).join(", ") || "-"}</div>
                </div>
                <div className="form-group">
                    <label>Beneficiary</label>
                    <div className="data-value">{viewData?.beneficiary||"-"}</div>
                </div>
                </div>
            </OffcanvasBody>
        </Offcanvas>
    </div>
  );
};

export default ViewAgents;
