/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { getAllAdditionalIdDetails, removeAdditionalIdDetails } from '../../../../../../../api/funeralhomes';
import './additional-ids.scss';
import AdditionalIdsTable from './additional-ids-table/additional-ids-table';
import { columnNames } from './additional-ids.constant';
import DeleteConfirmationPopup from '../delete-confirmation-popup/delete-confirmation-popup';
import AlertComponent from '../../../../../../../common/components/Alert/Alert';
const AdditionalIds: any = (props: any) => {
  const { funeralHomeId, setLoading, addMoreLabel, setAddMoreLabel } = props;
  const [rowsData, setRowsData] = useState<any[]>([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const [isDeletePopupOpen, setDeletePopupOpen] = useState<boolean>(false);
  const [deleteAdditionalId, setDeleteAdditionalId] = useState<number | null>(null);

  const handleEditClick = (id: any) => {
    const updatedrowsData = rowsData
      ?.map?.((data: any) => {
        return {
          ...data,
          isEdit: data?.id === id ? !data?.isEdit : false,
        };
      })
      ?.filter((data) => !data?.isNewAdded);
    setRowsData(updatedrowsData);
  };

  const handleAddMoreId = (id: any, data?: any) => {
    const tempRowsData = data?.length ? data : rowsData;
    const updatedrowsData = [
      ...tempRowsData
        ?.map?.((data: any) => {
          return {
            ...data,
            isEdit: data?.id === id ? !data?.isEdit : false,
          };
        })
        ?.filter((data: any) => !data?.isNewAdded),
      {
        Edit: '',
        id: null,
        additionalIdFocusedField: null,
        companyOptions: [],
        selectedCompany: null,
        comapnyName: '',
        funeralHomeIds: '',
        overrideId: '',

        isEdit: true,
        isNewAdded: true,
      },
    ];
    setRowsData(updatedrowsData);
  };

  const handleGetAdditionalIds = async () => {
    try {
      setLoading(true);
      const getAPIDetails = await getAllAdditionalIdDetails(funeralHomeId);
      setLoading(false);
      const tempRowsData = getAPIDetails?.data?.data?.map((data: any) => {
        return {
          Edit: '',
          id: data?.id,
          additionalIdFocusedField: null,
          companyOptions: [],
          selectedCompany: data?.company ? { id: data.company.id, name: data.company.name } : null,
          companyName: data?.company?.name || '-',
          funeralHomeIds: data?.company_given_id || '-',
          overrideId: data?.override_id || '-',
          isEdit: false,
        };
      });
      setRowsData(tempRowsData);
      if (addMoreLabel === 'Add Additional Ids') {
        setAddMoreLabel(null);
        handleAddMoreId(null, tempRowsData);
      }
    } catch {
      setLoading(false);
    }
  };

  const handleDeleteClick = (id: any) => {
    setDeleteAdditionalId(id);
    setDeletePopupOpen(true);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await removeAdditionalIdDetails(funeralHomeId, deleteAdditionalId);
      setLoading(false);
      setDeleteAdditionalId(null);
      setDeletePopupOpen(false);
      handleGetAdditionalIds();
    } catch (error) {
      const errorMessage =
        (typeof error === 'object' && error !== null && 'response' in error && (error as any).response?.data?.details?.[0]) ||
        (error instanceof Error ? error.message : 'An unexpected error occurred.');
      setAlertMessage(errorMessage);
      setAlertType('error');
      setAlertOpen(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('addmorelabel', addMoreLabel);
    // if(addMoreLabel === 'Add Additional Ids'){
    handleGetAdditionalIds();
    // }
  }, [addMoreLabel]);

  return (
    <div className="view-funeral-homes-right-container">
      <AdditionalIdsTable
        columnNames={columnNames}
        rowsData={rowsData}
        handleEditClick={handleEditClick}
        handleDeleteClick={handleDeleteClick}
        handleAddMoreId={handleAddMoreId}
        setLoading={setLoading}
        funeralHomeId={funeralHomeId}
        handleGetAdditionalIds={handleGetAdditionalIds}
        setAlertOpen={setAlertOpen}
        setAlertType={setAlertType}
        setAlertMessage={setAlertMessage}
      />
      <DeleteConfirmationPopup
        isOpen={isDeletePopupOpen}
        onClose={() => setDeletePopupOpen(!isDeletePopupOpen)}
        handleDelete={handleDelete}
        label="Additional ID"
      />
      <AlertComponent message={alertMessage} type={alertType} open={alertOpen} onClose={() => setAlertOpen(false)} />
    </div>
  );
};

export default AdditionalIds;
