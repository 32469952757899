import { Formik, Form, FormikHelpers, ErrorMessage } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import {
  DatePicker,
  SelectDropdownComponent,
} from "../../../common/components";
import BreadcrumbComponent from "../../../common/components/breadcrumb-component/breadcrumb-component";
import "./generate-reports.scss";
import * as Yup from "yup";
import { Autocomplete, TextField } from "@mui/material";
import { getAutoFillLocation } from "../../../api/profile";
import { debounce } from "lodash";
import { getModulesInGenerateReports, getSubModulesForModuleInGenerateReports } from "../../../api/reports/generate-reports";
import { LeadSource } from "../../leads-item/leads-item-props";
import { exportLeadsData, getLeadSources } from "../../../api/leads";
import AlertComponent from "../../../common/components/Alert/Alert";
import { exportUsersData } from "../../../api/user";
import { exportFuneralHomeData } from "../../../api/funeralhomes";
import { exportAgenciesData } from "../../../api/agencies";
import { exportAgentData } from "../../../api/agents";
import { exportRegionalRepList } from "../../../api/directories/regionalRep";
import { exportCompaniesList } from "../../../api/directories/companies";
import { formateDateToMMDDYYYY } from "../../directory/utils";
interface DropdownOption {
  label: string;
  value: string;
}

interface LocationOption {
  id: number;
  name: string;
  State?: any;
  Country?: any;
  Cities?: LocationOption[];
}
interface FormValues {
  fromDate: string;
  toDate: string;
  module: string;
  subModule?: string;
  leadSource?: string[];
  leadType?: string;
  country?: {id: number, name: string} | null;
  state?: {id: number, name: string} | null;
  city?: {id: number, name: string} | null;
};
const GenerateReports: React.FC = () => {
  const [module, setModule] = useState<string>("");
  const [countryOptions, setCountryOptions] = useState<LocationOption[]>([]);
  const [stateOptions, setStateOptions] = useState<LocationOption[]>([]);
  const [cityStateOptions, setCityStateOptions] = useState<LocationOption[]>([]);
  const [cityOptions, setCityOptions] = useState<LocationOption[]>([]);
  const [focusedField, setFocusedField] = useState<string | null>(null);
  const [subModules, setSubModules] = useState<any[]>([]);
  const [modules, setModulesForDropdown] = useState<any[]>([]);
  const [leadSources, setLeadSources] = useState<LeadSource[]>([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<
    "success" | "error" | "info" | "warning"
  >("success");
  //const [loading, setLoading] = useState<boolean>(true);
  //const [error, setError] = useState<string | null>(null);

  let crumbMapping = [
    { label: "Reports", link: "/home/reports/generate-reports" },
    { label: "Generate Reports", link: "home/reports/generate-reports" },
  ];
  const initialValues = {
    fromDate: "",
    toDate: "",
    module: "",
    subModule: "",
    leadSource: [],
    leadType: "",
    country: null,
    state: null,
    city: null,
  };
  const getValidationSchema = (module: string) => {
    const commonSchema = {
      fromDate: Yup.date().required("From Date is required"),
      toDate: Yup.date().required("To Date is required"),
      module: Yup.string().required("Module is required"),
    };
    let conditionalSchema = {};
    if (module === "Directory" || module === 'Users') {
      conditionalSchema = {
        subModule: Yup.string().required("Sub Module is required")
      };
    };
    return Yup.object().shape({
      ...commonSchema,
      ...conditionalSchema,
    });
  }
  const selectDropDownCustomStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      border: "none",
      backgroundColor: "#F7F7F7",
      boxShadow: state.isFocused ? "0 0 0 1px #887952" : provided.boxShadow,
      "&:hover": {
        border: "none",
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: "#B3B3B3", 
      fontSize: "16px", 
    }),
    option: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: state.isSelected ? "#887952" : "white",
      ":hover": {
        backgroundColor: state.isSelected ? "#887952" : "#EEEE",
      },
    }),
  };
  const fetchOptions =useCallback(async (key: string, value: string) => {
    try {
      if (value.length < 3) return;
      const response = await getAutoFillLocation(key, value);
      const data = response.data.data;
      const locationData = data as LocationOption[];

      if (key === "city") {
        setCityStateOptions(locationData);
        const uniqueCities = Array.from(
          new Map(locationData.map((item) => [item.name, item])).values()
        );
        setCityOptions(uniqueCities);
      } else if (key === "state") {
        setStateOptions(locationData);
        locationData.forEach((ele) => {
          if (ele.Cities) setCityOptions(ele.Cities);
        });
      } else if (key === "country") {
        setCountryOptions(locationData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },[]);
  const handleCityChange = (event: any, newValue: LocationOption | null, setFieldValue: (field: string, value: any) => void) => {
    if (newValue) {
      setFieldValue("city", newValue);

      const states = cityStateOptions
        .filter((city) => city.name === newValue.name)
        .map((city) => city.State);

      setStateOptions(states);

      if (states.length > 0) {
        const firstState = states[0];
        setFieldValue("state", firstState);

        if (firstState.Country) {
          setFieldValue("country",firstState.Country);
        }
      }
    } else {
      setFieldValue("city", null);
      setStateOptions([]);
      setFieldValue("state", null);
      setFieldValue("country", null);
    }
  };
  const handleStateChange = (event: any, newValue: LocationOption | null, setFieldValue: (field: string, value: any) => void) => {
    if (newValue) {
      setFieldValue("state", newValue);
      setFieldValue("country", newValue.Country || null);
      setCityOptions(newValue.Cities || []);
    } else {
      setFieldValue("state", null);
      setCityOptions([]);
      setFieldValue("city", null);
    }
  };
  const handleCountryChange = (event: any, newValue: LocationOption | null, setFieldValue: (field: string, value: any) => void) => {
    if (newValue) {
      setFieldValue?.("country", newValue);
      setFieldValue?.("state", null);
      setCityOptions([]);
    } else {
      setFieldValue("country", null);
      setFieldValue("state", null);
      setFieldValue("city", null);
      setCityOptions([]);
    }
  };
  const clearIrrelevantFields = (values: FormValues, setFieldValue: FormikHelpers<any>["setFieldValue"]) => {
    const retainedFields = ["module", "fromDate", "toDate"];
    Object.keys(values).forEach((field) => {
      if (!retainedFields.includes(field)) {
        setFieldValue(field, null); 
      }
    });
  };
  const debouncedFetchOptions = useMemo(() => debounce(fetchOptions, 500), [fetchOptions]);

  //const debouncedFetchOptions = useCallback(debounce(fetchOptions, 500), []);
  const handleFocus = (field: string) => setFocusedField(field);

  const fetchLeadSources = async () => {
    try {
      const response = await getLeadSources();
      const sources = response?.data?.data?.data || [];
      setLeadSources(sources);
    } catch (error: unknown) {
      console.error(error)
    }
  };
  const fetchSubModules =useCallback( async () => {
    try {
      const res = await getSubModulesForModuleInGenerateReports(module);
      if (module === 'Users') {
        const data = res.data.data.filter((item: { id: number; name: string }) => item.name !== "Roles");
        setSubModules(data)
      } else {
        setSubModules(res.data.data);
      }
    } catch (error: unknown) {
      console.error(error);
    }
  }, [module]);
  const fetchModules =useCallback( async () => {
    try {
      const res = await getModulesInGenerateReports();
      setModulesForDropdown(res.data.data);
    } catch (error: unknown) {
      console.error(error);
    }
  }, []);
  useEffect(() => {
    fetchModules();
    if (module) {
      fetchSubModules();
    }
    if (module === 'Leads') {
      fetchLeadSources();
    }
  }, [module,fetchModules,fetchSubModules]);
  const getDownloadFunction = async (module: string, subModule?: string) => {
    switch (module) {
      case "Directory":
        switch(subModule) {
          case "Funeral Homes":
            return exportFuneralHomeData;
          case "Agencies":
            return exportAgenciesData;
          case "Agents":
            return exportAgentData;
          case "Regional Reps":
            return exportRegionalRepList;
          case "Companies":
            return exportCompaniesList
          default:
            return async () => ({});
        }
      case "Leads":
        return exportLeadsData;
      case "Users":
        return exportUsersData;
      default:
        return async () => ({});
    }
  };
  const preparePayloadForDownloadFunction = (args: FormValues) => {
    switch(args?.module) {
      case "Directory":
        return {
          fromDate: args?.fromDate,
          toDate: args?.toDate,
          cityId: args?.city?.id,
          stateId: args?.state?.id
        }
      case "Leads":
        return {
          fromDate: args?.fromDate,
          toDate: args?.toDate,
          cityId: args?.city?.id,
          stateId: args?.state?.id,
          leadSource: args?.leadSource,
          leadType: args?.leadType
        }
      case "Users":
        return {
          fromDate: args.fromDate,
          toDate: args.toDate,
        }
      default:
        return {
          valid: false,
          error: "Unsupported module",
        };
    }    
  }
  const handleDownload = async (downloadFunction: (args: any) => Promise<any>, args: FormValues) => {
    //setLoading(true);
    const payload = preparePayloadForDownloadFunction(args);
    try {
      const response = await downloadFunction(payload);
      const responseText = await response.data.text();
      const responseData = JSON.parse(responseText); 
      if (responseData?.success) {
        setAlertMessage("Sent Downloadable link to Email , Please check");
        setAlertType("success");
      } else {
        setAlertMessage("No records found");
        setAlertType("warning");
      }
      //setTimeout(() => setLoading(false), 1000);
      setAlertOpen(true);
    } catch (error) {
      setAlertMessage("Failed to download RegionalReps data");
      setAlertType("error");
      setAlertOpen(true);
      //setError('Failed to download file');
      //setLoading(false);
    }
  };
  const onSubmit = async (values: typeof initialValues, { resetForm }: any) => {
    try {
      const downloadFunction = await getDownloadFunction(values?.module, values?.subModule);
      await handleDownload(downloadFunction, values);
    } catch(error: unknown) {
      console.error(error);
    }
    resetForm();
    setCountryOptions([]);
    setStateOptions([]);
    setCityOptions([]);
    setModule("");
    setSubModules([]);
  };
  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  const handleReset = (resetForm: ()=> void) => {
    resetForm();
    setCountryOptions([]);
    setStateOptions([]);
    setCityOptions([]);
  }
  return (
    <>
      <AlertComponent
        message={alertMessage}
        type={alertType}
        open={alertOpen}
        onClose={handleAlertClose}
      />
      <BreadcrumbComponent breadcrumbLink={crumbMapping} />
      <div className={"generate-reports-container"}>
        <div className="generate-reports-title-div">
          <h3>Select to Generate Report</h3>
        </div>
        <div className="generate-reports-form">
          <Formik
            initialValues={initialValues}
            validationSchema={getValidationSchema(module)}
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue, isSubmitting, resetForm, setValues }) => (
              <Form>
                <Row xs="12" className="generate-reports-row">
                  <Col xs="12" md="6" className="generate-reports-col">
                    <FormGroup className="generate-reports-form-group">
                      <Label htmlFor="fromDate" className="custom-form-label">
                        From Date
                      </Label>
                      <DatePicker
                        placeholder="Select From Date"
                        maxDate= {values?.toDate ? new Date(values.toDate) : new Date()}
                        value={
                          values.fromDate
                            ? new Date(values.fromDate)
                            : undefined
                        }
                        onChange={(selected) => {
                            setFieldValue("fromDate", selected
                            ? formateDateToMMDDYYYY(selected)
                            : null);
                        }}
                      />
                      <ErrorMessage
                        name="fromDate"
                        component="div"
                        className="error-message"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" className="generate-reports-col">
                    <FormGroup className="generate-reports-form-group">
                      <Label htmlFor="toDate" className="custom-form-label">
                        To Date
                      </Label>
                      <DatePicker
                        placeholder="Select To Date"
                        maxDate= {new Date()}
                        minDate={values.fromDate ? new Date(values.fromDate) : undefined}
                        value={
                          values.toDate ? new Date(values.toDate) : undefined
                        }
                        onChange={(selected) => {
                            setFieldValue("toDate", selected
                            ? formateDateToMMDDYYYY(selected)
                            : null);
                        }}
                      />
                      <ErrorMessage
                        name="toDate"
                        component="div"
                        className="error-message"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row xs="12" className="generate-reports-row">
                  <Col xs="12" md="6" className="generate-reports-col">
                  <FormGroup className="generate-reports-form-group">
                  <Label htmlFor="module" className="custom-form-label">
                    Select Module
                  </Label>
                  <SelectDropdownComponent
                    placeholder="Select module"
                    options={modules.map((module) => ({
                      label: module.name,
                      value: module.name
                    }))}
                    isSearchable={true}
                    isClearable={true}
                    customStyles={selectDropDownCustomStyles}
                    value={values.module ? { label: values.module, value: values.module } : null}
                    onChange={(selected) => {
                      const selectedOption = selected as DropdownOption;
                      setFieldValue("module", selectedOption?.value);
                      setModule(selectedOption?.value);
                      clearIrrelevantFields(values, setFieldValue);
                    }}
                  />
                  <ErrorMessage
                    name="module"
                    component="div"
                    className="error-message"
                  />
                  </FormGroup>
                  </Col>
                  {(values.module === "Directory" || values.module === 'Users') && (
                    <Col xs="12" md="6" className="generate-reports-col">
                    <FormGroup className="generate-reports-form-group">
                    <Label htmlFor="subModule" className="custom-form-label">
                      Select Sub Module
                    </Label>
                    <SelectDropdownComponent
                      placeholder="Select sub module"
                      options={subModules.map((subModule) => ({
                        label: subModule.name,
                        value: subModule.name
                      }))}
                      isSearchable={true}
                      isClearable={true}
                      customStyles={selectDropDownCustomStyles}
                      value={values.subModule ? { label: values.subModule, value: values.subModule } : null}
                      onChange={(selected) => {
                        const selectedOption = selected as DropdownOption;
                        setFieldValue("subModule", selectedOption?.value);
                      }}
                    />
                    <ErrorMessage
                    name="subModule"
                    component="div"
                    className="error-message"
                  />
                    </FormGroup>
                    </Col>
                  )}
                </Row>
                {values.module === 'Leads' && (
                  <Row xs="12" className="generate-reports-row">
                  <Col xs="12" md="6" className="generate-reports-col">
                  <FormGroup className="generate-reports-form-group">
                  <Label htmlFor="module" className="custom-form-label">
                    Select Lead Source
                  </Label>
                  <SelectDropdownComponent
                    placeholder="Select lead source"
                    options={[
                      ...leadSources.map((leadSource) => ({
                        label: leadSource.source,
                        value: leadSource.source,
                      })),
                      { label: 'All', value: 'All'}
                    ]}
                    isMulti={true}
                    isSearchable={true}
                    isClearable={true}
                    customStyles={selectDropDownCustomStyles}
                    value={
                      values.leadSource?.some((source: any) => source.value === "All")
                        ? leadSources.map((leadSource) => ({
                            label: leadSource.source,
                            value: leadSource.source,
                          }))
                        : values.leadSource
                    }
                    onChange = {(selected) => {
                      const selectedOptions = selected as DropdownOption[];
                      if (selectedOptions?.some((option) => option.value === "All")) {
                          setFieldValue(
                            "leadSource",
                            leadSources.map((leadSource) => ({
                              label: leadSource.source,
                              value: leadSource.source,
                            }))
                          );
                      } else {
                        setFieldValue(
                          "leadSource",
                          selectedOptions.map((option) => ({
                            label: option.label,
                            value: option.value,
                          }))
                        );
                      }
                    }}
                  />
                  </FormGroup>
                  </Col>
                  <Col xs="12" md="6" className="generate-reports-col">
                    <FormGroup className="generate-reports-form-group">
                    <Label htmlFor="subModule" className="custom-form-label">
                      Select Lead Type
                    </Label>
                    <SelectDropdownComponent
                      placeholder="Select lead type"
                      options={[
                        { label: "Internal", value: "Internal" },
                        { label: "External", value: "External" },
                        { label: "Both", value: "Both" }
                      ]}
                      isSearchable={true}
                      isClearable={true}
                      customStyles={selectDropDownCustomStyles}
                      value={values.leadType ? { label: values.leadType, value: values.leadType } : null}
                      onChange={(selected) => {
                        const selectedOption = selected as DropdownOption;
                        setFieldValue("leadType", selectedOption?.value);
                      }}
                    />
                    </FormGroup>
                    </Col>
                </Row>
                )}
                {(values.module === "Directory" || values.module === "Leads")&& (
                <Row xs="12" className="generate-reports-row">
                  <Col xs="12" md="6" className="generate-reports-col">
                    <FormGroup className="generate-reports-form-group">
                      <Label className="custom-label">Country</Label>
                      <div className="generate-reports-autocomplete">
                      <Autocomplete
                        options={countryOptions}
                        getOptionLabel={(option) => option?.name || ""}
                        onInputChange={(event, value) => {
                          if (focusedField === "country") {
                            debouncedFetchOptions("country", value);
                          }
                        }}
                        onFocus={() => handleFocus("country")}
                        onChange = {(event, newValue) => handleCountryChange(event, newValue, setFieldValue)}
                        value={values.country}
                        renderInput={(params) => (
                        <TextField {...params} 
                          placeholder="Search Country" 
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: 'none',
                              },
                              '&:hover fieldset': {
                                border: 'none',
                              },
                              '&.Mui-focused fieldset': {
                                border: 'none', 
                              },
                            },
                          }}
                        />
                        )}
                        className="generate-reports-autocomplete-inner-div"
                      />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" className="generate-reports-col">
                    <FormGroup className="generate-reports-form-group">
                      <Label className="custom-label">State</Label>
                      <div className="generate-reports-autocomplete">
                      <Autocomplete
                        options={stateOptions}
                        getOptionLabel={(option) => option?.name || ""}
                        onInputChange={(event, value) => {
                          if (focusedField === "state") {
                            debouncedFetchOptions("state", value);
                          }
                        }}
                        onFocus={() => handleFocus("state")}
                        onChange = {(event, newValue) => handleStateChange(event, newValue, setFieldValue)}
                        value={values.state}
                        renderInput={(params) => (
                          <TextField 
                            {...params} 
                            placeholder="Search State" 
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                },
                                '&:hover fieldset': {
                                  border: 'none',
                                },
                                '&.Mui-focused fieldset': {
                                  border: 'none', 
                                },
                              },
                            }}
                          />
                        )}
                        className="generate-reports-autocomplete-inner-div"
                      />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" className="generate-reports-col">
                    <FormGroup className="generate-reports-form-group">
                      <Label className="custom-label">City</Label>
                      <div className="generate-reports-autocomplete">
                      <Autocomplete
                        options={cityOptions}
                        getOptionLabel={(option) => option?.name || ""}
                        onInputChange={(event, value) => {
                          debouncedFetchOptions("city", value);
                        }}
                        onChange = {(event, newValue) => handleCityChange(event, newValue, setFieldValue)}
                        value={values.city}
                        renderInput={(params) => (
                          <TextField {...params} 
                            placeholder="Search City"
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                },
                                '&:hover fieldset': {
                                  border: 'none',
                                },
                                '&.Mui-focused fieldset': {
                                  border: 'none', 
                                },
                              },
                            }} 
                          />
                        )}
                        className="generate-reports-autocomplete-inner-div"
                      />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>)}
                <div className="generate-reports-buttons-container">
                  <button type="button" className="generate-report-reset-button" onClick={() => handleReset(resetForm)}>Reset</button>
                  <button type="submit" disabled={isSubmitting} className="generate-report-button">
                    Generate Report
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default GenerateReports;