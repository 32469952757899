/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { Row } from 'reactstrap';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { BreadcrumbComponentProps } from './breadcrumb-component-props';
import './breadcrumb-component.scss';

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  event.preventDefault();
}

const BreadcrumbComponent: React.FC<BreadcrumbComponentProps> = ({ breadcrumbLink }) => {
  return (
    <Row className="breadcrumb-component">
      <Stack spacing={2}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {breadcrumbLink?.map((linkData: any, index: any) =>
            index === breadcrumbLink?.length - 1 ? (
              <Typography key="3" sx={{ color: 'text.primary' }}>
                {linkData?.label}
              </Typography>
            ) : (
              <Link
                key={index}
                color="inherit"
                className="breadcrumb-link"
                to={linkData?.link}
                onClick={linkData?.handleClick || handleClick}
              >
                {linkData?.label}
              </Link>
            ),
          )}
        </Breadcrumbs>
      </Stack>
    </Row>
  );
};

export default BreadcrumbComponent;
