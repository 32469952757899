import React, { useEffect, useState } from "react";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  FormGroup,
  Label,
  Button,
} from "reactstrap";
import closeIcon from "../../assets/icons/closeIcon.svg";
import { Formik, Form } from "formik";
import { SelectDropdownComponent } from "../../common/components";
import { selectDropDowncustomStyles } from "../directory/agents/add-or-edit-agents.utils";
import LocationFilters from "../directory/LocationFilters";
import { getLeadSources } from "../../api/leads";
import { LeadSource, DropdownOption } from "./leads-item-props";

interface FilterLeadsProps {
  isOpen: boolean;
  toggleFilter: any;
  page?: number;
  appliedFilters?: any;
  setAppliedFilters:any;
}

const FilterLeads: React.FC<FilterLeadsProps> = ({
  isOpen,
  toggleFilter,
  page = 1,
  appliedFilters = {},
  setAppliedFilters,
}) => {
  const [leadSources, setLeadSources] = useState<LeadSource[]>([]);
  const [resetFlag, setResetFlag] = useState(false);

  const fetchLeadSources = async () => {
    try {
      const response = await getLeadSources();
      setLeadSources(response?.data?.data?.data);
    } catch (error: any) {}
  };

  useEffect(() => {
    fetchLeadSources();
  }, []);

   const initialValues = {
     leadType: appliedFilters.leadType || "",
     leadSourceIds: appliedFilters.leadSourceIds || [],
     countryIds: appliedFilters.countryIds || [],
     stateIds: appliedFilters.stateIds || [],
     cityIds: appliedFilters.cityIds || [],
   };
  const onSubmit = async (values: any) => {
     const isFilterApplied =
       values.leadType ||
       values.leadSourceIds.length > 0 ||
       values.countryIds.length > 0 ||
       values.stateIds.length > 0 ||
       values.cityIds.length > 0;

    if (isFilterApplied) {
      setAppliedFilters(values);
    }
    toggleFilter();
  };

  const handleLeadSourceChange = (selected: any, setFieldValue: any) => {
    setFieldValue(
      "leadSourceIds",
      selected ? selected.map((item: any) => item.value) : []
    );
  };

  const handleReset = (resetForm: () => void) => {
    if (Object.keys(appliedFilters).length > 0) {
      resetForm();
      setResetFlag((prev) => !prev);
      setAppliedFilters({})
      toggleFilter();
    } else {
      resetForm();
      setResetFlag((prev) => !prev);
    }
  };

  return (
    <div className="filter-offcanvas">
      <Offcanvas isOpen={isOpen} direction="end" className="offcanva-box">
        <OffcanvasHeader className="filter-offcanvas-header">
          <div className="header-div">
            <h2 className="mb-0">Filters</h2>
            <button onClick={toggleFilter}>
              <img src={closeIcon} alt="Close" />
            </button>
          </div>
        </OffcanvasHeader>
        <OffcanvasBody className="offcanvas-body">
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, setFieldValue, resetForm }) => (
              <Form className="filters-form">
                <FormGroup className="filter-form-group">
                  <Label className="custom-form-label">Lead Type</Label>
                  <SelectDropdownComponent
                    placeholder="Select Lead Type"
                    customStyles={selectDropDowncustomStyles}
                    isClearable={true}
                    options={[
                      { label: "Internal", value: "Internal" },
                      { label: "External", value: "External" },
                      { label: "Both", value: "Both" },
                    ]}
                    value={
                      values.leadType
                        ? { label: values.leadType, value: values.leadType }
                        : null
                    }
                    onChange={(selected) => {
                      const selectedOption = selected as DropdownOption;
                      setFieldValue("leadType", selectedOption?.value);
                    }}
                  />
                </FormGroup>
                <FormGroup className="filter-form-group">
                  <Label className="custom-form-label">Lead Source</Label>
                  <SelectDropdownComponent
                    placeholder="Select Lead Source"
                    customStyles={selectDropDowncustomStyles}
                    options={leadSources.map((source) => ({
                      label: source.source,
                      value: source.id,
                    }))}
                    isClearable={true}
                    isMulti={true}
                    onChange={(selected) =>
                      handleLeadSourceChange(selected, setFieldValue)
                    }
                    value={values.leadSourceIds.map((id: string) => ({
                      label:
                        leadSources.find((source: any) => source.id === id)
                          ?.source || "",
                      value: id,
                    }))}
                  />
                </FormGroup>
                <LocationFilters
                  module="leads"
                  setFieldValue={setFieldValue}
                  selectedCountries={values.countryIds}
                  selectedStates={values.stateIds}
                  selectedCities={values.cityIds}
                  resetLocationFilters={resetFlag}
                  appliedFiltersFlag={
                    Object.keys(appliedFilters).length > 0 ? true : false
                  }
                />
                <div className="filter-form-buttons">
                  <Button
                    className="filter-reset-button"
                    type="button"
                    onClick={() => handleReset(resetForm)}
                  >
                    Reset
                  </Button>
                  <Button className="filter-apply-button" type="submit">
                    Apply
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
};

export default FilterLeads;
