import React from "react";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import moment from 'moment';
import closeIcon from "../../../../assets/icons/closeIcon.svg";
import "./view-agencies.scss";
import { ViewComponentProps } from "../../types/view-regional-reps-props";

const ViewAgencies: React.FC<ViewComponentProps> = ({
  isOpen,
  viewData,
  toggleView,
}) =>{
  const formatAddress = (address: any) => {
    const street = address?.street || "-";
    const city = address?.City?.name || "-";
    const state = address?.State?.name || "-";
    const zipcode = address?.zipcode || "-";
    return `${street}, ${city}, ${state}, ${zipcode}`;
  };

  return (
    <div className="add-or-edit-offcanvas">
        <Offcanvas isOpen={isOpen} direction="end" className="view-agencies">
            <OffcanvasHeader className="offcanvas-header">
                <div className="header-div">
                    <h2 className="mb-0">{viewData?.name ?? "-"}</h2>
                    <button onClick={toggleView}>
                    <img src={closeIcon} alt="Close" />
                    </button>
                </div>
            </OffcanvasHeader>
            <OffcanvasBody className="offcanvas-body">
                <div className="view-form-agencies">
                <div className="form-group">
                    <label>Agency</label>
                    <div className="data-value"> {viewData?.name ||  "_"}</div>
                </div>
                <div className="form-group">
                    <label>Authorized Representative</label>
                    <div className="data-value"> {viewData?.authorized_representative || "_"}</div>
                </div>
                <div className="form-group">
                    <label>Mailing Address</label>
                    <div className="data-value"> {viewData?.mailingAddress ? formatAddress(viewData.mailingAddress) : "_"}</div>
                </div>
                <div className="form-group">
                    <label>Work Phone Number</label>
                    <div className="data-value">{viewData?.work_phone || "_"}</div>
                </div>
                <div className="form-group">
                    <label>Cell Phone Number</label>
                    <div className="data-value">{viewData?.cell_phone || "_"}</div>
                </div>
                <div className="form-group">
                    <label>Email Address</label>
                    <div className="data-value">{ viewData?.email_address || "-"}</div>
                </div>
                <div className="form-section-heading">
                    Owner(s) Details
                </div>
                { viewData?.agency_owners?.map((data: any)=>
                    <div className="form-section">
                        <div className="form-group">
                            <label>Name of owner</label>
                            <div className="data-value">{data?.name || "-"}</div>
                        </div>
                        <div className="form-group">
                            <label>Primary owner : Yes / No</label>
                            <div className="data-value">{data?.is_primary_owner ? 'Yes' : "-"}</div>
                        </div>
                        <div className="form-group">
                            <label>Companies Contracted With</label>
                            <div className="data-value">{"-"}</div>
                        </div>
                        <div className="form-group">
                            <label>Bank Routing Number</label>
                            <div className="data-value">{data?.bank_routing_number || "-"}</div>
                        </div>
                        <div className="form-group">
                            <label>Bank Account Number</label>
                            <div className="data-value">{data?.bank_account_number || "-"}</div>
                        </div>
                        <div className="form-group">
                            <label>Account Type</label>
                            <div className="data-value">{data?.account_type || "-"}</div>
                        </div>
                        <div className="form-group">
                            <label>Commission Payment Frequency</label>
                            <div className="data-value">{data?.commission_payment_frequency || "-"}</div>
                        </div>
                        <div className="form-group">
                            <label>Social Security Number</label>
                            <div className="data-value">{data?.social_security_number || "-"}</div>
                        </div>
                        <div className="form-group">
                            <label>Bank EIN</label>
                            <div className="data-value">{data?.ein || "-"}</div>
                        </div>
                        <div className="form-group">
                            <label>Date Of Birth</label>
                            <div className="data-value">{data?.date_of_birth ? moment(data?.date_of_birth).format('MM-DD-YYYY') : "-" }</div>
                        </div>
                    </div>
                )}

                <div className="form-section-heading">
                    State Licensed Details
                </div>
                {viewData?.agency_licenses?.map((data: any)=>
                    <div className="form-section">
                        <div className="form-group">
                            <label>State Licensed In</label>
                            <div className="data-value">{data?.states_licensed?.name || "-"}</div>
                        </div>
                        <div className="form-group">
                            <label>License Expiration</label>
                            <div className="data-value">{data?.license_expiration ? moment(data?.license_expiration).format('MM-DD-YYYY') : "-" }</div>
                        </div>
                        <div className="form-group">
                            <label>AML Certification</label>
                            <div className="data-value">{data?.aml_certification || "-" }</div>
                        </div>
                        <div className="form-group">
                            <label>Reserve Balance</label>
                            <div className="data-value">{data?.reserve_balance || "-"}</div>
                        </div>
                        <div className="form-group">
                            <label>Agency Number</label>
                            <div className="data-value">{data?.agency_number || "-"}</div>
                        </div>
                    </div>
                )}
                <div className="form-group">
                    <label>Special Instruction</label>
                    <div className="data-value">{viewData?.special_instructions || "-"}</div>
                </div>
                <div className="form-group">
                    <label>	Commission Level</label>
                    <div className="data-value">{viewData?.commission_level || "-"}</div>
                </div>
                <div className="form-group">
                    <label>	Regional Rep</label>
                    <div className="data-value">{viewData?.agency_regional_rep?.name || "-"}</div>
                </div>
                <div className="form-group">
                    <label>States Appointed/Active in</label>
                    {viewData?.agency_states_appointed?.length ? viewData?.agency_states_appointed?.map((data: any)=>
                        <div className="data-value">{data?.states_appointed_in?.name || "-"}</div>
                    ) : <div className="data-value">{"-"}</div>}
                </div>
                </div>
            </OffcanvasBody>
        </Offcanvas>
    </div>
  );
};

export default ViewAgencies;
