/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import './view-guides-video.scss';

const ViewGuidesVideo = (props: any) => {
  return (
    <div className="view-guides-video-popup-overlay">
      <div className="view-guides-video-popup">
        <div className="view-guides-video-head">
          <div className="">{props?.label}</div>
        </div>
        <div className="view-guides-video-section">
          <video loop controls width="700" height="400" src={props?.url} poster={props?.thumbnail} />
        </div>
        <div className="view-guides-video-bottom">
          <button className="cancel-button" onClick={props?.onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewGuidesVideo;
