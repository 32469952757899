/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Container } from 'reactstrap';
import SearchBar from '../../../common/components/search/search-bar';
import TableComponent from '../../../common/components/table-component/table-component';
import BreadcrumbComponent from '../../../common/components/breadcrumb-component/breadcrumb-component';
import PaginationComponent from '../../../common/components/pagination-component/pagination-component';
import { getFuneralHomesList, exportFuneralHomeData } from '../../../api/funeralhomes';
import ViewFuneralHomes from './view-funeral-homes/view-funeral-homes';
import AddNewFuneralHomes from './add-new-funeral-homes/add-new-funeral-homes';
import { useSetLoading } from '../../../common/hooks/appearance';
import { columnNames, crumbMapping } from './funeral-homes.constant';
import { formatAddress, mapColumnNameToField } from './funeral-homes.utils';
import _ from 'lodash';
import './funeral-homes.scss';
import AlertComponent from '../../../common/components/Alert/Alert';
import FilterFuneralHomes from './filter-funeral-homes';

const FuneralHomes: React.FC = () => {
  const [crumbMappingOption, setCrumbMappingOption] = useState<any>(crumbMapping);
  const [viewOpen, setViewOpen] = useState<boolean>(false);
  const [addNewOpen, setAddNewOpen] = useState<boolean>(false);
  const [editDetailsInViewMode, setEditDetailsInViewMode] = useState<boolean>(false);
  const [selectedFuneralHomeId, setSelectedFuneralHomeId] = useState<any>(null);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<any>(null);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize] = useState<number>(15);
  const [sortBy, setSortBy] = useState<string>('created_at');
  const [sortDirection, setSortDirection] = useState<string>('desc');
  const [columnName, setCloumnName] = useState<string>('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<any>({});
  const [alertType, setAlertType] = useState<'success' | 'error' | 'info' | 'warning'>('success');

  const isReset = false;
  const handleSearch = _.debounce((text: string) => {
    setSearchText(text);
  }, 500);

  const toggleFilters = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const fetchFuneralHomes = useCallback(
    async (page = 1, search = '', filters?: any) => {
      setLoading(true);
      const offset = (page - 1) * pageSize;
      try {
        const response = await getFuneralHomesList(offset, pageSize, sortBy, sortDirection, search, filters);
        setData(response.data.data.data);
        setTotalRecords(response.data.data.totalRecords);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    },
    [pageSize, sortBy, sortDirection],
  );

  useEffect(() => {
    if (searchText !== null) {
      setCurrentPage(1);
      fetchFuneralHomes(1, searchText, appliedFilters);
    }
  }, [searchText, fetchFuneralHomes, appliedFilters]);

  useEffect(() => {
    fetchFuneralHomes(currentPage, searchText, appliedFilters);
  }, [currentPage, searchText, sortBy, sortDirection, appliedFilters, fetchFuneralHomes]);

  const handleEditClick = async (funeralHomeId: number, funeralHomeName: any) => {
    handleViewClick(funeralHomeId, funeralHomeName);
    setEditDetailsInViewMode(true);
  };

  const handleViewClick = async (funeralHomeId: number, funeralHomeName: any) => {
    setCrumbMappingOption([
      { label: 'Directory', link: '/home/directory/funeral-homes' },
      {
        label: 'Funeral Homes',
        link: '/home/directory/funeral-homes',
        handleClick: (event: any) => {
          event.preventDefault();
          setCrumbMappingOption(crumbMapping);
          setViewOpen(false);
          fetchFuneralHomes(currentPage, '');
        },
      },
      { label: funeralHomeName, link: '' },
    ]);
    setViewOpen(!viewOpen);
    setSelectedFuneralHomeId(funeralHomeId);
  };

  const handleAddNewClick = async () => {
    setCrumbMappingOption([
      { label: 'Directory', link: '/home/directory/funeral-homes' },
      {
        label: 'Funeral Homes',
        link: '/home/directory/funeral-homes',
        handleClick: (event: any) => {
          event.preventDefault();
          setCrumbMappingOption(crumbMapping);
          setAddNewOpen(false);
        },
      },
      { label: 'Add New', link: '' },
    ]);
    setAddNewOpen(!addNewOpen);
  };

  const handleAddNewClose = async () => {
    setCrumbMappingOption(crumbMapping);
    setAddNewOpen(false);
    fetchFuneralHomes(currentPage, searchText, appliedFilters);
  };

  const formattedData = useMemo(() => {
    if (Array.isArray(data)) {
      return data.map((item) => ({
        id: item.id,
        'Funeral Home': item.name || '-',
        'Physical Address': formatAddress(item.physicalAddress) || '-',
        'Mailing Address': formatAddress(item.mailingAddress) || '-',
        'Owner Name': item.owner_name || '-',
        'Tax ID': item.tax_id || '',
        'Phone Number': item.contact_no || '-',
        'Fax Number': item.fax_number || '-',
        'Email Address': item.email || '-',
      }));
    } else {
      return [];
    }
  }, [data]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const showLoading = useMemo(() => loading, [loading]);
  useSetLoading(showLoading);

  const handleSort = (columnName: string) => {
    const mappedField = mapColumnNameToField(columnName);
    if (sortBy === mappedField) {
      setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortBy(mappedField);
      setCloumnName(columnName);
      setSortDirection('asc');
    }
  };

  const handleDownload = async () => {
    setLoading(true);
    try {
      await exportFuneralHomeData({ searchText: searchText, sortBy: sortBy, sortDirection: sortDirection }, appliedFilters);
      setTimeout(() => setLoading(false), 1000);
      setAlertMessage('Sent Downloadable link to Email , Please check');
      setAlertType('success');
      setAlertOpen(true);
    } catch {
      setAlertMessage('Failed to download FuneralHomes data');
      setAlertType('error');
      setAlertOpen(true);
      setLoading(false);
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <Container>
      <AlertComponent message={alertMessage} type={alertType} open={alertOpen} onClose={handleAlertClose} />
      <BreadcrumbComponent breadcrumbLink={crumbMappingOption} />
      <div className={'funeral-homes-section'}>
        {viewOpen ? (
          <ViewFuneralHomes
            setLoading={setLoading}
            funeralHomeId={selectedFuneralHomeId}
            editDetailsInViewMode={editDetailsInViewMode}
            setEditDetailsInViewMode={setEditDetailsInViewMode}
          />
        ) : addNewOpen ? (
          <AddNewFuneralHomes
            isOpen={addNewOpen}
            setLoading={setLoading}
            funeralHomeId={selectedFuneralHomeId}
            handleAddNewClose={handleAddNewClose}
          />
        ) : (
          <>
            <SearchBar
              placeholder={`Search by name, address, email etc..`}
              toggleAddEdit={handleAddNewClick}
              onDownload={handleDownload}
              onSearch={handleSearch}
              isReset={isReset}
              module="Funeral Homes"
              toggleFilter={toggleFilters}
              appliedFilters={appliedFilters}
            />
            {formattedData.length > 0 ? (
              <>
                <div className={'funeral-homes-table'}>
                  <TableComponent
                    columnNames={columnNames}
                    rowsData={formattedData}
                    handleEditClick={handleEditClick}
                    handleViewClick={handleViewClick}
                    handleSort={handleSort}
                    sortedColumn={columnName}
                    sortDirection={sortDirection}
                  />
                </div>
                <PaginationComponent
                  totalRecords={totalRecords}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              </>
            ) : (
              !loading && (
                <div className="no-results">
                  <p>No results found</p>
                </div>
              )
            )}
          </>
        )}

        {isFilterOpen && (
          <FilterFuneralHomes
            isOpen={isFilterOpen}
            toggleFilter={toggleFilters}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
          />
        )}
      </div>
    </Container>
  );
};
export default FuneralHomes;
