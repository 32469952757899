import React, { useState, useEffect, useMemo } from "react";
import { Formik, Form } from "formik";
import { Offcanvas, OffcanvasBody, OffcanvasHeader, Button } from "reactstrap";
import AlertComponent from "../../../../common/components/Alert/Alert";
import DeleteConfirmationPopup from "../../delete-confirmation-popup/delete-confirmation-popup";
import closeIcon from "../../../../assets/icons/closeIcon.svg";
import AgenciesDetailSection from "./agencies-detail-section";
import OtherSection from "./other-section";
import OwnerDetailsSection from "./owner-details-section";
import { useSetLoading } from "../../../../common/hooks/appearance";
import { AddOrEditComponentProps } from "../../types/add-or-edit-props";
import { validationSchema } from "./add-or-edit-agencies.validation";
import { initialValues } from "./add-or-edit-agencies.initialValues";
import { getAgenciesPayloadData } from "./add-or-edit-agencies.utils";
import {
  removeAgenciesDetails,
  updateAgenciesDetails,
  addAgenciesDetails,
} from "../../../../api/agencies";
import "./add-or-edit-agencies.scss";

const AddOrEditAgencies: React.FC<AddOrEditComponentProps> = ({
  isOpen,
  toggle,
  mode,
  editData,
  refreshData,
}) => {
  const [initialData, setInitialData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [deleteRegionalRepId, setDeleteRegionalRepId] = useState<number | null>(
    null
  );
  const [alertType, setAlertType] = useState<
    "success" | "error" | "info" | "warning"
  >("success");
  const [isDeletePopupOpen, setDeletePopupOpen] = useState<boolean>(false);
  const [deletedOwners, setDeletedOwners] = useState<any[]>([]);
  const [deletedAgencyLicenseDetails, setDeletedLicenseDetails] = useState<
    any[]
  >([]);
  const handleDelete = async (deleteCompanyId: number | null) => {
    setIsLoading(true);
    try {
      if (deleteCompanyId === null) {
        return;
      }
      const response = await removeAgenciesDetails(deleteCompanyId);
      setIsLoading(false);
      if (response.data.success && refreshData) {
        refreshData();
      }
      setDeletePopupOpen(false);
      toggle();
    } catch (error) {
      console.error("Error while deleting company:", error);
      const errorMessage =
          (typeof error === "object" 
              && error !== null 
              && "response" in error 
              && (error as any).response?.data?.details?.[0]) || (error instanceof Error ? error.message : "An unexpected error occurred.");
      setAlertMessage(errorMessage);
      setAlertType("error");
      setAlertOpen(true);
      setIsLoading(false);
    }
  };

  const handleAddOwnerDetails = (values: any, setFieldValue: any) => {
    setFieldValue("agencyOwners", [
      ...values?.agencyOwners,
      {
        name: "",
        bankName: null,
        bankRoutingNumber: null,
        bankAccountNumber: null,
        accountType: null,
        commissionPaymentFrequency: null,
        socialSecurityNumber: null,
        ein: null,
        dateOfBirth: null,
        isPrimaryOwner: false,
        companiesContractedWith: null,
      },
    ]);
  };

  const handleRemoveOwnerDetails = (
    values: any,
    setFieldValue: any,
    index: any
  ) => {
    const ownerToRemove = values?.agencyOwners?.[index];
    let updatedOwners = [];
    if (ownerToRemove?.id) {
      ownerToRemove.isDeleted = true;
      setDeletedOwners([...deletedOwners, ownerToRemove]);
    }
    updatedOwners = values.agencyOwners.filter((d: any, i: any) => i !== index);
    setFieldValue("agencyOwners", updatedOwners);
  };

  const handleAddOtherDetails = (values: any, setFieldValue: any) => {
    setFieldValue("agencyLicenseDetails", [
      ...values?.agencyLicenseDetails,
      {
        stateLicensedIn: null,
        licenseExpiration: null,
        amlCertification: null,
        reserveBalance: 0,
        agencyNumber: null,
      },
    ]);
  };

  const handleRemoveOtherDetails = (
    values: any,
    setFieldValue: any,
    index: any
  ) => {
    const licenseDetailsToRemove = values?.agencyLicenseDetails?.[index];
    let removeOtherDetails = [];
    if (licenseDetailsToRemove?.id) {
      licenseDetailsToRemove.isDeleted = true;
      setDeletedLicenseDetails([
        ...deletedAgencyLicenseDetails,
        licenseDetailsToRemove,
      ]);
    }
    removeOtherDetails = values?.agencyLicenseDetails?.filter(
      (d: any, i: any) => i !== index
    );
    setFieldValue("agencyLicenseDetails", removeOtherDetails);
  };
  

  useEffect(() => {
    setInitialData(initialValues(editData, mode));
  }, [editData,mode]);

  const onSubmit = async (values: any, { resetForm }: any) => {
    setIsLoading(true);
    const payloadData = getAgenciesPayloadData(
      values,
      editData,
      deletedOwners,
      deletedAgencyLicenseDetails
    );
    const activeOwners = payloadData.agencyOwners.filter((owner: any) => !owner.isDeleted);
    const hasPrimaryOwner = activeOwners.some((owner: any) => owner.isPrimaryOwner);
    if (!hasPrimaryOwner) {
      setAlertMessage("Primary owner is not selected");
      setAlertType("error");
      setAlertOpen(true);
      setIsLoading(false);
      return; 
    }
    try {
      if (editData && mode === "edit") {
        const response = await updateAgenciesDetails(editData.id, payloadData);
        setIsLoading(false);
        if (response.data.success && refreshData) {
          refreshData();
        }
      } else {
        const response = await addAgenciesDetails(payloadData);
        setIsLoading(false);
        if (response.data.success && refreshData) {
          refreshData();
        }
      }
      resetForm();
      toggle();
    } catch (error: unknown) {
      setIsLoading(false);
      console.error("Error submitting form:", error);
      let errorMessage = "An unexpected error occurred."; 
      if (typeof error === "object" && error !== null) {
        if ("response" in error && (error as any).response?.data) {
          const responseData = (error as any).response.data;
          errorMessage = responseData?.message || errorMessage;
        } else if ("message" in error) {
          errorMessage = (error as Error).message;
        }
      }
      setAlertMessage(errorMessage);
      setAlertType("error");
      setAlertOpen(true);
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const showLoading = useMemo(() => isLoading, [isLoading]);
  useSetLoading(showLoading);

  return (
    <>
      <AlertComponent
        message={alertMessage}
        type={alertType}
        open={alertOpen}
        onClose={handleAlertClose}
      />
      <div className="add-or-edit-agencies-offcanvas">
        <Offcanvas
          isOpen={isOpen}
          direction="end"
          fade={true}
          className="offcanvas add-or-edit-agencies-popup"
        >
          <OffcanvasHeader className="offcanvas-header">
            <div className="header-div">
              <h2 className="mb-0">
                {mode === "edit" ? "Edit" : "Add"} Agencies
              </h2>
              <button onClick={toggle}>
                <img src={closeIcon} alt="Close" />
              </button>
            </div>
          </OffcanvasHeader>
          <OffcanvasBody className="offcanvas-body">
            <Formik
              initialValues={initialData}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, errors, touched, values, setFieldValue }) => (
                <Form className="add-or-edit-agencies"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                >
                  <AgenciesDetailSection
                    values={values}
                    setFieldValue={setFieldValue}
                  />

                  <OwnerDetailsSection
                    values={values}
                    handleAddOwnerDetails={handleAddOwnerDetails}
                    handleRemoveOwnerDetails={handleRemoveOwnerDetails}
                    setFieldValue={setFieldValue}
                  />

                  <OtherSection
                    handleAddOtherDetails={handleAddOtherDetails}
                    handleRemoveOtherDetails={handleRemoveOtherDetails}
                    setFieldValue={setFieldValue}
                    values={values}
                  />

                  <div
                    className="form-button-container"
                    style={!editData ? { flexDirection: "row-reverse" } : {}}
                  >
                    {editData ? (
                      <div>
                        <Button
                          type="button"
                          className="delete-button"
                          onClick={() => {
                            setDeleteRegionalRepId(editData.id);
                            setDeletePopupOpen(true);
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}
                    <div>
                      <Button
                        type="button"
                        className={`cancel-button${
                          mode === "edit" ? "-in-edit" : ""
                        } `}
                        onClick={toggle}
                      >
                        Cancel
                      </Button>
                      <Button
                        className={mode === "edit" ? "save-button-changes" : "save-button"}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {mode === "edit" ? "Save Changes" : "Save"}
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </OffcanvasBody>
        </Offcanvas>
      </div>
      <DeleteConfirmationPopup
        isOpen={isDeletePopupOpen}
        onClose={() => setDeletePopupOpen(!isDeletePopupOpen)}
        handleDelete={handleDelete}
        deleteId={deleteRegionalRepId}
        label="Agency"
      />
    </>
  );
};

export default AddOrEditAgencies;
