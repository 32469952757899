import axios from '.';

export const getLeadsBySource = () => {
    return axios({
      method: "GET",
      url: "/dashboard/leadsBySource",
    });
};

export const getDayWiseLeads = () => {
    return axios({
      method: "GET",
      url: "/dashboard/getDayWiseLeads",
    });
};

export const getDashboardOverview = () => {
  return axios({
    method: "GET",
    url: "/dashboard/overview",
  });
};

export const getDirectoryAnalytics = () => {
  return axios({
    method: "GET",
    url: "/dashboard/directoryAnalytics",
  });
};