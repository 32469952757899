import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Row, Col, FormGroup, Label } from "reactstrap";
import { Autocomplete, TextField } from "@mui/material";
import { Field } from "formik";
import debounce from "lodash/debounce";
import { getAutoFillLocation } from "../../../api/profile";
import './country.scss';

interface LocationOption {
  id: number;
  name: string;
  State?: any;
  Country?: any;
  Cities?: LocationOption[];
}
interface CountryProps {
  cityId?: number | null;
  stateId?: number | null;
  countryId?: number | null;
  setCityId?: (id: number | null) => void;
  setStateId?: (id: number | null) => void;
  setCountryId?: (id: number | null) => void;
  selectedCity?: LocationOption | null;
  setSelectedCity?: (city: LocationOption | null) => void;
  selectedState?: LocationOption | null;
  setSelectedState?: (state: LocationOption | null) => void;
  selectedCountry?: LocationOption | null;
  setSelectedCountry?: (country: LocationOption | null) => void;
  addressLine1FieldName?: string;
  addressLine2FieldName?: string;
  postalCodeFieldName?: string;
}

const Country: React.FC<CountryProps> = ({
  setCityId,
  setStateId,
  setCountryId,
  selectedCity,
  setSelectedCity,
  selectedState,
  setSelectedState,
  selectedCountry,
  setSelectedCountry,
  addressLine1FieldName,
  addressLine2FieldName,
  postalCodeFieldName,
}) => {
  const [cityOptions, setCityOptions] = useState<LocationOption[]>([]);
  const [stateOptions, setStateOptions] = useState<LocationOption[]>([]);
  const [countryOptions, setCountryOptions] = useState<LocationOption[]>([]);
  const [focusedField, setFocusedField] = useState<string | null>(null);
  const [cityStateOptions, setCityStateOptions] = useState<LocationOption[]>(
    []
  );

  const fetchOptions =useCallback( async (key: string, value: string) => {
    try {
      if (value.length < 3) return;
      const response = await getAutoFillLocation(key, value);
      const data = response.data.data;
      const locationData = data as LocationOption[];
      if (key === "city") {
        setCityStateOptions(locationData);
        const uniqueCities = Array.from(
          new Map(locationData.map((item) => [item.name, item])).values()
        );
        setCityOptions(uniqueCities);
      } else if (key === "state") {
        setStateOptions(locationData);
        locationData.forEach((ele) => {
          if (ele.Cities) setCityOptions(ele.Cities);
        });
      } else if (key === "country") {
        setCountryOptions(locationData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [setCityStateOptions, setCityOptions, setStateOptions, setCountryOptions]);

  const debouncedFetchOptions = useMemo(() => debounce(fetchOptions, 500), [fetchOptions]);

  //const debouncedFetchOptions = useCallback(debounce(fetchOptions, 500), []);

  const handleFocus = (field: string) => setFocusedField(field);

  const handleCityChange = (event: any, newValue: LocationOption | null) => {
    if (newValue) {
      setSelectedCity?.({ name: newValue.name, id: newValue.id });
      setCityId?.(newValue.id);

      const states = cityStateOptions
        .filter((city) => city.name === newValue.name)
        .map((city) => city.State);

      setStateOptions(states);

      if (states.length > 0) {
        const firstState = states[0];
        setSelectedState?.({  name: firstState.name, id: firstState.id });
        setStateId?.(firstState.id);

        if (firstState.Country) {
          setSelectedCountry?.(firstState.Country);
          setCountryId?.(firstState.Country.id);
        }
      }
    } else {
      setSelectedCity?.(null);
      setCityId?.(null);
      setStateOptions([]);
      setCityOptions([]);
      setSelectedState?.(null);
      setStateId?.(null);
    }
  };

  const handleStateChange = (event: any, newValue: LocationOption | null) => {
    if (newValue) {
      setSelectedState?.({ name: newValue.name, id: newValue.id });
      setStateId?.(newValue?.id);
      setSelectedCountry?.(newValue.Country || null);
      setCountryId?.(newValue.Country?.id);
      setCityOptions(newValue.Cities || []);
    } else {
      setSelectedState?.(null);
      setStateId?.(null);
      setCityId?.(null);
      setCityOptions([]);
      setStateOptions([]);
      setSelectedCity?.(null);
    }
  };

  const handleCountryChange = (event: any, newValue: LocationOption | null) => {
    if (newValue) {
      setSelectedCountry?.(newValue);
      setCountryId?.(newValue.id);
      setSelectedState?.(null);
      setStateId?.(null);
      setCityOptions([]);
    } else {
      setSelectedCountry?.(null);
      setCountryId?.(null);
      setSelectedState?.(null);
      setStateId?.(null);
      setSelectedCity?.(null);
      setCityId?.(null);
      setCityOptions([]);
    }
  };
  useEffect(() => {
    if (selectedCity) {
      const states = cityStateOptions
        .filter((city) => city.name === selectedCity.name)
        .map((city) => city.State);
  
      setStateOptions(states);
  
      if (states.length > 0) {
        const firstState = states[0];
        setSelectedState?.({ name: firstState.name, id: firstState.id });
        setStateId?.(firstState.id);
      }
    }
    if (selectedCountry) {
      fetchOptions("country", selectedCountry.name);
    }
  }, [selectedCity, cityStateOptions, selectedCountry]);

  return (
    <div className="Country">
      <Row className="profile-row">
        <Col xs="6">
          <FormGroup className="form-group">
            <Label className="custom-label">Address Line 1</Label>
            <Field
              type="text"
              name={addressLine1FieldName || " "}
              className="custom-input"
              placeholder="Address Line 1"
            />
          </FormGroup>
        </Col>
        <Col xs="6">
          <FormGroup className="form-group">
            <Label className="custom-label">Address Line 2</Label>
            <Field
              type="text"
              name={addressLine2FieldName || " "}
              className="custom-input"
              placeholder="Address Line 2"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="profile-row">
        <Col xs="6">
          <FormGroup className="form-group">
            <Label className="custom-label">City</Label>
            <div style={{ width: "510px" }}>
              <Autocomplete
                options={cityOptions}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={selectedCity || null}
                onChange={handleCityChange}
                onInputChange={(event, newValue) => {
                  debouncedFetchOptions("city", newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onFocus={() => handleFocus("city")}
                    placeholder="Select City"
                    sx={{
                      "& .MuiAutocomplete-input": {
                        height: "9px",
                      },
                    }}
                  />
                )}
              />
            </div>
          </FormGroup>
        </Col>
        <Col xs="6">
          <FormGroup className="form-group">
            <Label className="custom-label">Postal Code</Label>
            <Field
              maxLength={5}
              type="text"
              name={postalCodeFieldName || " "}
              className={`custom-input`}
              placeholder="Postal Code"
              onKeyPress={(e:any) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault(); 
                }
              }}
              onInput={(e:any) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
              }}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row className="profile-row">
        <Col xs="6">
          <FormGroup className="form-group">
            <Label className="custom-label">State</Label>
            <div style={{ width: "510px" }}>
              <Autocomplete
                options={stateOptions}
                getOptionLabel={(option) => option?.name || ""}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onInputChange={(event, value) => {
                  if (focusedField === "state") {
                    debouncedFetchOptions("state", value);
                  }
                }}
                onFocus={() => handleFocus("state")}
                onChange={handleStateChange}
                value={selectedState || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select State"
                    sx={{
                      "& .MuiAutocomplete-input": {
                        height: "9px",
                      },
                    }}
                  />
                )}
              />
            </div>
          </FormGroup>
        </Col>
        <Col xs="6">
          <FormGroup className="form-group">
            <Label className="custom-label">Country</Label>
            <div style={{ width: "510px" }}>
              <Autocomplete
                options={countryOptions}
                getOptionLabel={(option) => option?.name || ""}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onInputChange={(event, value) => {
                  if (focusedField === "country") {
                    debouncedFetchOptions("country", value);
                  }
                }}
                onFocus={() => handleFocus("country")}
                onChange={handleCountryChange}
                value={selectedCountry || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Country"
                    sx={{
                      "& .MuiAutocomplete-input": {
                        height: "9px",
                      },
                    }}
                  />
                )}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default Country;
