import React from "react";
import { Row, Table } from "reactstrap";
import TableHeaderArrow from "../../../assets/icons/table-header-arrow.svg";
import SorterAsc from "../../../assets/icons/sorterasc.svg";
import SorterDesc from "../../../assets/icons/sorterdesc.svg";
import { SavedReportsTableProps } from "./saved-reports-table-props";
import "./saved-reports-table.scss";

const SavedReportsTable: React.FC<SavedReportsTableProps> = ({
  columnNames,
  rowsData = [],
  handleSort,
  sortedColumn,
  sortDirection,
  handleDownload,
}) => {
  const getSortArrowIcon = (columnName: string) => {
    if (columnName === sortedColumn) {
      return sortDirection === "asc" ? SorterAsc : SorterDesc;
    }
    return TableHeaderArrow;
  };
  return (
    <>
      <Row className="saved-reports-row">
        <div className="saved-reports-table-container">
          <Table
            hover
            className="saved-reports-table-container"
            bordered
            borderless={true}
            responsive
          >
            <thead>
              <tr>
                {columnNames.map((columnName, index) => (
                  <th key={index}>
                    <div className="header-column-names-arrow">
                     {index !==0 && columnName === "Download Report" ? (
                        <div>{columnName}</div>
                      ) : ( <>
                        <div>{columnName}</div>
                        <div style={{ cursor: "pointer" }}>
                          <img
                            src={getSortArrowIcon(columnName)}
                            alt="Table Header Arrow"
                            onClick={() => handleSort?.(columnName)}
                          />
                        </div>
                      </>)}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rowsData?.length > 0 ? (
                (rowsData ?? []).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {columnNames.map((columnName, colIndex) => (
                      <td key={colIndex}>
                        {colIndex !== 2 ? (
                          row[columnName] || "-"
                        ) : (
                          <button
                            className={row?.["Name Of Reports"] !=="New Contracts in Current Quarter" ? "saved-report-download-button": "saved-report-download-button-disabled"}
                            onClick={
                              row?.["Name Of Reports"] !== "New Contracts in Current Quarter"
                                ? () => handleDownload(row?.["Name Of Reports"])
                                : () => {}
                            }
                          >
                            Download
                          </button>
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={columnNames.length}
                    style={{ textAlign: "center" }}
                  >
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Row>
    </>
  );
};

export default SavedReportsTable;
