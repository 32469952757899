import {  Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { ViewContractTemplateComponentProps } from "./contracts-templates-props";
import closeIcon from '../../../assets/icons/closeIcon.svg';
// import downloadIcon from '../../../assets/icons/download.svg'
// import { useState } from "react";
import "@react-pdf-viewer/core/lib/styles/index.css";
//import { Viewer, Worker } from '@react-pdf-viewer/core';

const ViewTemplate: React.FC<ViewContractTemplateComponentProps> = ({
  isOpen,
  viewData,
  toggle,
}) => {
  return (
    <div className="add-or-edit-offcanvas">
      <Offcanvas isOpen={isOpen} direction="end" className="offcanvas">
      <OffcanvasHeader className="offcanvas-header">
          <div className="header-div">
            <h2 className="mb-0">{viewData?.template_name || "-"}</h2>
            <button
              onClick={() => {
                toggle(viewData.id);
              }}
            >
              <img src={closeIcon} alt="Close" />
            </button>
          </div>
        </OffcanvasHeader>
        <OffcanvasBody className="offcanvas-body">
          <iframe
            src={viewData.file_url}
            title="PDF Viewer"
            style={{ width: "100%", height: "95%", border: "none", padding: 0 }}
          ></iframe>
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
};

export default ViewTemplate;