import React, { useCallback, useEffect, useMemo, useState } from "react";
import debounce from "lodash/debounce";
import { FormGroup, Row, Col, Label } from "reactstrap";
import { Field, ErrorMessage } from "formik";
import { Autocomplete, TextField } from "@mui/material";
import { getAutoFillLocation } from "../../../api/profile";
import './directory-address-component.scss';
import { DirectoryAddressComponentProps, LocationOption } from "./directory-address-component-props";

const DirectoryAddressComponent: React.FC<DirectoryAddressComponentProps> = ({
  addressLabel,
  streetFieldName,
  zipCodeFieldName,
  cityFieldName,
  stateFieldName,
  setCity,
  setState,
  selectedState,
  selectedCity,
  setSelectedCity,
  setSelectedState,
  optional = true,
  mode
}) => {
  const [cityOptions, setCityOptions] = useState<LocationOption[]>([]);
  const [stateOptions, setStateOptions] = useState<LocationOption[]>([]);
  const [cityStateOptions, setCityStateOptions] = useState<LocationOption[]>([]);
  const [focusedField, setFocusedField] = useState<string | null>(null);

  const formatLabel = (labelName: string): string => {
    return labelName
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const fetchOptions =useCallback( async (key: string, value: string) => {
    try {
      if (value.length < 3) return;
      const response = await getAutoFillLocation(key, value);
      const data = response.data.data;
      const locationData = data as LocationOption[];
      if (key === "city") {
        setCityStateOptions(locationData);
        const uniqueCities = Array.from(
          new Map(locationData.map((item) => [item.name, item])).values()
        );
        setCityOptions(uniqueCities);
      } else if (key === "state") {
        setStateOptions(locationData);
        locationData.forEach((ele) => {
          if (ele.Cities) setCityOptions(ele.Cities);
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [setCityStateOptions, setCityOptions, setStateOptions]);

  const debouncedFetchOptions = useMemo(() => debounce(fetchOptions, 500), [fetchOptions]);

  //const debouncedFetchOptions = useCallback(debounce(fetchOptions, 500), []);

  const handleFocus = (field: string) => setFocusedField(field);

  const handleCityChange = (event: any, newValue: LocationOption | null) => {
    if (newValue) {
      setSelectedCity?.({ id: newValue.id, name: newValue.name })
      setCity({ id: newValue.id, name: newValue.name });

      const states = cityStateOptions
        .filter((city) => city.name === newValue.name)
        .map((city) => city.State);

      setStateOptions(states);

      if (states.length > 0) {
        const firstState = states[0];
        setSelectedState?.({ id: firstState.id, name: firstState.name });
        setState({ id: firstState.id, name: firstState.name });
      } else {
        setState(null);
      }
    } else {
      setSelectedCity?.(null);
      setCity(null);
      setCityOptions([]);
      setStateOptions([]);
      setSelectedState?.(null);
      setState(null);
    }
  };

  const handleStateChange = (event: any, newValue: LocationOption | null) => {
    if (newValue) {
      setSelectedState?.({id: newValue.id, name: newValue.name});
      setState({ id: newValue.id, name: newValue.name });
      setCityOptions(newValue.Cities || []);
    } else {
      setSelectedState?.(null);
      setState(null);
      setCity(null);
      setCityOptions([]);
      setStateOptions([]);
      setSelectedCity?.(null);
    }
  };
  useEffect(() => {
    if (mode === "add") {
      setSelectedCity?.(null);
      setSelectedState?.(null);
      setCity(null);
      setState(null);
      setCityOptions([]);
      setStateOptions([]);
      setCityStateOptions([]);
    }
  }, [mode, setCity, setState, setSelectedCity, setSelectedState]);

  return (
    <FormGroup className="add-or-edit-form-group">
      <Label className="custom-form-label">
        {formatLabel(addressLabel)}
        {!optional && <span>*</span>}
      </Label>
      <div className="custom-form-div">
        <Row className="custom-form-group-row">
          <Col md={6} className="custom-form-group-col">
            <Label htmlFor={streetFieldName}>Street</Label>
            <Field
              id={streetFieldName}
              name={streetFieldName}
              type="text"
              placeholder="Enter Street"
              className="custom-input-form-control form-control"
              maxLength={200}
            />
            <ErrorMessage
              name={streetFieldName}
              component="div"
              className="error-message"
            />
          </Col>

          <Col md={6} className="custom-form-group-col">
            <FormGroup className="form-group">
              <Label className="custom-label">City</Label>
              <Autocomplete
                options={cityOptions}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={handleCityChange}
                onInputChange={(event, newValue) => {
                  debouncedFetchOptions("city", newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onFocus={() => handleFocus("city")}
                    placeholder="Select City"
                    className="autocomplete-text-field"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                        },
                        '&:hover fieldset': {
                          border: 'none',
                        },
                        '&.Mui-focused fieldset': {
                          border: 'none', 
                        },
                      },
                    }}
                  />
                )}
                value={selectedCity || null}
                className="autocomplete-div"
              />
              {cityFieldName ?
              <ErrorMessage
                name={cityFieldName || ''}
                component="div"
                className="error-message"
              />: ""}
            </FormGroup>
          </Col>
        </Row>
        <Row className="custom-form-group-row">
          <Col md={6} className="custom-form-group-col">
            <Label className="custom-label">State</Label>
            <Autocomplete
              options={stateOptions}
              getOptionLabel={(option) => option?.name || ""}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={handleStateChange}
              onInputChange={(event, value) => {
                if (focusedField === "state") {
                  debouncedFetchOptions("state", value);
                }
              }}
              onFocus={() => handleFocus("state")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select State"
                  className="autocomplete-text-field"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none', 
                      },
                    },
                  }}
                />
              )}
              value={selectedState || null}
              className="autocomplete-div"
            />
            {stateFieldName ?
            <ErrorMessage
              name={stateFieldName || ''}
              component="div"
              className="error-message"
            />: ""}
          </Col>
          <Col md={6} className="custom-form-group-col">
            <Label htmlFor={zipCodeFieldName}>Zip Code</Label>
            <Field
              id={zipCodeFieldName}
              name={zipCodeFieldName}
              type="text"
              placeholder="Enter Zip Code"
              className="custom-input-form-control form-control"
              maxLength={5}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
              }}
            />
            <ErrorMessage
              name={zipCodeFieldName}
              component="div"
              className="error-message"
            />
          </Col>
        </Row>
      </div>
    </FormGroup>
  );
};

export default DirectoryAddressComponent;
