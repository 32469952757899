import React, {useState, useEffect, useMemo, useCallback} from "react";
import { Container } from "reactstrap";
import _ from "lodash";
import { exportUsersData, fetchUsersDetails } from "../../../api/user";
import BreadcrumbComponent from "../../../common/components/breadcrumb-component/breadcrumb-component";
import PaginationComponent from "../../../common/components/pagination-component/pagination-component";
import SearchBar from "../../../common/components/search/search-bar";
import TableComponent from "../../../common/components/table-component/table-component";
import { useSetLoading } from "../../../common/hooks/appearance";
import ViewUser from "./view-user";
import './user.scss';
import AddOrEditUser from "./add-or-edit-user";
import AlertComponent from "../../../common/components/Alert/Alert";
import FilterUsers from "./filter-users";

const User:React.FC =()=> {
    const [data, setData] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize] = useState<number>(15);
    const [searchText, setSearchText] = useState<any>(null);
    const [isReset, setIsReset] = useState<boolean>(false);
    const [sortBy, setSortBy] = useState<string>("created_at");
    const [sortDirection, setSortDirection] = useState<string>("desc");
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [columnName, setColumnName] = useState<string>("");
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState<
      "success" | "error" | "info" | "warning"
    >("success");
    const [viewState, setViewState] = useState<{viewOpen: boolean; selectedId: number | null;}>({
        viewOpen: false,
        selectedId: null,
      });
    const [addEditState, setAddEditState] = useState<{
      isOpen: boolean;
      mode: "add" | "edit" | null;
      selectedId: number | null;
    }>({
      isOpen: false,
      mode: null,
      selectedId: null,
    });
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState<any>({});
    const toggleFilters = () => {
      setIsFilterOpen(!isFilterOpen);
    };
    const columnNames: string[] = [
        "Edit",
        "First Name",
        "Last Name",
        "Phone Number",
        "Role",
        "Email ID",
        "Date Added",
      ];
    let crumbMapping = [
        { label: 'User Management', link: '/home/user/user'},
        { label: 'Users', link: 'home/user/user' },
    ];
    const handlePageChange = (
        event: React.ChangeEvent<unknown>,
        value: number
      ) => {
        setCurrentPage(value);
      };
      const fetchUsers =useCallback( async (page = 1, searchText = "", filters?: any) => {
        setLoading(true);
        const offset = (page - 1) * pageSize;
        try {
            const response = await fetchUsersDetails({offset, pageSize, searchText, sortBy, sortDirection}, filters);
            setData(response.data.data.data.rows);
            setTotalRecords(response.data.data.totalRecords);
            setLoading(false)
        } catch {
            setLoading(false);
          }
    },[sortBy, sortDirection, pageSize]);
      useEffect(() => {
        if(searchText !== null){
          setCurrentPage(1);
          fetchUsers(1, searchText, appliedFilters);
        }
      }, [ searchText,appliedFilters,fetchUsers]);
    
    useEffect(() => {
      fetchUsers(currentPage, searchText, appliedFilters);
    }, [currentPage,searchText,sortBy, sortDirection, appliedFilters,fetchUsers]);

  
    const formatDate = (dateString: string): string => {
      const date = new Date(dateString);
  
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      const year = date.getUTCFullYear();

      return `${month}-${day}-${year}`;
    };
    const formattedData = useMemo(()=> {
        if (Array.isArray(data)) {
            return data.map((item)=> ({
                id: item.id,
                "First Name": item?.first_name || "-",
                "Last Name": item?.last_name || "-",
                "Phone Number": item?.phone_number || "-",
                "Role": item?.userRole?.name || "-",
                "Email ID": item?.email || "-",
                "Date Added": formatDate(item.created_at)
            }))
        } else {
            return [];
          }
    }, [data])
    const mapColumnNameToField = (columnName: string): string => {
        const mapping: Record<string, string> = {
          'First Name': "first_name",
          "Last Name": "last_name",
          "Phone Number": "phone_number",
          'Role': "userRole.name",
          'Email ID': "email",
          'Date Added': "created_at",
        };
        return mapping[columnName] || columnName;
      };
    const handleSearch = _.debounce((text: string) => {
      setSearchText(text);
    }, 500);
    const handleSort = (columnName: string) => {
        const mappedField = mapColumnNameToField(columnName);
        if (sortBy === mappedField) {
          setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
        } else {
          setSortBy(mappedField);
          setColumnName(columnName);
          setSortDirection("asc");
        }
      };
    const toggleAddEdit = (mode: "add" | "edit" | null , id?: number) => {
      setAddEditState((prevState) => ({
        isOpen: !prevState.isOpen,
        mode: !prevState.isOpen ? mode : null,
        selectedId: !prevState.isOpen ? id ?? null : null,
      }));
    };
    const toggleView = (id: number) => {
      setViewState((prevState) => ({
        viewOpen: !prevState.viewOpen,
        selectedId: id,
      }));
    };
    const showLoading = useMemo(() => loading, [loading]);
    useSetLoading(showLoading);
    const handleDownload = async () => {
      setLoading(true);
      try {
       await exportUsersData({searchText: searchText, sortBy: sortBy, sortDirection: sortDirection}, appliedFilters);
        // const blob = new Blob([response.data], {
        //   type: "application/vnd.ms-excel",
        // });
        // const link = document.createElement("a");
        // link.href = URL.createObjectURL(blob);
        // link.download = "companies_data.xlsx";
        // link.click();
        setTimeout(() => setLoading(false), 1000);
        setAlertMessage("Sent Downloadable link to Email , Please check");
        setAlertType("success");
        setAlertOpen(true);
      } catch (error) {
        setAlertMessage("Failed to download Companies data");
        setAlertType("error");
        setAlertOpen(true);
        setError("Failed to download file");
        setLoading(false);
      }
    };
    const handleAlertClose = () => {
      setAlertOpen(false);
    };

  if (error) {
    return <div>{error}</div>;
  }
    return(
        <Container>
           <AlertComponent
        message={alertMessage}
        type={alertType}
        open={alertOpen}
        onClose={handleAlertClose}
      />
            <BreadcrumbComponent breadcrumbLink={crumbMapping}/>
            <SearchBar
                placeholder={`Search by name, email etc...`}
                fromUser={true}
                toggleAddEdit={toggleAddEdit}
                onSearch={handleSearch}
                isReset={isReset}
                onDownload={handleDownload}
                module="Users"
                toggleFilter={toggleFilters}
                appliedFilters={appliedFilters}
            />
            {formattedData.length > 0  ? (
            <>
            <div className={"user-table"}>
            <TableComponent
              columnNames={columnNames}
              rowsData={formattedData}
              handleViewClick={toggleView}
              toggleAddEdit={toggleAddEdit}
              handleSort={handleSort}
              sortedColumn={columnName}
              sortDirection={sortDirection}
            />
            </div>
            <PaginationComponent
            totalRecords={totalRecords}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            />
            </>
            ): (
              !loading && (
                <div className="no-results">
                  <p>No results found</p>
                </div>
              )
            )}
            {addEditState?.isOpen && (
              <AddOrEditUser
                isOpen={addEditState.isOpen}
                toggle={() => {
                  toggleAddEdit(null);
                }}
                mode={addEditState.mode}
                editData={data.find((item) => item.id === addEditState.selectedId)}
                page={currentPage}
                fetchUsers={()=>{
                  setSearchText(null);
                  setCurrentPage(1);
                  setIsReset(!isReset)
                  fetchUsers();
                }} 
              />
            )}
            {viewState.viewOpen && (
              <ViewUser
                isOpen={viewState.viewOpen}
                viewData={data.find((item) => item.id === viewState.selectedId)}
                toggleView={toggleView}
              />
            )}
            {
              isFilterOpen && (<FilterUsers isOpen={isFilterOpen} toggleFilter={toggleFilters} appliedFilters={appliedFilters} setAppliedFilters ={setAppliedFilters}/>)
            }
        </Container>
    )
}
export default User;