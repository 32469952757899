import axios from '.';

export const addFuneralHomesDetails = (data: any) => {
  return axios({
    method: 'POST',
    url: '/directories/funeral-home',
    data
  });
};
export const exportFuneralHomeList = (savedReport?: string) => {
  return axios({
    method: 'GET',
    url: `directories/funeral-home/export/saved-report`,
    responseType: 'blob',
    params: {
      reportName: savedReport
    },
  })
}
export const exportFuneralHomeData = ({
  searchText,
  sortBy,
  sortDirection,
  savedReport,
  fromDate,
  toDate,
  cityId,
  stateId
}: {
  searchText?: string;
  sortBy?: string;
  sortDirection?: string;
  savedReport?: string;
  fromDate?: string;
  toDate?: string;
  cityId?: number;
  stateId?: number;
}, filters?: any) => {
  return axios({
    method: 'GET',
    url: `directories/funeral-home/export`,
    responseType: 'blob',
    params: {
      searchText: searchText,
      sortBy: sortBy,
      sortDirection: sortDirection,
      reportName: savedReport,
      fromDate: fromDate,
      toDate: toDate,
      cityId: cityId,
      stateId: stateId,
      ...filters
    },
  })
}

export const getFuneralHomesList = (offset: number, limit: number, sortBy?: string, sortDirection?: string, searchText?: string, filters?: any) => {
  return axios({
    url: `/directories/funeral-home`,
    method: 'GET',
    params: {
      offset: offset,
      limit: limit,
      sortBy: sortBy,
      sortDirection: sortDirection,
      searchText: searchText,
      ...filters
    },
  });
};

export const updateFuneralHomesDetails = (id: any, data: any) => {
  return axios({
    method: 'PUT',
    url: `/directories/funeral-home/${id}`,
    data
  });
};

export const getFuneralHomesDetails = (id: any) => {
  return axios({
    url: `/directories/funeral-home/${id}`,
    method: 'GET',
  });
};

export const removeFuneralHomesDetails = (id: any) => {
  return axios({
    url: `/directories/funeral-home/${id}`,
    method: 'DELETE',
  });
};

export const removeCoOwnerDetails = (funeralHomeId: any, coOwnerID: any) => {
  return axios({
    url: `/directories/funeral-home/${funeralHomeId}/coOwner/${coOwnerID}`,
    method: 'DELETE',
  });
};

export const getAllCoOwnerDetails = (funeralHomeId: any) => {
  return axios({
    url: `/directories/funeral-home/${funeralHomeId}/coOwner`,
    method: 'GET',
  });
};

export const addUpdateCoOwnerDetails = (funeralHomeId: any, coOwnerID: any, data: any) => {
  return axios({
    url: `/directories/funeral-home/${funeralHomeId}/coOwner${coOwnerID ? `/${coOwnerID}` : ``}`,
    method: coOwnerID ? 'PUT' : 'POST',
    data
  });
};

export const getAllCompaniesDetails = (funeralHomeId: any) => {
  return axios({
    url: `/directories/funeral-home/${funeralHomeId}/company`,
    method: 'GET',
  });
};

export const removeCompaniesDetails = (funeralHomeId: any, companyID: any) => {
  return axios({
    url: `/directories/funeral-home/${funeralHomeId}/company/${companyID}`,
    method: 'DELETE',
  });
};

export const addUpdateCompaniesDetails = (funeralHomeId: any, companyID: any, data: any) => {
  return axios({
    url: `/directories/funeral-home/${funeralHomeId}/company${companyID ? `/${companyID}` : ``}`,
    method: companyID ? 'PUT' : 'POST',
    data
  });
};
export const removeFuneralHomeNote = ( funeralHomeId: any, noteID: any) => {
  return axios({
    url: `/directories/funeral-home/${funeralHomeId}/note/${noteID}`,
    method: 'DELETE',
  });
};
export const getAllFuneralHomeNotes = ( funeralHomeId: any) => {
  return axios({
    url: `/directories/funeral-home/${funeralHomeId}/note`,
    method: 'GET',
  });
  };
export const addOrUpdateFuneralHomeNote = ( funeralHomeId: any, noteID: any, data: any) => {
  return axios({
    url: `/directories/funeral-home/${funeralHomeId}/note${noteID ? `/${noteID}` : ``}`,
    method: noteID ? 'PUT' :  'POST',
    data
  });
  };
  export const removeFuneralHomeSpecialInstructions = ( funeralHomeId: any, specialInstructionID: any) => {
    return axios({
      url: `/directories/funeral-home/${funeralHomeId}/specialInstruction/${specialInstructionID}`,
      method: 'DELETE',
    });
  };
  
  export const getAllFuneralHomeSpecialInstructions = ( funeralHomeId: any) => {
  return axios({
    url: `/directories/funeral-home/${funeralHomeId}/specialInstruction`,
    method: 'GET',
  });
  };
  
  export const addOrUpdateFuneralHomeSpecialInstruction = ( funeralHomeId: any, specialInstructionID: any, data: any) => {
  return axios({
    url: `/directories/funeral-home/${funeralHomeId}/specialInstruction${specialInstructionID ? `/${specialInstructionID}` : ``}`,
    method: specialInstructionID ? 'PUT' :  'POST',
    data
  });
}
export const addUpdateAdditionalIdsDetails = ( funeralHomeId: any, additionalID: any, data: any) => {
  console.log('in axios add additional id',funeralHomeId,additionalID,data)
  return axios({
    url: `/directories/funeral-home/${funeralHomeId}/additionalIds${additionalID ? `/${additionalID}` : ``}`,
    method: additionalID ? 'PUT' :  'POST',
    data
  });
};

export const removeAdditionalIdDetails = ( funeralHomeId: any, additionalID: any) => {
  return axios({
    url: `/directories/funeral-home/${funeralHomeId}/additionalIds/${additionalID}`,
    method: 'DELETE',
  });
};

export const  getAllAdditionalIdDetails = ( funeralHomeId: any) => {
  console.log('getadditional')
  return axios({
    url: `/directories/funeral-home/${funeralHomeId}/additionalIds`,
    method: 'GET',
  });
};


export const removeCorrespondanceDetails = (funeralHomeId: any, correspondanceId: any) => {
  return axios({
    url: `/directories/funeral-home/${funeralHomeId}/correspondances/${correspondanceId}`,
    method: 'DELETE',
  });
};

export const getAllCorrespondanceDetails = (funeralHomeId: any) => {
  return axios({
    url: `/directories/funeral-home/${funeralHomeId}/correspondances`,
    method: 'GET',
  });
};

export const addUpdateCorrespondanceDetails = (funeralHomeId: any, correspondanceId: any, data: any) => {
  return axios({
    url: `/directories/funeral-home/${funeralHomeId}/correspondances${correspondanceId ? `/${correspondanceId}` : ``}`,
    method: correspondanceId ? 'PUT' : 'POST',
    data
  });
};

export const getCorrespondanceRoles = () => {
  return axios({
    url: `/directories/funeral-home/correspondance/roles`,
    method: 'GET',
  });
};

export const removeAuthorizedRepresentativeDetails = (funeralHomeId: any, authorisedRepresentativeId: any) => {
  return axios({
    url: `/directories/funeral-home/${funeralHomeId}/authorisedRepresentative/${authorisedRepresentativeId}`,
    method: 'DELETE',
  });
};

export const getAllAuthorizedRepresentativeDetails = (funeralHomeId: any) => {
  return axios({
    url: `/directories/funeral-home/${funeralHomeId}/authorisedRepresentative`,
    method: 'GET',
  });
};

export const addUpdateAuthorizedRepresentativeDetails = (funeralHomeId: any, authorisedRepresentativeId: any, data: any) => {
  return axios({
    url: `/directories/funeral-home/${funeralHomeId}/authorisedRepresentative${authorisedRepresentativeId ? `/${authorisedRepresentativeId}` : ``}`,
    method: authorisedRepresentativeId ? 'PUT' : 'POST',
    data
  });
};

export const addUpdateAdditionalLocationsDetails = ( funeralHomeId: any, additionalLocationID: any, data: any) => {
  return axios({
    url: `/directories/funeral-home/${funeralHomeId}/additionalLocations${additionalLocationID ? `/${additionalLocationID}` : ``}`,
    method: additionalLocationID ? 'PUT' :  'POST',
    data
  });
};

export const removeAdditionalLocationDetails = ( funeralHomeId: any, additionalLocationID: any) => {
  return axios({
    url: `/directories/funeral-home/${funeralHomeId}/additionalLocations/${additionalLocationID}`,
    method: 'DELETE',
  });
};

export const  getAllAdditionalLocationDetails = ( funeralHomeId: any) => {
  return axios({
    url: `/directories/funeral-home/${funeralHomeId}/additionalLocations`,
    method: 'GET',
  });
};

export const getAutoFillDirectories = (
  key?: string,
  value?: string,
  module?: string
) => {
  const params: Record<string, string> = {};

  if (key) params.key = key;
  if (value) params.value = value;
  if (module) params.module = module;

  return axios({
    method: "GET",
    url: "directories/search/autofill",
    params,
  });
};