export const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    width: "100%",
    height: "51px",
    borderColor: '#ffffff',
    backgroundColor: '#F7F7F7',
    boxShadow: '#ffffff',
    "&:hover": {
      borderColor: '#ffffff',
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    width: "100%",
  }),
  option: (styles: any, state: any) => ({
    ...styles,
    backgroundColor: state.isSelected ? "#887952" : "white",
    ":hover": {
      backgroundColor: state.isSelected ? "#887952" : "#EEEE",
    },
  }),
};

export const columnNames: any = [
  {
    header: "Edit",
    data: "edit",
  }, {
    header: "Select Company Name",
    data: "companyName",
  }, {
    header: "Funeral Home Id",
    data: "funeralHomeIds",
  }, {
    header: "Override Id",
    data: "overrideId",
  }
];