import axios from '.';

export const forgotpassword=(data:any)=>{
    return axios({
      method:'POST',
      url:'/reset-password-request',
      data
    })
  }

  export const resetpassword=(data:any)=>{
    return axios({
      method:'POST',
      url:'/reset-password',
      data
    })
  }

  export const validationResetPasswordToken=(data:any)=>{
    return axios({
      method:'POST',
      url: '/reset-password-link-validation',
      data
    })
  }