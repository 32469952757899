import React from 'react';
import { Provider } from 'react-redux';
import { ReactNotifications } from 'react-notifications-component';
import Routes from './Routes';
import 'react-notifications-component/dist/theme.css';

interface IProps {
  store: any, history: any
}

const Root= ({ store, history }: IProps) =>
(
    <Provider store={store}>
      <ReactNotifications />
      <Routes />
    </Provider>
);

export default Root;
