import React from 'react';
import styles from './welcome.module.scss';
import Logo from "../../assets/icons/logo.svg"
const Welcome: React.FC = () => {
  return (
    <div className={styles.leftSection}>
      <div className={styles.leftSectionContent}>
      <img src={Logo} alt="AFP Logo" />
      <h1>Welcome To AFP!</h1>
      <p>We're here to support and empower your<br></br> funeral home’s growth with tailored solutions<br></br> and insightful tools. Log in to start enhancing<br></br> your services and expanding your reach.</p>
      </div>
    </div>
  );
};

export default Welcome;