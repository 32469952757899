import React from 'react';
import { Row } from 'reactstrap';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { PaginationComponentProps } from './pagination-component-props';
import './pagination-component.scss';
 
const PaginationComponent: React.FC<PaginationComponentProps> = ({
  totalRecords,
  pageSize,
  currentPage,
  onPageChange
}) => {
  const pageCount = Math.ceil(totalRecords / pageSize) || 1;
 
  return (
    <Row className="pagination-component">
      <Stack spacing={2}>
        <Pagination
          count={pageCount}
          page={currentPage}
          onChange={onPageChange}
          variant="outlined"
          shape="rounded"
          boundaryCount={1}
          siblingCount={2}  
        />
      </Stack>
    </Row>
  );
};
 
export default PaginationComponent;