const MODULES = {
  DIRECTORY: "Directory",
  CONTRACTS: "Contracts",
  LEADS: "Leads",
  REPORTS: "Reports",
  USERS: "Users",
  EXPORT: "Export",
  IMPORT: "Import",
  DASHBOARD: "Dashboard",
  SETTINGS: "Settings",
  COMING_SOON: "Coming Soon",
  GUIDES: "Guides",
  TERMS_OF_SERVICE: "Terms of Service",
  LOGOUT: "Logout",
};

export default MODULES;