/* eslint-disable  @typescript-eslint/no-explicit-any */
import editIcon from '../../../../../../../assets/icons/edit.svg';
import { useEffect, useState, useRef } from 'react';
import { getUser } from '../../../../../../../api/profile';
import moment from 'moment';
import unionIcon from '../../../../../../../assets/icons/unionIcon.svg';
import './notes.scss';
import { addOrUpdateFuneralHomeNote, getAllFuneralHomeNotes, removeFuneralHomeNote } from '../../../../../../../api/funeralhomes';

const Notes: any = (props: any) => {
  const { funeralHomeId, notesList, setNotesList } = props;
  const [editingNoteIndex, setEditingNoteIndex] = useState<number | null>(null);
  const [editedText, setEditedText] = useState('');
  const [addingNote, setAddingNote] = useState<boolean>(false);
  const [newNoteText, setNewNoteText] = useState('');
  const [user, setUser] = useState<any>(null);
  const divRef: any = useRef(null);

  useEffect(() => {
    getUser()
      .then((response) => {
        const sharedUserData = response?.data?.userDetails;
        setUser(sharedUserData);
      })
      .catch((error) => console.error('Error fetching user data:', error));
    fetchNotes();
  }, []);

  const fetchNotes = async () => {
    try {
      const response = await getAllFuneralHomeNotes(funeralHomeId);
      setNotesList(response.data.data.rows);
    } catch (error) {
      console.error('Error fetching notes:', error);
    }
  };

  const handleEditClick = (index: number) => {
    setEditingNoteIndex(index);
    setEditedText(notesList[index].notes);
  };

  const handleEditCloseClick = () => {
    setEditingNoteIndex(null);
    setEditedText('');
  };

  const handleSaveClick = async (index: number) => {
    const noteToUpdate = notesList[index];
    try {
      await addOrUpdateFuneralHomeNote(funeralHomeId, noteToUpdate.id, { notes: editedText });
      setEditingNoteIndex(null);
      fetchNotes();
    } catch (error) {
      console.error('Error updating note:', error);
    }
  };

  // const handleCancelClick = () => {
  //   setEditingNoteIndex(null);
  // };

  const handleAddMoreNotesClick = () => {
    if (!addingNote) {
      divRef?.current?.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }

    setAddingNote(!addingNote);
  };

  const handleSaveNewNote = async () => {
    if (newNoteText.trim() !== '') {
      try {
        const noteData = {
          notesHeader: 'New Note',
          notes: newNoteText,
          createdAt: new Date().toISOString(),
        };
        await addOrUpdateFuneralHomeNote(funeralHomeId, null, noteData);
        setNewNoteText('');
        setAddingNote(false);
        fetchNotes();
      } catch (error) {
        console.error('Error adding new note:', error);
      }
    } else {
      setAddingNote(false);
      console.error('Error: Note text is empty.');
    }
  };

  const handleDeleteNewNote = async (noteID: any) => {
    try {
      await removeFuneralHomeNote(funeralHomeId, noteID);
      if (editingNoteIndex !== null && notesList[editingNoteIndex].id === noteID) {
        setEditingNoteIndex(null);
      }
      fetchNotes();
      if (addingNote) {
        setAddingNote(false);
      }
    } catch (error) {
      console.error('Error deleting note:', error);
    }
  };

  return (
    <div className="view-funeral-homes-notes-container">
      <button className="add-more-notes-button" onClick={handleAddMoreNotesClick}>
        Add More Notes
      </button>
      <div className="notes-list-div" ref={divRef}>
        <>
          {addingNote && (
            <>
              <div className="eachNote-div-add-note-mode">
                <p>
                  {user?.first_name || ''} {user?.last_name || ''}
                </p>
                <textarea
                  value={newNoteText}
                  onChange={(e) => setNewNoteText(e.target.value)}
                  className="new-note-textarea"
                  placeholder="Write Note"
                />
                <div className="edit-buttons">
                  <button
                    onClick={handleAddMoreNotesClick} // Delete for new note
                    className="edit-notes-cancel"
                  >
                    Cancel
                  </button>
                  <button onClick={handleSaveNewNote} className="edit-notes-save">
                    Save
                  </button>
                </div>
              </div>
              <div className="note-warning-div">
                <img src={unionIcon} />
                <p>For Admin Only</p>
              </div>
            </>
          )}

          {/* Check if there are no notes in the list */}
          {notesList.length === 0 ? (
            <p className="no-notes-found">No records</p> // Display when no notes are present
          ) : (
            notesList.map((eachNote: any, index: any) => (
              <>
                <div key={eachNote.id} className={`eachNote-div ${editingNoteIndex === index ? 'editing' : ''}`}>
                  {editingNoteIndex === index ? (
                    <div className="edit-note-mode">
                      <h3>{eachNote?.createdBy?.first_name}</h3>
                      <textarea value={editedText} onChange={(e) => setEditedText(e.target.value)} className="edit-note-textarea" />
                      <div className="edit-buttons">
                        <button
                          onClick={handleEditCloseClick} // Delete for new note
                          className="edit-notes-cancel"
                        >
                          Cancel
                        </button>
                        <button onClick={() => handleDeleteNewNote(eachNote.id)} className="edit-notes-delete">
                          Delete
                        </button>
                        <button onClick={() => handleSaveClick(index)} className="edit-notes-save">
                          Save
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="normal-note-mode">
                      <div className="note-header">
                        <h3>{eachNote?.createdBy?.first_name}</h3>
                        <div className="edit-note-icon-div" onClick={() => handleEditClick(index)}>
                          <img src={editIcon} className="edit-note-icon" />
                        </div>
                      </div>
                      <p className="notes-text">{eachNote.notes}</p>
                      <p className="notes-text-date">{moment(eachNote.created_at).format('MM-DD-YYYY h:mm A')}</p>
                    </div>
                  )}
                </div>
                <div className="note-warning-div">
                  <img src={unionIcon} />
                  <p>For Admin Only</p>
                </div>
              </>
            ))
          )}
        </>
      </div>
    </div>
  );
};

export default Notes;
