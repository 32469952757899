import React from 'react';
import './no-role.scss';
import { Button } from 'reactstrap';

import Logo from "../../assets/icons/bg_logo.svg";

const NoRole = () => {
  return (
    <div className="no-role">
      <div className="no-role-container">
        <h2 className={`reset-password-heading`}><img className={`reset-password-heading-icon`} src={Logo} alt="AFP Logo" /></h2>
        <div className="no-role-container-text">
          <h2 className="error-msg">
            <i className="fa fa-exclamation-triangle" />
          </h2>
          <h3> Role is updated</h3>
          <p>
            There is new role assigned to you. Please re login into the system.
          </p>
          <Button className="no-role-container-button"  onClick={()=>{window.location.href = '/';}}>Logout</Button>
        </div>

      </div>
    </div>
  );
};

export default NoRole;
