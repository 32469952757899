/* eslint-disable  @typescript-eslint/no-explicit-any */
import { useRef } from 'react';
import { Formik } from 'formik';
import { InputComponent, PhoneNumberComponent } from '../../../../../../../../common/components/index';
import DirectoryAddressComponent from '../../../../../../../../common/components/directory-address-component/directory-address-component';
import { validationSchema } from './co-owner-table.validation';
import { addUpdateCoOwnerDetails } from '../../../../../../../../api/funeralhomes';
import tickIcons from '../../../../../../../../assets/icons/tickIcons.svg';
import crossIcons from '../../../../../../../../assets/icons/crossIcons.svg';
import { CoOwnerTableCellFormProps } from './co-owner-table-props';
import { formatPayload } from '../co-owner-table.utils';

const CoOwnerTableCellForm: React.FC<CoOwnerTableCellFormProps> = ({
  initialValues,
  colIndex,
  handleEditClick,
  setIsEditProgress,
  setLoading,
  funeralHomeId,
  handleGetCoOwner,
  setAlertOpen,
  setAlertType,
  setAlertMessage,
}) => {
  const formRef: any = useRef();

  const onSubmit = async (value: any) => {
    const payloadFormatted = formatPayload(value);
    try {
      setLoading(true);
      await addUpdateCoOwnerDetails(funeralHomeId, value?.id, payloadFormatted);
      setLoading(false);
      handleGetCoOwner();
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message
        ? error?.response?.data?.message
        : (typeof error === 'object' && error !== null && 'response' in error && (error as any).response?.data?.details?.[0]) ||
          (error instanceof Error ? error.message : 'An unexpected error occurred.');
      setAlertMessage(errorMessage);
      setAlertType('error');
      setAlertOpen(true);
      setLoading(false);
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    formRef?.current?.handleSubmit();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={formRef}
      isValidating={true}
      setSubmitting={true}
      isSubmitting={true}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => (
        <tr>
          <td key={colIndex} className={`${'co-owner-table-sticky-column'}`} valign="top">
            <img
              className={`edit-form-icon`}
              src={tickIcons}
              alt="Edit Icon"
              onClick={() => {
                handleSubmit();
              }}
            />
            <img
              className={`edit-form-icon`}
              src={crossIcons}
              alt="Edit Icon"
              onClick={() => {
                setIsEditProgress('');
                handleEditClick?.(values?.id);
              }}
            />
          </td>
          <td key={colIndex} className={`${'co-owner-table-sticky-column'}`} valign="top">
            <div className={`co-owner-table-column-component`}>
              <InputComponent fieldName="fullName" placeholder="Enter company name" optional={true} isAlpha />
            </div>
          </td>
          <td key={colIndex} className={``} valign="top">
            <div className={`co-owner-table-column-component-address`}>
              <DirectoryAddressComponent
                addressLabel=""
                streetFieldName="physicalAddress.street"
                zipCodeFieldName="physicalAddress.zipcode"
                setCity={(city) => setFieldValue('physicalAddress.physicalAddressCity', city)}
                setState={(state) => setFieldValue('physicalAddress.physicalAddressState', state)}
                selectedState={values?.physicalAddress?.selectedPhysicalAddressState}
                selectedCity={values?.physicalAddress?.selectedPhysicalAddressCity}
                setSelectedCity={(city) => setFieldValue('physicalAddress.selectedPhysicalAddressCity', city)}
                setSelectedState={(state) => setFieldValue('physicalAddress.selectedPhysicalAddressState', state)}
                cityFieldName="physicalAddress.selectedPhysicalAddressCity"
                stateFieldName="physicalAddress.selectedPhysicalAddressState"
                optional={false}
                // mode={mode}
              />
            </div>
          </td>
          <td key={colIndex} valign="top">
            <div className={`co-owner-table-column-component-address`}>
              <DirectoryAddressComponent
                addressLabel=""
                streetFieldName="mailingAddress.street"
                zipCodeFieldName="mailingAddress.zipcode"
                setCity={(city) => setFieldValue('mailingAddress.mailingAddressCity', city)}
                setState={(state) => setFieldValue('mailingAddress.mailingAddressState', state)}
                selectedState={values?.mailingAddress?.selectedMailingAddressState}
                selectedCity={values?.mailingAddress?.selectedMailingAddressCity}
                setSelectedCity={(city) => setFieldValue('mailingAddress.selectedMailingAddressCity', city)}
                setSelectedState={(state) => setFieldValue('mailingAddress.selectedMailingAddressState', state)}
                cityFieldName="mailingAddress.selectedMailingAddressCity"
                stateFieldName="mailingAddress.selectedMailingAddressState"
                optional={false}
                // mode={mode}
              />
            </div>
          </td>
          <td key={colIndex} valign="top">
            <div className={`co-owner-table-column-component`}>
              <PhoneNumberComponent
                fieldName="contactNumber.phoneNumber"
                countryCodeFieldName="contactNumber.countryCode"
                placeholder="Enter contact number"
                menuPosition={'fixed'}
              />
            </div>
          </td>
          <td key={colIndex} valign="top">
            <div className={`co-owner-table-column-component`}>
              <InputComponent fieldName="email" placeholder="Enter email address" isEmail />
            </div>
          </td>
          <td key={colIndex} valign="top">
            <div className={`co-owner-table-column-component`}>
              <InputComponent fieldName="contactDepartments" placeholder="Enter contact departments" isAlpha />
            </div>
          </td>
        </tr>
      )}
    </Formik>
  );
};
export default CoOwnerTableCellForm;
