import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";

import {
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  FormGroup,
  Label,
  Button,
} from "reactstrap";
import closeIcon from "../../../assets/icons/closeIcon.svg";
import "../add-or-edit-directories.scss";
import { AddOrEditComponentProps } from "../types/add-or-edit-props";
import {
  removeAgentDetails,
  updateAgentsDetails,
  addAgentsDetails,
  getAutoFillAgent,
} from "../../../api/agents";
import AlertComponent from "../../../common/components/Alert/Alert";
import DeleteConfirmationPopup from "../delete-confirmation-popup/delete-confirmation-popup";
import { concatenatePhoneNumber, splitPhoneNumber } from "../utils";
import {
  DatePicker,
  DirectoryAddressComponent,
  InputComponent,
  PhoneNumberComponent,
  SelectDropdownComponent,
  CustomCheckbox
} from "../../../common/components";
import { DropdownOption } from "../../leads-item/leads-item-props";
import { Autocomplete, TextField } from "@mui/material";
import debounce from "lodash/debounce";
import { getAutoFillLocation } from "../../../api/profile";
import { LocationOption } from "../../../common/components/directory-address-component/directory-address-component-props";
import { validationSchema } from "./add-or-edit-agents.validations";
import { AgentOption, CommissionOption, getCustomDobStyle, selectDropDowncustomStyles } from "./add-or-edit-agents.utils";
import { useShouldDisplayField } from "../../../common/hooks/useShouldDisplayField";
import './add-or-edit-agents.scss';

const AddOrEditAgents: React.FC<AddOrEditComponentProps> = ({
  isOpen,
  toggle,
  mode,
  editData,
  refreshData,
}) => {

  const [isDeletePopupOpen, setDeletePopupOpen] = useState<boolean>(false);
  const [deleteAgentId, setDeleteAgentId] = useState<number | null>(null);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<
    "success" | "error" | "info" | "warning"
  >("success");
  const [selectedState, setSelectedState] = useState<LocationOption | null>(null);  
  const [selectedAppointedState, setSelectedAppointedState] = useState<LocationOption | null>(null);  
  const [stateOptions, setStateOptions] = useState<LocationOption[]>([]);
  const [stateAppointedOptions, setStateAppointedOptions] = useState<LocationOption[]>([]);
  const [focusedField, setFocusedField] = useState<string | null>(null);
  const [agencyOptions, setAgencyOptions] = useState<AgentOption[]>([]);
  const [regionalRepOptions, setRegionalRepOptions] = useState<AgentOption[]>([]);
  const [agentOptions, setAgentOptions] = useState<AgentOption[]>([]);
  const [funeralHomeOptions, setFuneralHomeOptions] = useState<AgentOption[]>([]);
  const [selectedAgency, setSelectedAgency] = useState<AgentOption | null>(null);
  const [selectedAgent, setSelectedAgent] = useState<AgentOption | null>(null);
  const [selectedRegionalRep, setSelectedRegionalRep] = useState<AgentOption | null>(null);
  const [selectedFuneralHome, setSelectedFuneralHome] = useState<AgentOption | null>(null);
  const [commssionDetailsOptions, setCommissionDetailsOptions] = useState<CommissionOption[]>([]);
  const [selectedCommissionLevel, setSelectedCommissionLevel] = useState<CommissionOption | null>(null);


  const shouldDisplayField = useShouldDisplayField();

  const handleDelete = async (deleteAgentId: number | null) => {
    try {
      if (deleteAgentId === null) {
        console.error("No Agent ID provided for deletion");
        return;
      }
      const response = await removeAgentDetails(deleteAgentId);
      if (response.data.success && refreshData) {
        refreshData();
      }
      setDeletePopupOpen(false);
      toggle();
    } catch (error) {
      console.error("Error while deleting agent:", error);
      setAlertMessage("Failed to delete agent.");
      setAlertType("error");
      setAlertOpen(true);
    }
  };

  const initialValues =
    editData && mode === "edit"
      ? {
          name: editData.name,
          entityName: editData?.entity_name || "",
          homeAddress: {
            id: editData.homeAddress.id,
            street: editData.homeAddress.street || "",
            zipcode: editData.homeAddress.zipcode || "",
            city: editData?.homeAddress?.City?.name || "",
            state: editData?.homeAddress?.State?.name || "",
            selectedHomeAddressCity: editData.homeAddress.City, 
            selectedHomeAddressState: editData.homeAddress.State,
            homeAddressCity: null,
            homeAddressState: null,
          },
          mailingAddress: {
            id: editData.mailingAddress.id,
            street: editData.mailingAddress.street || "",
            zipcode: editData.mailingAddress.zipcode || "",
            state: editData?.mailingAddress?.State?.name || "",
            city: editData?.mailingAddress?.City?.name || "",
            selectedMailingAddressCity: editData.mailingAddress.City, 
            selectedMailingAddressState: editData.mailingAddress.State,
            mailingAddressCity: null,
            mailingAddressState: null,
          },
          homePhone: editData?.home_phone ? splitPhoneNumber(editData?.home_phone) :  {
            countryCode: "+1", 
            phoneNumber: "",  
          },
          workPhone: splitPhoneNumber(editData.work_phone),
          cellPhone: editData?.cell_phone ? splitPhoneNumber(editData?.cell_phone) :  {
            countryCode: "+1", 
            phoneNumber: "",  
          },
          emailAddress: editData.email_address,
          gender: editData?.gender || "",
          agency: editData.agency || "",
          companiesContractedWith: editData?.companies_contracted_with || "",
          bankRoutingNumber: editData?.bank_routing_number || "",
          bankAccountNumber: editData?.bank_account_number || "",
          accountType: editData.account_type || "",
          ein: editData.ein || "",
          dateOfBirth: editData?.date_of_birth || "",
          commissionPaymentFrequency:
          editData?.commission_payment_frequency || "",
          bankName: editData.bank_name || "",
          socialSecurityNumber: editData?.social_security_number || "",
          stateLicensedIn: editData?.states_licensed?.id || "",
          licenseExpiration: editData?.license_expiration || "",
          amlCertification: editData?.aml_certification || "",
          reserveBalance: editData?.reserve_balance || "",
          agencyNumber: editData?.agency_number || "",
          funeralHomeAssociatedWith: editData.agent_funeral_home_associated ? editData.agent_funeral_home_associated.map((state:any)=>({
            funeralHomeId: state.funeral_home_id || "",
            name: state.funeral_home_associated.name || "",
          })):[],
          agentNumber: editData?.agent_number || "",
          uplineAgent:editData?.uplineAgent?.id || "",
          beneficiary:editData?.beneficiary || "",
          specialInstructions: editData?.special_instruction || "",
          commissionLevel: editData?.commission_level || "",
          regionalRepId: editData?.regional_rep_id || "",
          agentStatesAppointedIn: editData.agent_states_appointed
          ? editData.agent_states_appointed.map((state:any) => ({
              id: state.id || null,
              stateId: state.state_id || "",
              name: state.states_appointed_in?.name || ""
            }))
          : [],
          note: editData?.note || "",
        }
      : {
          name: "",
          homeAddress: {
            street: "",
            city: "",
            state: "",
            zipcode: "",
            selectedHomeAddressCity: null,
            selectedHomeAddressState: null,
            homeAddressCity: null,
            homeAddressState: null,
          },
          mailingAddress: {
            street: "",
            city: "",
            state: "",
            zipcode: "",
            selectedMailingAddressCity: null,
            selectedMailingAddressState: null,
            mailingAddressCity: null,
            mailingAddressState: null,
          },
          homePhone: {
            countryCode: "+1",
            phoneNumber: "",
          },
          workPhone: {
            countryCode: "+1",
            phoneNumber: "",
          },
          cellPhone: {
            countryCode: "+1",
            phoneNumber: "",
          },
          emailAddress: "",
          stateLicensedIn: "",
          commissionLevel: "",
          funeralHomeAssociatedWith: [],
          agentStatesAppointedIn: [],
        };

  useEffect(() => {
    if (editData) {
      setSelectedState(editData.states_licensed);
      setSelectedAgency(editData.agency);
      setSelectedRegionalRep(editData.regionalRep);
      setSelectedAgent(editData.uplineAgent);
      setSelectedAppointedState(editData.agent_states_appointed?.[0]?.states_appointed_in || null);
      setSelectedFuneralHome(editData.agent_funeral_home_associated?.[0]?.funeral_home_associated || null);
      setSelectedCommissionLevel(editData.commissionLevel);
    }
  }, [editData]);

  const onSubmit = async (values: typeof initialValues, { resetForm }: any) => {
    const agentData = {
      name: values.name,
      entityName: values.entityName || null,
      homeAddress: {
        id: mode === "edit" ? editData?.homeAddress.id : undefined,
        street: values.homeAddress.street,
        city: {
          id: values?.homeAddress?.selectedHomeAddressCity?.id || editData?.homeAddress.City.id,
          name:
          values?.homeAddress?.selectedHomeAddressCity?.name ||
            editData?.homeAddress.City.name,
        },
        state: {
          id:
          values?.homeAddress?.selectedHomeAddressState?.id || editData?.homeAddress.State.id,
          name:
          values?.homeAddress?.selectedHomeAddressState?.name ||
            editData?.homeAddress.State.name,
        },
        zipcode: values.homeAddress.zipcode,
      },
      mailingAddress: {
        id: mode === "edit" ? editData?.mailingAddress.id : undefined,
        street: values.mailingAddress.street,
        city: {
          id:
          values?.mailingAddress?.selectedMailingAddressCity?.id || editData?.mailingAddress.City.id,
          name:
          values?.mailingAddress?.selectedMailingAddressCity?.name ||
            editData?.mailingAddress.City.name,
        },
        state: {
          id:
          values?.mailingAddress?.selectedMailingAddressState?.id ||
            editData?.mailingAddress.State.id,
          name:
          values?.mailingAddress?.selectedMailingAddressState?.name ||
            editData?.mailingAddress.State.name,
        },
        zipcode: values.mailingAddress.zipcode,
      },
      homePhone: values.homePhone.phoneNumber
        ? concatenatePhoneNumber(values.homePhone)
        : null,
      workPhone: values.workPhone.phoneNumber
        ? concatenatePhoneNumber(values.workPhone)
        : null,
      cellPhone: values.cellPhone.phoneNumber
        ? concatenatePhoneNumber(values.cellPhone)
        : null,
      emailAddress: values.emailAddress,
      gender: values.gender || null,
      agency: values?.agency || null,
      companiesContractedWith: values.companiesContractedWith || null,
      bankRoutingNumber: values.bankRoutingNumber || null,
      bankAccountNumber: values.bankAccountNumber || null,
      accountType: values.accountType || null,
      ein: values.ein || null,
      dateOfBirth: values.dateOfBirth || null,
      commissionPaymentFrequency: values.commissionPaymentFrequency || null,
      bankName: values.bankName || null,
      socialSecurityNumber: values.socialSecurityNumber || null,
      stateLicensedIn: values.stateLicensedIn,
      licenseExpiration: values.licenseExpiration || null,
      amlCertification: values.amlCertification || null,
      reserveBalance: values.reserveBalance || null,
      agencyNumber: values.agencyNumber || null,
      specialInstructions: values.specialInstructions || null,
      agentNumber: values.agentNumber || null,
      upline_agent: values.uplineAgent || null,
      funeralHomeAssociatedWith: values.funeralHomeAssociatedWith.map((state: any) => {
        if (mode === "edit") {
          return {
            id: state.id || editData.id,
            funeralHomeId: state.funeralHomeId,
            name: state.name || "",
          };
        }
        return {
          funeralHomeId: state.funeralHomeId,
          name: state.name || ""
        };
      }),
      beneficiary: values.beneficiary || null,
      commissionLevel: values.commissionLevel ||null,
      regionalRepId: values.regionalRepId || null,
      agentStatesAppointedIn: values.agentStatesAppointedIn.map((state: any) => {
        if (mode === "edit") {
          return {
            id: state.id || editData.id,
            stateId: state.stateId,
            name: state.name || "",
          };
        }
        return {
          stateId: state.stateId,
          name: state.name || ""
        };
      }),
      note: values?.note || null,
    };
    try {
      if (editData && mode === "edit") {
        const response = await updateAgentsDetails(editData.id, agentData)
        if (response.data.success && refreshData) {
          refreshData();
        }
      } else {
        const response = await addAgentsDetails(agentData);
        if (response.data.success && refreshData) {
          refreshData();
        }
      }
      resetForm();
      toggle();
    } catch (error) {
      console.error("Error submitting form:", error);
      setAlertMessage("Failed to update Agent.");
      setAlertType("error");
      setAlertOpen(true);
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  
  const fetchStateOptions =useCallback( async (key: string, value: string) => {
    try {
      if (value.length < 3) return;
      const response = await getAutoFillLocation("state", value);
      const data = response.data.data;
      const locationData = data as LocationOption[];
      const transformedOptions = locationData.map((item) => ({
        id: item.id,
        name: item.name,
      }));
      
      if (key === "statesAppointed"){
      setStateAppointedOptions(transformedOptions);
      } else {
        setStateOptions(transformedOptions);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },[]);
  const debouncedStateFetchOptions = useMemo(
    () => debounce(fetchStateOptions, 500),
    [fetchStateOptions]
  );
  //const debouncedStateFetchOptions = useCallback(debounce(fetchStateOptions, 500), []);
  
  const fetchOptions =useCallback( async (key: string, value: string) => {
    try {
      if (value.length < 3) return;
      const response = await getAutoFillAgent(key, value);
      const data = response.data.data;
      const fetchData = data as LocationOption[];
      const commissionData = data as CommissionOption[];
      if (key === "funeralHome") {
        setFuneralHomeOptions(fetchData);
      } else if (key === "agent") {
        setAgentOptions(fetchData);
      } else if (key === "agency") {
        setAgencyOptions(fetchData);
      } else if(key === "regionalRep"){
        setRegionalRepOptions(fetchData);
      } else if(key === "commissionDetails"){
        setCommissionDetailsOptions(commissionData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },[]);
  const debouncedFetchOptions = useMemo(
    () => debounce(fetchOptions, 500),
    [fetchOptions]
  );
  //const debouncedFetchOptions = useCallback(debounce(fetchOptions, 500), []);
  const handleFocus = (field: string) => setFocusedField(field);

  return (
    <>
      <AlertComponent
        message={alertMessage}
        type={alertType}
        open={alertOpen}
        onClose={handleAlertClose}
      />
      <div className="add-or-edit-offcanvas">
        <Offcanvas isOpen={isOpen} direction="end" className="offcanvas">
          <OffcanvasHeader className="offcanvas-header">
            <div className="header-div">
              <h2 className="mb-0">
                {mode === "edit" ? "Edit" : "Add"} Agents
              </h2>
              <button onClick={toggle}>
                <img src={closeIcon} alt="Close" />
              </button>
            </div>
          </OffcanvasHeader>
          <OffcanvasBody className="offcanvas-body">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                <Form className="add-or-edit-form add-or-edit-agents"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                >
                  <InputComponent
                    fieldName="name"
                    label="Agent Name"
                    placeholder="Enter Agent Name"
                    optional={false}
                    isAlpha
                  />
                  <InputComponent
                    fieldName="entityName"
                    label="Entity Name"
                    placeholder="Enter Entity Name"
                    isAlpha
                  />
                  <DirectoryAddressComponent
                    addressLabel="mailingAddress"
                    streetFieldName="mailingAddress.street"
                    zipCodeFieldName="mailingAddress.zipcode"
                    setCity={(city)=>setFieldValue('mailingAddress.mailingAddressCity', city)}   
                    setState={(state)=>setFieldValue('mailingAddress.mailingAddressState', state)} 
                    selectedState={values?.mailingAddress?.selectedMailingAddressState}
                    selectedCity={values?.mailingAddress?.selectedMailingAddressCity}
                    setSelectedCity = {(city)=>setFieldValue('mailingAddress.selectedMailingAddressCity', city)} 
                    setSelectedState = {(state)=>setFieldValue('mailingAddress.selectedMailingAddressState', state)}
                    cityFieldName="mailingAddress.selectedMailingAddressCity"
                    stateFieldName="mailingAddress.selectedMailingAddressState"
                    optional={false}
                    // mode={mode}
                  />
                  <DirectoryAddressComponent
                    addressLabel="homeAddress"
                    streetFieldName="homeAddress.street"
                    zipCodeFieldName="homeAddress.zipcode"
                    setCity={(city)=>setFieldValue('homeAddress.homeAddressCity', city)}   
                    setState={(state)=>setFieldValue('homeAddress.homeAddressState', state)} 
                    selectedState={values?.homeAddress?.selectedHomeAddressState}
                    selectedCity={values?.homeAddress?.selectedHomeAddressCity}
                    setSelectedCity = {(city)=>setFieldValue('homeAddress.selectedHomeAddressCity', city)} 
                    setSelectedState = {(state)=>setFieldValue('homeAddress.selectedHomeAddressState', state)}
                    cityFieldName="homeAddress.selectedHomeAddressCity"
                    stateFieldName="homeAddress.selectedHomeAddressState"
                    optional={false}
                    // mode={mode}
                  />
                  <PhoneNumberComponent
                    label="Home Phone Number"
                    fieldName="homePhone.phoneNumber"
                    countryCodeFieldName="homePhone.countryCode"
                    placeholder="Enter Home Phone Number"
                  />
                  <PhoneNumberComponent
                    label="Work Phone Number"
                    fieldName="workPhone.phoneNumber"
                    countryCodeFieldName="workPhone.countryCode"
                    placeholder="Enter Work Phone Number"
                    optional={false}
                  />
                  <PhoneNumberComponent
                    label="Cell Phone Number"
                    fieldName="cellPhone.phoneNumber"
                    countryCodeFieldName="cellPhone.countryCode"
                    placeholder="Enter Cell Phone Number"
                  />
                  <InputComponent
                    fieldName="emailAddress"
                    label="Email Address"
                    placeholder="Enter Email Address"
                    optional={false}
                    isEmail
                  />
                  <FormGroup className="add-or-edit-form-group">
                    <Label htmlFor="gender" className="custom-form-label">
                      Gender
                    </Label>
                    <SelectDropdownComponent
                      placeholder="Select Gender"
                      options={[
                        { label: "Male", value: "Male" },
                        { label: "Female", value: "Female" },
                      ]}
                      customStyles={selectDropDowncustomStyles}
                      value={
                        values.gender
                          ? { label: values.gender, value: values.gender }
                          : null
                      }
                      onChange={(selected) => {
                        const selectedOption = selected as DropdownOption;
                        setFieldValue("gender", selectedOption?.value);
                      }}
                    />
                  </FormGroup>
                  <FormGroup className="add-or-edit-form-group">
                    <div>
                    <Label
                        htmlFor="agency"
                        className="custom-form-label"
                      >Agency</Label>
                      <Autocomplete
                        freeSolo
                        options={agencyOptions}
                        getOptionLabel={(option) => {
                          return typeof option === 'string' ? option : option?.name || "";
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, value) => {
                          if (typeof value !== 'string') {
                            setSelectedAgency(value);
                            setFieldValue("agency", value ? value.name : null);
                          }
                        }}   
                        onInputChange={(event, value) => {
                          if (focusedField === "agency"){
                            debouncedFetchOptions("agency", value);
                          }
                          setFieldValue("agency", value);
                        }}
                        onFocus={()=> handleFocus("agency")}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Enter Agency Name"
                            className="autocomplete-text-field"
                            value={selectedAgency ? selectedAgency.name : ""}
                          />
                        )}
                        value={selectedAgency || null}
                        className="autocomplete-div"
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="add-or-edit-form-group">
                    <Label htmlFor="companiesContractedWith" className="custom-form-label">
                    Companies Contracted With
                    </Label>
                    <SelectDropdownComponent
                      placeholder="Select Companies Contracted With"
                      options={[
                        { label: "NGL", value: "NGL" },
                        { label: "GWIC", value: "GWIC" },
                        { label: "Global Atlantic", value: "Global Atlantic" },
                        { label: "FDLIC", value: "FDLIC" },
                        { label: "Travel plans by Inman", value: "Travel plans by Inman" },
                        { label: "APSI", value: "APSI" },
                        { label: "Other", value: "Other" },
                      ]}
                      customStyles={selectDropDowncustomStyles}
                      value={
                        values.companiesContractedWith
                          ? { label: values.companiesContractedWith, value: values.companiesContractedWith }
                          : null
                      }
                      onChange={(selected) => {
                        const selectedOption = selected as DropdownOption;
                        setFieldValue("companiesContractedWith", selectedOption?.value);
                      }}
                    />
                    <ErrorMessage
                      name="companiesContractedWith"
                      component="div"
                      className="error-message"
                    />
                  </FormGroup>
                  <InputComponent
                    fieldName="bankRoutingNumber"
                    label="Bank Routing Number"
                    placeholder="Enter Bank Routing Number"
                    optional={true}
                    isAlphaNumeric
                  />
                  <InputComponent
                    fieldName="bankAccountNumber"
                    label="Bank Account Number"
                    placeholder="Enter Bank Account Number"
                    optional={true}
                    onlyNums
                  />
                  <FormGroup className="add-or-edit-form-group">
                    <div className="checkbox-group">
                      <CustomCheckbox
                        name="accountType"
                        label="Checking"
                        value="Checking"
                      />
                      <CustomCheckbox
                        name="accountType"
                        label="Savings"
                        value="Savings"
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="add-or-edit-form-group">
                    <Label
                      htmlFor="commissionPaymentFrequency"
                      className="custom-form-label"
                    >
                      Commission Payment Frequency
                    </Label>
                    <SelectDropdownComponent
                      placeholder="Select Commission Payment Frequency"
                      options={[
                        { label: "Daily", value: "Daily" },
                        { label: "Weekly", value: "Weekly" },
                        { label: "Monthly", value: "Monthly" },
                        { label: "Quarterly", value: "Quarterly" },
                        { label: "Half-yearly", value: "Half-yearly" },
                        { label: "Yearly", value: "Yearly" },
                      ]}
                      customStyles={selectDropDowncustomStyles}
                      value={
                        values.commissionPaymentFrequency
                          ? {
                              label: values.commissionPaymentFrequency,
                              value: values.commissionPaymentFrequency,
                            }
                          : null
                      }
                      onChange={(selected) => {
                        const selectedOption = selected as DropdownOption;
                        setFieldValue(
                          "commissionPaymentFrequency",
                          selectedOption?.value
                        );
                      }}
                    />
                  </FormGroup>
                  <InputComponent
                    fieldName="bankName"
                    label="Bank Name"
                    placeholder="Enter Bank Name"
                    optional={true}
                    isAlpha
                  />
                  <InputComponent
                    fieldName="socialSecurityNumber"
                    label="Social Security Number(Last 4 digits)"
                    placeholder="Enter Social Security Number"
                    optional={true}
                    isSSN={true}
                  />
                  <InputComponent
                    fieldName="ein"
                    label="EIN"
                    placeholder="Enter EIN"
                    optional={true}
                    isEin
                  />
                  <FormGroup className="add-or-edit-form-group">
                    <div>
                      <Label
                        htmlFor="dateOfBirth"
                        className="custom-form-label"
                      >
                        Date Of Birth
                      </Label>
                      <DatePicker
                        placeholder="Select Date of Birth"
                        value={values.dateOfBirth ? new Date(values.dateOfBirth) : undefined} 
                        maxDate={new Date()}
                        onChange={(selected) => {
                            setFieldValue("dateOfBirth", selected?.toISOString());
                        }}
                        customStyles={getCustomDobStyle('styleVariant')}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="add-or-edit-form-group">
                    <div>
                    <Label
                        htmlFor="stateLicensedIn"
                        className="custom-form-label"
                      >States Licensed In<span>*</span></Label>
                      <Autocomplete
                        options={stateOptions}
                        getOptionLabel={(option) => option?.name || ""}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, value) => {
                          setSelectedState(value);
                          setFieldValue("stateLicensedIn", value ? value.id : null);
                        }}     
                        onInputChange={(event, value) => {
                            debouncedStateFetchOptions("state", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select State"
                            className="autocomplete-text-field"
                          />
                        )}
                        value={selectedState || null}
                        className="autocomplete-div"
                      />
                    </div>
                    <ErrorMessage
                      name="stateLicensedIn"
                      component="div"
                      className="error-message"
                    />
                  </FormGroup>
                  <FormGroup className="add-or-edit-form-group">
                    <div>
                      <Label
                        htmlFor="licenseExpiration"
                        className="custom-form-label"
                      >
                        License Expiration (each state)
                      </Label>
                      <DatePicker
                        placeholder="Select License Expiration (each state)"
                        value={values.licenseExpiration ? new Date(values.licenseExpiration) : undefined} 
                        minDate={new Date()}
                        onChange={(selected) => {
                            setFieldValue("licenseExpiration", selected?.toISOString());
                        }}
                        customStyles={getCustomDobStyle('styleVariant')}
                      />
                    </div>
                  </FormGroup>
                  <InputComponent
                    fieldName="amlCertification"
                    label="Aml Certification (each company)"
                    placeholder="Enter Aml Certification"
                    optional={true}
                  />
                  <InputComponent
                    fieldName="reserveBalance"
                    label="Reserve Balance (each company)"
                    placeholder="Enter Reserve Balance"
                    optional={true}
                  />
                  <InputComponent
                    fieldName="agencyNumber"
                    label="Agency Number (each company)"
                    placeholder="Enter Agency Number"
                    optional={true}
                  />
                  <InputComponent
                    fieldName="specialInstructions"
                    label="Special Instructions"
                    placeholder="Enter Special Instructions"
                    inputIsTextArea={true}
                    optional={true}
                    isAlphaNumeric
                  />
                  <FormGroup className="add-or-edit-form-group">
                    <div>
                    <Label
                        htmlFor="commissionDetails"
                        className="custom-form-label"
                      >Commission Level<span>*</span></Label>
                      <Autocomplete
                        options={commssionDetailsOptions}
                        getOptionLabel={(option) => option?.commission_name || ""}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, value) => {
                          setSelectedCommissionLevel(value);
                          setFieldValue("commissionLevel", value ? value.id : []);
                        }}        
                        onInputChange={(event, value) => {
                          if (focusedField === "commissionDetails"){
                            debouncedFetchOptions("commissionDetails", value);
                          }
                        }}
                        onFocus={()=> handleFocus("commissionDetails")}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Commission Level"
                            className="autocomplete-text-field"
                          />
                        )}
                        value={ selectedCommissionLevel || null}
                        className="autocomplete-div"
                      />
                    </div>
                    <ErrorMessage
                      name="commissionLevel"
                      component="div"
                      className="error-message"
                    />
                  </FormGroup>
                  <FormGroup className="add-or-edit-form-group">
                    <div>
                    <Label
                        htmlFor="regionalRep"
                        className="custom-form-label"
                      >Regional Rep</Label>
                      <Autocomplete
                        options={regionalRepOptions}
                        getOptionLabel={(option) => option?.name || ""}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, value) => {
                          setSelectedRegionalRep(value);
                          setFieldValue("regionalRepId", value ? value.id : []);
                        }}        
                        onInputChange={(event, value) => {
                          if (focusedField === "regionalRep"){
                            debouncedFetchOptions("regionalRep", value);
                          }
                        }}
                        onFocus={()=> handleFocus("regionalRep")}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Regional Rep"
                            className="autocomplete-text-field"
                          />
                        )}
                        value={ selectedRegionalRep || null}
                        className="autocomplete-div"
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="add-or-edit-form-group">
                    <div>
                    <Label
                        htmlFor="agentStatesAppointedIn"
                        className="custom-form-label"
                      >State Appointed</Label>
                      <Autocomplete
                        options={stateAppointedOptions}
                        getOptionLabel={(option) => option?.name || ""}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, value) => {
                          setSelectedAppointedState(value);
                          setFieldValue("agentStatesAppointedIn", value ? [{ stateId: value.id, name: value.name }] : []);
                        }}         
                        onInputChange={(event, value) => {
                          if(focusedField==="statesAppointed"){
                            debouncedStateFetchOptions("statesAppointed", value);
                          }
                        }}
                        onFocus={()=>handleFocus("statesAppointed")}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select State Appointed"
                            className="autocomplete-text-field"
                          />
                        )}
                        value={selectedAppointedState || null}
                        className="autocomplete-div"
                      />
                    </div>
                  </FormGroup>
                  <InputComponent
                    fieldName="agentNumber"
                    label="Agent Number"
                    placeholder="Enter Agent Number"
                    onlyNums
                  />
                  <FormGroup className="add-or-edit-form-group">
                    <div>
                    <Label
                        htmlFor="uplineAgent"
                        className="custom-form-label"
                      >UplineAgents</Label>
                      <Autocomplete
                        options={agentOptions}
                        getOptionLabel={(option) => option?.name || ""}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, value) => {
                          setSelectedAgent(value);
                          setFieldValue("uplineAgent", value ? value.id : []);
                        }}        
                        onInputChange={(event, value) => {
                          if (focusedField === "agent"){
                            debouncedFetchOptions("agent", value);
                          }
                        }}
                        onFocus={()=> handleFocus("agent")}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Agent"
                            className="autocomplete-text-field"
                          />
                        )}
                        value={selectedAgent || null}
                        className="autocomplete-div"
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="add-or-edit-form-group">
                    <div>
                    <Label
                        htmlFor="funeralHomeAssociatedWith"
                        className="custom-form-label"
                      >Funeral Home Associated With</Label>
                      <Autocomplete
                        options={funeralHomeOptions}
                        getOptionLabel={(option) => option?.name || ""}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, value) => {
                          setSelectedFuneralHome(value);
                          setFieldValue("funeralHomeAssociatedWith", value ? [{ funeralHomeId: value.id }] : []);
                        }}       
                        onInputChange={(event, value) => {
                          if (focusedField === "funeralHome"){
                            debouncedFetchOptions("funeralHome", value);
                          }
                        }}
                        onFocus={()=> handleFocus("funeralHome")}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Funeral Home Associated With"
                            className="autocomplete-text-field"
                          />
                        )}
                        value={selectedFuneralHome || null}
                        className="autocomplete-div"
                      />
                    </div>
                  </FormGroup>
                  <InputComponent
                    fieldName="beneficiary"
                    label="Beneficiary"
                    placeholder="Enter Beneficiary"
                    optional={true}
                  />
                  {shouldDisplayField &&
                    <InputComponent
                      fieldName="note"
                      label="Note (admins only)"
                      placeholder="Enter Note"
                      inputIsTextArea={true}
                      optional={true}
                    />}
                  <div className="form-button-container">
                    {editData ? (
                      <Button
                        type="button"
                        className="delete-button"
                        onClick={() => {
                          setDeleteAgentId(editData.id);
                          setDeletePopupOpen(true);
                        }}
                      >
                        Delete
                      </Button>
                    ) : (
                      ""
                    )}
                    <button
                      type="button"
                      className={`cancel-button${
                        mode === "edit" ? "-in-edit" : ""
                      }`}
                      onClick={toggle}
                    >
                      Cancel
                    </button>
                    <button
                      className={mode === "edit" ? "save-button-changes" : "save-button"}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {mode === "edit" ? "Save Changes" : "Save"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </OffcanvasBody>
        </Offcanvas>
      </div>
      <DeleteConfirmationPopup
        isOpen={isDeletePopupOpen}
        onClose={() => setDeletePopupOpen(!isDeletePopupOpen)}
        handleDelete={handleDelete}
        deleteId={deleteAgentId}
        label="Agent"
      />
    </>
  );
};
export default AddOrEditAgents;
