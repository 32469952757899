/* eslint-disable  @typescript-eslint/no-explicit-any */
import { useCallback, useMemo, useRef } from 'react';
import { Formik, ErrorMessage } from 'formik';
import { InputComponent } from '../../../../../../../../common/components/index';
import { validationSchema } from './additional-ids-table.validation';
import tickIcons from '../../../../../../../../assets/icons/tickIcons.svg';
import crossIcons from '../../../../../../../../assets/icons/crossIcons.svg';
import { AdditionalIdsTableCellFormProps } from './additional-ids-table-props';
import { addUpdateAdditionalIdsDetails, getAutoFillDirectories } from '../../../../../../../../api/funeralhomes';
import { debounce } from 'lodash';
import { Autocomplete, TextField } from '@mui/material';

const AdditionalIdsTableCellForm: React.FC<AdditionalIdsTableCellFormProps> = ({
  initialValues,
  colIndex,
  handleEditClick,
  setIsEditProgress,
  setLoading,
  funeralHomeId,
  setAlertOpen,
  setAlertType,
  setAlertMessage,
  handleGetAdditionalIds,
}) => {
  const formRef: any = useRef();
  const handleAdditionalIdsChange = (event: any, newValue: any, setFieldValue: any) => {
    if (newValue) {
      setFieldValue(`selectedCompany`, { id: newValue.id, name: newValue.name });
    } else {
      setFieldValue(`selectedCompany`, null);
    }
  };
  const handleAdditionalIdFocus = (setFieldValue: any) => setFieldValue(`additionalIdFocusedField`, 'additionalIdFocusedField');

  const fetchAdditionalIdOptions = useCallback(async (value: string, setFieldValue?: any) => {
    try {
      if (value.length < 3) return;
      const response = await getAutoFillDirectories('companies', value);
      const data: any = response.data.data;
      setFieldValue('companyOptions', data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);
  const debouncedFetchAdditionalIdOptions = useMemo(() => debounce(fetchAdditionalIdOptions, 500), [fetchAdditionalIdOptions]);

  const onSubmit = async (value: any) => {
    const payloadFormatted = {
      companyId: value?.selectedCompany?.id,
      companyGivenId: value?.funeralHomeIds,
      overrideId: value?.overrideId,
    };
    try {
      setLoading(true);
      await addUpdateAdditionalIdsDetails(funeralHomeId, value?.id, payloadFormatted);
      setLoading(false);
      handleGetAdditionalIds();
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message
        ? error?.response?.data?.message
        : (typeof error === 'object' && error !== null && 'response' in error && (error as any).response?.data?.details?.[0]) ||
          (error instanceof Error ? error.message : 'An unexpected error occurred.');
      setAlertMessage(errorMessage);
      setAlertType('error');
      setAlertOpen(true);
      setLoading(false);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    formRef?.current?.handleSubmit();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={formRef}
      isValidating={true}
      setSubmitting={true}
      isSubmitting={true}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => (
        <tr>
          <td key={`col-${colIndex}`} className={`${'additional-ids-table-sticky-column'}`}>
            <img
              className={`edit-form-icon`}
              src={tickIcons}
              alt="Edit Icon"
              onClick={() => {
                handleSubmit();
              }}
            />
            <img
              className={`edit-form-icon`}
              src={crossIcons}
              alt="Edit Icon"
              onClick={() => {
                setIsEditProgress('');
                handleEditClick?.(values?.id);
              }}
            />
          </td>
          <td key={`company-${colIndex}`} className={`${'additional-ids-table-sticky-column'}`}>
            <div className={`additional-ids-table-column-component`}>
              <Autocomplete
                options={values?.companyOptions}
                getOptionLabel={(option) => option?.name || ''}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event: any, newValue: any) => handleAdditionalIdsChange(event, newValue, setFieldValue)}
                onInputChange={(event, value) => {
                  if (values?.additionalIdFocusedField === 'additionalIdFocusedField') {
                    debouncedFetchAdditionalIdOptions(value, setFieldValue);
                  }
                }}
                onFocus={() => handleAdditionalIdFocus(setFieldValue)}
                renderInput={(params) => <TextField {...params} placeholder="Select Company" className="autocomplete-text-field" />}
                value={values?.selectedCompany || null}
                className="autocomplete-div"
              />
              <ErrorMessage name={`selectedCompany`} component="div" className="error-message" />
            </div>
          </td>
          <td key={`funeralHome-${colIndex}`}>
            <div className={`additional-ids-table-column-component`}>
              <InputComponent fieldName={`funeralHomeIds`} placeholder="Enter Funeral Home ID's" />
            </div>
          </td>
          <td key={`overrideId-${colIndex}`}>
            <div className={`additional-ids-table-column-component`}>
              <InputComponent fieldName={`overrideId`} placeholder="Enter Override Id" />
            </div>
          </td>
        </tr>
      )}
    </Formik>
  );
};
export default AdditionalIdsTableCellForm;
