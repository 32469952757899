/* eslint-disable  @typescript-eslint/no-explicit-any */
import { concatenatePhoneNumber } from '../../../../../utils';

export const formatPayload = (value: any) => {
  return {
    name: value?.name,
    faxNumber: value?.faxNumber,
    phoneNumber: concatenatePhoneNumber(value?.contactNumber),
    email: value?.email,
  };
};
