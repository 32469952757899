import axios from '..';
import { AddCompanyType, EditCompanyType } from '../../features/directory/types/companies-props';
export const getCompaniesList = (offset: number, limit: number,searchText:string, sortBy?: string, sortDirection?: string, filters?: any) => {
    return axios({
        method: 'GET',
        url: '/directories/company',
        params: {
            offset: offset, 
            limit: limit,
            searchText:searchText,
            sortBy: sortBy,
            sortDirection: sortDirection,
            ...filters
          },
    });
};

export const getCompanyDetails = (companyId: number) => {
    return axios({
        method: 'GET',
        url: `/directories/company/${companyId}`,
    })
}
export const exportCompaniesList=({searchText, sortBy, sortDirection, fromDate, toDate, cityId, stateId}:{searchText?:string; sortBy?: string; sortDirection?: string; fromDate?: string; toDate?: string; cityId?: number; stateId?: number}, filters?: any)=>{
    return axios({
      method:'GET',
      url:`directories/company/export`,
      params: {
        searchText: searchText,
        sortBy: sortBy,
        sortDirection: sortDirection,
        fromDate: fromDate,
        toDate: toDate,
        cityId: cityId,
        stateId: stateId,
        ...filters
      },
      responseType: 'blob',
    })
  }
export const addCompany = (data: AddCompanyType) => {
    return axios({
        method: 'POST',
        url: `directories/company`,
        data
    })
}

export const editCompany = (companyId: number, data: EditCompanyType) => {
    return axios({
        method: 'PUT',
        url: `directories/company/${companyId}`,
        data
    })
}
export const deleteCompany = (companyId: number) => {
    return axios({
        method: 'DELETE',
        url: `directories/company/${companyId}`
    })
}