import React, { useEffect, useState, useMemo, useCallback } from "react";
import moment from "moment-timezone";
import BreadcrumbComponent from "../../common/components/breadcrumb-component/breadcrumb-component";
import PaginationComponent from "../../common/components/pagination-component/pagination-component";
import { getUploadTrackingRecords } from "../../api/login";
import {
  DynamicTable,
  SelectDropdownComponent,
  DatePicker,
} from "../../common/components/index";
import { fetchModulesOfUploads, fetchUsersList } from "../../api/user";
import "./file-upload-tracking.scss";
import { useSetLoading } from "../../common/hooks/appearance";

interface UploadTrackingRecord {
  module_name: string;
  status: string;
  uploaded_by: {
    id: number;
    first_name: string;
    last_name: string;
  };
  uploaded_at: string;
  error_message: string;
  inserted_rows_count: number;
  failed_rows_count: number;
  file_key: string;
  output_file_key: string;
}

interface User {
  id: number;
  first_name: string;
  last_name: string;
}
interface Filters {
  moduleName?: string;
  status?: string;
  uploadedBy?: number;
  uploadDate?: Date;
}
interface Option {
  label: string;
  value: string;
}
const FileUploadTracking: React.FC = () => {
  const [data, setData] = useState<UploadTrackingRecord[]>([]);
  const [page, setPage] = useState<number>(1);
  const [limit] = useState<number>(15);
  const [filters, setFilters] = useState<Filters>({});
  const [appliedFilters, setAppliedFilters] = useState<Filters>({});
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modules, setModules] = useState([]);
  const [sortBy, setSortBy] = useState<string>("created_at");
  const [sortDirection, setSortDirection] = useState<string>("desc");
  const [columnName, setCloumnName] = useState<string>("");

  let crumbMapping = [
    { label: "Settings", link: "/home/settings" },
    { label: "Track Uploads", link: "/home/settings/trackuploads" },
  ];
  const columns = [
    {
      header: "Module Name",
      accessor: (row: UploadTrackingRecord) => row.module_name,
    },
    { header: "Status", accessor: (row: UploadTrackingRecord) => row.status },
    {
      header: "Uploaded By",
      accessor: (row: UploadTrackingRecord) => {
        const firstName = row.uploaded_by?.first_name;
        const lastName = row.uploaded_by?.last_name;
        if (!firstName && !lastName) {
          return "-";
        }
        return `${firstName || ""} ${lastName || ""}`.trim() || "-";
      },
    },
    {
      header: "Uploaded Date",
      accessor: (row: UploadTrackingRecord) =>
        moment.utc(row.uploaded_at).local().format("MM-DD-YYYY"),
    },
    {
      header: "Failed Reason",
      accessor: (row: UploadTrackingRecord) => row.error_message || "-",
    },
    {
      header: "Inserted Rows Count",
      accessor: (row: UploadTrackingRecord) =>
        row.inserted_rows_count == null ? "-" : row.inserted_rows_count,
    },
    {
      header: "Failed Rows Count",
      accessor: (row: UploadTrackingRecord) =>
        row.failed_rows_count == null ? "-" : row.failed_rows_count,
    },
    {
      header: "Original File",
      accessor: (row: UploadTrackingRecord) => row.file_key,
    },
    {
      header: "Output File",
      accessor: (row: UploadTrackingRecord) => row.output_file_key,
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await fetchUsersList();
        const moduleRes = await fetchModulesOfUploads();
        setUsers(res.data.data);
        setModules(moduleRes.data.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching user list:", error);
      }
    };

    fetchData();
  }, []);

  const fetchData =useCallback( async () => {
    try {
      setIsLoading(true);
      const result = await getUploadTrackingRecords({
        ...appliedFilters,
        page,
        limit,
        sortBy,
        sortDirection,
      });
      setData(result.data.data);
      setTotalRecords(result.data.totalRecords);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching tracking records:", error);
      setIsLoading(false);
    }
  }, [page, limit, appliedFilters, sortBy, sortDirection]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      width: "190px",
      borderColor: state.isFocused ? "#887952" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px #887952" : provided.boxShadow,
      "&:hover": {
        borderColor: "#887952",
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      width: "190px",
    }),
    option: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: state.isSelected ? "#887952" : "white",
      ":hover": {
        backgroundColor: state.isSelected ? "#887952" : "#EEEE",
      },
    }),
  };
  const mapColumnNameToField = (columnName: string): string => {
    const mapping: Record<string, string> = {
      "Module Name": "module_name",
      Status: "status",
      "Uploaded By": "users.first_name",
      "Uploaded Date": "uploaded_at",
      "Failed Reason": "error_message",
      "Inserted Rows Count": "inserted_rows_count",
      "Failed Rows Count": "failed_rows_count",
    };

    return mapping[columnName] || columnName;
  };
  const handleSort = (columnName: string) => {
    const mappedField = mapColumnNameToField(columnName);
    if (sortBy === mappedField) {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortBy(mappedField);
      setCloumnName(columnName);
      setSortDirection("asc");
    }
  };

  const showLoading = useMemo(() => isLoading, [isLoading]);
  useSetLoading(showLoading);

  return (
    <div>
      <BreadcrumbComponent breadcrumbLink={crumbMapping} />
      <div className="filter-section">
        <div>
          <SelectDropdownComponent
            placeholder="Select a module"
            options={modules.map((module_name) => ({
              label: module_name,
              value: module_name,
            }))}
            value={
              filters.moduleName
                ? { label: filters.moduleName, value: filters.moduleName }
                : null
            }
            onChange={(selected) => {
              if (!Array.isArray(selected)) {
                const selectedValue = selected as Option;
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  moduleName: selectedValue.value,
                }));
              }
            }}
            isSearchable={true}
            customStyles={customStyles}
          />
        </div>
        <div>
          <SelectDropdownComponent
            placeholder="Select a status"
            options={[
              { label: "Pending", value: "Pending" },
              { label: "In progress", value: "In progress" },
              {
                label: "Completed",
                value: "Completed",
              },
              { label: "Failed", value: "Failed" },
            ]}
            value={
              filters.status
                ? { label: filters.status, value: filters.status }
                : null
            }
            onChange={(selected) => {
              if (!Array.isArray(selected)) {
                const selectedValue = selected as Option;
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  status: selectedValue.value,
                }));
              }
            }}
            isSearchable={true}
            customStyles={customStyles}
          />
        </div>
        <div>
          <SelectDropdownComponent
            placeholder="Select a user"
            options={users.map((user) => ({
              label: `${user.first_name || ""} ${user.last_name || ""}`,
              value: String(user.id),
            }))}
            value={
              filters.uploadedBy
                ? users
                    .map((user) => ({
                      label: `${user.first_name || ""} ${user.last_name || ""}`,
                      value: String(user.id),
                    }))
                    .find(
                      (user) =>
                        String(user.value) === String(filters.uploadedBy)
                    )
                : null
            }
            onChange={(selected) => {
              if (!Array.isArray(selected)) {
                const selectedValue = selected as Option;
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  uploadedBy: parseInt(selectedValue.value),
                }));
              }
            }}
            isSearchable={true}
            customStyles={customStyles}
          />
        </div>
        <div className="date-picker">
          <DatePicker
            placeholder="Select a date"
            value={filters.uploadDate}
            onChange={(selected) => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                uploadDate: selected,
              }));
            }}
            maxDate={new Date()}
          />
        </div>
        <div className="filter-buttons">
          <button
            className="button clear-button"
            onClick={() => {
              if (isFilterApplied) {
                setAppliedFilters({});
                setIsFilterApplied(false);
              }
              setFilters({});
              setCloumnName("");
              setSortBy("created_at");
              setSortDirection("desc");
            }}
          >
            Clear
          </button>
          <button
            className="button submit-button"
            onClick={async () => {
              if (
                !filters.status &&
                !filters.moduleName &&
                !filters.uploadedBy &&
                !filters.uploadDate
              ) {
                return;
              }
              const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
              const updatedFilters = {
                ...filters,
                timezone,
              };
              setPage(1);
              setAppliedFilters(updatedFilters);
              setIsFilterApplied(true);
            }}
          >
            Submit
          </button>
        </div>
      </div>
      <div className="file-upload-tracking-table">
        <DynamicTable
          columns={columns}
          data={data}
          handleSort={handleSort}
          sortedColumn={columnName}
          sortDirection={sortDirection}
          setIsFilterApplied={setIsFilterApplied}
        />
      </div>
      {totalRecords > 0 && (
        <PaginationComponent
          totalRecords={totalRecords}
          pageSize={limit}
          currentPage={page}
          onPageChange={(event, value) => {
            setPage(value);
          }}
        />
      )}
    </div>
  );
};

export default FileUploadTracking;
