/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import './view-funeral-homes.scss';
import ViewFuneralHomesBottomSection from './view-funeral-homes-bottom-section/view-funeral-homes-bottom-section';
import ViewFuneralHomesLeftSection from './view-funeral-homes-left-section/view-funeral-homes-left-section';
import ViewFuneralHomesRightSection from './view-funeral-homes-right-section/view-funeral-homes-right-section';
import { tabName, tabsToBeDisplayed, quickLinkItems } from './view-funeral-homes.constant';
import { ViewComponentProps } from '../../types/view-regional-reps-props';
import { useShouldDisplayField } from '../../../../common/hooks/useShouldDisplayField';

const ViewFuneralHomes: React.FC<ViewComponentProps> = ({ funeralHomeId, setLoading, setEditDetailsInViewMode, editDetailsInViewMode }) => {
  const shouldDisplayTab = useShouldDisplayField();
  const [quickLinksCollapsed, setQuickLinksCollapsed] = useState<boolean>(false);
  const [addMoreLabel, setAddMoreLabel] = useState<any>('');
  const [tabIndex, setTabIndex] = useState(0);
  const [isUpdateNotesData, setIsUpdateNotesData] = useState<any>([]); //

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleQuickLinksTab = () => {
    setQuickLinksCollapsed(!quickLinksCollapsed);
  };

  const handleQuickLinkTabInBottomSection = (label: string) => {
    setAddMoreLabel(label);
    if (quickLinkItems[label]) {
      const findTabIndex = tabsToBeDisplayed(shouldDisplayTab).findIndex((item: any) => item.label === quickLinkItems[label]);
      setTabIndex(findTabIndex);
    }
  };

  return (
    <div className="view-funeral-home">
      <div className="view-funeral-home-left-right-section">
        <div className={`view-funeral-home-left-section ${quickLinksCollapsed ? 'quickLinksCollapsed' : ''}`}>
          <ViewFuneralHomesLeftSection
            funeralHomeId={funeralHomeId}
            setLoading={setLoading}
            editDetailsInViewMode={editDetailsInViewMode}
            setEditDetailsInViewMode={setEditDetailsInViewMode}
          />
        </div>
        <div className={`view-funeral-home-right-section ${quickLinksCollapsed ? 'quickLinksCollapsed' : ''}`}>
          <ViewFuneralHomesRightSection
            handleQuickLinksTab={handleQuickLinksTab}
            quickLinksCollapsed={quickLinksCollapsed}
            handleQuickLinkTabInBottomSection={handleQuickLinkTabInBottomSection}
            shouldDisplayTab={shouldDisplayTab}
            funeralHomeId={funeralHomeId}
            notesData={isUpdateNotesData}
            setNotesData={setIsUpdateNotesData}
          />
        </div>
      </div>
      <div className="view-funeral-home-bottom-section">
        <ViewFuneralHomesBottomSection
          handleTabChange={handleTabChange}
          tabName={tabName}
          tabIndex={tabIndex}
          funeralHomeId={funeralHomeId}
          setLoading={setLoading}
          shouldDisplayTab={shouldDisplayTab}
          addMoreLabel={addMoreLabel}
          setAddMoreLabel={setAddMoreLabel}
          isUpdateNotesData={isUpdateNotesData}
          setIsUpdateNotesData={setIsUpdateNotesData}
        />
      </div>
    </div>
  );
};

export default ViewFuneralHomes;
