import React, { useState } from "react";
import { Offcanvas, OffcanvasBody, OffcanvasHeader, Button, FormGroup, Label } from "reactstrap";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import phone from 'phone';
import closeIcon from "../../../assets/icons/closeIcon.svg";
import InputComponent  from "../../../common/components/input-component/input-component";
import AlertComponent from "../../../common/components/Alert/Alert";
import { AddOrEditUserProps, DropdownOption } from "./user-props";
import { concatenatePhoneNumber, splitPhoneNumber } from "../../directory/utils";
import { PhoneNumberComponent, SelectDropdownComponent } from "../../../common/components";
import DeleteConfirmationPopup from "../../directory/delete-confirmation-popup/delete-confirmation-popup";
import { addUser, deleteUser, editUser } from "../../../api/user";
import { emailValidationRegex } from "../../../constants/regex-string";

const AddOrEditUser: React.FC<AddOrEditUserProps> = ({
    isOpen,
  toggle,
  mode,
  editData,
  fetchUsers,
  page,
}) => {
    const [deletePopupState, setDeletePopupState] = useState<{
        isOpen: boolean;
        userId: number | null;
      }>({
        isOpen: false,
        userId: null,
    });
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState<
      "success" | "error" | "info" | "warning"
    >("success");
    //const [isLoading, setIsLoading] = useState(false);

    const initialValues =
    editData && mode === "edit"
      ? {
          firstName: editData.first_name,
          lastName: editData.last_name,
          phoneNumber: splitPhoneNumber(editData.phone_number) || "",
          email: editData.email,
          userRole: editData?.userRole?.name
        }
      : {
          firstName: "",
          lastName: "",
          phoneNumber: {
            countryCode: "+1",
            phoneNumber: "",
          },
          email: "",
          userRole: ""
        };
        const validationSchema = Yup.object({
            firstName: Yup.string().required("Mandatory Field*"),
            lastName: Yup.string().required("Mandatory Field*"),
            phoneNumber: Yup.object({
                countryCode: Yup.string().matches(/^\+\d{1,4}$/),
                phoneNumber:  Yup.string()
                .matches(/^\d{10}$/, `Number format is invalid`)
                .test('is-valid-phone', `Invalid number`, (value, allValue) => {
                  if (!value) return true;
                  const { isValid } = phone(`${allValue?.parent?.countryCode || ''}-${value}`);
                  return isValid;
                })
            }),
            email: Yup.string().required("Mandatory Field*").matches(emailValidationRegex, { message: "Invalid email address. Please provide a valid email."}),
            userRole: Yup.string().required('Mandatory Field*')
        })
        const selectDropDowncustomStyles = {
            control: (provided: any, state: any) => ({
              ...provided,
              borderColor: state.isFocused ? "#887952" : provided.borderColor,
              boxShadow: state.isFocused ? "0 0 0 1px #887952" : provided.boxShadow,
              "&:hover": {
                borderColor: "#887952",
              },
            }),
            option: (styles: any, state: any) => ({
              ...styles,
              backgroundColor: state.isSelected ? "#887952" : "white",
              ":hover": {
                backgroundColor: state.isSelected ? "#887952" : "#EEEE",
              },
            }),
          };
        const onSubmit = async (values: typeof initialValues, { resetForm }: any) => {
          //setIsLoading(true);
          const userData = {
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber.phoneNumber ? concatenatePhoneNumber(values.phoneNumber) : null,
            email: values.email,
            userRole: values.userRole
          }
          try {
            let response;
            if (editData && mode === "edit") {
              response = await editUser(editData.id, userData);
            }
            else {
              response = await addUser(userData)
            }
            if (response?.data.success && fetchUsers) {
              setAlertMessage(response?.data?.data?.message);
              setAlertType("success");
              setAlertOpen(true);
              //setIsLoading(false);
              setTimeout(() => {
                fetchUsers(page); 
                toggle(); 
                resetForm();
                window.location.reload();
            }, 1000);
          }
          } catch (error: unknown) {
            console.error("Error submitting form:", error);
            let errorMessage = "An unexpected error occurred."; 
            if (typeof error === "object" && error !== null) {
              if ("response" in error && (error as any).response?.data) {
                const responseData = (error as any).response.data;
                if (Array.isArray(responseData?.details)) {
                  errorMessage = responseData.details.join(", ");
                } else if (responseData?.message) {
                  errorMessage = responseData.message;
                }
              } else if ("message" in error) {
                errorMessage = (error as Error).message;
              }
            }
            setAlertMessage(errorMessage);
            setAlertType("error");
            setAlertOpen(true);
          }
        }
        const handleDelete = async (deleteUserId: number | null) => {
            try {
                if(deleteUserId === null) {
                    return;
                }
                const response = await deleteUser(deleteUserId)
                if(response.data.success && fetchUsers) {
                  fetchUsers(page)
                }
                setDeletePopupState((prevState) => ({ ...prevState, isOpen: false }));
                toggle()
            } catch (error) {
              console.error("Error while deleting user:", error);
              setAlertMessage("Failed to delete user.");
              setAlertType("error");
              setAlertOpen(true);
            }
        }
        const handleAlertClose = () => {
          setAlertOpen(false);
        };
    return (
        <>
        <AlertComponent
            message={alertMessage}
            type={alertType}
            open={alertOpen}
            onClose={handleAlertClose}
        />
        <div className="add-or-edit-offcanvas">
            <Offcanvas isOpen={isOpen} direction="end" className="offcanvas">
            <OffcanvasHeader className="offcanvas-header">
              <div className="header-div">
                <h2 className="mb-0">{mode === "edit" ? "Edit" : "Add"} User</h2>
                <button onClick={toggle}>
                  <img src={closeIcon} alt="Close" className="header-div-img" />
                </button>
              </div>
            </OffcanvasHeader>
            <OffcanvasBody className="offcanvas-body">
                <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                >   
                {({isSubmitting, errors, touched, values, setFieldValue}) => (
                    <Form className="add-or-edit-form">
                        <InputComponent
                          fieldName="firstName"
                          label="First Name"
                          placeholder="Enter First Name"
                          optional={false}
                          isAlpha
                        />
                        <InputComponent
                          fieldName="lastName"
                          label="Last Name"
                          placeholder="Enter Last Name"
                          optional={false}
                          isAlpha
                        />
                        <PhoneNumberComponent
                          label="Phone Number"
                          fieldName="phoneNumber.phoneNumber"
                          countryCodeFieldName="phoneNumber.countryCode"
                          placeholder="Enter Phone Number"
                        />
                        <FormGroup className="add-or-edit-form-group">
                            <Label htmlFor="userRole" className="custom-form-label">
                              Select Role<span>*</span>
                            </Label>
                            <SelectDropdownComponent
                              placeholder="Select Role"
                              options={[
                                { label: "Super Admin", value: "Super Admin" },
                                { label: "Admin", value: "Admin" },
                                { label: "Partner", value: "Partner" },
                                { label: "Agent", value: "Agent" },
                                { label: "Customer", value: "Customer" },
                              ]}
                              customStyles={selectDropDowncustomStyles}
                              value={values.userRole? { label: values.userRole, value: values.userRole } : null}
                              onChange={(selected) => {
                                const selectedOption = selected as DropdownOption;
                                setFieldValue("userRole", selectedOption?.value);
                              }}
                            />
                            <ErrorMessage
                              name="userRole"
                              component="div"
                              className="error-message"
                            />
                        </FormGroup>
                        <InputComponent
                          fieldName="email"
                          label="Email ID"
                          placeholder="Enter Email ID"
                          optional={false}
                          isEmail
                        />
                        <div className="form-button-container">
                            {editData && (
                                <Button
                                  type="button"
                                  className="delete-button"
                                  onClick={() => {
                                    setDeletePopupState({
                                        isOpen: true,
                                        userId: editData?.id,
                                      });
                                  }}
                                >
                                  Delete
                                </Button>
                            )}
                            <button
                              type="button"
                              className={`cancel-button${
                                mode === "edit" ? "-in-edit" : ""
                              }`}
                              onClick={toggle}
                            >
                              Cancel
                            </button>
                            <button
                              className={mode === "edit" ? "save-button-changes" : "save-button"}
                              type="submit"
                              disabled={isSubmitting}
                            >
                              {mode === "edit" ? "Save Changes" : "Save"}
                            </button>
                        </div>
                    </Form>
                )}
                </Formik>
            </OffcanvasBody>
          </Offcanvas>
        </div>
        <DeleteConfirmationPopup
            isOpen={deletePopupState.isOpen}
            deleteId={deletePopupState.userId}
            onClose={() =>
              setDeletePopupState((prevState) => ({
                ...prevState,
                isOpen: false,
              }))
            }
            handleDelete={handleDelete}
            label="User"
        />
        </>
    )
}
export default AddOrEditUser;