import React from "react";
import "./owner-confirmation-popup.scss";

const OwnerConfirmationPopup = (props: any) => {
  if (!props?.isOpen) return null;

  return (
    <div className="owner-popup-overlay">
      <div className="owner-popup">
        <p className="owner-popup-message">
          Another owner is already marked as the primary owner. Do you want to
          change the primary owner?
        </p>
        <div className="owner-popup-buttons">
          <button className="no-owner-button" onClick={props?.onClose}>
            NO
          </button>
          <button
            className="yes-owner-button"
            onClick={() =>
              props?.setConfirmationCallback(
                props?.selectedIndex,
                props?.currentPrimaryIndex
              )
            }
          >
            YES
          </button>
        </div>
      </div>
    </div>
  );
};

export default OwnerConfirmationPopup;
