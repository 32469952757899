/* eslint-disable  @typescript-eslint/no-explicit-any */
import { useRef } from 'react';
import { Formik } from 'formik';
import { InputComponent, PhoneNumberComponent } from '../../../../../../../../common/components/index';
import { validationSchema } from './authorized-representative-table.validation';
import { addUpdateAuthorizedRepresentativeDetails } from '../../../../../../../../api/funeralhomes';
import tickIcons from '../../../../../../../../assets/icons/tickIcons.svg';
import crossIcons from '../../../../../../../../assets/icons/crossIcons.svg';
import { AuthorisedRepresentativeTableCellFormProps } from './authorized-representative-table-props';
import { formatPayload } from '../authorized-representative-table.utils';

const AuthorisedRepresentativeTableCellForm: React.FC<AuthorisedRepresentativeTableCellFormProps> = ({
  initialValues,
  colIndex,
  handleEditClick,
  setIsEditProgress,
  setLoading,
  funeralHomeId,
  handleGetAuthorisedRepresentative,
  setAlertOpen,
  setAlertType,
  setAlertMessage,
}) => {
  const formRef: any = useRef();

  const onSubmit = async (value: any) => {
    const payloadFormatted = formatPayload(value);
    try {
      setLoading(true);
      await addUpdateAuthorizedRepresentativeDetails(funeralHomeId, value?.id, payloadFormatted);
      setLoading(false);
      handleGetAuthorisedRepresentative();
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message
        ? error?.response?.data?.message
        : (typeof error === 'object' && error !== null && 'response' in error && (error as any).response?.data?.details?.[0]) ||
          (error instanceof Error ? error.message : 'An unexpected error occurred.');
      setAlertMessage(errorMessage);
      setAlertType('error');
      setAlertOpen(true);
      setLoading(false);
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    formRef?.current?.handleSubmit();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={formRef}
      isValidating={true}
      setSubmitting={true}
      isSubmitting={true}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <tr>
          <td key={colIndex} className={`${'authorized-representative-table-sticky-column'}`} valign="top">
            <img
              className={`edit-form-icon`}
              src={tickIcons}
              alt="Edit Icon"
              onClick={() => {
                handleSubmit();
              }}
            />
            <img
              className={`edit-form-icon`}
              src={crossIcons}
              alt="Edit Icon"
              onClick={() => {
                setIsEditProgress('');
                handleEditClick?.(values?.id);
              }}
            />
          </td>
          <td key={colIndex} className={`${'authorized-representative-table-sticky-column'}`} valign="top">
            <div className={`authorized-representative-table-column-component`}>
              <InputComponent fieldName="name" placeholder="Enter Authorised Representative name" optional={true} isAlpha />
            </div>
          </td>
          <td key={colIndex} valign="top">
            <div className={`authorized-representative-table-column-component`}>
              <PhoneNumberComponent
                fieldName="contactNumber.phoneNumber"
                countryCodeFieldName="contactNumber.countryCode"
                placeholder="Enter phone number"
                menuPosition={'fixed'}
              />
            </div>
          </td>
          <td key={colIndex} valign="top">
            <div className={`authorized-representative-table-column-component`}>
              <InputComponent fieldName="faxNumber" placeholder="Enter fax number" maxLength={20} isFax={true} />
            </div>
          </td>
          <td key={colIndex} valign="top">
            <div className={`authorized-representative-table-column-component`}>
              <InputComponent fieldName="email" placeholder="Enter email address" isEmail />
            </div>
          </td>
        </tr>
      )}
    </Formik>
  );
};
export default AuthorisedRepresentativeTableCellForm;
