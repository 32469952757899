/* eslint-disable  @typescript-eslint/no-explicit-any */
import { concatenatePhoneNumber } from '../../../utils';

export const getFuneralHomesPayloadData = (values: any, editData: any) => {
  return {
    name: values?.name,
    ownerName: values?.ownerName,
    physicalAddress: {
      id: editData?.physicalAddress?.id || undefined,
      street: values?.physicalAddress?.street,
      city: {
        id: values?.physicalAddress?.selectedPhysicalAddressCity?.id || editData?.physicalAddress?.City?.id,
        name: values?.physicalAddress?.selectedPhysicalAddressCity?.name || editData?.physicalAddress?.City?.name,
      },
      state: {
        id:
          values?.physicalAddress?.physicalAddressState?.id ||
          values?.physicalAddress?.selectedPhysicalAddressState?.id ||
          editData?.physicalAddress?.State?.id,
        name:
          values?.physicalAddress?.physicalAddressState?.name ||
          values?.physicalAddress?.selectedPhysicalAddressState?.name ||
          editData?.physicalAddress?.State?.name,
      },
      zipcode: values?.physicalAddress?.zipcode,
    },
    mailingAddress: {
      id: editData?.mailingAddress?.id || undefined,
      street: values?.mailingAddress?.street,
      city: {
        id: values?.mailingAddress?.selectedMailingAddressState?.id || editData?.mailingAddress?.City?.id,
        name: values?.mailingAddress?.selectedMailingAddressState?.name || editData?.mailingAddress?.City?.name,
      },
      state: {
        id:
          values?.mailingAddress?.mailingAddressState?.id ||
          values?.mailingAddress?.selectedMailingAddressState?.id ||
          editData?.mailingAddress?.State?.id,
        name:
          values?.mailingAddress?.mailingAddressState?.name ||
          values?.mailingAddress?.selectedMailingAddressState?.name ||
          editData?.mailingAddress?.State?.name,
      },
      zipcode: values?.mailingAddress?.zipcode,
    },
    faxNumber: values?.faxNumber,
    taxId: values?.taxId,
    email: values?.emailAddress,
    phoneNumber: concatenatePhoneNumber(values?.phoneNumber),
  };
};

const formatAddress = (address: any) => {
  const street = address?.street || '';
  const city = address?.City?.name || '';
  const state = address?.State?.name || '';
  const zipcode = address?.zipcode || '';

  return `${street}, ${city}, ${state}, ${zipcode}`;
};

export const getFuneralHomesViewData = (values: any) => {
  return {
    'Owner Name': values?.owner_name || '-',
    'Physical Address': formatAddress(values?.physicalAddress) || '-',
    'Mailing Address': formatAddress(values?.mailingAddress) || '-',
    'Phone Number': values?.contact_no || '-',
    'Fax Number': values?.fax_number || '-',
    'Email Address': values?.email || '-',
    'Tax Id': values?.tax_id || '-',
  };
};
