import { combineReducers } from 'redux';
import session from './session';
import notifications from './notificatons';
import server from './server';
import appearance from './appearance';
import profile from './profile';

export default function createRootReducer(history:any) {
  return combineReducers({
    session,
    notifications,
    server,
    appearance,
    profile
  });
}
