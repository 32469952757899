import axios from '.';
export const getLeads = (
  params: {
    offset: number;
    limit: number;
    searchText?: string;
    sortBy?: string;
    sortDirection?: string;
    createdToday?: boolean;
    timezone?: string;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters?: any,
) => {
  return axios({
    method: 'GET',
    url: '/leads',
    params: { ...params, ...filters },
  });
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addLead = (payload: any) => {
  return axios({
    method: 'POST',
    url: 'leads',
    data: payload,
  });
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const editLead = (id: number, payload: any) => {
  return axios({
    method: 'PUT',
    url: `/leads/${id}`,
    data: payload,
  });
};

export const deleteLead = (id: number) => {
  return axios({
    method: 'DELETE',
    url: `/leads/${id}`,
  });
};

export const updateLeadStatus = (id: number) => {
  return axios({
    method: 'PUT',
    url: `leads/update-status/${id}`,
  });
};

export const getLeadSources = () => {
  return axios({
    method: 'GET',
    url: '/leads/lead-source',
  });
};

export const exportLeadsList = (savedReport?: string) => {
  return axios({
    method: 'GET',
    url: '/leads/export/saved-report',
    responseType: 'blob',
    params: {
      reportName: savedReport,
    },
  });
};
export const exportLeadsData = (
  {
    searchText,
    sortBy,
    sortDirection,
    savedReport,
    fromDate,
    toDate,
    cityId,
    stateId,
    leadSourceIds,
    leadType,
    createdToday,
    timezone,
  }: {
    searchText?: string;
    sortBy?: string;
    sortDirection?: string;
    savedReport?: string;
    fromDate?: string;
    toDate?: string;
    cityId?: number;
    stateId?: number;
    leadSourceIds?: number[];
    leadType?: string;
    createdToday?: boolean;
    timezone?: string;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters?: any,
) => {
  return axios({
    method: 'GET',
    url: '/leads/export',
    responseType: 'blob',
    params: {
      searchText: searchText,
      sortBy: sortBy,
      sortDirection: sortDirection,
      reportName: savedReport,
      fromDate: fromDate,
      toDate: toDate,
      cityId: cityId,
      stateId: stateId,
      leadSourceIds: leadSourceIds,
      leadType: leadType,
      createdToday: createdToday,
      timezone,
      ...filters,
    },
  });
};
