import React, { useEffect, useState } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { SideBarProps } from '../types/side-bar-props';
import afpLogo from '../../../assets/icons/side-bar/afp-logo.svg';
import hamburgerIcon from '../../../assets/icons/side-bar/hamburgerIcon.svg';
import hamburgerIconTwo from '../../../assets/icons/side-bar/hamburgerIconTwo.svg';
import { Link as RouterNavLink, useNavigate, useLocation } from 'react-router-dom';
import { MenuItem } from '../types/menu-item';
import './side-bar.scss';
import SubMenu from './sub-menu/sub-menu';
import LogoutConfirmationPopup from './logout-confirmation-popup/logout-confirmation-popup';
import { routes } from '../../../../app/constants/routes';
import { logout } from '../../../common/routines/user-operations';

const SideBar: React.FC<SideBarProps> = ({ navItemsInfo, footerItems, iconMapping, footerIconMapping }) => {
  const [activeItem, setActiveItem] = useState<string>('');
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [navItems, setNavItems] = useState<MenuItem[] | null>(navItemsInfo);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false);
  const [isLogoutPopupOpen, setIsLogoutPopupOpen] = useState<boolean>(false);
  const locationInfo = useLocation();
  const { pathname } = locationInfo;

  useEffect(() => {
    const savedActiveItem = localStorage.getItem('activeItem');
    const savedOpenIndex = localStorage.getItem('openIndex');
    const savedSidebarState = localStorage.getItem('isSidebarCollapsed');
    if (savedActiveItem) {
      setActiveItem(savedActiveItem);
      const updatedNavItems = navItemsInfo.map((navItem) => {
        if (navItem.subItems?.some((subItem) => subItem.path === savedActiveItem)) {
          return {
            ...navItem,
            isOpen: false,
          };
        }
        return navItem;
      });
      setNavItems(updatedNavItems);
    }
    if (savedOpenIndex) setOpenIndex(Number(savedOpenIndex));
    if (savedSidebarState) setIsSidebarCollapsed(JSON.parse(savedSidebarState));
  }, [navItemsInfo]);

  useEffect(() => {
    localStorage.setItem('activeItem', activeItem);
    localStorage.setItem('openIndex', openIndex !== null ? openIndex.toString() : '');
    localStorage.setItem('isSidebarCollapsed', JSON.stringify(isSidebarCollapsed));
  }, [activeItem, openIndex, isSidebarCollapsed]);

  useEffect(() => {
    if (pathname?.includes('/home/')) {
      const pathnameInfo = pathname?.substr(6);
      if (pathnameInfo.split('/')?.length > 1) {
        const pathnameInfoIndex = navItemsInfo?.findIndex((data) => data?.path === pathnameInfo.split('/')[0]);
        const updateNavItem: MenuItem[] = (navItems || [])?.map((data: MenuItem, index: number) => {
          return {
            ...data,
            isOpen: index !== pathnameInfoIndex,
          };
        });
        setNavItems(updateNavItem);
      }
      setActiveItem(pathname?.substr(6));
    }
  }, [pathname, navItemsInfo]);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };
  const toggleMenuItem = (index: number): void => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const handleClick = (navItem: MenuItem, index: number) => (): void => {
    setOpenIndex(index);
    setTimeout(() => {
      localStorage.setItem('currentUrl', window.location.pathname);
    }, 100);
    if (navItem?.subItems) {
      toggleMenuItem(index);
    } else {
      setActiveItem(navItem.path);
    }
  };
  const navigate = useNavigate();
  const handleClickWithLogout = (footerItem: MenuItem, index: number): void => {
    if (footerItem.label === 'Logout') {
      setIsLogoutPopupOpen(true);
    } else {
      setActiveItem(footerItem.path);
      setTimeout(() => {
        localStorage.setItem('currentUrl', window.location.pathname);
      }, 100);
      handleClick(footerItem, index);
    }
  };

  const handleLogout = (): void => {
    logout();
    navigate(routes.LOGIN);
    setTimeout(() => {
      localStorage.setItem('currentUrl', '');
      localStorage.setItem('activeItem', 'dashboard');
    }, 100);
  };

  const toggleItem = () => {
    const updateNavItem: MenuItem[] = (navItems || [])?.map((data: MenuItem) => {
      return {
        ...data,
        isOpen: true,
      };
    });
    setNavItems(updateNavItem);
  };

  useEffect(() => {
    if (pathname === '/home/profile' || pathname === '/home/dashboard' || pathname === '/home/leads') {
      setOpenIndex(null);
      const updatedNavItems: MenuItem[] = (navItems || [])?.map((item: MenuItem) => ({
        ...item,
        isOpen: true,
      }));
      setNavItems(updatedNavItems);
    }
  }, [pathname]);

  return (
    <div className={`sidebar ${isSidebarCollapsed ? 'collapsed' : ''}`}>
      <div className="sidebar-hamburger-icon">
        <div className="sidebar-hamburger-icon-logo">
          <div className="sidebar-header">
            <img src={afpLogo} alt="afp-logo" className="logo-image" />
          </div>
          <img src={hamburgerIcon} className="hamburgerIconOne" alt="hamburgerIcon" onClick={toggleSidebar} />
        </div>
        <div>
          <img src={hamburgerIconTwo} className="hamburgerIconTwo" alt="hamburgerIcon" onClick={toggleSidebar} />
        </div>
      </div>
      <div className="side-menu">
        <Nav vertical className="list-unstyled pb-3">
          {navItems?.map((navItem: MenuItem, index: number) =>
            !navItem.subItems ? (
              <NavItem
                key={index.toString()}
                onClick={toggleItem}
                className={`${isSidebarCollapsed ? 'collapsed-item' : ''} ${navItem?.disabled ? 'disabledClassName' : ''} ${navItem?.classNames} ${activeItem === navItem.path || (activeItem === 'leads/new' && navItem.path === 'leads') ? 'active-item' : ''} `}
                activeClassName="nav-active"
              >
                <NavLink
                  to={navItem.path}
                  tag={RouterNavLink}
                  activeClassName="nav-active"
                  onClick={handleClick(navItem, index)}
                  title={isSidebarCollapsed ? navItem.label : ''}
                  disabled={navItem?.disabled}
                >
                  <div className="side-bar-icon-text">
                    <div className="side-bar-icon">
                      <img
                        src={
                          activeItem === navItem.path || (activeItem === 'leads/new' && navItem.path === 'leads')
                            ? iconMapping[navItem.label as keyof typeof iconMapping]
                            : navItem.icon
                        }
                        alt={`${navItem.label}-icon`}
                      />
                    </div>
                    <div className="side-bar-text">
                      <span>{!isSidebarCollapsed && navItem.label}</span>
                    </div>
                  </div>
                </NavLink>
              </NavItem>
            ) : (
              <SubMenu
                navItem={navItem}
                navItems={navItems}
                setNavItems={setNavItems}
                isCollapsed={isSidebarCollapsed}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
              />
            ),
          )}
        </Nav>
      </div>
      <div className="side-bar-footer">
        <Nav>
          {footerItems.map((footerItem, index) => (
            <NavItem
              key={index.toString()}
              onClick={toggleItem}
              className={`${isSidebarCollapsed ? 'collapsed-item' : ''} ${footerItem?.classNames} ${activeItem === footerItem.path ? 'active-item' : ''}`}
              activeClassName="nav-active"
            >
              <NavLink
                to={footerItem.path}
                tag={RouterNavLink}
                activeClassName="nav-active"
                onClick={() => handleClickWithLogout(footerItem, index)}
                title={isSidebarCollapsed ? footerItem.label : ''}
              >
                <div className="icon">
                  <img
                    src={
                      activeItem === footerItem.path
                        ? footerIconMapping[footerItem.label as keyof typeof footerIconMapping]
                        : footerItem.icon
                    }
                    alt={`${footerItem.label}-icon`}
                  />
                  <span>{!isSidebarCollapsed && footerItem.label}</span>
                </div>
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </div>
      <LogoutConfirmationPopup
        isOpen={isLogoutPopupOpen}
        onClose={() => setIsLogoutPopupOpen(!isLogoutPopupOpen)}
        handleLogOut={handleLogout}
        label="Logout"
      />
    </div>
  );
};
export default SideBar;
