import React from "react";
import BreadcrumbComponent from "../../common/components/breadcrumb-component/breadcrumb-component";

const FuneralServices:React.FC =()=> {
    let crumbMapping = [
        { label: 'Funeral Services', link: '/funeral-services' },
    ];

    return(
        <div>
            <BreadcrumbComponent breadcrumbLink={crumbMapping}/>
        </div>
    )
}
export default FuneralServices;