import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Table } from "reactstrap";
import { fetchRolesPermissions, modifyUserRolePermissions } from "../../../api/roles";
import AlertComponent from "../../../common/components/Alert/Alert";
import BreadcrumbComponent from "../../../common/components/breadcrumb-component/breadcrumb-component";
import { useSetLoading } from "../../../common/hooks/appearance";
import RolesConfirmationPopup from "./roles-confirmation-popup";
import { UserRoleWithPermissions, RolesData } from "./roles-props";
import './roles.scss';

const Roles: React.FC = () => {
  const [rolesData, setRolesData] = useState<RolesData[]>([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [modifiedPermissions, setModifiedPermissions] = useState<RolesData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<
    "success" | "error" | "info" | "warning"
  >("success");

  const crumbMapping = [
    { label: 'User Management', link: '/home/user/role' },
    { label: 'Roles', link: '/home/user/role' },
  ];

  // const columns: string[] = [
  //   'Directory', 'Contracts', 'Leads', 'Reports', 'Users', 'Import', 'Export'
  // ];
  const columns: string[] = useMemo(()=>[

       'Directory', 'Contracts', 'Leads', 'Reports', 'Users', 'Import', 'Export'
   
     ],[]);
     const fetchRoles =useCallback( async () => {
      setLoading(true);
      try {
        const response = await fetchRolesPermissions();
        const sortedData = response.data.data.map((role: RolesData) => ({
          ...role,
          userRoleWithPermissions: role.userRoleWithPermissions.sort((a, b) =>
            columns.indexOf(a.userRoleModule.name) - columns.indexOf(b.userRoleModule.name)
          ),
        })).sort((a: RolesData, b: RolesData) => a.id - b.id);
        setRolesData(sortedData);
      } catch {
        setRolesData([]);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1000);;
      }
    },[columns]);
  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

 

  const handleCheckboxChange = (permissionId: number, permission: UserRoleWithPermissions) => {
    const updatedPermission = { ...permission, is_enabled: !permission.is_enabled };
    setRolesData((prevRoles) => {
      const updatedRoles = prevRoles.map((role) => ({
        ...role,
        userRoleWithPermissions: role.userRoleWithPermissions.map((perm) =>
          perm.id === permissionId ? updatedPermission : perm
        ),
      }));
      return updatedRoles;
    });
    setModifiedPermissions((prevModified: any) => {
      const existingPermissionIndex = prevModified.findIndex((perm: any) => perm.id === permissionId);
      if (existingPermissionIndex > -1) {
        const updatedPermissions = [...prevModified];
        updatedPermissions[existingPermissionIndex] = updatedPermission;
        return updatedPermissions;
      } else {
        return [...prevModified, updatedPermission];
      }
    });
  };

  const handleSaveChanges = async () => {
    try {
      setLoading(true);
      await modifyUserRolePermissions(modifiedPermissions);
      setLoading(false);
      setModifiedPermissions([]);
      setAlertMessage("Updated successfully");
      setAlertType("success");
      setAlertOpen(true);
      setIsPopupOpen(false);
    } catch (error) {
      console.error('Error saving permissions:', error);
      setAlertMessage("Update failed");
      setAlertType("error");
      setAlertOpen(true);
      setIsPopupOpen(false);
      setLoading(false);
    }
  };

  const showLoading = useMemo(() => loading, [loading]);
  useSetLoading(showLoading);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  return (
    <>
      <AlertComponent
        message={alertMessage}
        type={alertType}
        open={alertOpen}
        onClose={handleAlertClose}
      />
      <div className="roles-container">
        <BreadcrumbComponent breadcrumbLink={crumbMapping} />
      </div>
      {!loading && (
        <>
      <div className="roles-table-container">
        <Table
          className="roles-table"
          hover
          bordered
          borderless={true}
          responsive
        >
          <thead>
            <tr>
              <th>Roles</th>
              {columns.map((column, index) => (
                <th key={index}>{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rolesData.map((role) => (
              <tr key={role.id}>
                <td>{role.name}</td>
                {columns.map((column) => {
                  const permission = role.userRoleWithPermissions.find(
                    (perm) => perm.userRoleModule.name === column
                  );
                  return (
                    <td key={permission?.id ?? `${role.id}-${column}`}>
                      <input
                        type="checkbox"
                        id={`checkbox-${role.id}-${permission?.id ?? column}`}
                        checked={permission?.is_enabled ?? false}
                        onChange={() =>
                          permission && handleCheckboxChange(permission.id, permission)
                        }
                        disabled={role.name === "Super Admin"}
                        className={role.name === "Super Admin" ? 'disabled-checkbox' : ''}
                      />
                      <label htmlFor={`checkbox-${role.id}-${permission?.id ?? column}`}></label>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="roles-actions-container">
        <button
          className="save-permission-button"
          type="button"
          onClick={()=> setIsPopupOpen(true)}
        >
          Save Changes
        </button>
      </div>
      </>
      )}
      {
        isPopupOpen && (
          <RolesConfirmationPopup isOpen={isPopupOpen} onClose={()=> setIsPopupOpen(!isPopupOpen)} handleSaveChanges={handleSaveChanges}/>
        )
      }
    </>
  );
};

export default Roles;
