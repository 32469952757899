import React from 'react';
import { connect } from 'react-redux';
import './app-route.scss'

const Loader = connect((state: any) => {
  return {
    isLoading: state.appearance.isLoading
  };
}, null)(( props : any ) => {
  if (props.isLoading.length) {
    return (
      <div className="loader-section">
        <div className="loader">
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  }
  return null;
});

const App = (props: any ) => {

  return (
    <>
      <Loader />
      {props?.children}
    </>
  );
};

export default App;

