export const columnNames: string[] = [
  'Edit',
  'Funeral Home',
  'Physical Address',
  'Mailing Address',
  'Owner Name',
  'Email Address',
  'Phone Number',
  'Tax ID',
  'Fax Number',
];

export const crumbMapping = [
  { label: 'Directory', link: '/home/directory/funeral-homes' },
  { label: 'Funeral Homes', link: '/home/directory/funeral-homes' },
];
