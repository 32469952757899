/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import CoOwnerTable from './co-owner-table/co-owner-table';
import { getAllCoOwnerDetails, removeCoOwnerDetails } from '../../../../../../../api/funeralhomes';
import { splitPhoneNumber } from '../../../../../utils';
import { columnNames } from './co-owner-table.constant';
import { formatAddress } from './co-owner-table.utils';
import DeleteConfirmationPopup from '../delete-confirmation-popup/delete-confirmation-popup';
import AlertComponent from '../../../../../../../common/components/Alert/Alert';
import './co-owner.scss';

const CoOwner: any = (props: any) => {
  const { funeralHomeId, setLoading, addMoreLabel, setAddMoreLabel } = props;
  const [rowsData, setRowsData] = useState<any[]>([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const [isDeletePopupOpen, setDeletePopupOpen] = useState<boolean>(false);
  const [deleteAdditionalLocationId, setDeleteAdditionalLocationId] = useState<number | null>(null);
  const [isEditProgress, setIsEditProgress] = useState<any>(' ');

  const handleEditClick = (id: any) => {
    const updatedrowsData = rowsData
      ?.map?.((data: any) => {
        return {
          ...data,
          isEdit: data?.id === id ? !data?.isEdit : false,
        };
      })
      ?.filter((data) => !data?.isNewAdded);

    setRowsData(updatedrowsData);
  };

  const handleAddMoreCompanies = (id?: any, data?: any) => {
    setIsEditProgress('co-owner-table-box-active');
    const tempRowsData = data?.length ? data : rowsData;
    const updatedrowsData = [
      ...tempRowsData
        ?.map?.((data: any) => {
          return {
            ...data,
            isEdit: data?.id === id ? !data?.isEdit : false,
          };
        })
        ?.filter((data: any) => !data?.isNewAdded),
      {
        Edit: '',
        fullName: '',
        physicalAddress: {
          street: '',
          city: '',
          state: '',
          zipcode: '',
          selectedPhysicalAddressCity: null,
          selectedPhysicalAddressState: null,
          physicalAddressCity: null,
          physicalAddressState: null,
        },
        mailingAddress: {
          street: '',
          city: '',
          state: '',
          zipcode: '',
          selectedMailingAddressCity: null,
          selectedMailingAddressState: null,
          mailingAddressCity: null,
          mailingAddressState: null,
        },
        contactNumber: {
          countryCode: '+1',
          phoneNumber: '',
        },
        email: '',
        contactDepartments: '',
        isEdit: true,
        isNewAdded: true,
      },
    ];
    setRowsData(updatedrowsData);
  };

  const handleGetCoOwner = async () => {
    try {
      setLoading(true);
      const getAPIDetails = await getAllCoOwnerDetails(funeralHomeId);
      const tempRowsData = getAPIDetails?.data?.data?.rows?.map((data: any) => {
        return {
          Edit: '',
          id: data?.id,
          fullName: data?.full_name,
          physicalAddressRow: formatAddress(data?.physicalAddress) || '-',
          mailingAddressRow: formatAddress(data?.mailingAddress) || '-',
          physicalAddress: {
            ...data?.physicalAddress,
            selectedPhysicalAddressCity: data?.physicalAddress?.City,
            selectedPhysicalAddressState: data?.physicalAddress?.State,
            physicalAddressCity: null,
            physicalAddressState: null,
          },
          mailingAddress: {
            ...data?.mailingAddress,
            selectedMailingAddressCity: data?.mailingAddress?.City,
            selectedMailingAddressState: data?.mailingAddress?.State,
            mailingAddressCity: null,
            mailingAddressState: null,
          },
          email: data?.email || '-',
          contactNumberRow: data?.contact_number || '-',
          contactNumber: splitPhoneNumber(data?.contact_number),
          contactDepartments: data?.contact_department,
          isEdit: false,
        };
      });
      setRowsData(tempRowsData);
      if (addMoreLabel === 'Add more owner') {
        setAddMoreLabel(null);
        handleAddMoreCompanies(null, tempRowsData);
      }

      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const handleDeleteClick = async (id: any) => {
    setDeleteAdditionalLocationId(id);
    setDeletePopupOpen(true);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await removeCoOwnerDetails(funeralHomeId, deleteAdditionalLocationId);
      setLoading(false);
      setDeleteAdditionalLocationId(null);
      setDeletePopupOpen(false);
      handleGetCoOwner();
    } catch (error) {
      const errorMessage =
        (typeof error === 'object' && error !== null && 'response' in error && (error as any).response?.data?.details?.[0]) ||
        (error instanceof Error ? error.message : 'An unexpected error occurred.');
      setAlertMessage(errorMessage);
      setAlertType('error');
      setAlertOpen(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (addMoreLabel !== 'Add more owner') {
      handleGetCoOwner();
    }
  }, [funeralHomeId]);

  useEffect(() => {
    if (addMoreLabel === 'Add more owner') {
      handleGetCoOwner();
    }
  }, [addMoreLabel]);

  return (
    <div className="view-funeral-homes-right-container">
      <CoOwnerTable
        columnNames={columnNames}
        rowsData={rowsData}
        handleEditClick={handleEditClick}
        handleDeleteClick={handleDeleteClick}
        handleAddMoreCompanies={handleAddMoreCompanies}
        setLoading={setLoading}
        funeralHomeId={funeralHomeId}
        handleGetCoOwner={handleGetCoOwner}
        setAlertOpen={setAlertOpen}
        setAlertType={setAlertType}
        setAlertMessage={setAlertMessage}
        isEditProgress={isEditProgress}
        setIsEditProgress={setIsEditProgress}
      />
      <DeleteConfirmationPopup
        isOpen={isDeletePopupOpen}
        onClose={() => setDeletePopupOpen(!isDeletePopupOpen)}
        handleDelete={handleDelete}
        label="Co-Owner"
      />
      <AlertComponent message={alertMessage} type={alertType} open={alertOpen} onClose={() => setAlertOpen(false)} />
    </div>
  );
};

export default CoOwner;
