/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import './view-details-section.scss';
import editIcon from '../../../../../../assets/icons/edit.svg';
import { getFuneralHomesViewData } from '../view-funeral-homes-left-section.utils';

interface ViewDetailsSectionProps {
  handleEditDetailsSectionPopUp: () => void;
  viewData: any;
}
const ViewDetailsSection: React.FC<ViewDetailsSectionProps> = ({ handleEditDetailsSectionPopUp, viewData }) => {
  const details = getFuneralHomesViewData(viewData);
  return (
    <div className="view-details-section">
      <div className="view-details-header-section">
        <div className="view-details-header-tab">
          <div className="view-details-header-text">Details</div>
          <div className="edit-icon-section">
            <img
              className="edit-icon-styles"
              src={editIcon}
              alt="Edit Icon"
              onClick={() => {
                handleEditDetailsSectionPopUp();
              }}
            />
          </div>
        </div>
        <div className="view-details-data-section">
          {Object.entries(details).map(([key, value]) => (
            <div key={key} className="view-details-data-row">
              <div className="view-details-key">{key}</div>
              <div className="view-details-value">{value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewDetailsSection;
