import axios from '.';

export const getVideoList = (searchText?: string, category?: string, filters?:any) => {
    return axios({
      url: `/guides`,
      method: 'GET',
      params: {
            category: category,
            searchText: searchText,
            ...filters
        },
    });
};

export const addVideo = ( fileData: any) =>{
    return axios({
      method: "POST",
      url: `/guides`,
      data:fileData,
    });
  }