import { AddRegionalRepType, EditRegionalRepType } from '../../features/directory/types/regional-rep-props';
import axios from '../index';

export const createRegionalRep = (data: AddRegionalRepType) => {
  return axios({
    method: 'POST',
    url: `/directories/regionalRep`,
    data
  });
};
export const getRegionalRepList = (offset: number, limit: number,searchText:string, sortBy?: string, sortDirection?: string, filters?: any) => {
  return axios({
    method: 'GET',
    url: `/directories/regionalRep`,
    params: {
      offset: offset, 
      limit: limit,
      searchText: searchText,
      sortBy: sortBy,
      sortDirection: sortDirection,
      ...filters
    },
  });
};
export const exportRegionalRepList=({searchText, sortBy, sortDirection, fromDate, toDate, cityId, stateId}:{searchText?:string; sortBy?: string; sortDirection?: string; fromDate?: string; toDate?: string; cityId?: number; stateId?: number}, filters?: any)=>{
  return axios({
    method:'GET',
    url:`directories/regionalRep/export`,
    params: {
      searchText: searchText,
      sortBy: sortBy,
      sortDirection: sortDirection,
      fromDate: fromDate,
      toDate: toDate,
      cityId: cityId,
      stateId: stateId,
      ...filters
    },
    responseType: 'blob',
  })
}
export const getCurrentRegionalRep = (regionalRepId: number) => {
  return axios({
    method: 'GET',
    url: `/directories/regionalRep/${regionalRepId}`,
  });
};
export const editRegionalRep = (regionalRepId: number,data: EditRegionalRepType) => {
  return axios({
    method: 'PUT',
    url: `/directories/regionalRep/${regionalRepId}`,
    data
  });
};
export const deleteRegionalRep = (regionalRepId: number) => {
  return axios({
    method: 'DELETE',
    url: `/directories/regionalRep/${regionalRepId}`,
  });
};