import { concatenatePhoneNumber } from "../../utils";


  export const getFuneralHomesPayloadData = (values: any, editData: any ) => {
    return {
        name: values?.name,
        ownerName: values?.ownerName,
        physicalAddress: {
          id: editData?.physicalAddress.id || undefined,
          street: values.physicalAddress.street,
          city: {
            id: values?.physicalAddress?.physicalAddressCity?.id || editData?.physicalAddress.City.id,
            name: values?.physicalAddress?.physicalAddressCity?.name || editData?.physicalAddress.City.name
          },
          state: {
            id: values?.physicalAddress?.physicalAddressState?.id ||  values?.physicalAddress?.selectedPhysicalAddressState?.id || editData?.physicalAddress.State.id,
            name: values?.physicalAddress?.physicalAddressState?.name || values?.physicalAddress?.selectedPhysicalAddressState?.name || editData?.physicalAddress.State.name,
          },
          zipcode: values.physicalAddress.zipcode,
        },
        mailingAddress: {
          id: editData?.mailingAddress.id || undefined,
          street: values.mailingAddress.street,
          city: {
            id: values?.mailingAddress?.mailingAddressCity?.id || editData?.mailingAddress.City.id,
            name: values?.mailingAddress?.mailingAddressCity?.name || editData?.mailingAddress.City.name
          },
          state: {
            id: values?.mailingAddress?.mailingAddressState?.id ||  values?.mailingAddress?.selectedMailingAddressState?.id || editData?.mailingAddress.State.id,
            name: values?.mailingAddress?.mailingAddressState?.name || values?.mailingAddress?.selectedMailingAddressState?.name  || editData?.mailingAddress.State.name
          },
          zipcode: values.mailingAddress.zipcode,
        },
        faxNumber: values?.faxNumber,
        taxId: values?.taxId,
        email: values?.emailAddress,
        phoneNumber: concatenatePhoneNumber(values?.phoneNumber),
    };
  }