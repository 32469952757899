import store from '../../store';

export const checkUserUpdate = () => {
    
    
    store.dispatch({
      type: 'PROFILE:SET_USER_UPDATE',
      payload: {
        value: !store?.getState()?.profile?.isUserUpdate,
      }
    });
  };