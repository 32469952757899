import { concatenatePhoneNumber } from "../../utils";

  export const getAgenciesPayloadData = (values: any, editData: any, deletedOwners: any, deletedAgencyLicenseDetails: any ) => {
    const formatAgencyLicenseDetails = values?.agencyLicenseDetails?.map((data: any, index: any )=>{ return {
      id: data.id || undefined,
      stateLicensedIn:  data?.stateLicensedIn?.id || null,
      amlCertification: data?.amlCertification?.[0] ||  null,
      reserveBalance:  data?.reserveBalance ||  null,
      agencyNumber:  data?.agencyNumber||  null,
      licenseExpiration: data?.licenseExpiration ||  null,
    }});
    const formatDeletedAgencyLicenseDetails = deletedAgencyLicenseDetails?.map((data: any, index: any )=>{ return {
      id: data.id || undefined,
      stateLicensedIn:  data?.stateLicensedIn?.id || null,
      amlCertification: data?.amlCertification?.[0] ||  null,
      reserveBalance:  data?.reserveBalance||  null,
      agencyNumber:  data?.agencyNumber ||  null,
      licenseExpiration: data?.licenseExpiration ||  null,
      isDeleted: data?.isDeleted
    }});
    const formatAgencyOwners = values?.agencyOwners?.map((data: any, index: any )=>{ 
      return {
      ...data,
      id: data.id,
      commissionPaymentFrequency: data?.commissionPaymentFrequency?.value,
      companiesContractedWith: undefined,
    }});
    const formatDeletedOwners = deletedOwners?.map((data: any) => {
      return {
        ...data,
        commissionPaymentFrequency: data?.commissionPaymentFrequency?.value
      }
    })
    const formatAgencyStatesAppointedIn = values?.agencyStatesAppointedIn?.map((data: any, index: any )=>{ return {
        stateId: data?.id,
        id: data?.editID
    }});
    return {
      name: values?.name || null,
      mailingAddress: {
          id: editData?.mailingAddress?.id || undefined,
          street: values?.mailingAddress?.street || null,
          city: {
              id: values?.mailingAddress?.mailingAddressCity?.id || editData?.mailingAddress?.City?.id || null,
              name: values?.mailingAddress?.mailingAddressCity?.name || editData?.mailingAddress?.City?.name || "",
          },
          state: {
              id: values?.mailingAddress?.mailingAddressState?.id || values?.mailingAddress?.selectedMailingAddressState?.id || editData?.mailingAddress?.State?.id || null,
              name: values?.mailingAddress?.mailingAddressState?.name || values?.mailingAddress?.selectedMailingAddressState?.name || editData?.mailingAddress?.State?.name || "",
          },
          zipcode: values?.mailingAddress?.zipcode || null,
      },
      authorizedRepresentative: values?.authorizedRepresentative || null,
      agencyLicenseDetails: [...(formatAgencyLicenseDetails || []), ...(formatDeletedAgencyLicenseDetails || [])],
      agencyOwners: [...(formatAgencyOwners || []), ...(formatDeletedOwners || [])],
      agencyStatesAppointedIn: [...(formatAgencyStatesAppointedIn || []), ...(values?.deletedAgencyStatesAppointed || [])],
      workPhone: values?.workPhone?.phoneNumber ? concatenatePhoneNumber(values?.workPhone) : null,
      cellPhone: values?.cellPhone?.phoneNumber ? concatenatePhoneNumber(values?.cellPhone) : null,
      emailAddress: values?.emailAddress || null,
      specialInstructions: values?.specialInstructions || null,
      commissionLevel: values?.commissionLevel || null,
      regionalRepId: values?.regionalRepId || null,
      note: values?.note || null,
  };
  
  }

  export interface LocationOption {
    id: number;
    name: string;
    State?: any;
    Country?: any;
    Cities?: LocationOption[];
  }