import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useSearchParams, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import styles from '../welcome/welcome.module.scss';
import Logo from "../../assets/icons/bg_logo.svg";
import Password from "../../assets/icons/password_hidden.svg";
import { passwordRules } from '../../constants/regex-string';
import { useSetLoading } from '../../common/hooks/appearance';
import StopwatchDisplay from '../../common/components/stop-watch-display/stop-watch-display';
import './reset-password.scss'
import * as resetPasswordAPI from '../../api/resetpassword';
import ViewIcon from "../../assets/icons/viewIcon.svg";

const ResetPassword: React.FC = () => {
  const [step, setStep] = useState<'invalid' | 'valid'>('invalid');
  const [message, setMessage] = useState<any | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [stopwatchValue, setStopwatchValue] = useState(0);
  //const [oldPasswordHash, setOldPasswordHash] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const passwordInitialValues = { newPassword: '', confirmPassword: '' };

  const passwordValidationSchema = Yup.object({
    newPassword: Yup.string()
      .required('New password is required')
      .min(8, 'Password must be at least 8 characters')
      .test("has-digit", "Password must contain at least one digit", (value) =>
        /\d/.test(value)
      )
      .test(
        "has-lowercase",
        "Password must contain at least one lowercase letter",
        (value) => /[a-z]/.test(value)
      )
      .test(
        "has-uppercase",
        "Password must contain at least one capital letter",
        (value) => /[A-Z]/.test(value)
      )
      .test(
        "has-special-character",
        "Password must contain at least one special character",
        (value) => /\W/.test(value)
      )
      .test(
        "min-length",
        "Password must be at least 5 characters long",
        (value) => {
          if (!value || !value.length) return true;
          return value.length >= 5;
        }
      )
      // .test(
      //   'not-old-password',
      //   'New password cannot be the same as the old password',
      //   (value) => !oldPasswordHash || value !== oldPasswordHash
      // ),
      ,
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], 'Passwords must match')
      .required('Confirm password is required')
      .matches(passwordRules, { message: "Please create a stronger password" }),
  });

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const togglePasswordVisibilityConfirm = () => {
    setShowPasswordConfirm((prevState) => !prevState);
  };

  const toggleStopwatchValue =useCallback( (message:any, types: any) => {
    setIsLoading(false);
    setStep('invalid');
    setMessage({message: message, types: types});
    const stopwatchTimer = setInterval(() => {
      setStopwatchValue((prevState) => prevState + 10);
    }, 10);
    setTimeout(() => {
      clearInterval(stopwatchTimer);
      setStopwatchValue(0);
      navigate("/");
    }, 10000);
  }, [setIsLoading, setStep, setMessage, setStopwatchValue, navigate]);

  const handleValidationToken =useCallback( async (payload: any) => {
    setIsLoading(true);
    try {
      await resetPasswordAPI?.validationResetPasswordToken({token: payload});
      setStep('valid');
      setIsLoading(false);
    } catch (error: any) {
      toggleStopwatchValue(error?.response?.data?.message, 'error')
    }
  },[toggleStopwatchValue]);
  useEffect(() => {
    const tokenFromUrl = searchParams.get('token');
    if (tokenFromUrl) {
      handleValidationToken(tokenFromUrl)
      setToken(tokenFromUrl);
    }
  }, [searchParams,handleValidationToken]);

  const handleResetSubmit = async (values: typeof passwordInitialValues) => {
    setIsLoading(true);
    try {
      const payload = { token: token, password: values?.confirmPassword };
      const response: any=await resetPasswordAPI?.resetpassword(payload);
      toggleStopwatchValue(response?.data?.message,'success' )
    } catch (error:any ) {
      setIsLoading(false);
      setMessage({message: error?.response?.data?.message, types: 'error'});
    }
  };

  const showLoading = useMemo(() => isLoading, [isLoading]);
  useSetLoading(showLoading);

  return (
    <div className="reset-password">
      <div className={`reset-password-container`}>
        <h2 className={`reset-password-heading`}><img className={`reset-password-heading-icon`} src={Logo} alt="AFP Logo" /></h2>
        <h2 className={`reset-password-heading`}>Change Password</h2>
        {step === 'invalid' ? (
          message?.message ? 
          <div className="timer-error-msg-container">
            <div className={`${message?.types === 'error' ?`reset-password-error-message` : `reset-password-success-message`}`}>{message?.message}</div>
            <StopwatchDisplay value={stopwatchValue}/>
          </div> : ""
        ) : (
          <Formik
            initialValues={passwordInitialValues}
            validationSchema={passwordValidationSchema}
            onSubmit={handleResetSubmit}
          >
            <Form className={`${styles.loginForm} reset-password-form`}>
              {message?.message ? <div className={`reset-password-error-message`}>{message?.message}</div>:""}
              <div className={styles.field}>
                <Field type={showPassword ? "text" : "password"} name="newPassword" placeholder="New Password *" className={`${styles.passwordField} confirm-password-field`} />
                <span
                  className={"password-toggle-icon-styles"}
                  onClick={togglePasswordVisibility}
                >
                 <img src={showPassword ? ViewIcon : Password} alt="Toggle Password Visibility" />
                </span>
                <ErrorMessage name="newPassword" component="div" className={`${styles.errorMessage} reset-password-field-error-message`} />
              </div>
              <div className={styles.field}>
                <Field 
                  type={showPasswordConfirm ? "text" : "password"}
                  name="confirmPassword" 
                  placeholder="Confirm Password *" 
                  className={`${styles.passwordField} confirm-password-field`}/>
                <span
                  className={"password-toggle-icon-styles"}
                  onClick={togglePasswordVisibilityConfirm}
                >
                 <img src={showPasswordConfirm ? ViewIcon : Password} alt="Toggle Password Visibility" />
                </span>
                <ErrorMessage name="confirmPassword" component="div" className={`${styles.errorMessage} reset-password-field-error-message`} />
              </div>
              <button type="submit" className={styles.submitButton}>
                Reset Password
              </button>
              <div className={"reset-password-footer-text"}>
              <p>
                In case of any issues, please{' '}
                <a
                  href={`${process.env.REACT_APP_WP_WEBSITE_BASE_URL}/contact-us/`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  contact us
                </a>
                </p>
                <a href="/#" onClick={(e)=>{e.preventDefault();navigate("/"); }}> Login </a>
              </div>
            </Form>
          </Formik>
        )}
      </div>
    </div>

  );
};

export default ResetPassword;
