/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { Row, Table } from 'reactstrap';
import editIcon from '../../../../../../../../assets/icons/edit.svg';
import deleteIcon from '../../../../../../../../assets/icons/deleteIcon.svg';
import { TableComponentProps } from './companies-table-props';
import './companies-table.scss';
import CompaniesTableCellContent from './companies-table-cell-content';
import CompaniesTableCellForm from './companies-table-cell-form';

const CompaniesTable: React.FC<TableComponentProps> = ({
  columnNames = [],
  rowsData = [],
  handleEditClick,
  handleDeleteClick,
  handleAddMoreCompanies,
  setLoading,
  funeralHomeId,
  handleGetCompaniesDetails,
  setAlertOpen,
  setAlertType,
  setAlertMessage,
  isEditProgress,
  setIsEditProgress,
}) => {
  return (
    <>
      <Row className={'companies-tables'}>
        <div className={'companies-table-container'}>
          <Table hover className={`companies-table-box ${isEditProgress}`} bordered={true} striped>
            <thead>
              <tr>
                {columnNames.map((columnName: any, index: any) => (
                  <th key={index}>
                    <div className={'companies-table-header-column-names'}>
                      <div className={'companies-table-header-column'}>{columnName?.header}</div>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rowsData?.length ? (
                (rowsData ?? []).map((row: any, rowIndex: any) =>
                  !row.isEdit ? (
                    <tr key={rowIndex}>
                      {columnNames.map((columnName: any, colIndex: any) => {
                        return (
                          <td
                            key={colIndex}
                            className={`${colIndex < 2 ? 'companies-table-sticky-column' : ''} ${'companies-table-sticky-column-hover'}`}
                          >
                            {colIndex === 0 ? (
                              <div>
                                <img
                                  className={`edit-icon edit-icon-hover`}
                                  src={editIcon}
                                  alt="Edit Icon"
                                  onClick={() => {
                                    setIsEditProgress('companies-table-box-active');
                                    handleEditClick?.(row.id);
                                  }}
                                />
                                <img
                                  className={`edit-icon edit-icon-hover`}
                                  src={deleteIcon}
                                  alt="Edit Icon"
                                  onClick={() => {
                                    handleDeleteClick?.(row.id);
                                  }}
                                />
                              </div>
                            ) : (
                              <CompaniesTableCellContent text={row[columnName?.data]} />
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  ) : (
                    <CompaniesTableCellForm
                      initialValues={row}
                      colIndex={rowIndex}
                      handleEditClick={handleEditClick}
                      setIsEditProgress={setIsEditProgress}
                      setLoading={setLoading}
                      funeralHomeId={funeralHomeId}
                      handleGetCompaniesDetails={handleGetCompaniesDetails}
                      setAlertOpen={setAlertOpen}
                      setAlertType={setAlertType}
                      setAlertMessage={setAlertMessage}
                    />
                  ),
                )
              ) : (
                <tr className="companies-table-no-record">
                  {' '}
                  <td colSpan={9}> No Records found </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Row>
      <div className="companies-table-add-btn-container">
        <div
          className="companies-table-add-btn"
          onClick={() => {
            setIsEditProgress('companies-table-box-active');
            handleAddMoreCompanies();
          }}
        >
          + Add More Companies
        </div>
      </div>
    </>
  );
};

export default CompaniesTable;
