/* eslint-disable  @typescript-eslint/no-explicit-any */
import { useRef, useState, useEffect } from 'react';
import { Formik, ErrorMessage } from 'formik';
import { InputComponent } from '../../../../../../../../common/components/index';
import { validationSchema } from './correspondance-table.validation';
import { addUpdateCorrespondanceDetails, getCorrespondanceRoles } from '../../../../../../../../api/funeralhomes';
import tickIcons from '../../../../../../../../assets/icons/tickIcons.svg';
import crossIcons from '../../../../../../../../assets/icons/crossIcons.svg';
import { CorrespondanceTableCellFormProps, CorrespondanceRole } from './correspondance-table-props';
import { formatPayload } from '../correspondance-table.utils';
import { SelectDropdownComponent } from '../../../../../../../../common/components';
import { DropdownOption } from '../../../../../../../leads-item/leads-item-props';
import { dropDowncustomStyles } from '../correspondance-table.constant';

const CorrespondanceTableCellForm: React.FC<CorrespondanceTableCellFormProps> = ({
  initialValues,
  colIndex,
  handleEditClick,
  setIsEditProgress,
  setLoading,
  funeralHomeId,
  handleGetCorrespondance,
  setAlertOpen,
  setAlertType,
  setAlertMessage,
}) => {
  const [correspondanceRoles, setCorrespondanceRoles] = useState<CorrespondanceRole[]>([]);
  const formRef: any = useRef();

  const onSubmit = async (value: any) => {
    const payloadFormatted = formatPayload(value);
    try {
      setLoading(true);
      await addUpdateCorrespondanceDetails(funeralHomeId, value?.id, payloadFormatted);
      setLoading(false);
      handleGetCorrespondance();
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message
        ? error?.response?.data?.message
        : (typeof error === 'object' && error !== null && 'response' in error && (error as any).response?.data?.details?.[0]) ||
          (error instanceof Error ? error.message : 'An unexpected error occurred.');
      setAlertMessage(errorMessage);
      setAlertType('error');
      setAlertOpen(true);
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    formRef?.current?.handleSubmit();
  };

  const fetchCorrespondanceRoles = async () => {
    try {
      const response = await getCorrespondanceRoles();
      setCorrespondanceRoles(response?.data?.data);
    } catch {}
  };

  useEffect(() => {
    fetchCorrespondanceRoles();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={formRef}
      isValidating={true}
      setSubmitting={true}
      isSubmitting={true}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => (
        <tr>
          <td key={colIndex} className={`${'correspondance-table-sticky-column'}`} valign="top">
            <img
              className={`edit-form-icon`}
              src={tickIcons}
              alt="Edit Icon"
              onClick={() => {
                handleSubmit();
              }}
            />
            <img
              className={`edit-form-icon`}
              src={crossIcons}
              alt="Edit Icon"
              onClick={() => {
                setIsEditProgress('');
                handleEditClick?.(values?.id);
              }}
            />
          </td>
          <td key={colIndex} className={`${'correspondance-table-sticky-column'}`} valign="top">
            <div className={`correspondance-table-column-component`}>
              <InputComponent fieldName="email" placeholder="Enter email address" optional={true} isEmail />
            </div>
          </td>
          <td key={colIndex} valign="top">
            <div className={`correspondance-table-column-component`}>
              <InputComponent fieldName="firstName" placeholder="Enter first name" isAlpha />
            </div>
          </td>
          <td key={colIndex} valign="top">
            <div className={`correspondance-table-column-component`}>
              <InputComponent fieldName="lastName" placeholder="Enter last name" isAlpha />
            </div>
          </td>
          <td key={colIndex} valign="top">
            <div className={`correspondance-table-column-component`}>
              <SelectDropdownComponent
                placeholder="Select Role"
                options={correspondanceRoles?.map((role) => ({
                  label: role.role,
                  value: role.id,
                }))}
                menuPosition={'fixed'}
                customStyles={dropDowncustomStyles}
                value={values?.correspondanceRoleId || null}
                onChange={(selected) => {
                  const selectedOption = selected as DropdownOption;
                  setFieldValue('correspondanceRoleId', selectedOption);
                }}
              />
              <ErrorMessage name={`correspondanceRoleId`} component="div" className="error-message" />
            </div>
          </td>
        </tr>
      )}
    </Formik>
  );
};

export default CorrespondanceTableCellForm;
