import axios from '.';

export const addAgentsDetails = (data: any) => {
  return axios({
    method: 'POST',
    url: '/directories/agent',
    data
  });
};

export const getAgentstList = (offset: number, limit: number, searchText?: string, sortBy?: string, sortDirection?: string, filters?:any) => {
  return axios({
    url: `/directories/agent`,
    method: 'GET',
    params: {
        offset: offset, 
        limit: limit, 
        sortBy: sortBy,
        sortDirection: sortDirection,
        searchText: searchText,
        ...filters
      },
  });
};


export const updateAgentsDetails = (id: number, data: any) => {
    return axios({
      method: 'PUT',
      url: `/directories/agent/${id}`,
      data
    });
  };

export const getAgentDetails = ( id: number) => {
  return axios({
    url: `/directories/agent/${id}`,
    method: 'GET',
  });
};

export const removeAgentDetails = ( id: number) => {
    return axios({
      url: `/directories/agent/${id}`,
      method: 'DELETE',
    });
  };


export const getAutoFillAgent = (key: string, value: string) => {
  return axios({
    method: 'GET',
    url: '/directories/search/autofill',
    params: { key, value },
  });
};

export const exportAgentList=(savedReport?: string) => {
  return axios({
    method:'GET',
    url:`directories/agent/export/saved-report`,
    responseType: 'blob',
    params: {
      reportName: savedReport
    },
  })
}
export const exportAgentData=(
  {
    searchText,
    sortBy,
    sortDirection,
    savedReport,
    fromDate,
    toDate,
    cityId,
    stateId
  }: {
    searchText?: string;
    sortBy?: string;
    sortDirection?: string;
    savedReport?: string;
    fromDate?: string;
    toDate?: string;
    cityId?: number;
    stateId?: number;
  }, filters?: any
) => {
  return axios({
    method:'GET',
    url:`directories/agent/export`,
    responseType: 'blob',
    params: {
      searchText: searchText,
      sortBy: sortBy,
      sortDirection: sortDirection,
      reportName:savedReport,
      fromDate: fromDate,
      toDate: toDate,
      cityId: cityId,
      stateId: stateId,
      ...filters
    },
  })
}