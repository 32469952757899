import axios from ".";
export const fetchRolesPermissions = () => {
  return axios({
    method: "GET",
    url: "/user-roles/permissions",
  });
};
export const modifyUserRolePermissions = (data: any) => {
  return axios({
      method: 'POST',
      url: `/user-roles/modify-permissions`,
      data
  })
}