import React, {useState} from "react";
import { ErrorMessage } from "formik";
import {
  Row,
  Label,
  Col,
  FormGroup 
} from "reactstrap";
import {
  SelectDropdownComponent,
  InputComponent,
  DatePicker,
  CheckBoxComponent
} from "../../../../common/components/index";
import { customStyles } from "./add-or-edit-agencies.constant";
import OwnerConfirmationPopup from "./owner-confirmation-popup";
import { getCustomDobStyle } from "../../agents/add-or-edit-agents.utils";
const OwnerDetailsSection = (props: any) => {

   const { 
        values,
        handleAddOwnerDetails,
        handleRemoveOwnerDetails,
        setFieldValue,
    } = props;
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [currentPrimaryIndex, setCurrentPrimaryIndex] = useState<number | null>(null);
    
    const handlePrimaryOwnerSelection = async (index: number) => {
        const currentPrimaryIndex = values?.agencyOwners?.findIndex(
          (owner: any) => owner.isPrimaryOwner
        );
        if (currentPrimaryIndex === index) {
          await setFieldValue(`agencyOwners[${index}].isPrimaryOwner`, false);
          return;
        }
        if (currentPrimaryIndex >= 0 && currentPrimaryIndex !== index) {
          setSelectedIndex(index); 
          setCurrentPrimaryIndex(currentPrimaryIndex)
          setIsPopupOpen(true); 
        } else {
          await setFieldValue(`agencyOwners[${index}].isPrimaryOwner`, true);
        }
      };
    const setConfirmationCallback = async (index: number, currentPrimaryIndex: number) => {
        await setFieldValue(`agencyOwners[${currentPrimaryIndex}].isPrimaryOwner`, false);
        await setFieldValue(`agencyOwners[${index}].isPrimaryOwner`, true);
        setIsPopupOpen(false); 
      };
    
    return (
    <>
        <div className="agencies-owner-details">
            <span className="agencies-owner-details-heading">Owner(s) Details*</span>
            <div className="agencies-owner-details-container">
            <div className="agencies-owner-details-sub-container">
                {values?.agencyOwners
                ?.sort((a: any, b: any) => a.id - b.id)
                ?.map((data: any, index: any)=>
                <div key={data?.id} className="agencies-owner-details-container-form">
                    {values?.agencyOwners?.length > 1 ?
                    <div className="agencies-owner-details-remove-btn-container">
                        <div className="agencies-owner-details-remove-btn" onClick={()=>{handleRemoveOwnerDetails(values, setFieldValue, index)}}>
                            <i className="fa fa-regular fa-trash"></i>
                        </div>
                    </div>:
                    ""}
                    <Row xs={12} className="multi-line-row-form-input heading-name-label">
                    <Col className="multi-line-row-form-input-single  ">
                        <InputComponent
                            fieldName={`agencyOwners[${index}].name`}
                            label="Name of owner"
                            placeholder="Enter Owner Name"
                            isAlpha
                        />
                    </Col>
                    <Col className="multi-line-row-form-input-single ">
                        <FormGroup className="add-or-edit-form-group">
                            <Label htmlFor={"funeralHomeContactNumber"} className="custom-form-label">
                                Companies Contracted With *
                            </Label>
                            <SelectDropdownComponent
                                placeholder="Select Companies..."
                                options={[
                                    { label: "NGL", value: 1 },
                                    { label: "GWIC", value: 2 },
                                    { label: "Global Atlantic", value: 3 },
                                    { label: "FDLIC", value: 4 },
                                    { label: "Company 5", value: 5 },
                                    { label: "Travel plans by Inman", value: 6 },
                                    { label: "APASI", value: 7 },
                                    { label: "Other", value: 8 },
                                    
                                ]}
                                value={data?.companiesContractedWith ||  null}
                                onChange={(selected) => {
                                setFieldValue(`agencyOwners[${index}].companiesContractedWith`, selected)
                                }}
                                isSearchable={true}
                                customStyles={customStyles}
                            />
                            <ErrorMessage
                                name={`agencyOwners[${index}].companiesContractedWith`}
                                component="div"
                                className="error-message"
                            />
                        </FormGroup>
                    </Col>
                    </Row>
                    <Row xs={12} className="single-line-row-form-input heading-name-label">
                        <InputComponent
                            fieldName={`agencyOwners[${index}].bankName`}
                            label="Bank Name"
                            placeholder="Enter Bank Name"
                            isAlpha
                        />
                    </Row>
                    <Row xs={12} className="single-line-row-form-input heading-name-label">
                        <InputComponent
                            fieldName={`agencyOwners[${index}].bankRoutingNumber`}
                            label="Bank Routing  Number"
                            placeholder="Enter Bank Routing Number"
                            isAlphaNumeric
                        />
                    </Row>
                    <Row xs={12} className="single-line-row-form-input heading-name-label">
                        <InputComponent
                            fieldName={`agencyOwners[${index}].bankAccountNumber`}
                            label="Bank Account Number"
                            placeholder="Enter Bank Account Number"
                            onlyNums
                        />
                    </Row>
                    <Row xs={12} className="multi-line-row-form-checkbox heading-name-label">
                        <CheckBoxComponent
                            fieldName={`Checking`}
                            label="Checking"
                            checked={values?.agencyOwners[index].accountType === "Checking" ? true : false}
                            onClick={() =>{
                                setFieldValue(`agencyOwners[${index}].accountType`, values?.agencyOwners[index].accountType === "Checking" ? "" : "Checking" )
                            }}
                        />
                        <CheckBoxComponent
                            fieldName={`Savings`}
                            label="Savings"
                            checked={values?.agencyOwners[index].accountType === "Savings" ? true : false}
                            onClick={() =>{
                                setFieldValue(`agencyOwners[${index}].accountType`, values?.agencyOwners[index].accountType === "Savings" ? "" : "Savings" )
                            }}
                        />
                    </Row>
                    <Row  xs={12} className="single-line-row-form-input heading-name-label">
                        <FormGroup className="add-or-edit-form-group">
                            <Label htmlFor={"funeralHomeContactNumber"} className="custom-form-label">
                                Commission Payment Frequency 
                            </Label>
                            <SelectDropdownComponent
                                placeholder="Select Commission Payment Frequency"
                                options={[
                                { label: 'Daily', value: 'Daily' },
                                { label: 'Weekly', value: 'Weekly' },
                                { label: 'Monthly', value: 'Monthly' },
                                { label: 'Quarterly', value: 'Quarterly' },
                                { label: 'Half-yearly', value: 'Half-yearly' },
                                { label: 'Yearly', value: 'Yearly' },
                                ]}
                                value={data?.commissionPaymentFrequency ||  null}
                                onChange={(selected) => {
                                    setFieldValue(`agencyOwners[${index}].commissionPaymentFrequency`, selected);
                                }}
                                isSearchable={true}
                                customStyles={customStyles}
                            />
                            <ErrorMessage
                                name={`agencyOwners[${index}].commissionPaymentFrequency`}
                                component="div"
                                className="error-message"
                            />
                        </FormGroup>
                    </Row>
                    <Row xs={12} className="single-line-row-form-input heading-name-label">
                        <InputComponent
                            fieldName={`agencyOwners[${index}].socialSecurityNumber`}
                            label="Social Security Number(Last 4 digits)"
                            placeholder="Enter Social Security Number"
                            isSSN = {true}
                        />
                    </Row>
                    <Row xs={12} className="single-line-row-form-input heading-name-label">
                        <InputComponent
                            fieldName={`agencyOwners[${index}].ein`}
                            label="EIN"
                            placeholder="Enter EIN"
                            isEin
                        />
                    </Row>
                    <Row  xs={12} className="single-line-row-form-input heading-name-label">
                        <FormGroup className="add-or-edit-form-group">
                            <Label className="custom-form-label">
                                Date Of Birth
                            </Label>
                            <DatePicker
                                placeholder="Select Date Of Birth"
                                value={data?.dateOfBirth}
                                maxDate={new Date()}
                                onChange={(selected) => {
                                    setFieldValue(`agencyOwners[${index}].dateOfBirth`, selected);
                                }}
                                customStyles={getCustomDobStyle()}
                            />
                            <ErrorMessage
                                name={`agencyOwners[${index}].dateOfBirth`}
                                component="div"
                                className="error-message"
                            />
                        </FormGroup>
                        <FormGroup className="add-or-edit-form-group">
                            <CheckBoxComponent
                                fieldName={`isPrimaryOwner`}
                                label="Primary Owner"
                                checked={values?.agencyOwners[index].isPrimaryOwner || false}
                                onClick={() => handlePrimaryOwnerSelection(index)}
                            />
                        </FormGroup>
                    </Row>
                </div>
                )}
                <div className="agencies-owner-details-add-btn-container">
                <div className="agencies-owner-details-add-btn" onClick={()=>{handleAddOwnerDetails(values, setFieldValue)}}>
                    + Add More Owner
                </div>
                </div>
            </div>
            </div>
        </div>
        {
            isPopupOpen && (
                <OwnerConfirmationPopup
                    isOpen = {isPopupOpen}
                    onClose={() => setIsPopupOpen(!isPopupOpen)}
                    selectedIndex={selectedIndex}
                    currentPrimaryIndex={currentPrimaryIndex}
                    setConfirmationCallback={setConfirmationCallback}
                />
            )
        }
    </>
    );
};

export default OwnerDetailsSection;