import { useEffect } from 'react';
import _ from 'lodash';
import store from '../../store';

const defaultCloseLoader = _.debounce(() => {
  const state = store.getState();
  if (_.get(state, 'appearance.isLoading.length')) {
    store.dispatch({
      type: `APPEARANCE:SET_LOADING`,
      payload: {}
    });
  }
}, 60000);

export const useSetLoading = (value:any) => {
  useEffect(() => {
    store.dispatch({
      type: `APPEARANCE:SET_LOADING`,
      payload: {
        value
      }
    });
    defaultCloseLoader();
  }, [value]);
};
