import { useEffect, useRef, useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import { AdditionalIdsTableCellContentProps } from "./additional-ids-table-props";

const AdditionalIdsTableCellContent: React.FC<AdditionalIdsTableCellContentProps> = ({ text }) => {
  const cellRef = useRef<HTMLDivElement | null>(null);
  const [isTextTruncated, setIsTextTruncated] = useState<boolean>(false);

  useEffect(() => {
    const cell = cellRef.current;
    if (!cell) return;

    const checkTextTruncation = () => {
      const clone = document.createElement('div');
      clone.style.visibility = 'hidden';
      clone.style.position = 'absolute';
      clone.style.whiteSpace = 'nowrap';
      clone.style.font = window.getComputedStyle(cell).font;
      clone.textContent = text;
      document.body.appendChild(clone);

      const contentWidth = clone.offsetWidth;
      const isTruncated = contentWidth > cell.clientWidth;

      setIsTextTruncated(isTruncated);
      document.body.removeChild(clone);
    };

    checkTextTruncation();
    const observer = new ResizeObserver(checkTextTruncation);
    observer.observe(cell);

    return () => observer.disconnect();
  }, [text]);

  return (
    <div className={"tooltip-container"} ref={cellRef}>
      {
        isTextTruncated ? (
          <Tooltip
            title={isTextTruncated ? text : ""} placement="bottom" arrow
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [100, 0],
                  },
                },
              ],
            }}>
            <span className={"truncated-text"}>
              {text}
            </span>
          </Tooltip>
        ) : (
          <span className="truncated-text" title={text}>{text}</span>
        )
      }
    </div>
  );
};
export default AdditionalIdsTableCellContent;