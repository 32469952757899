import React from "react";
import './roles-confirmation-popup.scss';
interface RolesConfirmationPopupProps {
    isOpen: boolean;
    onClose: () => void;
    handleSaveChanges: () => void;
} 
const RolesConfirmationPopup: React.FC<RolesConfirmationPopupProps> = ({
  isOpen,
  onClose,
  handleSaveChanges
}) => {
  if (!isOpen) return null;

  return (
    <div className="confirm-roles-popup-overlay">
      <div className="roles-confirm-popup">
        <h4 className="roles-confirm-title">Save Changes?</h4>
        <p className="roles-confirm-message">
          You are about to modify the access permissions for these roles.These changes will impact all users assigned to these roles.
        </p>
        <div className="roles-confirm-buttons">
          <button className="roles-confirm-cancel-button" onClick={onClose}>
            Cancel
          </button>
          <button
            className="roles-confirm-button"
            onClick={handleSaveChanges}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default RolesConfirmationPopup;
