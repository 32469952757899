import axios from '.';

export const addAgenciesDetails = (data: any) => {
  return axios({
    method: 'POST',
    url: '/directories/agency',
    data
  });
};
export const exportAgenciesData=({searchText, sortBy, sortDirection, fromDate, toDate, cityId, stateId}:{searchText?: string; sortBy?: string; sortDirection?: string; fromDate?: string; toDate?: string; cityId?: number; stateId?: number}, filters?: any)=>{
  return axios({
    method:'GET',
    url:`directories/agency/export`,
    params: {
      searchText: searchText,
      sortBy: sortBy,
      sortDirection: sortDirection,
      fromDate: fromDate,
      toDate: toDate,
      cityId: cityId,
      stateId: stateId,
      ...filters
    },
    responseType: 'blob',
  })
}

export const getAgenciesList = (offset: number, limit: number, sortBy?: string, sortDirection?: string, searchText?: string, filters?: any) => {
  return axios({
    url: `/directories/agency`,
    method: 'GET',
    params: {
        offset: offset, 
        limit: limit, 
        sortBy: sortBy,
        sortDirection: sortDirection,
        searchText: searchText,
        ...filters
      },
  });
};

export const updateAgenciesDetails = (id: any, data: any) => {
    return axios({
      method: 'PUT',
      url: `/directories/agency/${id}`,
      data
    });
  };

export const getAgenciesDetails = ( id: any) => {
  return axios({
    url: `/directories/agency/${id}`,
    method: 'GET',
  });
};

export const removeAgenciesDetails = ( id: any) => {
    return axios({
      url: `/directories/agency/${id}`,
      method: 'DELETE',
    });
  };

  export const getRegionalRepList = (kay: any, value: any) => {
    return axios({
      url: `/directories/search/autofill`,
      method: 'GET',
      params: {
        key: kay,
        value: value
        },
    });
  };