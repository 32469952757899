export const columnNames: any = [{
  header: "Edit",
  data: "edit",
},{
  header: "Authorised Representative",
  data: "name",
},{
  header: "Phone Number",
  data: "phoneNumber",
},{
  header: "Fax Number",
  data: "faxNumber",
},{
  header: "Email Address",
  data: "email",
}];