import React, { useState, useEffect, useRef } from "react";
import { Formik, Form } from "formik";
import DetailsSection from "./details-section/details-section";
import TaxIdSection from "./tax-id-section/tax-id-section";
import { validationSchema } from "./view-funeral-homes-left-section.validation";
import { getInitialValues } from "./view-funeral-homes-left-section.initialValues";
import { getFuneralHomesPayloadData } from "./view-funeral-homes-left-section.utils";
import { getFuneralHomesDetails, updateFuneralHomesDetails} from '../../../../../api/funeralhomes';
import AlertComponent from "../../../../../common/components/Alert/Alert";
import "./view-funeral-homes-left-section.scss";


const ViewFuneralHomesLeftSection: any = (  props: any ) =>{
  const { funeralHomeId, setLoading, setEditDetailsInViewMode, editDetailsInViewMode }= props;
  const formRef: any = useRef();
  const [initialValues, setInitialValues] = useState({});
  const [viewData, setViewData] = useState({});
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<"success" | "error" | "info" | "warning">("success");

  const handleGetFuneralHome = async () => {
    setLoading(true);
    try {
        const getAPIDetails = await getFuneralHomesDetails(funeralHomeId);
        setInitialValues(getInitialValues(getAPIDetails.data.data.data[0]));
        setViewData(getAPIDetails.data.data.data[0]);
        setLoading(false);
    } catch(error) {
      setLoading(false);
      setAlertMessage("Error while fetching details:");
      setAlertType("error");
      setAlertOpen(true);
    }
  }

  const handleEditDetailsSectionPopUp = () => {
    setEditDetailsInViewMode(!editDetailsInViewMode);
  }

  const onSubmit = async (values: any, { resetForm }: any) => {
    setLoading(true);
    const payloadData = getFuneralHomesPayloadData(values, initialValues);
    try {
      const response = await updateFuneralHomesDetails(funeralHomeId, payloadData);
        resetForm();
        setLoading(false);
        handleGetFuneralHome();
        handleEditDetailsSectionPopUp();
    } catch (error) {
      setAlertMessage("Failed to update funeral home.");
      setAlertType("error");
      setAlertOpen(true);
      setLoading(false);
    }
  };

  useEffect(()=>{
    handleGetFuneralHome();
  },[]);

  return (
    <div className="view-funeral-homes-left">
      <AlertComponent
          message={alertMessage}
          type={alertType}
          open={alertOpen}
          onClose={()=>setAlertOpen(false)}
        />
      <Formik 
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        innerRef={formRef}
        isValidating={true}
        setSubmitting={true}
        isSubmitting={true}
        onSubmit={onSubmit}>
        {({isSubmitting, errors, touched, values, setFieldValue, validateForm})=> (
          <Form className="view-funeral-homes-left-container">
            <div className="view-funeral-homes-left-container">
              <DetailsSection 
                editDetailsInViewMode={editDetailsInViewMode} 
                handleEditDetailsSectionPopUp={handleEditDetailsSectionPopUp} 
                setFieldValue={setFieldValue} 
                validateForm={validateForm}  
                values={values}
                viewData={viewData}
                isSubmitting={ isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ViewFuneralHomesLeftSection;