import * as React from "react";
import styles from "./positioned-image.module.scss";
import { Menu} from "@mui/material";

interface PositionedImageProps {
  anchorEl: HTMLElement | null;
  imageUrl?: string;
  anchorOrigin?: { vertical: 'top' | 'bottom'; horizontal: 'left' | 'right' };
  transformOrigin?: { vertical: 'top' | 'bottom'; horizontal: 'left' | 'right' };
  onClose: () => void;
}

const PositionedImage: React.FC<PositionedImageProps> = ({
  anchorEl,
  imageUrl,
  anchorOrigin = { vertical: 'top', horizontal: 'left' },
  transformOrigin = { vertical: 'top', horizontal: 'left' },
  onClose,
}) => {
  const open = Boolean(anchorEl);

  if (!open) {
    return null;
  }

  return (
    <Menu
      id="positioned-image"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      className={styles["menu"]}
    >
      <div className={styles["image-wrapper"]}>
        {imageUrl && (
            <img
              src={imageUrl}
              alt="Signature"
              className={styles["displayed-image"]}
            />
        )}
      </div>
    </Menu>
  );
};

export default PositionedImage;