/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import CompaniesTable from './companies-table/companies-table';
import { getAllCompaniesDetails, removeCompaniesDetails } from '../../../../../../../api/funeralhomes';
import { columnNames } from './companies.constant';
import { formatAddress } from './companies.utils';
import DeleteConfirmationPopup from '../delete-confirmation-popup/delete-confirmation-popup';
import AlertComponent from '../../../../../../../common/components/Alert/Alert';
import './companies.scss';

const Companies: any = (props: any) => {
  const { funeralHomeId, setLoading } = props;
  const [rowsData, setRowsData] = useState<any[]>([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const [isDeletePopupOpen, setDeletePopupOpen] = useState<boolean>(false);
  const [deleteAdditionalLocationId, setDeleteAdditionalLocationId] = useState<number | null>(null);
  const [isEditProgress, setIsEditProgress] = useState<any>(' ');

  const handleEditClick = (id: any) => {
    const updatedrowsData = rowsData
      ?.map?.((data: any) => {
        return {
          ...data,
          isEdit: data?.id === id ? !data?.isEdit : false,
        };
      })
      ?.filter((data) => !data?.isNewAdded);

    setRowsData(updatedrowsData);
  };

  const handleAddMoreCompanies = (id: any) => {
    setIsEditProgress('companies-table-box-active');
    const updatedrowsData = [
      ...rowsData
        ?.map?.((data: any) => {
          return {
            ...data,
            isEdit: data?.id === id ? !data?.isEdit : false,
          };
        })
        ?.filter((data) => !data?.isNewAdded),
      {
        Edit: '',
        companyName: '',
        companyFocusedField: null,
        selectedCompany: null,
        companyOptions: [],
        companyPhysicalAddress: '',
        companyMailingAddress: '',
        contactNumbersClaims: '',
        contactNumbersAgents: '',
        contactNames: '',
        contactDepartments: '',
        isEdit: true,
        isNewAdded: true,
      },
    ];
    setRowsData(updatedrowsData);
  };

  const handleGetCompaniesDetails = async () => {
    try {
      setLoading(true);
      const getAPIDetails = await getAllCompaniesDetails(funeralHomeId);
      setRowsData(
        getAPIDetails?.data?.data?.rows?.map((data: any) => {
          return {
            Edit: '',
            id: data?.id,
            companyFocusedField: null,
            selectedCompany: { id: data?.company?.id, name: data?.company?.name },
            companyOptions: [],
            companyName: data?.company?.name,
            companyPhysicalAddress: formatAddress(data?.company?.physicalAddress) || '-',
            companyMailingAddress: formatAddress(data?.company?.physicalAddress) || '-',
            contactNumbersClaims: data?.company?.contact_numbers_claims || '-',
            contactNumbersAgents: data?.company?.contact_numbers_agents || '-',
            contactNames: data?.company?.contact_names || '-',
            contactDepartments: data?.company?.contact_departments || '-',
            isEdit: false,
          };
        }),
      );
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const handleDeleteClick = async (id: any) => {
    setDeleteAdditionalLocationId(id);
    setDeletePopupOpen(true);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await removeCompaniesDetails(funeralHomeId, deleteAdditionalLocationId);
      setLoading(false);
      setDeleteAdditionalLocationId(null);
      setDeletePopupOpen(false);
      handleGetCompaniesDetails();
    } catch {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetCompaniesDetails();
  }, [funeralHomeId]);

  return (
    <div className="view-funeral-homes-right-container">
      <CompaniesTable
        columnNames={columnNames}
        rowsData={rowsData}
        handleEditClick={handleEditClick}
        handleDeleteClick={handleDeleteClick}
        handleAddMoreCompanies={handleAddMoreCompanies}
        setLoading={setLoading}
        funeralHomeId={funeralHomeId}
        handleGetCompaniesDetails={handleGetCompaniesDetails}
        setAlertOpen={setAlertOpen}
        setAlertType={setAlertType}
        setAlertMessage={setAlertMessage}
        isEditProgress={isEditProgress}
        setIsEditProgress={setIsEditProgress}
      />
      <DeleteConfirmationPopup
        isOpen={isDeletePopupOpen}
        onClose={() => setDeletePopupOpen(!isDeletePopupOpen)}
        handleDelete={handleDelete}
        label="Company"
      />
      <AlertComponent message={alertMessage} type={alertType} open={alertOpen} onClose={() => setAlertOpen(false)} />
    </div>
  );
};
export default Companies;
