import { useState, useEffect } from "react";
import { getUser } from "../../api/profile";

let sharedUserData: any = null; 
let userSubscribers: ((user: any) => void)[] = []; 

export const clearUserData = () => {
  sharedUserData = null;
  userSubscribers.forEach((subscriber) => subscriber(null));
};

export const refreshUserData = async () => {
  try {
    const response = await getUser();
    sharedUserData = response.data.userDetails;
    userSubscribers.forEach((subscriber) => subscriber(sharedUserData));
  } catch (error) {
    console.error("Error refreshing user data:", error);
    sharedUserData = null;
    userSubscribers.forEach((subscriber) => subscriber(null));
  }
};

export const useUser = () => {
  const [user, setUser] = useState(sharedUserData);

  useEffect(() => {
    if (!sharedUserData) {
      getUser()
        .then((response) => {
          sharedUserData = response.data.userDetails;
          setUser(sharedUserData);
          userSubscribers.forEach((subscriber) => subscriber(sharedUserData));
        })
        .catch((error) => console.error("Error fetching user data:", error));
    } else {
      setUser(sharedUserData); 
    }


    const subscriber = (updatedUser: any) => setUser(updatedUser);
    userSubscribers.push(subscriber);

    return () => {
      userSubscribers = userSubscribers.filter((sub) => sub !== subscriber);
    };
  }, []);

  return user;
};
