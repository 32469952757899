/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import CorrespondanceTable from './correspondance-table/correspondance-table';
import { getAllCorrespondanceDetails, removeCorrespondanceDetails } from '../../../../../../../api/funeralhomes';
import DeleteConfirmationPopup from '../delete-confirmation-popup/delete-confirmation-popup';
import AlertComponent from '../../../../../../../common/components/Alert/Alert';
import { columnNames } from './correspondance-table.constant';

const Correspondance: any = (props: any) => {
  const { funeralHomeId, setLoading } = props;
  const [rowsData, setRowsData] = useState<any[]>([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const [isDeletePopupOpen, setDeletePopupOpen] = useState<boolean>(false);
  const [deleteAdditionalLocationId, setDeleteAdditionalLocationId] = useState<number | null>(null);

  const handleEditClick = (id: any) => {
    const updatedrowsData = rowsData
      ?.map?.((data: any) => {
        return {
          ...data,
          isEdit: data?.id === id ? !data?.isEdit : false,
        };
      })
      ?.filter((data) => !data?.isNewAdded);

    setRowsData(updatedrowsData);
  };

  const handleAddMoreCorrespondances = (id: any) => {
    const updatedrowsData = [
      ...rowsData
        ?.map?.((data: any) => {
          return {
            ...data,
            isEdit: data?.id === id ? !data?.isEdit : false,
          };
        })
        ?.filter((data) => !data?.isNewAdded),
      {
        Edit: '',
        email: '',
        firstName: '',
        lastName: '',
        correspondanceRoleId: '',
        isEdit: true,
        isNewAdded: true,
      },
    ];
    setRowsData(updatedrowsData);
  };

  const handleGetCorrespondance = async () => {
    try {
      setLoading(true);
      const getAPIDetails = await getAllCorrespondanceDetails(funeralHomeId);
      setRowsData(
        getAPIDetails?.data?.data?.map((data: any) => {
          return {
            Edit: '',
            id: data?.id,
            email: data?.email,
            firstName: data?.first_name,
            lastName: data?.last_name,
            correspondanceRoleId: data.FuneralHomeCorrespondanceRole
              ? {
                  value: data?.FuneralHomeCorrespondanceRole?.id,
                  label: data?.FuneralHomeCorrespondanceRole?.role,
                }
              : null,
            role: data?.FuneralHomeCorrespondanceRole?.role || '-',
            isEdit: false,
          };
        }),
      );
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const handleDeleteClick = async (id: any) => {
    setDeleteAdditionalLocationId(id);
    setDeletePopupOpen(true);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await removeCorrespondanceDetails(funeralHomeId, deleteAdditionalLocationId);
      setLoading(false);
      setDeleteAdditionalLocationId(null);
      setDeletePopupOpen(false);
      handleGetCorrespondance();
    } catch {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetCorrespondance();
  }, [funeralHomeId]);

  return (
    <div className="view-funeral-homes-right-container">
      <CorrespondanceTable
        columnNames={columnNames}
        rowsData={rowsData}
        handleEditClick={handleEditClick}
        handleDeleteClick={handleDeleteClick}
        handleAddMoreCorrespondances={handleAddMoreCorrespondances}
        setLoading={setLoading}
        funeralHomeId={funeralHomeId}
        handleGetCorrespondance={handleGetCorrespondance}
        setAlertOpen={setAlertOpen}
        setAlertType={setAlertType}
        setAlertMessage={setAlertMessage}
      />
      <DeleteConfirmationPopup
        isOpen={isDeletePopupOpen}
        onClose={() => setDeletePopupOpen(!isDeletePopupOpen)}
        handleDelete={handleDelete}
        label="Correspondence"
      />
      <AlertComponent message={alertMessage} type={alertType} open={alertOpen} onClose={() => setAlertOpen(false)} />
    </div>
  );
};

export default Correspondance;
