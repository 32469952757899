import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Container } from "reactstrap";
import BreadcrumbComponent from "../../../common/components/breadcrumb-component/breadcrumb-component";
import PaginationComponent from "../../../common/components/pagination-component/pagination-component";
import SearchBar from "../../../common/components/search/search-bar";
import TableComponent from "../../../common/components/table-component/table-component";
import AddOrEditRegionalReps from "../regional-reps/add-or-edit-regional-reps";
import { useSetLoading } from "../../../common/hooks/appearance";
import "./regional-reps.scss";
import {exportRegionalRepList, getCurrentRegionalRep, getRegionalRepList } from "../../../api/directories/regionalRep";
import ViewRegionalReps from "./view-regional-reps";

import _ from "lodash";
import AlertComponent from "../../../common/components/Alert/Alert";
import FilterRegionalReps from "./filter-regional-reps";
const RegionalReps: React.FC = () => {
  const [addOrEditOpen, setEditOpen] = useState<boolean>(false);
  const [addOrEditOpenMode, setEditOpenMode] = useState<'add' | 'edit' | null>(null);
  const [viewOpen, setViewOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<any>(null);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize] = useState<number>(15);
  const [searchText, setSearchText] = useState<any>(null);
  const [isReset, setIsReset] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>("created_at");
  const [sortDirection, setSortDirection] = useState<string>("desc");
  const [columnName, setCloumnName] = useState<string>("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<
    "success" | "error" | "info" | "warning"
  >("success");
  const handleSearch = _.debounce((text: string) => {
    // setLoading(true)
    setSearchText(text)
  }, 500);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<any>({});

  const toggleAddEdit = (modeType: any) => {
    const newState = !addOrEditOpen;
    setEditOpen(newState);
    setEditOpenMode(newState ? modeType : '');
  };
  const toggleView = () => {
    setViewOpen(!viewOpen);
  };

  const toggleFilters = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleEditClick = async (regionalRepId: number) => {
    try {
      setLoading(true)
      const getAPIDetails = await getCurrentRegionalRep(regionalRepId);
      setEditData(getAPIDetails.data.data.data[0]);
      setTimeout(() => setLoading(false), 1000);
    } catch (error) {
      setLoading(false)
    }
  };

  const handleViewClick = async (regionalRepId: number) => {
    try {
      setLoading(true)
      const getAPIDetails = await getCurrentRegionalRep(regionalRepId);
      setEditData(getAPIDetails?.data?.data?.data[0]);
      setViewOpen(!viewOpen);
      setTimeout(() => setLoading(false), 1000);
    } catch (error) {
      setLoading(false)
    }
  };

  const columnNames: string[] = ['Edit', 'Name', 'Address', 'Email Address', 'Cell Phone Number', 'Region/State/Agencies Assigned'];

  let crumbMapping = [
    { label: "Directory", link: "/home/directory/funeral-homes" },
    { label: "Regional Reps", link: "/home/directory/policies" },
  ];

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const fetchRegionalReps =useCallback( async (page = 1, search = "", filters?: any) => {
    setLoading(true);
    const offset = (page - 1) * pageSize;
    try {
      const response = await getRegionalRepList(offset, pageSize, search, sortBy, sortDirection, filters);
      setData(response.data.data.data);
      setTotalRecords(response.data.data.totalRecords);
      setTimeout(() => setLoading(false), 1000);
    } catch (error) {
      setError('Failed to fetch regional reps');
      setLoading(false);
    }
  },
  [ pageSize,sortBy, sortDirection] );

  useEffect(() => {
    if(searchText !== null){
      setCurrentPage(1);
      fetchRegionalReps(1, searchText, appliedFilters);  
    }
  }, [searchText, fetchRegionalReps, appliedFilters]);  

  useEffect(() => {
    fetchRegionalReps(currentPage, searchText, appliedFilters);  
  }, [currentPage, searchText,sortBy, sortDirection, appliedFilters,fetchRegionalReps]);  

  const formatAddress = (address: any) => {
    const street = address?.street || "N/A";
    const city = address?.City?.name || "N/A";
    const state = address?.State?.name || "N/A";
    const zipcode = address?.zipcode || "N/A";
    return `${street}, ${city}, ${state}, ${zipcode}`;
  };

  const formattedData = useMemo(() => {
    if (Array.isArray(data)) {
      return data.map((item) => ({
        'id': item.id,
        'Name': item.name,
        'Address': formatAddress(item.address),
        'Email Address': item.email,
        'Cell Phone Number': item.phone_number,
        'Region/State/Agencies Assigned': item.region || "-",
      }));
    } else {
      return [];
    }
  }, [data]);
   const showLoading = useMemo(() => loading, [loading]);
  useSetLoading(showLoading);


  if (error) {
    return <div>{error}</div>;
  }
 
  const mapColumnNameToField = (columnName: string): string => {
    const mapping: Record<string, string> = {
      'Name': "name",
      'Address': "address.street",
      'Email Address': "email",
      'Cell Phone Number': "phone_number",
      'Region/State/Agencies Assigned': "region",

    };

    return mapping[columnName] || columnName;
  };

  const handleSort = (columnName: string) => {
    const mappedField = mapColumnNameToField(columnName);
    if (sortBy === mappedField) {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortBy(mappedField);
      setCloumnName(columnName);
      setSortDirection("asc");
    }
  };
  const handleDownload = async () => {
    setLoading(true);
    try {
      await exportRegionalRepList({searchText: searchText, sortBy: sortBy, sortDirection: sortDirection}, appliedFilters); 
      // const blob = new Blob([response.data], { type: "application/vnd.ms-excel" });
      // const link = document.createElement('a');
      // link.href = URL.createObjectURL(blob);
      // link.download = 'regional_reps_data.xlsx'; 
      // link.click();
      setTimeout(() => setLoading(false), 1000);
      setAlertMessage("Sent Downloadable link to Email , Please check");
      setAlertType("success");
      setAlertOpen(true);
    } catch (error) {
      setAlertMessage("Failed to download RegionalReps data");
      setAlertType("error");
      setAlertOpen(true);
      setError('Failed to download file');
      setLoading(false);
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  
  return (
    <Container>
      <AlertComponent
        message={alertMessage}
        type={alertType}
        open={alertOpen}
        onClose={handleAlertClose}
      />
      <BreadcrumbComponent breadcrumbLink={crumbMapping} />
      <SearchBar
        placeholder={`Search by name, address, email etc..`}
        toggleAddEdit={toggleAddEdit}
        onSearch={handleSearch}
        module="Regional Reps"
        onDownload={handleDownload}
        isReset={isReset}
        toggleFilter={toggleFilters}
        appliedFilters={appliedFilters}
      />
      {formattedData.length > 0 ? (
      <>
      <div className={"regional-reps-table"}>
        <TableComponent
          columnNames={columnNames}
          toggleAddEdit={toggleAddEdit}
          rowsData={formattedData}
          handleEditClick={handleEditClick}
          handleViewClick={handleViewClick}
          handleSort={handleSort}
          sortedColumn={columnName}
          sortDirection={sortDirection}
        />
      </div>
      <PaginationComponent
        totalRecords={totalRecords}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
      </>
      ) : (
        !loading && (
          <div className="no-results">
            <p>No results found</p>
          </div>
        )
      )}
      {addOrEditOpen && (
        <AddOrEditRegionalReps
          isOpen={addOrEditOpen}
          toggle={() => {
            toggleAddEdit("");
          }}
          mode={addOrEditOpenMode}
          editData={editData}
          refreshData={()=>{
            setSearchText(null);
            setCurrentPage(1);
            setIsReset(!isReset)
            fetchRegionalReps();
          }} 
        />
      )}
      {viewOpen && (
        <ViewRegionalReps
          isOpen={viewOpen}
          viewData={editData}
          toggleView={toggleView}
        />
      )}
      {isFilterOpen && (
        <FilterRegionalReps
          isOpen={isFilterOpen}
          toggleFilter={toggleFilters}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
        />
      )}
    </Container>
  );
};

export default RegionalReps;
