/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react';
import { FormGroup, Label, Button } from 'reactstrap';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import * as Yup from 'yup';
import { Form, Formik, ErrorMessage } from 'formik';
import './upload-guides-video.scss';
import closeIcon from '../../../assets/icons/closeIconeVideo.svg';
import uploadGroup from '../../../assets/icons/uploadGroup.svg';
import { addVideo } from '../../../api/guiders';
import { SelectDropdownComponent, InputComponent } from '../../../common/components';

const UploadGuidesVideo = (props: any) => {
  const fileThumbnailInputRef: any = useRef();
  const fileVideoInputRef: any = useRef();
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      width: '100%',
      height: '45.59px',
      borderColor: '#ffffff',
      backgroundColor: '#F7F7F7',
      boxShadow: '#ffffff',
      '&:hover': {
        borderColor: '#ffffff',
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      width: '100%',
    }),
    option: (styles: any, state: any) => ({
      ...styles,
      textAlign: 'left',
      backgroundColor: state.isSelected ? '#887952' : 'white',
      ':hover': {
        backgroundColor: state.isSelected ? '#887952' : '#EEEE',
      },
    }),
  };

  const categoriesList = [
    {
      label: 'Contracts',
      value: 'Contracts',
    },
    {
      label: 'Dashboard',
      value: 'Dashboard',
    },
    {
      label: 'Directory',
      value: 'Directory',
    },
    {
      label: 'Leads',
      value: 'Leads',
    },
    {
      label: 'Miscellaneous',
      value: 'Miscellaneous',
    },
    {
      label: 'Reports',
      value: 'Reports',
    },
    {
      label: 'Search & Filter',
      value: 'Search & Filter',
    },
    {
      label: 'Users',
      value: 'Users',
    },
  ];

  const initialValues = {
    nameOfVideo: '',
    categoryOfVideo: null,
    thumbnailOfVideo: null,
    thumbnailOfVideoName: '',
    isUrlOfVideo: false,
    urlOfVideo: null,
    uploadOfVideo: null,
    uploadOfVideName: '',
  };

  const validationSchema = Yup.object({
    nameOfVideo: Yup.string().required('Video name is required'),
    categoryOfVideo: Yup.mixed<any>().required('Video Category is required'),
    thumbnailOfVideo: Yup.mixed<File>()
      .required('Thumbnail of video is required')
      .test('fileSize', 'Thumbnail size should be less than 2MB', (value) => {
        if (!value) return false;
        return value.size <= 1 * 1024 * 1024;
      })
      .test('fileFormat', 'Thumbnail format must be jpeg, png', (value) => {
        if (!value) return true;
        return value.type === 'image/png' || value.type === 'image/jpeg';
      }),
    uploadOfVideo: Yup.mixed<File>()
      .required('Video is required')
      .test('fileFormat', 'Video format must be MP4', (value) => {
        if (!value) return true;
        return value.type === 'video/quicktime' || value.type === 'video/mp4';
      }),
  });

  const onSubmit = async (values: any) => {
    try {
      props?.setIsLoading(true);
      const formData = new FormData();
      formData.append('category', values?.categoryOfVideo?.label);
      formData.append('name', values?.nameOfVideo);
      formData.append('thumbnail', values?.thumbnailOfVideo);
      formData.append('video', values?.uploadOfVideo);
      await addVideo(formData);
      props?.onClose();
      props?.setIsLoading(false);
      props?.refreshData();
    } catch (error) {
      console.log(error);

      props?.setIsLoading(false);
    }
  };

  return (
    <div className="upload-guides-video-popup-overlay">
      <div className="upload-guides-video-popup">
        <div className="upload-guides-video-head">
          <div className="upload-guides-video-head-text">Add Guide Video</div>
          <div className="upload-guides-video-close-btn">
            <img src={closeIcon} onClick={props?.onClose} alt="Close" />
          </div>
        </div>
        <div className="upload-guides-video-section">
          <div className="upload-guides-video-row-section">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={onSubmit}
              isValidating={true}
              setSubmitting={true}
              isSubmitting={true}
            >
              {({ values, setFieldValue, setFieldTouched }) => (
                <Form className="upload-guides-video-form-section">
                  <div className="upload-guides-video-form-dropdown-section">
                    <div className="upload-guides-video-form-dropdown">
                      <InputComponent
                        fieldName="nameOfVideo"
                        label="Name of Video"
                        placeholder="Enter name of video"
                        optional={false}
                        isAlpha
                      />
                    </div>
                    <div className="upload-guides-video-form-dropdown">
                      <FormGroup className="add-or-edit-form-group">
                        <Label htmlFor="category" className="custom-form-label">
                          Category <span>*</span>
                        </Label>
                        <SelectDropdownComponent
                          placeholder="Select Category"
                          options={categoriesList}
                          customStyles={customStyles}
                          isMulti={false}
                          value={values?.categoryOfVideo}
                          onChange={(selected) => {
                            setFieldValue('categoryOfVideo', selected);
                          }}
                        />
                        <ErrorMessage name="categoryOfVideo" component="div" className="error-message" />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="upload-guides-video-form-video-section">
                    <div className="upload-guides-video-form-video-right">
                      <div className="upload-guides-video-form-video-right-box">
                        <div className="upload-guides-video-form-video-right-box-img">
                          <img src={uploadGroup} alt="Upload" />
                        </div>
                        {/* {values?.isUrlOfVideo ? 
                                            <div className='url-of-video'>
                                                <OutlinedInput
                                                    id="urlOfVideo"
                                                    type={"text" }
                                                    placeholder="Enter URL"
                                                    onChange={(data)=>{
                                                        setFieldValue('urlOfVideo', data?.target?.value); 
                                                    }}
                                                    autoComplete="off"
                                                    endAdornment={
                                                    <InputAdornment className={""}  onClick={()=>setFieldValue("isUrlOfVideo", false)}  position="end">
                                                        <img src={closeIcon} alt="Toggle" />
                                                    </InputAdornment>

                                                    }
                                                    label={""}
                                                    className={'url-of-video-input'}
                                                    sx={{
                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: '#F7F7F7',
                                                        borderWidth: "0px",
                                                    },
                                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                        borderColor: '#F7F7F7',
                                                        borderWidth: "10px",
                                                    },
                                                    }}
                                                />
                                                <ErrorMessage name="urlOfVideo" component="div" className={""} />
                                            </div>:
                                            <div className="upload-guides-video-form-video-right-box-text-link">
                                                Click to Paste the <span onClick={()=>setFieldValue("isUrlOfVideo", true)}>Video URL here</span>
                                            </div>}
                                        <div className="upload-guides-video-form-video-right-box-text-link">
                                            Or
                                        </div> */}
                        {values?.uploadOfVideName ? (
                          <div className="url-of-video">
                            <OutlinedInput
                              id="uploadOfVideo"
                              type={'text'}
                              placeholder=""
                              value={values?.uploadOfVideName}
                              autoComplete="off"
                              endAdornment={
                                <InputAdornment
                                  className={'url-of-video-close'}
                                  onClick={() => {
                                    setFieldValue('uploadOfVideo', null);
                                    setFieldValue('uploadOfVideName', '');
                                  }}
                                  position="end"
                                >
                                  <img src={closeIcon} alt="Toggle" />
                                </InputAdornment>
                              }
                              label={''}
                              className={'url-of-video-input'}
                              sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                  borderColor: '#F7F7F7',
                                  borderWidth: '0px',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  borderColor: '#F7F7F7',
                                  borderWidth: '10px',
                                },
                              }}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                        <div className="upload-guides-video-form-video-right-box-add-button">
                          <FormGroup>
                            <input
                              type="file"
                              accept="video/*"
                              style={{ display: 'none' }}
                              id="uploadOfVideo"
                              onChange={(event) => {
                                const { files } = event.currentTarget;
                                if (files && files[0]) {
                                  setFieldValue('uploadOfVideo', files[0]);
                                  setFieldValue('uploadOfVideName', files[0]?.name);
                                  setFieldTouched('uploadOfVideo', false, true);
                                }
                              }}
                              multiple={false}
                              ref={fileVideoInputRef}
                              hidden
                            />
                            <label htmlFor="uploadOfVideo">
                              <Button
                                variant="contained"
                                className={'btn'}
                                color="primary"
                                component="span"
                                onClick={() => {
                                  if (!values?.uploadOfVideo) {
                                    setFieldValue('urlOfVideo', '');
                                    setFieldValue('isUrlOfVideo', false);
                                    fileVideoInputRef.current.value = '';
                                    fileVideoInputRef.current.click();
                                  } else {
                                    setFieldValue('uploadOfVideo', null);
                                    setFieldValue('uploadOfVideName', '');
                                    fileVideoInputRef.current.value = '';
                                  }
                                }}
                              >
                                {values?.uploadOfVideo ? 'Remove Video' : 'Add Video'}
                              </Button>
                            </label>
                            <ErrorMessage name="uploadOfVideo" component="div" className="error-message" />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="upload-guides-video-form-video-left">
                      <div className="upload-guides-video-form-video-left-box">
                        {values?.thumbnailOfVideoName ? (
                          <div className="url-of-video">
                            <OutlinedInput
                              id="thumbnailOfVideo"
                              type={'text'}
                              placeholder=""
                              value={values?.thumbnailOfVideoName}
                              autoComplete="off"
                              endAdornment={
                                <InputAdornment
                                  className={'url-of-video-close'}
                                  onClick={() => {
                                    setFieldValue('thumbnailOfVideo', null);
                                    setFieldValue('thumbnailOfVideoName', '');
                                  }}
                                  position="end"
                                >
                                  <img src={closeIcon} alt="Toggle" />
                                </InputAdornment>
                              }
                              label={''}
                              className={'url-of-video-input'}
                              sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                  borderColor: '#F7F7F7',
                                  borderWidth: '0px',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  borderColor: '#F7F7F7',
                                  borderWidth: '10px',
                                },
                              }}
                            />
                          </div>
                        ) : (
                          ''
                        )}
                        <div className="upload-guides-video-form-video-left-box-add-button">
                          <FormGroup>
                            <input
                              type="file"
                              accept="image/*"
                              style={{ display: 'none' }}
                              id="thumbnailOfVideo"
                              onChange={(event) => {
                                const { files } = event.currentTarget;
                                if (files && files[0]) {
                                  setFieldValue('thumbnailOfVideo', files[0]);
                                  setFieldValue('thumbnailOfVideoName', files[0]?.name);
                                  setFieldTouched('thumbnailOfVideo', false, true);
                                }
                              }}
                              multiple={false}
                              ref={fileThumbnailInputRef}
                              hidden
                            />
                            <label htmlFor="thumbnailOfVideo">
                              <Button
                                variant="contained"
                                className={'btn'}
                                color="primary"
                                component="span"
                                onClick={() => {
                                  if (!values?.thumbnailOfVideo) {
                                    fileThumbnailInputRef.current.click();
                                    fileThumbnailInputRef.current.value = '';
                                  } else {
                                    setFieldValue('thumbnailOfVideo', null);
                                    setFieldValue('thumbnailOfVideoName', '');
                                  }
                                }}
                              >
                                {values?.thumbnailOfVideo ? 'Remove Thumbnail' : 'Add Thumbnail'}
                              </Button>
                            </label>
                            <ErrorMessage name="thumbnailOfVideo" component="div" className="error-message" />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="upload-guides-video-bottom">
                    <button className="cancel-button" onClick={props?.onClose}>
                      Cancel
                    </button>
                    <button className="save-button" type="submit">
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadGuidesVideo;
