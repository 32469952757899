import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSetLoading } from '../../common/hooks/appearance';
import Logo from "../../assets/icons/bg_logo.svg";
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { passwordRules } from '../../constants/regex-string';
import * as setPasswordAPI from '../../api/set-password';
import Password from "../../assets/icons/password_hidden.svg";
import StopwatchDisplay from '../../common/components/stop-watch-display/stop-watch-display';
import './set-password.scss';
import { routes } from '../../../app/constants/routes';

const SetPassword: React.FC = () => {
  const [step, setStep] = useState<'invalid' | 'valid'>('invalid');
  const [message, setMessage] = useState<any | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [stopwatchValue, setStopwatchValue] = useState(0);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const passwordInitialValues = { temporaryPassword: '', newPassword: '', confirmNewPassword: '' };
  const passwordValidationSchema = Yup.object({
    temporaryPassword: Yup.string().required('Temporary password is required'),
    newPassword: Yup.string()
      .required('New password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(passwordRules, { message: "Please create a stronger password" }),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], 'Passwords must match')
      .required('Confirm password is required')
      .matches(passwordRules, { message: "Please create a stronger password" }),
  });
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const togglePasswordVisibilityConfirm = () => {
    setShowPasswordConfirm((prevState) => !prevState);
  };

  const handleResetSubmit = async (values: typeof passwordInitialValues) => {
    setIsLoading(true);
    try {
      const payload = { token: token, temporaryPassword: values?.temporaryPassword, password: values?.confirmNewPassword };
      const response: any = await setPasswordAPI?.setPassword(payload);
      toggleStopwatchValue(response?.data?.message, 'success');
    } catch (error:any ) {
      setIsLoading(false);
      setMessage({message: error?.response?.data?.message, types: 'error'});
    }
  };

  const toggleStopwatchValue =useCallback( (message: string, types: string) => {
    setIsLoading(false);
    setStep('invalid');
    setMessage({ message, types });
  
    const stopwatchTimer = setInterval(() => {
      setStopwatchValue((prevState) => prevState + 10);
    }, 10);
    setTimeout(() => {
      clearInterval(stopwatchTimer);
      setStopwatchValue(0);
     if (message === 'Temporary password has been expired. Please try forgot password and reset your password.') {
        navigate(routes.FORGOTPASSWORD);
      } else {
        navigate(routes.LOGIN);
      }
    }, 10000);
  }, [setIsLoading, setStep, setMessage, setStopwatchValue, navigate]);
  const handleValidationToken =useCallback( async (payload: any) => {
    setIsLoading(true);
    try {
      await setPasswordAPI?.verifyTemporaryPasswordAndExpiration({token: payload});
      setStep('valid');
      setIsLoading(false);
    } catch (error: any) {
      toggleStopwatchValue(error?.response?.data?.message, 'error');
    }
  },[toggleStopwatchValue]);
  useEffect(() => {
    const tokenFromUrl = searchParams.get('token');
    if (tokenFromUrl) {
      handleValidationToken(tokenFromUrl)
      setToken(tokenFromUrl);
    }
  }, [searchParams,handleValidationToken]);
  const showLoading = useMemo(() => isLoading, [isLoading]);
  useSetLoading(showLoading);
  return (
    <div className="set-password">
      <div className={`set-password-container`}>
      <h2 className={`set-password-heading`}><img className={`set-password-heading-icon`} src={Logo} alt="AFP Logo" /></h2>
      <h2 className={`set-password-heading-text`}>Set Password</h2>
      {step === 'invalid' ? (
        message?.message ?
        <div className="timer-error-msg-container">
            <div className={`${message?.types === 'error' ?`set-password-error-message` : `set-password-success-message`}`}>{message?.message}</div>
            <StopwatchDisplay value={stopwatchValue}/>
        </div> : ""
      ): (
        <Formik 
          initialValues={passwordInitialValues}
          validationSchema={passwordValidationSchema}
          onSubmit={handleResetSubmit}
        >
          <Form className={`set-password-form`}>
            {message?.message ? <div className={`set-password-error-message`}>{message?.message}</div> : ""}
            <div className={"set-password-input-div"}>
              <div className="set-password-input-field">
              <Field type={showPassword ? "text" : "password"} name="temporaryPassword" placeholder="Temporary Password *" className={`set-password-confirm-password-field`} />
              <span
                  className={"set-password-toggle-icon-styles"}
                  onClick={togglePasswordVisibility}
                >
                 <img src={showPassword ? Password : Password} alt="Toggle Password Visibility" />
                </span>
              </div>
              <ErrorMessage name="temporaryPassword" component="div" className={`set-password-field-error-message`} />
            </div>
            <div className={"set-password-input-div"}>
              <div className="set-password-input-field">
              <Field type={showPassword ? "text" : "password"} name="newPassword" placeholder="New Password *" className={`set-password-confirm-password-field`} />
              <span
                  className={"set-password-toggle-icon-styles"}
                  onClick={togglePasswordVisibility}
                >
                 <img src={showPassword ? Password : Password} alt="Toggle Password Visibility" />
                </span>
              </div>
              <ErrorMessage name="newPassword" component="div" className={`set-password-field-error-message`} />
            </div>
            <div className={"set-password-input-div"}>
              <div className="set-password-input-field">
                <Field 
                    type={showPasswordConfirm ? "text" : "password"}
                    name="confirmNewPassword" 
                    placeholder="Confirm Password *" 
                    className={`set-password-confirm-password-field`}/>
                  <span
                    className={"set-password-toggle-icon-styles"}
                    onClick={togglePasswordVisibilityConfirm}
                  >
                  <img src={showPasswordConfirm ? Password : Password} alt="Toggle Password Visibility" />
                  </span>
              </div>
              <ErrorMessage name="confirmNewPassword" component="div" className={`set-password-field-error-message`} />
            </div>
            <button type="submit" className={"set-password-button"}>
              Create New Password
            </button>
          </Form>
        </Formik>
      )}
      </div>
    </div>
  )
}

export default SetPassword;