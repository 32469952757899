import React from "react";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./date-picker.scss";
interface DatePickerProps {
  placeholder?: string;
  value?: Date | undefined;
  onChange?: (selected: Date | undefined) => void;
  customStyles?: any;
  maxDate?: Date;
  minDate?: Date;
}

const DatePicker: React.FC<DatePickerProps> = ({
  placeholder,
  value,
  onChange,
  customStyles,
  maxDate,
  minDate
}) => {
  const convertToDayjs = (date?: Date): Dayjs | null => {
    if (!date) return null;
    if (Array.isArray(date)) {
      return dayjs(date[1] || null);
    }
    return dayjs(date);
  };
  const handleSingleDateChange = (newValue: Dayjs | null) => {
    if (onChange) {
      if (newValue && newValue.isValid()) {
        let selectedDate = newValue.toDate();
        if (minDate && selectedDate < minDate) {
          selectedDate = minDate;
        }
        if (maxDate && selectedDate > maxDate) {
          selectedDate = maxDate;
        }
        onChange(selectedDate);
      } else {
        onChange(undefined);
      }
    }
  };
  const stylesToBeApplied = {
    "& .MuiInputBase-root": { bgcolor: "#F7F7F7", borderRadius: "6px" },
    "& .MuiOutlinedInput-root": {
      "& fieldset": { border: "none" },
      "&:hover fieldset": { border: "none" },
      "&.Mui-focused fieldset": { border: "none" },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px",
    },
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiDatePicker
        value={convertToDayjs(value) as Dayjs | undefined}
        onChange={handleSingleDateChange}
        maxDate={maxDate ? dayjs(maxDate) : undefined}
        minDate={minDate ? dayjs(minDate) : undefined}
        slotProps={{
          textField: {
            placeholder,
            sx: customStyles ? customStyles : stylesToBeApplied,
            inputProps: {
              readOnly: true,
            },
          },
        }}
        format="MM-DD-YYYY"
      />
    </LocalizationProvider>
  );
};
export default DatePicker;
