/* eslint-disable  @typescript-eslint/no-explicit-any */
import AdditionalLocations from './view-funeral-homes-bottom-section/tabs/additional-locations/additional-locations';
import Companies from './view-funeral-homes-bottom-section/tabs/companies/companies';
import CoOwner from './view-funeral-homes-bottom-section/tabs/co-owner/co-owner';
import SpecialInstructions from './view-funeral-homes-bottom-section/tabs/special-instructions/special-instructions';
import Notes from './view-funeral-homes-bottom-section/tabs/notes/notes';
// import Contracts from "./view-funeral-homes-bottom-section/tabs/contracts/contracts";
import AdditionalIds from './view-funeral-homes-bottom-section/tabs/additional-ids/additional-ids';
import Correspondance from './view-funeral-homes-bottom-section/tabs/correspondance/correspondance';
import AuthorizedRepresentative from './view-funeral-homes-bottom-section/tabs/authorized-representative/authorized-representative';

export const tabName = (shouldDisplayTab: any) => {
  return [
    'Additional Locations',
    'Companies',
    'Co-Owner',
    'Special Instructions',
    ...(shouldDisplayTab ? ['Notes'] : []),
    // 'Contracts',
    'Additional IDs',
    'Correspondence',
    'Authorised Representative',
  ];
};

export const tabsToBeDisplayed: any = (shouldDisplayTab: any) => [
  { label: 'Additional Locations', component: AdditionalLocations },
  { label: 'Companies', component: Companies },
  { label: 'Co-Owner', component: CoOwner },
  { label: 'Special Instructions', component: SpecialInstructions },
  ...(shouldDisplayTab ? [{ label: 'Notes', component: Notes }] : []),
  // { label: "Contracts", component: Contracts },
  { label: 'Additional IDs', component: AdditionalIds },
  { label: 'Correspondence', component: Correspondance },
  { label: 'Authorised Representative', component: AuthorizedRepresentative },
];

export const quickLinkItems: Record<string, string> = {
  'Add Additional Location': 'Additional Locations',
  'Add more owner': 'Co-Owner',
  Notes: 'Notes',
  'Special Instructions': 'Special Instructions',
};
