import React from "react";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import closeIcon from "../../../assets/icons/closeIcon.svg";
import { ViewComponentProps } from "../../directory/types/view-signature-props";

const ViewContractSignature: React.FC<ViewComponentProps> = ({
    viewData,
    isOpen,
    toggleView,
}) =>{

  return (
    <div className="add-or-edit-offcanvas">
        <Offcanvas isOpen={isOpen} direction="end" className="view-funeral-homes">
            <OffcanvasHeader className="offcanvas-header">
                <div className="header-div">
                    <h2 className="mb-0">Contract Signature Details</h2>
                    <button onClick={()=>toggleView(viewData.id)}>
                    <img src={closeIcon} alt="Close" />
                    </button>
                </div>
            </OffcanvasHeader>
            <OffcanvasBody className="offcanvas-body">
                <div className="view-form">
                <div className="form-group">
                    <label>Signature Name</label>
                    <div className="data-value">{viewData?.signature_name ||"-"}</div>
                </div>
                <div className="form-group">
                    <label>First name</label>
                    <div className="data-value">{viewData?.first_name || "-"}</div>
                </div>
                <div className="form-group">
                    <label>Last name</label>
                    <div className="data-value">{viewData?.last_name || "-"}</div>
                </div>
                <div className="form-group">
                    <label>Designation</label>
                    <div className="data-value scrollable-text">{viewData?.designation || "_" }</div>
                </div>
                <div className="form-group">
                    <label>Signature Image</label>
                    <div className="data-value">
                        <img src={viewData?.signature_image_url} alt={"signature"}/>
                    </div>
                </div>
                </div>
            </OffcanvasBody>
        </Offcanvas>
    </div>
  );
};

export default ViewContractSignature;
