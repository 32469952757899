import { createNamespacer, createReducer } from '../utils/reducers';

const initialState = {
  isLoading: [],
  appDataLoading: false,
  showAdvanceSearch: false
};

const namespacer = createNamespacer('APPEARANCE');

const handlers = {
  [`${namespacer('SET_LOADING')}`]: (state:any, action:any) => {
    const { value } = action.payload;
    const isLoading = [...state.isLoading];
    if (value) {
      isLoading.push(value);
    } else {
      isLoading.pop();
    }
    return {
      ...state,
      isLoading
    };
  },
  [`${namespacer('SET_APP_DATA_LOADING')}`]: (state:any, action:any) => {
    return {
      ...state,
      appDataLoading: action.payload.value
    };
  },
  [`${namespacer('SHOW_ADVANCED_SEARCH')}`]: (state:any, action:any) => {
    return {
      ...state,
      showAdvanceSearch: !state.showAdvanceSearch
    };
  }
};

const appearanceReducer = createReducer(initialState, handlers, ['APPEARANCE']);

export default appearanceReducer;
