import React, { useState, useMemo } from "react";
import { AddOrEditComponentProps } from "../../directory/types/add-or-edit-props";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap";
import { ErrorMessage, Form, Formik } from "formik";
import { InputComponent } from "../../../common/components";
import closeIcon from "../../../assets/icons/closeIcon.svg";
import {
  addContractSignature,
  removeContractSignature,
  updateContractSignature,
  uploadContractSignatureFile,
} from "../../../api/contractSignature";
import DeleteConfirmationPopup from "../../directory/delete-confirmation-popup/delete-confirmation-popup";
import * as Yup from "yup";
import AlertComponent from "../../../common/components/Alert/Alert";
import { useSetLoading } from "../../../common/hooks/appearance";
import "./contracts-signature.scss";

const AddOrEditContractSignature: React.FC<AddOrEditComponentProps> = ({
  isOpen,
  mode,
  toggle,
  refreshData,
  editData,
}) => {
  const [isDeletePopupOpen, setDeletePopupOpen] = useState<boolean>(false);
  const [deleteSignatureId, setDeleteSignatureId] = useState<number | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [signatureImageUrl, setSignatureImageUrl] = useState<string>("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<
    "success" | "error" | "info" | "warning"
  >("success");
  const [fileError, setFileError] = useState<string>("");

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleDelete = async (deleteSignatureId: number | null) => {
    try {
      if (deleteSignatureId === null) {
        console.error("No Siganture ID provided for deletion");
        return;
      }
      const response = await removeContractSignature(deleteSignatureId);
      if (response.data.success && refreshData) {
        refreshData();
      }
      setDeletePopupOpen(false);
      toggle();
    } catch (error) {
      console.error("Error while deleting Contract Signature:", error);
      setAlertMessage("Failed to delete Contract Signature.");
      setAlertType("error");
      setAlertOpen(true);
    }
  };

  const initialValues =
    editData && mode === "edit"
      ? {
          signatureName: editData.signature_name,
          firstName: editData.first_name,
          lastName: editData.last_name,
          designation: editData.designation,
          signatureImgUrl: editData.signature_image_url
            ? editData.signature_image_url.split("/").pop()?.split("-").pop() ||
              "Existing File"
            : null,
          file: "",
        }
      : {
          signatureName: "",
          firstName: "",
          lastName: "",
          designation: "",
          signatureImgUrl: "",
          file: "",
        };

  const validateImageDimensions = (
    file: any,
    maxWidth: number,
    maxHeight: number
  ): Promise<boolean> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const { width, height } = img;
        resolve(width <= maxWidth && height <= maxHeight);
      };
      img.onerror = () => {
        resolve(false);
      };
      img.src = URL.createObjectURL(file);
    });
  };

  const validationSchema = Yup.object({
    signatureName: Yup.string()
      .min(3, "Signature Name must be at least 3 characters")
      .required("Signature Name is required"),
    firstName: Yup.string()
      .matches(/^[A-Za-z .]+$/, "First Name must only contain alphabets")
      .min(2, "First Name must be at least 2 characters")
      .required("First Name is required"),
    lastName: Yup.string()
      .matches(/^[A-Za-z .]+$/, "Last Name must only contain alphabets")
      .min(2, "Last Name must be at least 2 characters")
      .required("Last Name is required"),
    designation: Yup.string()
      .min(3, "Designation must be at least 3 characters")
      .required("Designation is required"),
    file:
      mode === "edit"
        ? Yup.mixed().notRequired()
        : Yup.mixed<File>()
            .required("A file is required")
            .test(
              "fileSize",
              "File size should be less than 2MB",
              (value) =>
                !value ||
                (value instanceof File && value.size <= 2 * 1024 * 1024)
            )
            .test(
              "fileFormat",
              "File format must be PNG, JPG, JPEG, or HEIF",
              (value) =>
                !value ||
                (value instanceof File &&
                  [
                    "image/png",
                    "image/jpg",
                    "image/jpeg",
                    "image/heif",
                  ].includes(value.type))
            )
            .test(
              "fileValidation",
              "Image dimensions must be 200x100 pixels or smaller",
              async (value) => {
                if (!value || !(value instanceof File)) {
                  return true;
                }
                const dimensionsValid = await validateImageDimensions(
                  value,
                  200,
                  100
                );
                return dimensionsValid;
              }
            ),
  });

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    mode: "add" | "edit" | null,
    setFieldValue: (field: string, value: any) => void,
    setFieldError: (field: string, message: string | undefined) => void
  ) => {
    const { files } = event.currentTarget;
  
    if (!files || files.length === 0) {
      setFieldValue("file", null);
      setFileError("");
      setFieldError("file", "");
      return;
    }
  
    const file = files[0];
  
    if (mode === "add") {
      setFieldValue("file", file);
    } else if (mode === "edit") {
      handleFileUpload(files, setFieldError);
    }
  };

  const handleFileUpload = async (
    files: FileList | null,
    setFieldError: any
  ) => {
    if (!files || files.length === 0) {
      return;
    }
    const file = files[0];
    try {
      if (file.size > 2 * 1024 * 1024) {
        setFileError("File size should be less than 2MB.");
        setAlertType("error");
        return;
      }
      const validFormats = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "image/heif",
      ];
      if (!validFormats.includes(file.type)) {
        setFileError("File format must be PNG, JPG, JPEG, or HEIF.");
        setAlertType("error");
        return;
      }
      const isValidDimensions = await validateImageDimensions(file, 200, 100);
      if (!isValidDimensions) {
        setFileError("Image dimensions must be 200x100 pixels or smaller.");
        setAlertType("error");
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      setIsLoading(true);
      const response = await uploadContractSignatureFile(editData.id, formData);
      setIsLoading(false);
      if (response.data.success) {
        const imageUrl = response.data.data.signatureImageUrl;
        setSignatureImageUrl(imageUrl);
        setAlertMessage("File Uploaded Successfully");
        setAlertType("success");
        setFieldError("file", "");
        setFileError("");
        setAlertOpen(true);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      setAlertMessage("Failed to Upload File");
      setAlertType("error");
      setAlertOpen(true);
    }
  };

  const onSubmit = async (
    values: typeof initialValues,
    { resetForm }: any
  ) => {
    if (fileError !== "") {
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("signatureName", values.signatureName);
    formData.append("firstName", values.firstName);
    formData.append("lastName", values.lastName);
    formData.append("designation", values.designation);
    if (editData && mode === "edit") {
      formData.append(
        "signatureImgUrl",
        signatureImageUrl ? signatureImageUrl : editData.signature_image_url
      );
    } else {
      formData.append("file", values.file);
    }

    try {
      if (editData && mode === "edit") {
        const response = await updateContractSignature(editData.id, formData);
        setIsLoading(false);
        if (response.data.success && refreshData) {
          refreshData();
        }
      } else {
        const response = await addContractSignature(formData);
        setIsLoading(false);
        if (response.data.success && refreshData) {
          refreshData();
        }
      }
      resetForm();
      toggle();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const showLoading = useMemo(() => isLoading, [isLoading]);
  useSetLoading(showLoading);

  return (
    <>
      <AlertComponent
        message={alertMessage}
        type={alertType}
        open={alertOpen}
        onClose={handleAlertClose}
      />
      <div className="add-or-edit-offcanvas">
        <Offcanvas isOpen={isOpen} direction="end" className="offcanvas">
          <OffcanvasHeader className="offcanvas-header">
            <div className="header-div">
              <h2 className="mb-0">
                {" "}
                {mode === "edit" ? "Edit" : "Add"} Contract Signature
              </h2>
              <button onClick={toggle}>
                <img src={closeIcon} alt="Close" />
              </button>
            </div>
          </OffcanvasHeader>
          <OffcanvasBody className="offcanvas-body">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize={true}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, values, setFieldValue, setFieldError }) => (
                <Form className="add-or-edit-form">
                  <InputComponent
                    fieldName="signatureName"
                    label="Signature Name"
                    placeholder="Enter Signature Name"
                    optional={false}
                    isAlpha
                  />
                  <InputComponent
                    fieldName="firstName"
                    label="First Name"
                    placeholder="Enter First Name"
                    optional={false}
                    isAlpha
                  />
                  <InputComponent
                    fieldName="lastName"
                    label="Last Name"
                    placeholder="Enter Last Name"
                    optional={false}
                    isAlpha
                  />
                  <InputComponent
                    fieldName="designation"
                    label="Designation"
                    placeholder="Enter Designation"
                    optional={false}
                    isAlphaNumeric
                  />
                  {editData && (
                    <div className="existing-file">
                      <label className="existing-file-label">
                        Existing File:
                      </label>
                      <div className="data-value">
                        {values.signatureImgUrl || "-"}
                      </div>
                    </div>
                  )}
                  <FormGroup className="add-or-edit-form-group">
                    <Label
                      htmlFor="signatureImageUrl"
                      className="custom-form-label"
                    >
                      {editData ? "New Signature Image" : "Signature Image"}
                      <span>*</span>
                    </Label>
                    <Input
                      type="file"
                      id="fileInput"
                      accept="image/*"
                      onChange={(event) =>
                        handleFileChange(event, mode, setFieldValue, setFieldError)
                      }
                    />
                    <ErrorMessage
                      name="file"
                      component="div"
                      className="error-message"
                    />
                    {fileError && (
                      <div className="error-message">{fileError}</div>
                    )}
                  </FormGroup>

                  <div className="form-button-container">
                    {editData ? (
                      <Button
                        type="button"
                        className="delete-button"
                        onClick={() => {
                          setDeleteSignatureId(editData.id);
                          setDeletePopupOpen(true);
                        }}
                      >
                        Delete
                      </Button>
                    ) : (
                      ""
                    )}
                    <button
                      type="button"
                      className={`cancel-button${
                        mode === "edit" ? "-in-edit" : ""
                      }`}
                      onClick={toggle}
                    >
                      Cancel
                    </button>
                    <button
                      className={
                        mode === "edit" ? "save-button-changes" : "save-button"
                      }
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {mode === "edit" ? "Save Changes" : "Save"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </OffcanvasBody>
        </Offcanvas>
      </div>
      <DeleteConfirmationPopup
        isOpen={isDeletePopupOpen}
        onClose={() => setDeletePopupOpen(!isDeletePopupOpen)}
        handleDelete={handleDelete}
        deleteId={deleteSignatureId}
        label="Signature"
      />
    </>
  );
};

export default AddOrEditContractSignature;
