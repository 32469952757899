import React from "react";
import {
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import closeIcon from "../../../assets/icons/closeIcon.svg";
import "../add-or-edit-directories.scss";
import { AddOrEditComponentProps } from "../types/add-or-edit-props";
const AddOrEditPolicies: React.FC<AddOrEditComponentProps> = ({ isOpen, toggle }) => {
  return (
    <Offcanvas isOpen={isOpen} direction="end" className="offcanvas">
      <OffcanvasHeader className="offcanvas-header">
        <div className="header-div">
          <h2 className="mb-0">Add Policies</h2>
          <button onClick={toggle}>
            <img src={closeIcon} alt="Close" />
          </button>
        </div>
      </OffcanvasHeader>
      <OffcanvasBody className="offcanvas-body">
        <Form className="add-or-edit-form">
          <FormGroup className="add-or-edit-form-group">
            <Label for="policyHolderName" className="custom-form-label">
            Policy Holder Name<span>*</span>
            </Label>
            <Input id="policyHolderName" placeholder="Enter policy holder name" />
          </FormGroup>
          <FormGroup className="add-or-edit-form-group">
            <Label for="policyNumber" className="custom-form-label">
            Policy Number<span>*</span>
            </Label>
            <Input id="policyNumber" placeholder="Enter policy number" />
          </FormGroup>
          <FormGroup className="add-or-edit-form-group">
            <Label for="ageOfInsured" className="custom-form-label">
            Age Of Insured<span>*</span>
            </Label>
            <Input id="ageOfInsured" placeholder="Enter age of insured" />
          </FormGroup>
          <FormGroup className="add-or-edit-form-group">
            <Label for="effectiveDate" className="custom-form-label">
            Effective Date
            </Label>
            <Input
              id="effectiveDate"
              placeholder="Enter effective date"
            />
          </FormGroup>
          <FormGroup className="add-or-edit-form-group">
            <Label for="status" className="custom-form-label">
            Status<span>*</span>
            </Label>
            <Input id="status" placeholder="Enter status" />
          </FormGroup>
          <FormGroup className="add-or-edit-form-group">
            <Label for="terminationDate" className="custom-form-label">
            Termination Date<span>*</span>
            </Label>
            <Input id="terminationDate" placeholder="Enter termination date" />
          </FormGroup>
          <FormGroup className="add-or-edit-form-group">
            <Label for="issuedFace" className="custom-form-label">
            Issued Face
            </Label>
            <Input id="issuedFace" placeholder="Enter issued face" />
          </FormGroup>
          <FormGroup className="add-or-edit-form-group">
            <Label for="currentFaceAmount" className="custom-form-label">
            Current Face Amount
            </Label>
            <Input id="currentFaceAmount" placeholder="Enter current face amount" />
          </FormGroup>
          <FormGroup className="add-or-edit-form-group">
            <Label for="state" className="custom-form-label">
            State
            </Label>
            <Input id="state" placeholder="Enter state" />
          </FormGroup>
          <FormGroup className="add-or-edit-form-group">
            <Label for="funeralHomeAssignedTo" className="custom-form-label">
            Funeral Home Assigned To
            </Label>
            <Input id="funeralHomeAssignedTo" placeholder="Enter funeral home assigned to" />
          </FormGroup>
          <FormGroup className="add-or-edit-form-group">
            <Label for="writingAgentName" className="custom-form-label">
            Writing Agent Name
            </Label>
            <Input id="writingAgentName" placeholder="Enter writing agent name" />
          </FormGroup>
          <FormGroup className="add-or-edit-form-group">
            <Label for="agentNumber" className="custom-form-label">
            Agent Number
            </Label>
            <Input id="agentNumber" placeholder="Enter agent number" />
          </FormGroup>
          <FormGroup className="add-or-edit-form-group">
            <Label for="product" className="custom-form-label">
            Product
            </Label>
            <Input id="product" placeholder="Enter product" />
          </FormGroup>
          <FormGroup className="add-or-edit-form-group">
            <Label for="planCode" className="custom-form-label">
            Plan Code
            </Label>
            <Input id="planCode" placeholder="Enter plan code" />
          </FormGroup>
          <FormGroup className="add-or-edit-form-group">
            <Label for="paymentPeriod" className="custom-form-label">
            Payment Period (SP,OP,3P,5P,7P,10P)
            </Label>
            <Input id="paymentPeriod" placeholder="Enter payment period (SP,OP,3P,5P,7P,10P)" />
          </FormGroup>
          <FormGroup className="add-or-edit-form-group">
            <Label for="planDescription" className="custom-form-label">
            Plan Description
            </Label>
            <Input
              id="planDescription"
              type="textarea"
              placeholder="Type Something"
              className="form-input-text-area"
            />
          </FormGroup>
          <FormGroup className="add-or-edit-form-group">
            <Label for="companyInsurer" className="custom-form-label">
            Company Insurer
            </Label>
            <Input id="companyInsurer" placeholder="Enter company insurer" />
          </FormGroup>
          <FormGroup className="add-or-edit-form-group">
            <Label for="growthRate" className="custom-form-label">
            Growth Rate
            </Label>
            <Input id="growthRate" placeholder="Enter growth rate" />
          </FormGroup>
          <FormGroup className="add-or-edit-form-group">
            <Label for="gender" className="custom-form-label">
            Gender
            </Label>
            <Input id="gender" placeholder="Enter gender" />
          </FormGroup>
          <FormGroup className="add-or-edit-form-group">
            <Label for="lastSSN/FullSSN" className="custom-form-label">
            Last 4 of SSN/Full SSN
            </Label>
            <Input id="lastSSN/FullSSN" placeholder="Enter last 4 of SSN/Full SSN" />
          </FormGroup>
          <FormGroup className="add-or-edit-form-group">
            <Label for="paidToDate" className="custom-form-label">
            Paid To Date
            </Label>
            <Input id="paidToDate" placeholder="Enter paid to date" />
          </FormGroup>
          <FormGroup className="add-or-edit-form-group">
            <Label for="lastPaymentDate" className="custom-form-label">
            Last Payment Date
            </Label>
            <Input id="lastPaymentDate" placeholder="Enter last payment date" />
          </FormGroup>
          <FormGroup className="add-or-edit-form-group">
            <Label for="commissionsPaidToWritingAgents/OverrideAgents" className="custom-form-label">
            Commissions Paid to Writing Agents/Override Agents
            </Label>
            <Input id="commissionsPaidToWritingAgents" placeholder="Enter commissions paid to writing agents/override agents" />
          </FormGroup>
          <div className="form-button-container">
            <Button className="cancel-button" onClick={toggle}>
              Cancel
            </Button>
            <Button className="save-button">Save</Button>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  );
};
export default AddOrEditPolicies;
