export const S3_IMPORT_TEMPLATE_FOLDER = "bulk-import-sample-template";

export const IMPORT_TEMPLATES = {
  Leads: "leads-import-template.xlsx",
  "Funeral Homes": "funeral-homes-import-template.xlsx",
  Agencies: "agencies-import-template.xlsx",
  Agents: "agents-import-template.xlsx",
  Companies: "companies-import-template.xlsx",
  "Regional Reps": "regional-reps-import-template.xlsx",
  Users: "users-import-template.xlsx",
};
