export  const formatAddress = (address: any) => {
    const street = address?.street || "";
    const city = address?.City?.name || "";
    const state = address?.State?.name || "";
    const zipcode = address?.zipcode || "";

    return `${street}, ${city}, ${state}, ${zipcode}`;
  };

export  const mapColumnNameToField = (columnName: string): string => {
    const mapping: Record<string, string> = {
      "Funeral Home":"name",
      "Physical Address":"physicalAddress.street",
      "Mailing Address":"mailingAddress.street",
      "Send Correspondence To":"send_correspondance_to",
      "Special Instructions":"special_instructions",
      "Tax ID":"tax_id",
      "Authorized Representative":"authorized_representative_name",
      "Phone Number":"authorized_representative_phn_no",
      "Fax Number":"authorized_representative_fax_no",
      "Email Address":"authorized_representative_email",
    };

    return mapping[columnName] || columnName;
  };
  