import * as Yup from 'yup';
import phone from 'phone';
import { emailValidationRegex } from '../../../../../../../../constants/regex-string';

export const validationSchema = Yup.object({
  name: Yup.string()
    .matches(/^[A-Za-z ´',-.()!&Åë]+$/, 'Authorized Representative Name can only contain alphabets, spaces, and special characters')
    .max(200, 'Authorized Representative Name can\'t be longer than 200 characters.')
    .required('Mandatory Field*'),
  contactNumber: Yup.object({
    countryCode: Yup.string().matches(/^\+\d{1,4}$/),
    phoneNumber: Yup.string()
      .matches(/^\d{10}$/, 'Number format is invalid')
      .required('Mandatory Field*')
      .test('is-valid-phone', 'Invalid number', (value, allValue) => {
        if (!value) return true;
        const { isValid } = phone(`${allValue?.parent?.countryCode || ''}-${value}`);
        return isValid;
      }),
  }),
  faxNumber: Yup.string()
    // .matches(/^[A-Za-z0-9]+$/, 'fax number can only contain alphabets and numbers')
    .max(20, 'fax number can\'t be longer than 20 characters.')
    .required('Mandatory Field*'),
  email: Yup.string()
    .matches(emailValidationRegex, { message: 'Invalid email address. Please provide a valid email.' })
    .required('Mandatory Field*'),
});
