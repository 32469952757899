/* eslint-disable  @typescript-eslint/no-explicit-any */
import { useRef, useMemo, useCallback } from 'react';
import { Formik, ErrorMessage } from 'formik';
import debounce from 'lodash/debounce';
import { Autocomplete, TextField } from '@mui/material';
import { addUpdateAdditionalLocationsDetails, getAutoFillDirectories } from '../../../../../../../../api/funeralhomes';
import { InputComponent } from '../../../../../../../../common/components/index';
import { formatAddress } from '../additional-locations.utils';
import { validationSchema } from './additional-locations-table.validation';
import { AdditionalLocationsTableCellFormProps } from './additional-locations-table-props';
import tickIcons from '../../../../../../../../assets/icons/tickIcons.svg';
import crossIcons from '../../../../../../../../assets/icons/crossIcons.svg';

const AdditionalLocationsTableCellForm: React.FC<AdditionalLocationsTableCellFormProps> = ({
  initialValues,
  colIndex,
  handleEditClick,
  setIsEditProgress,
  setLoading,
  funeralHomeId,
  setAlertOpen,
  setAlertType,
  setAlertMessage,
  handleGetAdditionalLocations,
}) => {
  const formRef: any = useRef();

  const handleAdditionalLocationsChange = (event: any, newValue: any, setFieldValue: any) => {
    if (newValue) {
      setFieldValue(`selectedFuneralHome`, { id: newValue.id, name: newValue.name });
      setFieldValue(`selectLocation`, formatAddress(newValue?.physicalAddress) || '-');
      setFieldValue(`ownerName`, newValue?.owner_name || '-');
      setFieldValue(`phoneNumber`, newValue?.contact_no || '-');
      setFieldValue(`emailAddress`, newValue?.email || '-');
      setFieldValue(`faxNumber`, newValue?.fax_number || '-');
      setFieldValue(`taxID`, newValue?.tax_id || '-');
    } else {
      setFieldValue(`selectedFuneralHome`, null);
      setFieldValue(`ownerName`, '');
      setFieldValue(`selectLocation`, '');
      setFieldValue(`phoneNumber`, '');
      setFieldValue(`emailAddress`, '');
      setFieldValue(`faxNumber`, '');
      setFieldValue(`taxID`, '');
    }
  };

  const handleAdditionalLocationFocus = (setFieldValue: any) =>
    setFieldValue(`additionalLocationFocusedField`, 'additionalLocationFocusedField');

  const fetchAdditionalLocationOptions = useCallback(async (value: string, setFieldValue?: any) => {
    try {
      if (value.length < 3) return;
      const response = await getAutoFillDirectories('funeralHome', value);
      const data: any = response.data.data;
      setFieldValue('funeralHomOptions', data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);

  const debouncedFetchAdditionalLocationOptions = useMemo(
    () => debounce(fetchAdditionalLocationOptions, 500),
    [fetchAdditionalLocationOptions],
  );

  const onSubmit = async (value: any) => {
    const payloadFormatted = { affiliateFuneralHomeId: value?.selectedFuneralHome?.id };
    try {
      setLoading(true);
      await addUpdateAdditionalLocationsDetails(funeralHomeId, value?.id, payloadFormatted);
      setLoading(false);
      handleGetAdditionalLocations();
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message
        ? error?.response?.data?.message
        : (typeof error === 'object' && error !== null && 'response' in error && (error as any).response?.data?.details?.[0]) ||
          (error instanceof Error ? error.message : 'An unexpected error occurred.');
      setAlertMessage(errorMessage);
      setAlertType('error');
      setAlertOpen(true);
      setLoading(false);
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    formRef?.current?.handleSubmit();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={formRef}
      isValidating={true}
      setSubmitting={true}
      isSubmitting={true}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => (
        <tr>
          <td key={colIndex} className={`${'additional-locations-table-sticky-column'}`}>
            <img
              className={`edit-form-icon`}
              src={tickIcons}
              alt="Edit Icon"
              onClick={() => {
                handleSubmit();
              }}
            />
            <img
              className={`edit-form-icon`}
              src={crossIcons}
              alt="Edit Icon"
              onClick={() => {
                setIsEditProgress('');
                handleEditClick?.(values?.id);
              }}
            />
          </td>
          <td key={colIndex} className={`${'additional-locations-table-sticky-column'}`}>
            <div className={`additional-locations-table-column-component`}>
              <Autocomplete
                options={values?.funeralHomOptions}
                getOptionLabel={(option) => option?.name || ''}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event: any, newValue: any) => handleAdditionalLocationsChange(event, newValue, setFieldValue)}
                onInputChange={(event, value) => {
                  if (values?.additionalLocationFocusedField === 'additionalLocationFocusedField') {
                    debouncedFetchAdditionalLocationOptions(value, setFieldValue);
                  }
                }}
                onFocus={() => handleAdditionalLocationFocus(setFieldValue)}
                renderInput={(params) => <TextField {...params} placeholder="Select Funeral Home" className="autocomplete-text-field" />}
                value={values?.selectedFuneralHome || null}
                className="autocomplete-div"
              />
              <ErrorMessage name={`selectedFuneralHome`} component="div" className="error-message" />
            </div>
          </td>
          <td key={colIndex} className={``}>
            <div className={`additional-locations-table-column-component`}>
              <InputComponent fieldName={`selectLocation`} placeholder="Enter Address" disabled={true} isEmail />
            </div>
          </td>
          <td key={colIndex}>
            <div className={`additional-locations-table-column-component`}>
              <InputComponent fieldName={`ownerName`} placeholder="Enter Owner Name" disabled={true} isAlpha />
            </div>
          </td>
          <td key={colIndex}>
            <div className={`additional-locations-table-column-component`}>
              <InputComponent fieldName={`phoneNumber`} placeholder="Enter Phone Number" disabled={true} isEmail />
            </div>
          </td>
          <td key={colIndex}>
            <div className={`additional-locations-table-column-component`}>
              <InputComponent fieldName={`emailAddress`} placeholder="Enter Email Address" disabled={true} isEmail />
            </div>
          </td>
          <td key={colIndex}>
            <div className={`additional-locations-table-column-component`}>
              <InputComponent fieldName={`faxNumber`} placeholder="Enter Fax Number" disabled={true} isEmail />
            </div>
          </td>
          <td key={colIndex}>
            <div className={`additional-locations-table-column-component`}>
              <InputComponent fieldName={`taxID`} placeholder="Enter Tax ID" disabled={true} isEmail />
            </div>
          </td>
        </tr>
      )}
    </Formik>
  );
};
export default AdditionalLocationsTableCellForm;
