export const customStyles = {
    control: (provided: any, state:any) => ({
      ...provided,
      width: "100%",
      borderColor: '#ffffff',
      backgroundColor: '#F7F7F7',
      boxShadow: '#ffffff',
      "&:hover": {
        borderColor: '#ffffff',
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      width: "100%",
    }),
    option: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: state.isSelected ? "#887952" : "white",
      ":hover": {
        backgroundColor: state.isSelected ? "#887952" : "#EEEE",
      },
    }),
  };