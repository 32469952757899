import store from '../../store';
import { clearUserData } from '../hooks/useUser';

export const getCurrentUser = () =>
  JSON.parse(localStorage.getItem('user') || '{}');
export const getAuthToken = () => {
  return localStorage.getItem('token') || '';
};

export const getToken = () => {
  const token = getAuthToken();
  return token || '';
};


export const login = (userDetails: any = {}) => {
  localStorage.setItem('user', JSON.stringify(userDetails));

  store.dispatch({
    type: 'SESSION:SET_IS_LOGGED_IN',
    payload: {
      value: true,
      email: userDetails.email,
      location: userDetails.location
    }
  });
};

export const logout = () => {
  store.dispatch({
    type: 'SESSION:SET_IS_LOGGED_IN',
    payload: {
      value: false
    }
  });
  
  // Reset set loading to display login.
  store.dispatch({
    type: `APPEARANCE:SET_LOADING`,
    payload: {
      value: false
    }
  });

  store.dispatch({ type: 'SUPPORT:RESET_STATE', payload: { value: {} } });

  localStorage.removeItem('user');
  localStorage.removeItem('currentUrl');
  localStorage.removeItem('openIndex');
  localStorage.setItem("activeItem", "dashboard");
  clearUserData();
};
