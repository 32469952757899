import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { Label, FormGroup } from 'reactstrap';
import { CheckBoxComponentProps } from './checkbox-component-props';
import './checkbox-component.scss';

const CheckBoxComponent: React.FC<CheckBoxComponentProps> = ({
  fieldName,
  label,
  optional = true,
  disabled,
  checked,
  onClick
}) => {
  return (
    <FormGroup className="add-or-edit-form-group">
      <Label htmlFor={fieldName} className="custom-form-label">
        {label}
        {!optional && <span>*</span>}
        <Field
          id={fieldName}
          name={fieldName}
          type="checkbox"
          className={`form-control-checkbox`}
          disabled={disabled}
          checked={checked}
          onClick = {onClick}
        />
      </Label>

      <ErrorMessage
        name={fieldName}
        component="div"
        className="error-message"
      />
    </FormGroup>
  );
};

export default CheckBoxComponent;