/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import './view-funeral-homes-bottom-section.scss';
import AdditionalIds from './tabs/additional-ids/additional-ids';
import AdditionalLocations from './tabs/additional-locations/additional-locations';
import CoOwner from './tabs/co-owner/co-owner';
import Companies from './tabs/companies/companies';
// import Contracts from "./tabs/contracts/contracts";
import Correspondance from './tabs/correspondance/correspondance';
import AuthorisedRepresentative from './tabs/authorized-representative/authorized-representative';
import Notes from './tabs/notes/notes';
import SpecialInstructions from './tabs/special-instructions/special-instructions';
import CustomTabPanel from '../../../../../common/components/custom-tab-panel/custom-tab-panel';

interface ViewFuneralHomesBottomSectionProps {
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  tabName: any;
  tabIndex: number;
  funeralHomeId: number;
  setLoading: any;
  shouldDisplayTab: boolean;
  addMoreLabel: any;
  setAddMoreLabel: any;
  setIsUpdateNotesData: any;
  isUpdateNotesData: any;
}
const ViewFuneralHomesBottomSection: React.FC<ViewFuneralHomesBottomSectionProps> = ({
  handleTabChange,
  tabName,
  tabIndex,
  funeralHomeId,
  setLoading,
  shouldDisplayTab,
  addMoreLabel,
  setAddMoreLabel,
  setIsUpdateNotesData,
  isUpdateNotesData,
}) => {
  return (
    <div className="view-funeral-homes-bottom-container">
      <Box sx={{ bgcolor: 'background.paper' }}>
        <div className="view-funeral-homes-bottom-container-tab-name">
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            {tabName(shouldDisplayTab)?.map((data: any, index: any) => <Tab label={data} key={index} />)}
          </Tabs>
        </div>
        <CustomTabPanel value={tabIndex} index={0}>
          <AdditionalLocations
            funeralHomeId={funeralHomeId}
            setLoading={setLoading}
            addMoreLabel={addMoreLabel}
            setAddMoreLabel={setAddMoreLabel}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={1}>
          <Companies setLoading={setLoading} funeralHomeId={funeralHomeId} addMoreLabel={addMoreLabel} setAddMoreLabel={setAddMoreLabel} />
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={2}>
          <CoOwner setLoading={setLoading} funeralHomeId={funeralHomeId} addMoreLabel={addMoreLabel} setAddMoreLabel={setAddMoreLabel} />
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={3}>
          <SpecialInstructions setLoading={setLoading} funeralHomeId={funeralHomeId} />
        </CustomTabPanel>
        {shouldDisplayTab && (
          <CustomTabPanel value={tabIndex} index={4}>
            <Notes
              funeralHomeId={funeralHomeId}
              setLoading={setLoading}
              notesList={isUpdateNotesData}
              setNotesList={setIsUpdateNotesData}
            />
          </CustomTabPanel>
        )}
        {/* <CustomTabPanel value={tabIndex} index={5}>
          <Contracts setLoading={setLoading} funeralHomeId={funeralHomeId} />
        </CustomTabPanel> */}
        <CustomTabPanel value={tabIndex} index={5}>
          <AdditionalIds
            funeralHomeId={funeralHomeId}
            setLoading={setLoading}
            addMoreLabel={addMoreLabel}
            setAddMoreLabel={setAddMoreLabel}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={6}>
          <Correspondance setLoading={setLoading} funeralHomeId={funeralHomeId} />
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={7}>
          <AuthorisedRepresentative setLoading={setLoading} funeralHomeId={funeralHomeId} />
        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default ViewFuneralHomesBottomSection;
