import axios from '.';

export const signup = (data: any) => {
  return axios({
    method: 'POST',
    url: '/signup',
    data
  });
};

export const verifyEmail = (params: any) => {
  return axios({
    url: `/verify-email`,
    method: 'GET',
    params
  });
};

export const resendVerificationEmail= (url: any) => {
  return axios({
    url: url,
    method: 'GET',
  });
};