import React, { useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Navigate,
} from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getCurrentUser } from './app/common/routines/user-operations';
import {routes}  from './app/constants/routes';
import AppRoute from './app-route';
import NoRole from './app/features/no-role';
import LandingPage from './app/features/landing-page/landing-page';
import MainLayout from './app/features/home/main-layout/main-layout';
import EmailVerifications from './app/features/email-verification/email-verification';
import ResetPassword from './app/features/reset-password/reset-password';
import DevRoutes from './app/routes/dev-routes';
import SetPassword from './app/features/set-password/set-password';
import ForgotPassword from './app/features/forgot-password/forgot-password';

const mstp = (state: any) => {
  return {
    isLoggedIn: state.session.isLoggedIn,
    appDataLoading: state.appearance.appDataLoading,
  };
};

const mdtp = (dispatch: any) => {
  return {
    setLoggedIn: (value:any) => {
      dispatch({
        type: 'SESSION:SET_IS_LOGGED_IN',
        payload: { value },
      });
    },
  };
};

function App(props:any) {
  let router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
      {process.env.NODE_ENV === 'development' &&  <>
      <Route path="/dev/*" element={<DevRoutes />} />
      </>}
        {props.isLoggedIn ? (
              <>
                <Route path={routes.HOME} element={ <MainLayout />} />
                <Route
                  path="*"
                  element={<Navigate to={routes.DASHBOARD} replace />}
                />
              </>
        ) : (
          <>
            <Route path={routes.NO_ROLE} element={<NoRole />} />
            <Route path={routes.EMAILVERIFICATION} element={<EmailVerifications />} />
            <Route path={routes.RESETPASSWORD} element={<ResetPassword />} />
            <Route path={routes.SETPASSWORD} element={<SetPassword />}/>
            <Route path={routes.FORGOTPASSWORD} element={<ForgotPassword setPassword={true}/>}/>
            <Route path={routes.LOGIN} element={<LandingPage isLoggedIn ={props.isLoggedIn} />} />
            <Route path="*" element={<Navigate to={routes.LOGIN} replace />} />
          </>
        )}
      </Route>
    )
  );
  return <RouterProvider router={router} />;
}

export default connect(
  mstp,
  mdtp
)((props: any) => {

  const { setLoggedIn } = props
  useEffect(() => {
    const loggedIn = !_.isEmpty(getCurrentUser());
    setLoggedIn(loggedIn);
    //props.setLoggedIn(loggedIn);
  }, [setLoggedIn]);

  return (
    <AppRoute>
      <App {...props} />
    </AppRoute>
  );
});
