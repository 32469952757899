import React, { useState, useMemo } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import * as Yup from 'yup';
import styles from '../welcome/welcome.module.scss';
import Password from "../../assets/icons/password_hidden.svg";
import ViewIcon from "../../assets/icons/viewIcon.svg";
import * as loginAPI  from '../../api/login';
import store from '../../store';
import { login } from '../../common/routines/user-operations';
import { useSetLoading } from '../../common/hooks/appearance';
import './login.scss';
import { emailValidationRegex } from '../../constants/regex-string';

const Login= ( props: { isLoggedIn: any ;onToggleSignup: () => void ;onForgotPassword: () => void}) => {
  const initialValues = { email: '', password: '' };
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const validationSchema = Yup.object({
    email: Yup.string().matches(emailValidationRegex, { message: "Invalid email address. Please provide a valid email."})
    .required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const onSubmit = async (values: typeof initialValues) => {
    setIsLoading(true);
    setLoading(true)
    try{
      const response =await loginAPI?.login(values);
      setIsLoading(false);
      setLoading(false);
      const token = response.headers.authorization;
      localStorage.setItem('token', token);
      login(response.data.data.user)
      store.dispatch({
        type: 'SESSION:SET_IS_LOGGED_IN',
        payload: {
          value: true,
          email: values?.email,
        }
      });    
    }catch(error: any){
      setIsLoading(false);
      setLoading(false);
      setMessage({message: error?.response?.data?.message, types: 'error'});
    }
  };

  const showLoading = useMemo(() => isLoading, [isLoading]);
  useSetLoading(showLoading);

  return (
    <div className={styles.rightSection}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ isSubmitting,  setFieldValue }) => (
          <Form className={styles.loginForm}>
            <h2>Log In</h2>
            <div className={styles.formBody}>
              <div className={styles.field}>
                <Field type="email" name="email" placeholder="Email *" />
                <ErrorMessage name="email" component="div"  className={styles.errorMessage}/>
              </div>
              <div className='passwordFieldOutlinedInputBox'>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password *"
                  onChange={(data)=>{
                    setFieldValue('password', data?.target?.value); 
                  }}
                  autoComplete="off"
                  endAdornment={
                    <InputAdornment className={styles.passwordToggleIconInputAdornment}  onClick={togglePasswordVisibility}  position="end">
                      <img src={showPassword ? ViewIcon : Password} alt="Toggle Password Visibility" />
                    </InputAdornment>

                  }
                  label={""}
                  className={'passwordFieldOutlinedInput'}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: '#F7F7F7',
                      borderWidth: "0px",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: '#F7F7F7',
                      borderWidth: "10px",
                    },
                  }}
                />
                <ErrorMessage name="password" component="div" className={styles.errorMessage} />
              </div>
              <button type="submit" className={styles.submitButton} disabled={isSubmitting || loading}>
                {loading ? 'Logging in...' : 'Login'}
              </button>
              {message?.message ? <div className={`${message?.types === 'error' ? `login-error-message` : `login-success-message`}`}>{message?.message}</div>:""}
              <div className={styles.helperLinks}>
                <label>
                  <Field type="checkbox" name="rememberMe" />
                  Remember me
                </label>
                <a href="/#" onClick={(e) => { e.preventDefault(); props.onForgotPassword(); }}>Forgot Password?</a>
              </div>
              <a href="/#" onClick={(e)=>{e.preventDefault();props.onToggleSignup();}}>Create account.</a>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;