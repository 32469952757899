import React from "react";
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  FormGroup,
  Label,
  Button,
} from "reactstrap";
import closeIcon from "../../../assets/icons/closeIcon.svg";
import { Formik, Form } from "formik";
import {
  DatePicker,
  SelectDropdownComponent,
} from "../../../common/components";
import { selectDropDowncustomStyles } from "../../directory/agents/add-or-edit-agents.utils";

interface FilterUsersProps {
  isOpen: boolean;
  toggleFilter: any;
  page?: number;
  appliedFilters?: any;
  setAppliedFilters?: any;
}

const FilterUsers: React.FC<FilterUsersProps> = ({
  isOpen,
  toggleFilter,
  page = 1,
  appliedFilters = {},
  setAppliedFilters,
}) => {
  const formatDate = (date: Date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}-${day}-${year}`;
  };
  const initialValues = {
    roles: appliedFilters.roles || [],
    fromDate: appliedFilters.fromDate || null,
    toDate: appliedFilters.toDate || null,
  };
  const onSubmit = async (values: any) => {
    console.log(values);
    const isFiltersSelected =
      values.roles.length > 0 || values.fromDate || values.toDate;
    if (isFiltersSelected) {
      setAppliedFilters(values);
    }
    toggleFilter();
  };

  const handleRoleChange = (selected: any, setFieldValue: any) => {
    setFieldValue(
      "roles",
      selected ? selected.map((item: any) => item.value) : []
    );
  };

  const handleReset = (resetForm: () => void) => {
    if (Object.keys(appliedFilters).length > 0) {
      resetForm();
      setAppliedFilters({});
      toggleFilter();
    } else {
      resetForm();
    }
  };
  return (
    <div className="filter-offcanvas">
      <Offcanvas isOpen={isOpen} direction="end" className="offcanva-box">
        <OffcanvasHeader className="filter-offcanvas-header">
          <div className="header-div">
            <h2 className="mb-0">Filters</h2>
            <button onClick={toggleFilter}>
              <img src={closeIcon} alt="Close" />
            </button>
          </div>
        </OffcanvasHeader>
        <OffcanvasBody className="offcanvas-body">
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, setFieldValue, resetForm }) => (
              <Form className="filters-form">
                <FormGroup className="filter-form-group">
                  <Label className="custom-form-label">Role</Label>
                  <SelectDropdownComponent
                    placeholder="Select Role"
                    customStyles={selectDropDowncustomStyles}
                    isClearable={true}
                    isMulti={true}
                    options={[
                      { label: "Super Admin", value: "Super Admin" },
                      { label: "Admin", value: "Admin" },
                      { label: "Partner", value: "Partner" },
                      { label: "Agent", value: "Agent" },
                      { label: "Customer", value: "Customer" },
                    ]}
                    value={values.roles.map((role: string) => ({
                      label: role,
                      value: role,
                    }))}
                    onChange={(selected) =>
                      handleRoleChange(selected, setFieldValue)
                    }
                  />
                </FormGroup>
                <FormGroup className="filter-form-group">
                  <Label className="custom-form-label">Created Range</Label>
                  <div className="filters"
                  style={{
                    display: 'flex',
                    gap: '10px'
                  }}>
                    <DatePicker
                      placeholder="select created date"
                      value={
                        values.fromDate
                          ? new Date(values.fromDate)
                          : undefined
                      }
                      onChange={(selected) => {
                        setFieldValue(
                          "fromDate",
                          selected ? formatDate(selected) : null
                        );
                        setFieldValue(
                          "toDate",
                          selected ? formatDate(new Date()) : null
                        );
                      }}
                      maxDate= {new Date()}
                    />
                    <DatePicker
                      placeholder="select created date"
                      value={
                        values.toDate
                          ? new Date(values.toDate)
                          : undefined
                      }
                      onChange={(selected) => {
                        setFieldValue(
                          "toDate",
                          selected ? formatDate(selected) : null
                        );
                      }}
                      maxDate= {new Date()}
                      minDate={values.fromDate ? new Date(values.fromDate) : undefined}
                    />
                  </div>
                </FormGroup>
                <div className="filter-form-buttons">
                  <Button
                    className="filter-reset-button"
                    type="button"
                    onClick={() => handleReset(resetForm)}
                  >
                    Reset
                  </Button>
                  <Button className="filter-apply-button" type="submit">
                    Apply
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
};

export default FilterUsers;
