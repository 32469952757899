import React, { useMemo } from "react";
const withPermissions = (
  Component: React.ComponentType<any>,
  options: { modules: string[] }
) => {
  return (props: any) => {
    const userPermissions = useMemo(() => {
      let permissionsMap: Record<string, boolean> = {};
      const userData = localStorage.getItem("user");
      if (userData) {
        const parsedUser = JSON.parse(userData);
        const permissions = parsedUser?.userRole?.permissions || [];
        permissions.forEach((permission: any) => {
          permissionsMap[permission.module.toUpperCase()] =
            permission.isEnabled;
        });
      }
      return options.modules.reduce((acc, module) => {
        acc[module.toUpperCase()] =
          permissionsMap[module.toUpperCase()] || false;
        return acc;
      }, {} as Record<string, boolean>);
    }, []);
    return <Component {...props} permissions={userPermissions} />;
  };
};
export default withPermissions;
