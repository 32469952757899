import React from "react";
import { ViewUserProps } from "./user-props";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import closeIcon from "../../../assets/icons/closeIcon.svg";
import "./view-user.scss";
const ViewUser: React.FC<ViewUserProps> = ({
  isOpen,
  viewData,
  toggleView,
}) => {
  const concatenateName = (
    firstName?: string | null,
    lastName?: string | null
  ): string => {
    return [firstName, lastName].filter((name) => name?.trim()).join(" ");
  };
  const dateStr = viewData?.created_at;
  const date = new Date(dateStr);
  const formattedDate = `${date.getDate()} ${date.toLocaleString("en-US", {
    month: "long",
  })}, ${date.getFullYear()}`;
  return (
    <div className="add-or-edit-offcanvas">
      <Offcanvas isOpen={isOpen} direction="end" className="offcanvas">
        <OffcanvasHeader className="offcanvas-header">
          <div className="header-div">
            <h2 className="mb-0">
              User Details
            </h2>
            <button
              onClick={() => {
                toggleView(viewData.id);
              }}
            >
              <img src={closeIcon} alt="Close" />
            </button>
          </div>
        </OffcanvasHeader>
        <OffcanvasBody className="offcanvas-body">
          <div className="view-form">
            <div className="form-group">
              <label>Full name</label>
              <div className="data-value scroll-text">              
              {concatenateName(viewData?.first_name, viewData?.last_name) || "-"}
              </div>
            </div>
            <div className="form-group">
              <label>Role</label>
              <div className="data-value">
                {viewData?.userRole?.name?.trim()
                  ? viewData?.userRole?.name
                  : "-"}
              </div>
            </div>
            <div className="form-group">
              <label>Permissions</label>
              {viewData?.userRole?.userRoleWithPermissions?.length ? (
                <div className="permissions-section">
                  {viewData.userRole.userRoleWithPermissions
                    .filter((permission: any) => permission.is_enabled)
                    .map((permission: any, index: number) => (
                      <div key={index} className="data-value-permissions">
                        {permission?.userRoleModule?.name || "-"}
                      </div>
                    ))}
                </div>
              ) : (
                <div className="data-value">-</div>
              )}
            </div>
            <div className="form-group">
              <label>Email Address</label>
              <div className="data-value scroll-text">
                {viewData?.email?.trim() ? viewData.email : "-"}
              </div>
            </div>
            <div className="form-group">
              <label>Phone Number</label>
              <div className="data-value">
                {viewData?.phone_number?.trim() ? viewData.phone_number : "-"}
              </div>
            </div>
            <div className="form-group">
              <label>Date Added</label>
              <div className="data-value">{formattedDate}</div>
            </div>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
};

export default ViewUser;
