
import * as Yup from "yup";
import { emailValidationRegex } from "../../../../../../../../constants/regex-string";

export const validationSchema = Yup.object({
  email: Yup.string()
    .matches(emailValidationRegex, { message: "Invalid email address. Please provide a valid email." })
    .required("Mandatory Field*"),
  firstName: Yup.string()
    .matches(/^[A-Za-z ´',-.()!&Åë]+$/, 'first Name can only contain alphabets, spaces, and special characters')
    .max(200, "first Name can't be longer than 200 characters.")
    .required("Mandatory Field*"),
  lastName: Yup.string()
    .matches(/^[A-Za-z ´',-.()!&Åë]+$/, 'last Name can only contain alphabets, spaces, and special characters')
    .max(200, "last Name can't be longer than 200 characters.")
    .required("Mandatory Field*"),
  correspondanceRoleId: Yup.mixed()
    .required('correspondance role id is required'),
});