import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { Label, FormGroup } from 'reactstrap';
import { InputComponentProps } from './input-component-props';
import './input-component.scss';

const InputComponent: React.FC<InputComponentProps> = ({
  fieldName,
  label,
  placeholder = '',
  optional = true,
  inputIsTextArea = false,
  disabled,
  isAlpha = false,
  isEmail = false,
  onlyNums = false,
  isEin = false,
  isAlphaNumeric = false,
  isSSN = false,
  isTax = false,
  isFax = false,
  maxLength,
}) => {
  const handleInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
    if (isEmail) {
      const sanitizedValue = value.replace(/[^A-Za-z0-9._%+-@]/g, '');
      const [localPart, domainPart] = sanitizedValue.split('@');

      if (domainPart !== undefined) {
        if (domainPart.includes('..')) {
          event.target.value = `${localPart}@${domainPart.replace(/\.+/g, '.')}`;
        } else if (domainPart.split('.').length > 2) {
          const parts = domainPart.split('.');
          const extension = parts.pop();
          const domain = parts.join('.');
          event.target.value = `${localPart}@${domain}.${extension?.slice(0, 6)}`;
        } else {
          event.target.value = sanitizedValue;
        }
      } else {
        event.target.value = sanitizedValue;
      }
    } else if (isEin) {
      event.target.value = value.replace(/[^0-9-]/g, '');
    } else if (isAlpha) {
      event.target.value = value.replace(/[^A-Za-z ëÅ´',-.()!&]/g, '');
    } else if (isAlphaNumeric) {
      event.target.value = value.replace(/[^A-Za-z0-9 ]/g, '');
    } else if (onlyNums) {
      event.target.value = value.replace(/[^0-9.]/g, '');
    } else if (isSSN) {
      event.target.value = value.replace(/[^0-9]/g, '').slice(0, 4);
    } else if (isTax) {
      event.target.value = value.replace(/[^A-Za-z0-9 ]/g, '').slice(0, 20);
    } else if (isFax) {
      event.target.value = value.replace(/[^0-9 `',-.()!Åë&]/g, '').slice(0, 20);
    }
  };

  return (
    <FormGroup className="add-or-edit-form-group">
      <Label htmlFor={fieldName} className="custom-form-label">
        {label}
        {!optional && <span>*</span>}
      </Label>
      <Field
        id={fieldName}
        name={fieldName}
        as={inputIsTextArea ? 'textarea' : 'input'}
        placeholder={placeholder}
        className={`form-control ${inputIsTextArea ? 'form-input-text-area' : ''}`}
        disabled={disabled}
        {...(!inputIsTextArea && { maxLength: maxLength || 200 })}
        onInput={handleInput}
      />
      <ErrorMessage name={fieldName} component="div" className="error-message" />
    </FormGroup>
  );
};

export default InputComponent;
