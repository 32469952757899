import axios from "..";
export const getSubModulesForModuleInGenerateReports = (module :string) => {
    return axios({
      method: "GET",
      url: `/reports/generate-reports/sub-modules/${module}`
  });
};
export const getModulesInGenerateReports = () => {
  return axios({
    method: "GET",
    url: `/reports/generate-reports/modules`
  });
};