import React, { useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import { Container } from "reactstrap";
import { useSetLoading } from '../../../common/hooks/appearance';
import { getAgenciesList, getAgenciesDetails, exportAgenciesData } from '../../../api/agencies';
import SearchBar from "../../../common/components/search/search-bar";
import TableComponent from "../../../common/components/table-component/table-component";
import BreadcrumbComponent from "../../../common/components/breadcrumb-component/breadcrumb-component";
import PaginationComponent from "../../../common/components/pagination-component/pagination-component";
import ViewAgencies from "./view-agencies/view-agencies";
import AddOrEditAgencies from "./add-or-edit-agencies/add-or-edit-agencies";
import "./agencies.scss";
import AlertComponent from "../../../common/components/Alert/Alert";
import FilterAgencies from "./filter-agencies";

const Agencies: React.FC = () => {

  const columnNames: string[] = [
    "Edit",
    "Agency",
    "Authorized Representative",
    "Mailing Address",
    "Work Phone Number",
    "Cell Phone Number",
    "Email Address",
    "Special Instructions",
    "Commission Level",
    "Regional Rep",

    // "Owner(s)",
    // "Agency",
    // "Companies Contacted With",
    // "Bank Name",
    // "Bank Routing Name",
    // "Bank Account Number",
    // "Checking or Savings",
    // "Commission Payment Frequency",
    // "Social Security Number",
    // "EIN",
    // "Date Of Birth",
    // "States Licensed In",
    // "License Expiration(each state)",
    // "AML Certification(each company)",
    // "Reserve Balance(each company)",
    // "Agency Number(each company)",
    // "Special Instructions",
    // "Commission Level",
    // "Regional Rep",
    // "State Appointed",
  ];

  let crumbMapping = [
    { label: "Directory", link: "/home/directory/funeral-homes" },
    { label: "Agencies", link: "/home/directory/agencies" },
  ];

  const [addOrEditOpen, setEditOpen] = useState<boolean>(false);
  const [addOrEditOpenMode, setEditOpenMode] = useState<'add' | 'edit' | null>(null);
  const [editData, setEditData] = useState<any>(null);
  const [viewOpen, setViewOpen] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isReset, setIsReset] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<any>(null);
  //const [error, setError] = useState<string | null>(null);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize] = useState<number>(15);
  const [sortBy, setSortBy] = useState<string>("created_at");
  const [sortDirection, setSortDirection] = useState<string>("desc");
  const [columnName, setCloumnName] = useState<string>("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<
    "success" | "error" | "info" | "warning"
  >("success");
  const handleSearch = _.debounce((text: string) => {
    // setLoading(true)
    setSearchText(text)
  }, 500);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<any>({});

  const toggleAddEdit = (modeType: any) => {
    setEditOpen(!addOrEditOpen);
    setEditOpenMode(!addOrEditOpen ? modeType : "");
    if (modeType !== 'edit') {
      setEditData(null);
    }
  };

  const toggleView = () => {
    setViewOpen(!viewOpen);
  };

  const toggleFilters = () => {
    setIsFilterOpen(!isFilterOpen);
  };
  const fetchAgencies = useCallback(async (page = 1, search = "", filters?: any) => {
    setLoading(true);
    const offset = (page - 1) * pageSize
    try {
      const response = await getAgenciesList(offset, pageSize, sortBy, sortDirection, search, filters);
      setData(response?.data?.data?.data);
      setTotalRecords(response?.data?.data?.totalRecords);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [pageSize, sortBy, sortDirection]);
  useEffect(() => {
    if (searchText !== null) {
      setCurrentPage(1);
      fetchAgencies(1, searchText, appliedFilters);
    }
  }, [searchText, fetchAgencies, appliedFilters]);

  useEffect(() => {
    fetchAgencies(currentPage, searchText, appliedFilters);
  }, [currentPage, searchText, sortBy, sortDirection, appliedFilters, fetchAgencies]);

  const handleEditClick = async (companyId: number) => {
    setLoading(true);
    try {
      const getAPIDetails = await getAgenciesDetails(companyId);
      setEditData(getAPIDetails?.data?.data[0]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error while fetching company details:", error)
    }
  }

  const handleViewClick = async (regionalRepId: number) => {
    try {
      setLoading(true)
      const getAPIDetails = await getAgenciesDetails(regionalRepId);
      setLoading(false)
      setEditData(getAPIDetails?.data?.data[0]);
      setViewOpen(!viewOpen);
    } catch (error) {
      setLoading(false)
    }
  };

  const formatAddress = (address: any) => {
    const street = address?.street || "";
    const city = address?.City?.name || "";
    const state = address?.State?.name || "";
    const zipcode = address?.zipcode || "";

    return `${street}, ${city}, ${state}, ${zipcode}`;
  };

  const formattedData = useMemo(() => {
    if (Array.isArray(data)) {

      return data.map((item) => ({
        'id': item?.id,
        "Agency": item?.name || "-",
        "Authorized Representative": item?.authorized_representative || "-",
        "Mailing Address": formatAddress(item.mailingAddress) || "-",
        "Work Phone Number": item?.work_phone || "-",
        "Cell Phone Number": item?.cell_phone || "-",
        "Email Address": item?.email_address || "-",
        // "Owner(s)": item?.agencyOwners?.length ? item?.agency_owners[0]?.name  || "-" : "-",
        //  "Agency":"name",
        // "Companies Contacted With":"-",
        // "Bank Name": item?.agency_owners?.length ? item?.agency_owners[0]?.bank_name  || "-" : "-",
        // "Bank Routing Name":  item?.agency_owners?.length ? item?.agency_owners[0]?.bank_routing_number || "-" : "-",
        // "Bank Account Number": item?.agency_owners?.length ? item?.agency_owners[0]?.bank_account_number  || "-" : "-",
        // "Checking or Savings": item?.agency_owners?.length ? item?.agency_owners[0]?.account_type || "-" : "-",
        // "Commission Payment Frequency": item?.agency_owners?.length ? item?.agency_owners[0]?.commission_payment_frequency || "-" : "-",
        // "Social Security Number": item?.agency_owners?.length ? item?.agency_owners[0]?.social_security_number || "-" : "-",
        // "EIN": item?.agency_owners?.length ? item?.agency_owners[0]?.ein || "-" : "-",
        // "Date Of Birth": item?.agency_owners?.length ? item?.agency_owners[0]?.date_of_birth ? moment(item?.agency_owners[0]?.date_of_birth).format('MM/DD/YYYY'):  "-"  : "-",
        // "States Licensed In": item?.agency_licenses?.length ? item?.agency_licenses[0]?.states_licensed?.name || "-" : "-",
        // "License Expiration(each state)":  item?.agency_licenses?.length ? item?.agency_licenses[0]?.license_expiration ? moment( item?.agency_licenses[0]?.license_expiration).format('MM/DD/YYYY') : "-"  : "-",
        // "AML Certification(each company)":  item?.agency_licenses?.length ? item?.agency_licenses[0]?.aml_certification || "-" : "-",
        // "Reserve Balance(each company)":  item?.agency_licenses?.length ? item?.agency_licenses[0]?.reserve_balance  || "-" : "-",
        // "Agency Number(each company)": item?.agency_licenses?.length ? item?.agency_licenses[0]?.agency_number || "-" : "-",
        "Special Instructions": item?.special_instructions || "-",
        "Commission Level": item?.commissionLevel?.commission_name || "-",
        "Regional Rep": item?.agency_regional_rep?.name || "-",
        //"State Appointed": item?.agency_states_appointed?.length ? item?.agency_states_appointed[0]?.states_appointed_in?.name || "-" : "-",
      }))
    } else {
      return [];
    }
  }, [data]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const showLoading = useMemo(() => loading, [loading]);
  useSetLoading(showLoading);

  const mapColumnNameToField = (columnName: string): string => {
    const mapping: Record<string, string> = {

      "Agency": "name",
      "Authorized Representative": "authorized_representative",
      "Mailing Address": "mailingAddress.street",
      "Work Phone": "work_phone",
      "Cell Phone": "cell_phone",
      "Email Address": "email_address",
      "Special Instructions": "special_instructions",
      "Commission Level": "commission_level",
      "Regional Rep": "name",

      // "Owner(s)":"name",
      //  "Agency":"name",
      // "Companies Contacted With":"name",
      // "Bank Name":"name",
      // "Bank Routing Name":"name",
      // "Checking or Savings":"name",
      // "Commission Payment Frequency":"name",
      // "Social Security Number":"name",
      // "EIN":"name",
      // "Date Of Birth":"name",
      // "States Licensed In":"name",
      // "License Expiration(each state)":"name",
      // "AML Certification(each company)":"name",
      // "Reserve Balance(each company)":"name",
      // "Agency Number(each company)":"name",
      // "State Appointed":"name",
    };

    return mapping[columnName] || columnName;
  };

  const handleSort = (columnName: string) => {
    const mappedField = mapColumnNameToField(columnName);
    if (sortBy === mappedField) {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortBy(mappedField);
      setCloumnName(columnName);
      setSortDirection("asc");
    }
  };
  const handleDownload = async () => {
    setLoading(true);
    try {
      await exportAgenciesData({ searchText: searchText, sortBy: sortBy, sortDirection: sortDirection }, appliedFilters);
      // const blob = new Blob([response.data], {
      //   type: "application/vnd.ms-excel",
      // });
      // const link = document.createElement("a");
      // link.href = URL.createObjectURL(blob);
      // link.download = "companies_data.xlsx";
      // link.click();
      setTimeout(() => setLoading(false), 1000);
      setAlertMessage("Sent Downloadable link to Email , Please check");
      setAlertType("success");
      setAlertOpen(true);
    } catch (error) {
      setAlertMessage("Failed to download Companies data");
      setAlertType("error");
      setAlertOpen(true);
      //setError("Failed to download file");
      setLoading(false);
    }
  };
  const handleAlertClose = () => {
    setAlertOpen(false);
  };


  return (
    <Container>
      <AlertComponent
        message={alertMessage}
        type={alertType}
        open={alertOpen}
        onClose={handleAlertClose}
      />
      <BreadcrumbComponent breadcrumbLink={crumbMapping} />
      <SearchBar
        placeholder={`Search by name, address, email etc..`}
        toggleAddEdit={toggleAddEdit}
        onSearch={handleSearch}
        isReset={isReset}
        module={"Agencies"}
        onDownload={handleDownload}
        toggleFilter={toggleFilters}
        appliedFilters={appliedFilters}
      />
      {formattedData.length > 0 ?
        (
          <>
            <div className={"agencies-table"}>
              <TableComponent
                columnNames={columnNames}
                rowsData={formattedData}
                toggleAddEdit={toggleAddEdit}
                handleEditClick={handleEditClick}
                handleViewClick={handleViewClick}
                handleSort={handleSort}
                sortedColumn={columnName}
                sortDirection={sortDirection}
              />
            </div>
            <PaginationComponent
              totalRecords={totalRecords}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </>
        ) :
        (
          !loading && (
            <div className="no-results">
              <p>No results found</p>
            </div>
          )
        )
      }
      {addOrEditOpen ? (
        !loading ?
          <AddOrEditAgencies
            isOpen={addOrEditOpen}
            toggle={() => {
              toggleAddEdit("");
            }}
            mode={addOrEditOpenMode}
            editData={editData}
            refreshData={() => {
              setSearchText(null);
              setCurrentPage(1);
              setIsReset(!isReset)
              fetchAgencies();
            }}
          /> : ""
      ) : ""}
      {viewOpen && (
        <ViewAgencies isOpen={viewOpen} viewData={editData} toggleView={toggleView} />
      )}
      {isFilterOpen && (
        <FilterAgencies
          isOpen={isFilterOpen}
          toggleFilter={toggleFilters}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
        />
      )}
    </Container>
  );
};
export default Agencies;
