import React from "react";
import "./stop-watch-display.scss";
import { StopwatchDisplayProps } from './stop-watch-display-props';

const StopwatchDisplay: React.FC<StopwatchDisplayProps> = ({ value }) => {
    const timeNumber= Number(("0" + Math.floor((value / 1_000) % 60)).slice(-2))
  return (
    <div className="stopwatch-container">
        <div className="time-display">
            {/* <div>{("0" + Math.floor((value / 60_000) % 60)).slice(-2)}</div>: */}
            <div>{10 - timeNumber }</div>
            {/* <div>{("0" + ((value / 10) % 1_000)).slice(-2)}</div> */}
        </div>
    </div>
  );
};

export default StopwatchDisplay;