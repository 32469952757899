import React, { useState, useMemo} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import * as signupAPI from '../../api/signup'
import styles from '../welcome/welcome.module.scss';
import { useSetLoading } from '../../common/hooks/appearance';
import { emailValidationRegex, passwordRules } from '../../constants/regex-string';
import Password from "../../assets/icons/password_hidden.svg";
import ViewIcon from "../../assets/icons/viewIcon.svg";
import './signup.scss';

const Signup = ( props: { isLoggedIn: any ;onToggleSignup: () => void }) => {

  const [message, setMessage] = useState<any | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const validationSchema = Yup.object({
    email: Yup.string()
      .matches(emailValidationRegex, {
        message: "Invalid email address. Please provide a valid email.",
      })
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .test("has-digit", "Password must contain at least one number", (value) =>
        /\d/.test(value)
      )
      .test(
        "has-lowercase",
        "Password should have at least one lowercase letter",
        (value) => /[a-z]/.test(value)
      )
      .test(
        "has-uppercase",
        "Password should have at least one capital letter",
        (value) => /[A-Z]/.test(value)
      )
      .test(
        "has-special-character",
        "Password should have at least one special character",
        (value) => /\W/.test(value)
      )
      .test(
        "min-length",
        "Password should be 8 characters or more",
        (value) => {
          if (!value || !value.length) return true;
          return value.length >= 8;
        }
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), undefined], "Passwords must match")
      .required("Confirm Password is required")
      .matches(passwordRules, { message: "Please create a stronger password" }),
    termsAndCondition: Yup.boolean().oneOf(
      [true],
      "Please select terms and conditions"
    ),
  });
      
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const togglePasswordVisibilityConfirm = () => {
    setShowPasswordConfirm((prevState) => !prevState);
  };

  const onSubmit = async(values: { email: string; password: string }) => {
    setIsLoading(true);
    try{
      const response =await signupAPI?.signup(values);
      setIsLoading(false);
      if(response?.data?.userDetails?.message){
        setMessage({message: response?.data?.userDetails?.message, types: 'success'
        });
      }
    }catch(error: any){
      setIsLoading(false);
      setMessage({message: error?.response?.data?.message, types: 'error'});
    }
  };

  const showLoading = useMemo(() => isLoading, [isLoading]);
  useSetLoading(showLoading);

  return (
    <div className={styles.rightSection}>
      <Formik
        initialValues={{ email: '', password: '', confirmPassword: '', termsAndCondition: false }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form className={styles.loginForm}>
            <h2>Sign Up</h2>
            <div className={styles.formBody}>
              <div className={styles.field}>
                <Field type="email" name="email" placeholder="Email *" />
                <ErrorMessage name="email" component="div"  className={styles.errorMessage} />
              </div>
              <div className={styles.field}>
                <Field
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password *"
                  className={styles.passwordField}
                  maxLength={16}
                />
                <span
                  className={styles.passwordToggleIcon}
                  onClick={togglePasswordVisibility}
                >
                 <img src={showPassword ? ViewIcon : Password} alt="Toggle Password Visibility" />
                </span>
                <ErrorMessage name="password" component="div" className={styles.errorMessage} />
              </div>
              <div className={styles.field}>
                <Field
                  type={showPasswordConfirm ? "text" : "password"}
                  name="confirmPassword"
                  placeholder="Confirm Password *"
                  className={styles.passwordField}
                  maxLength={16}
                />
                <span
                  className={styles.passwordToggleIcon}
                  onClick={togglePasswordVisibilityConfirm}
                >
                 <img src={showPasswordConfirm ? ViewIcon : Password} alt="Toggle Password Visibility" />
                </span>
                <ErrorMessage name="confirmPassword" component="div" className={styles.errorMessage} />
              </div>
              <button type="submit" className={styles.submitButton} disabled={isSubmitting}>
                Sign Up
              </button>
              {message?.message ? <div className={`${message?.types === 'error' ? `signup-error-message` : `signup-success-message`}`}>{message?.message}</div>:""}
              <div className={styles.termscheckbox}>
                <div>
                  <div>
                    <label>
                      <Field type="checkbox" name="termsAndCondition"  />
                      By Clicking , you agree to AFP Terms of<br></br> service and Privacy Policy
                    </label>
                  </div>
                  <ErrorMessage name="termsAndCondition" component="div" className={styles.errorMessage} />
                </div>
              </div>
              <div className={styles.helperLinks}>
                  <label>
                    <Field type="checkbox" name="rememberMe" />
                    Remember me
                  </label> 
                </div>
              <a href="/#" onClick={(e)=>{e.preventDefault();props.onToggleSignup();}}>Login</a>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Signup;
