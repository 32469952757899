import * as Yup from 'yup';
import phone from 'phone';
import { emailValidationRegex } from '../../../../../../../../constants/regex-string';

export const validationSchema = Yup.object({
  fullName: Yup.string()
    .matches(/^[A-Za-z ´',-.()!&Åë]+$/, 'Company Name can only contain alphabets, spaces, and special characters')
    .max(200, 'Company Name can\'t be longer than 200 characters.')
    .required('Mandatory Field*'),
  physicalAddress: Yup.object({
    street: Yup.string()
      .required('Mandatory Field*')
      .min(3, 'Street name must be at least 3 characters.')
      .max(200, 'Street name can\'t be longer than 200 characters.'),
    selectedPhysicalAddressCity: Yup.object().required('Mandatory Field*'),
    selectedPhysicalAddressState: Yup.object().required('Mandatory Field*'),
    zipcode: Yup.string()
      .required('Mandatory Field*')
      .matches(/^\d{5}$/, 'Invalid zipcode format'),
  }),
  mailingAddress: Yup.object({
    street: Yup.string()
      .required('Mandatory Field*')
      .min(3, 'Street name must be at least 3 characters.')
      .max(200, 'Street name can\'t be longer than 200 characters.'),
    selectedMailingAddressCity: Yup.object().required('Mandatory Field*'),
    selectedMailingAddressState: Yup.object().required('Mandatory Field*'),
    zipcode: Yup.string()
      .required('Mandatory Field*')
      .matches(/^\d{5}$/, 'Invalid zipcode format'),
  }),
  contactNumber: Yup.object({
    countryCode: Yup.string().matches(/^\+\d{1,4}$/),
    phoneNumber: Yup.string()
      .matches(/^\d{10}$/, 'Number format is invalid')
      .required('Mandatory Field*')
      .test('is-valid-phone', 'Invalid number', (value, allValue) => {
        if (!value) return true;
        const { isValid } = phone(`${allValue?.parent?.countryCode || ''}-${value}`);
        return isValid;
      }),
  }),
  contactDepartments: Yup.string()
    .matches(/^[A-Za-z ´',-.()!&Åë]+$/, 'Contact Departments can only contain alphabets, spaces, and special characters')
    .required('Mandatory Field*')
    .max(200, 'Contact Departments can\'t be longer than 200 characters.'),
  email: Yup.string()
    .matches(emailValidationRegex, { message: 'Invalid email address. Please provide a valid email.' })
    .required('Mandatory Field*'),
});
