/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { Field, ErrorMessage, FieldProps, useFormikContext } from 'formik';
import { Input, Label, FormGroup } from 'reactstrap';
import Select from 'react-select';
import Flags from 'react-world-flags';
import { get } from 'lodash';
import './phone-number-component.scss';
import { PhoneNumberComponentProps } from './phone-number-component-props';

const countryCodes = [
  { code: '+1', flag: 'US' }, // United States
  { code: '+91', flag: 'IN' }, // India
  { code: '+44', flag: 'GB' }, // United Kingdom
];

const PhoneNumberComponent: React.FC<PhoneNumberComponentProps> = ({
  label,
  fieldName,
  countryCodeFieldName,
  placeholder,
  optional = true,
  menuPosition = 'absolute',
}) => {
  const { setFieldValue } = useFormikContext<{ [key: string]: any }>();
  const handlePhoneNumberInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!/^\d*$/.test(value)) {
      event.target.value = value.replace(/\D/g, '');
    } else if (value.length > 10) {
      event.target.value = value.slice(0, 10);
    }
  };

  const customCountryCodeOptions = countryCodes.map((item) => ({
    value: item.code,
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Flags code={item.flag} style={{ width: 20, height: 14, marginRight: 8 }} />
        {item.code}
      </div>
    ),
  }));

  return (
    <FormGroup className="phone-number-form-group">
      <Label className="custom-form-label">
        {label}
        {!optional && <span>*</span>}
      </Label>
      <div className="custom-form-group-row">
        <div className="custom-form-group-col-select">
          <Field name={countryCodeFieldName}>
            {({ form }: FieldProps) => {
              const countryCodeValue = get(form.values, countryCodeFieldName);
              return (
                <Select
                  isSearchable={false}
                  options={customCountryCodeOptions}
                  className="custom-country-code-dropdown"
                  classNamePrefix="custom-country-code-dropdown"
                  onChange={(selectedOption) => {
                    setFieldValue(countryCodeFieldName, selectedOption?.value);
                  }}
                  menuPosition={menuPosition as any}
                  value={customCountryCodeOptions.find((option) => option.value === countryCodeValue)}
                />
              );
            }}
          </Field>
          <ErrorMessage name={countryCodeFieldName} component="div" className="error-message" />
        </div>
        <div className="custom-form-group-col">
          <Field name={fieldName}>
            {({ field }: FieldProps) => (
              <Input
                {...field}
                type="text"
                id={fieldName}
                placeholder={placeholder}
                className="custom-phone-input form-control"
                onInput={handlePhoneNumberInput}
              />
            )}
          </Field>
          <ErrorMessage name={fieldName} component="div" className="error-message" />
        </div>
      </div>
    </FormGroup>
  );
};

export default PhoneNumberComponent;
