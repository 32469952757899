import React from "react";
import "./logout-confirmation-popup.scss";

const LogoutConfirmationPopup = (props: any) => {
  if (!props?.isOpen) return null;

  return (
    <div className="logout-popup-overlay">
      <div className="logout-popup">
        <p className="logout-message">
          Are you sure you want to logout?
        </p>
        <div className="logout-buttons">
          <button className="cancel-button" onClick={props?.onClose}>
            Cancel
          </button>
          <button
            className="logout-button"
            onClick={()=> props?.handleLogOut()}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogoutConfirmationPopup;
