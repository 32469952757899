
import * as Yup from "yup";
import phone from 'phone';
import { emailValidationRegex } from "../../../../constants/regex-string";

export const validationSchema = Yup.object({
    name: Yup.string()
        .matches(/^[A-Za-z ´',-.()!&Åë]+$/, 'Name can only contain alphabets, spaces, and special characters')
        .max(200, "Name can't be longer than 200 characters.")
        .required('Mandatory Field*'),
    authorizedRepresentative: Yup.string()
        .matches(/^[A-Za-z ´',-.()!&Åë]+$/, 'Name can only contain alphabets, spaces, and special characters')
        .max(200, "Authorized Representative can't be longer than 200 characters."),
    mailingAddress: Yup.object({
        street: Yup.string()
            .required("Mandatory Field*")
            .min(3, "Street name must be at least 3 characters.")
            .max(200, "Street name can't be longer than 200 characters."),
        selectedMailingAddressCity:  Yup.object().required ("Mandatory Field*"),
        selectedMailingAddressState: Yup.object().required ("Mandatory Field*"),
        zipcode: Yup.string()
            .trim()
            .matches(/^\d+$/, 'ZIP Code must contain only digits')
            .required('Mandatory Field*'),
    }),
    emailAddress: Yup.string()
    .matches(emailValidationRegex, { message: "Invalid email address. Please provide a valid email."})
    .required('Mandatory Field*')
    ,
    agencyOwners:Yup.array().of( Yup.object({
        // name: Yup.string()
        //     .matches(/^[A-Za-z\s]+$/, 'Name Owner can only contain alphabets and spaces'),
        // bankName: Yup.string()
        //     .required('Mandatory Field*'),
        bankAccountNumber: Yup.string()
            .matches(/^[0-9]+$/, 'Bank Account Number can only contain numbers')
            .max(200, "Bank account number can't be longer than 200 characters.")
            .nullable(),
        bankRoutingNumber: Yup.string()
            .matches(/^[A-Za-z0-9]+$/, 'Bank Routing Number can only contain alphabets and numbers')
            .max(200, "Bank routing number can't be longer than 200 characters.")
            .nullable(),
        // commissionPaymentFrequency: Yup.mixed()
        //     .required('Mandatory Field*'),
        socialSecurityNumber: Yup.string().matches(/^\d{4}$/, 'Invalid Social Security Number').nullable(),
        ein:  Yup.string().matches(/^\d{2}-\d{7}$/, 'Invalid EIN').nullable(),
        dateOfBirth: Yup.date().typeError('Invalid Date').nullable(),
        companiesContractedWith: Yup.mixed()
            .required('Mandatory Field*'),
    })),
    agencyLicenseDetails:Yup.array().of( Yup.object({
        stateLicensedIn: Yup.mixed()
            .required('Mandatory Field*'),
    })),
    cellPhone: Yup.object({
        phoneNumber: Yup.string()
        .matches(/^\d{10}$/, `Number format is invalid`)
        .test('is-valid-phone', `Invalid number`, (value, allValue) => {
          if (!value) return true;
          const { isValid } =  phone(`${allValue?.parent?.countryCode || ''}-${value}`);
          return isValid;
        })
    }),
    workPhone: Yup.object({
        phoneNumber: Yup.string()
        .matches(/^\d{10}$/, `Number format is invalid`)
        .test('is-valid-phone', `Invalid number`, (value, allValue) => {
          if (!value) return true;
          const { isValid } = phone(`${allValue?.parent?.countryCode || ''}-${value}`);
          return isValid;
      }).required('Mandatory Field*'),
    }),
    commissionLevel: Yup.number().required('Mandatory Field*'),
  });