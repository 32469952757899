/* eslint-disable  @typescript-eslint/no-explicit-any */
import editIcon from '../../../../../../../assets/icons/edit.svg';
import { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import './special-instructions.scss';
import {
  addOrUpdateFuneralHomeSpecialInstruction,
  getAllFuneralHomeSpecialInstructions,
  removeFuneralHomeSpecialInstructions,
} from '../../../../../../../api/funeralhomes';

const SpecialInstructions: any = ({ funeralHomeId }: { funeralHomeId: any }) => {
  const [specialInstructionsList, setSpecialInstructionsList] = useState<any[]>([]);
  const [editingSpecialInstructionIndex, setEditingSpecialInstructionIndex] = useState<number | null>(null);
  const [editedText, setEditedText] = useState('');
  const [addingSpecialInstruction, setAddingSpecialInstruction] = useState<boolean>(false);
  const [newSpecialInstructionText, setNewSpecialInstructionText] = useState('');
  const divRef: any = useRef(null);

  useEffect(() => {
    fetchSpecialInstructions();
  }, []);

  const fetchSpecialInstructions = async () => {
    try {
      const response = await getAllFuneralHomeSpecialInstructions(funeralHomeId);
      setSpecialInstructionsList(response.data.data.rows);
    } catch (error) {
      console.error('Error fetching notes:', error);
    }
  };
  const handleEditClick = (index: number) => {
    setEditingSpecialInstructionIndex(index);
    setEditedText(specialInstructionsList[index].special_instruction);
  };

  const handleEditCloseClick = () => {
    setEditingSpecialInstructionIndex(null);
    setEditedText('');
  };

  const handleSaveClick = async (index: number) => {
    const specialInstructionToUpdate = specialInstructionsList[index];
    try {
      await addOrUpdateFuneralHomeSpecialInstruction(funeralHomeId, specialInstructionToUpdate.id, { specialInstruction: editedText });
      const updatedNotesList = [...specialInstructionsList];
      updatedNotesList[index] = { ...specialInstructionToUpdate, special_instruction: editedText };
      setSpecialInstructionsList(updatedNotesList);
      setEditingSpecialInstructionIndex(null);
    } catch (error) {
      console.error('Error updating note:', error);
    }
  };
  // const handleCancelClick = () => {
  //   setEditingNoteIndex(null);
  // };

  const handleAddMoreSpecialInstructionsClick = () => {
    if (!addingSpecialInstruction) {
      divRef?.current?.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
    setAddingSpecialInstruction(!addingSpecialInstruction);
  };

  const handleSaveNewSpecialInstruction = async () => {
    if (newSpecialInstructionText.trim() !== '') {
      try {
        const specialInstructionData = {
          specialInstructionHeader: 'New Special Instruction',
          specialInstruction: newSpecialInstructionText,
          createdAt: new Date().toISOString(),
        };
        const response = await addOrUpdateFuneralHomeSpecialInstruction(funeralHomeId, null, specialInstructionData);
        fetchSpecialInstructions();
        setSpecialInstructionsList([response.data, ...specialInstructionsList]);
        setNewSpecialInstructionText('');
        setAddingSpecialInstruction(false);
      } catch (error) {
        console.error('Error adding new special instruction:', error);
      }
    } else {
      setAddingSpecialInstruction(false);
      console.error('Error: Special Instruction text is empty.');
    }
  };

  const handleDeleteNewSpecialInstruction = async (specialInstructionID: any) => {
    try {
      await removeFuneralHomeSpecialInstructions(funeralHomeId, specialInstructionID);
      if (editingSpecialInstructionIndex !== null && specialInstructionsList[editingSpecialInstructionIndex].id === specialInstructionID) {
        setEditingSpecialInstructionIndex(null);
      }

      fetchSpecialInstructions();

      setSpecialInstructionsList(specialInstructionsList.filter((specialInstruction) => specialInstruction.id !== specialInstructionID));

      if (addingSpecialInstruction) {
        setAddingSpecialInstruction(false);
      }
      setSpecialInstructionsList(specialInstructionsList.filter((specialInstruction) => specialInstruction.id !== specialInstructionID));
    } catch (error) {
      console.error('Error deleting special instruction:', error);
    }
  };
  return (
    <div className="view-funeral-homes-special-instruction-container">
      <button className="add-more-special-instruction-button" onClick={handleAddMoreSpecialInstructionsClick}>
        Add Special Instructions
      </button>
      <div className="special-instruction-list-div" ref={divRef}>
        <>
          {addingSpecialInstruction && (
            <>
              <div className="eachSpecialInstruction-div-add-special-instruction-mode">
                <p>Special Instruction</p>
                <textarea
                  value={newSpecialInstructionText}
                  onChange={(e) => setNewSpecialInstructionText(e.target.value)}
                  className="new-special-instruction-textarea"
                  placeholder="Write Special Instruction"
                />
                <div className="edit-buttons-special-instruction">
                  <button
                    onClick={handleSaveNewSpecialInstruction} // Delete for new note
                    className="edit-notes-cancel"
                  >
                    Cancel
                  </button>
                  <button onClick={handleSaveNewSpecialInstruction} className="edit-special-instruction-save">
                    Save
                  </button>
                </div>
              </div>
            </>
          )}
          {specialInstructionsList.length === 0 ? (
            <p className="no-special-instruction-found">No records</p>
          ) : (
            specialInstructionsList.map((eachSpecialInstruction, index) => (
              <>
                <div
                  key={eachSpecialInstruction.id}
                  className={`eachSpecialInstruction-div ${editingSpecialInstructionIndex === index ? 'editing' : ''}`}
                >
                  {editingSpecialInstructionIndex === index ? (
                    <div className="edit-special-instruction-mode">
                      <h3>Special Instruction</h3>
                      <textarea
                        value={editedText}
                        onChange={(e) => setEditedText(e.target.value)}
                        className="edit-special-instruction-textarea"
                      />
                      <div className="edit-buttons-special-instruction">
                        <button
                          onClick={handleEditCloseClick} // Delete for new note
                          className="edit-notes-cancel"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={() => handleDeleteNewSpecialInstruction(eachSpecialInstruction.id)}
                          className="edit-special-instruction-delete"
                        >
                          Delete
                        </button>
                        <button onClick={() => handleSaveClick(index)} className="edit-special-instruction-save">
                          Save
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="normal-special-instruction-mode">
                      <div className="special-instruction-header">
                        <h3>Special Instruction</h3>
                        <div className="edit-special-instruction-icon-div" onClick={() => handleEditClick(index)}>
                          <img src={editIcon} className="edit-special-instruction-icon" />
                        </div>
                      </div>
                      <p className="special-instruction-text">{eachSpecialInstruction.special_instruction}</p>
                      <p className="notes-text-date">{moment(eachSpecialInstruction.created_at).format('MM-DD-YYYY h:mm A')}</p>
                    </div>
                  )}
                </div>
              </>
            ))
          )}
        </>
      </div>
    </div>
  );
};

export default SpecialInstructions;
