import { createNamespacer, createReducer } from '../utils/reducers';

const initialState = {
  isLoggedIn: false,
  user: null,
  email: null,
  location: null
};

const namespacer = createNamespacer('SESSION');

const handlers = {
  [namespacer('SET_IS_LOGGED_IN')]: (state:any, action:any) => {
    return {
      ...state,
      isLoggedIn: action.payload.value,
      email: action.payload.email,
      location: action.payload.location
    };
  }
};

const reducer = createReducer(initialState, handlers, ['SESSION']);

export default reducer;
