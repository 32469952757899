import React, { useState } from "react";
import { Offcanvas, OffcanvasHeader, OffcanvasBody, Button } from "reactstrap";
import closeIcon from "../../../assets/icons/closeIcon.svg";
import { Formik, Form } from "formik";
import LocationFilters from "../LocationFilters";

interface FilterRegionalRepsProps {
  isOpen: boolean;
  toggleFilter: any;
  page?: number;
  appliedFilters?: any;
  setAppliedFilters?: any;
}

const FilterRegionalReps: React.FC<FilterRegionalRepsProps> = ({
  isOpen,
  toggleFilter,
  page = 1,
  appliedFilters = {},
  setAppliedFilters,
}) => {
  const [resetFlag, setResetFlag] = useState(false);
  const initialValues = {
    countryIds: appliedFilters.countryIds || [],
    stateIds: appliedFilters.stateIds || [],
    cityIds: appliedFilters.cityIds || [],
  };
  const onSubmit = async (values: any) => {
    const isFiltersSelected =
      values.countryIds.length > 0 ||
      values.stateIds.length > 0 ||
      values.cityIds.length > 0;
    if (isFiltersSelected) {
      setAppliedFilters(values);
    }
    toggleFilter();
  };
  const handleReset = (resetForm: () => void) => {
    if (Object.keys(appliedFilters).length > 0) {
      resetForm();
      setResetFlag((prev) => !prev);
      setAppliedFilters({})
      toggleFilter();
    } else {
      resetForm();
      setResetFlag((prev) => !prev);
    }
  };
  return (
    <div className="filter-offcanvas">
      <Offcanvas isOpen={isOpen} direction="end" className="offcanva-box">
        <OffcanvasHeader className="filter-offcanvas-header">
          <div className="header-div">
            <h2 className="mb-0">Filters</h2>
            <button onClick={toggleFilter}>
              <img src={closeIcon} alt="Close" />
            </button>
          </div>
        </OffcanvasHeader>
        <OffcanvasBody className="offcanvas-body">
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ setFieldValue, values, resetForm }) => (
              <Form className="filters-form">
                <LocationFilters
                  module="regionalRep"
                  setFieldValue={setFieldValue}
                  selectedCountries={values.countryIds}
                  selectedStates={values.stateIds}
                  selectedCities={values.cityIds}
                  resetLocationFilters={resetFlag}
                  appliedFiltersFlag={
                    Object.keys(appliedFilters).length > 0 ? true : false
                  }
                />
                <div className="filter-form-buttons">
                  <Button
                    className="filter-reset-button"
                    type="button"
                    onClick={() => handleReset(resetForm)}
                  >
                    Reset
                  </Button>
                  <Button className="filter-apply-button" type="submit">
                    Apply
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
};

export default FilterRegionalReps;
