import React from "react";
import crownIcon from '../../assets/icons/crownIcons.svg';
import charmTickIcons from '../../assets/icons/charmTickIcons.svg';
import diamondIcons from '../../assets/icons/diamondIcons.svg';
import starIcons from '../../assets/icons/starIcons.svg';
import BreadcrumbComponent from "../../common/components/breadcrumb-component/breadcrumb-component";
import './packages.scss';

const Packages:React.FC =()=> {
    let crumbMapping = [
        { label: 'Packages', link: '/packages' },
    ];
    return(
        <div>
            <BreadcrumbComponent breadcrumbLink={crumbMapping}/>
            <div className="packages">
                <div className="guides-funding-programs">
                    <div className="guides-funding-programs-cards">
                        <div className="guides-funding-programs-cards-header-icons-status">
                            <div className="cards-header-icons">
                                <img src={crownIcon} className="cardsHeaderIcons" alt="cardsHeaderIcons"/>
                            </div>
                        </div>
                        <div className="guides-funding-programs-cards-header-text">
                            <div className="cards-header-text-main">
                                Mid-Premium Plan
                            </div>
                            <div className="cards-header-text-sub">
                                Flexible funding programs developed by our insurance partners.
                            </div>
                        </div>
                        <div className="guides-funding-programs-cards-prices-text">
                            <div>
                                <span className="cards-prices-text-main">$22,000</span><span className="cards-prices-text-sub">/month</span>
                            </div>
                        </div>
                        <div className="guides-funding-programs-cards-benefits-text">
                            <div className="guides-funding-programs-cards-benefits-header-text">
                                Benefits
                            </div>
                            <div className="guides-funding-programs-cards-benefits-list">
                                <div className="guides-funding-programs-cards-benefits-list-img">
                                    <img src={charmTickIcons}  alt="down-arrow-icon"/>
                                </div>
                                <div className="guides-funding-programs-cards-benefits-list-text">
                                    Flexible funding programs
                                </div>
                            </div>
                            <div className="guides-funding-programs-cards-benefits-list">
                                <div className="guides-funding-programs-cards-benefits-list-img">
                                    <img src={charmTickIcons}  alt="down-arrow-icon"/>
                                </div>
                                <div className="guides-funding-programs-cards-benefits-list-text">
                                    Easy access
                                </div>
                            </div>
                            <div className="guides-funding-programs-cards-benefits-list">
                                <div className="guides-funding-programs-cards-benefits-list-img">
                                    <img src={charmTickIcons}  alt="down-arrow-icon"/>
                                </div>
                                <div className="guides-funding-programs-cards-benefits-list-text">
                                    Complete control
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="guides-funding-programs-cards">
                        <div className="guides-funding-programs-cards-header-icons-status">
                            <div className="cards-header-icons">
                                <img src={diamondIcons} className="cardsHeaderIcons" alt="cardsHeaderIcons"/>
                            </div>
                            <div className="cards-header-status">
                                Premium Plan
                            </div>
                        </div>
                        <div className="guides-funding-programs-cards-header-text">
                            <div className="cards-header-text-main">
                                Premium Plan
                            </div>
                            <div className="cards-header-text-sub">
                                Flexible funding programs developed by our insurance partners.
                            </div>
                        </div>
                        <div className="guides-funding-programs-cards-prices-text">
                            <div>
                                <span className="cards-prices-text-main">$50,000</span><span className="cards-prices-text-sub">/month</span>
                            </div>
                        </div>
                        <div className="guides-funding-programs-cards-benefits-text">
                            <div className="guides-funding-programs-cards-benefits-header-text">
                                Benefits
                            </div>
                            <div className="guides-funding-programs-cards-benefits-list">
                                <div className="guides-funding-programs-cards-benefits-list-img">
                                    <img src={charmTickIcons}  alt="down-arrow-icon"/>
                                </div>
                                <div className="guides-funding-programs-cards-benefits-list-text">
                                    Flexible funding programs
                                </div>
                            </div>
                            <div className="guides-funding-programs-cards-benefits-list">
                                <div className="guides-funding-programs-cards-benefits-list-img">
                                    <img src={charmTickIcons}  alt="down-arrow-icon"/>
                                </div>
                                <div className="guides-funding-programs-cards-benefits-list-text">
                                    Easy access
                                </div>
                            </div>
                            <div className="guides-funding-programs-cards-benefits-list">
                                <div className="guides-funding-programs-cards-benefits-list-img">
                                    <img src={charmTickIcons}  alt="down-arrow-icon"/>
                                </div>
                                <div className="guides-funding-programs-cards-benefits-list-text">
                                    Complete control
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="guides-funding-programs-cards">
                        <div className="guides-funding-programs-cards-header-icons-status">
                            <div className="cards-header-icons">
                                <img src={starIcons} className="cardsHeaderIcons" alt="cardsHeaderIcons"/>
                            </div>
                        </div>
                        <div className="guides-funding-programs-cards-header-text">
                            <div className="cards-header-text-main">
                                Basic Plan
                            </div>
                            <div className="cards-header-text-sub">
                                Flexible funding programs developed by our insurance partners.
                            </div>
                        </div>
                        <div className="guides-funding-programs-cards-prices-text">
                            <div>
                                <span className="cards-prices-text-main">$10,000</span><span className="cards-prices-text-sub">/month</span>
                            </div>
                        </div>
                        <div className="guides-funding-programs-cards-benefits-text">
                            <div className="guides-funding-programs-cards-benefits-header-text">
                                Benefits
                            </div>
                            <div className="guides-funding-programs-cards-benefits-list">
                                <div className="guides-funding-programs-cards-benefits-list-img">
                                    <img src={charmTickIcons}  alt="down-arrow-icon"/>
                                </div>
                                <div className="guides-funding-programs-cards-benefits-list-text">
                                    Flexible funding programs
                                </div>
                            </div>
                            <div className="guides-funding-programs-cards-benefits-list">
                                <div className="guides-funding-programs-cards-benefits-list-img">
                                    <img src={charmTickIcons}  alt="down-arrow-icon"/>
                                </div>
                                <div className="guides-funding-programs-cards-benefits-list-text">
                                    Easy access
                                </div>
                            </div>
                            <div className="guides-funding-programs-cards-benefits-list">
                                <div className="guides-funding-programs-cards-benefits-list-img">
                                    <img src={charmTickIcons}  alt="down-arrow-icon"/>
                                </div>
                                <div className="guides-funding-programs-cards-benefits-list-text">
                                    Complete control
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Packages;