/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import AdditionalLocationsTable from './additional-locations-table/additional-locations-table';
import { getAllAdditionalLocationDetails, removeAdditionalLocationDetails } from '../../../../../../../api/funeralhomes';
import { formatAddress } from './additional-locations.utils';
import { columnNames } from './additional-locations.constant';
import DeleteConfirmationPopup from '../delete-confirmation-popup/delete-confirmation-popup';
import AlertComponent from '../../../../../../../common/components/Alert/Alert';
import './additional-locations.scss';

const AdditionalLocations: any = (props: any) => {
  const { funeralHomeId, setLoading, addMoreLabel, setAddMoreLabel } = props;
  const [rowsData, setRowsData] = useState<any[]>([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const [isDeletePopupOpen, setDeletePopupOpen] = useState<boolean>(false);
  const [isEditProgress, setIsEditProgress] = useState<any>(' ');
  const [deleteAdditionalLocationId, setDeleteAdditionalLocationId] = useState<number | null>(null);

  const handleEditClick = (id: any) => {
    const updatedrowsData = rowsData
      ?.map?.((data: any) => {
        return {
          ...data,
          isEdit: data?.id === id ? !data?.isEdit : false,
        };
      })
      ?.filter((data) => !data?.isNewAdded);

    setRowsData(updatedrowsData);
  };

  const handleAddMoreLocation = (id?: any, data?: any) => {
    setIsEditProgress('additional-locations-table-box-active');
    const tempRowsData = data?.length ? data : rowsData;
    const updatedrowsData = [
      ...tempRowsData
        ?.map?.((data: any) => {
          return {
            ...data,
            isEdit: data?.id === id ? !data?.isEdit : false,
          };
        })
        ?.filter((data: any) => !data?.isNewAdded),
      {
        Edit: '',
        id: null,
        additionalLocationFocusedField: null,
        funeralHomOptions: [],
        selectedFuneralHome: null,
        funeralHomeName: '',
        selectLocation: '',
        ownerName: '',
        phoneNumber: '',
        emailAddress: '',
        faxNumber: '',
        taxId: '',
        isEdit: true,
        isNewAdded: true,
      },
    ];
    setRowsData(updatedrowsData);
  };

  const handleGetAdditionalLocations = async () => {
    try {
      setLoading(true);
      const getAPIDetails = await getAllAdditionalLocationDetails(funeralHomeId);
      setLoading(false);
      const tempRowsData = getAPIDetails?.data?.data?.map((data: any) => {
        return {
          Edit: '',
          id: data?.id,
          additionalLocationFocusedField: null,
          funeralHomOptions: [],
          selectedFuneralHome: { id: data?.affiliateFuneralHome?.id, name: data?.affiliateFuneralHome?.name },
          funeralHomeName: data?.affiliateFuneralHome?.name || '-',
          selectLocation: formatAddress(data?.affiliateFuneralHome?.physicalAddress) || '-',
          ownerName: data?.affiliateFuneralHome?.owner_name || '-',
          phoneNumber: data?.affiliateFuneralHome?.contact_no || '-',
          emailAddress: data?.affiliateFuneralHome?.email || '-',
          faxNumber: data?.affiliateFuneralHome?.fax_number || '-',
          taxID: data?.affiliateFuneralHome?.tax_id || '-',
          isEdit: false,
        };
      });
      setRowsData(tempRowsData);
      if (addMoreLabel === 'Add Additional Location') {
        setAddMoreLabel(null);
        handleAddMoreLocation(null, tempRowsData);
      }
    } catch {
      setLoading(false);
    }
  };

  const handleDeleteClick = async (id: any) => {
    setDeleteAdditionalLocationId(id);
    setDeletePopupOpen(true);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await removeAdditionalLocationDetails(funeralHomeId, deleteAdditionalLocationId);
      setLoading(false);
      setDeleteAdditionalLocationId(null);
      setDeletePopupOpen(false);
      handleGetAdditionalLocations();
    } catch (error) {
      const errorMessage =
        (typeof error === 'object' && error !== null && 'response' in error && (error as any).response?.data?.details?.[0]) ||
        (error instanceof Error ? error.message : 'An unexpected error occurred.');
      setAlertMessage(errorMessage);
      setAlertType('error');
      setAlertOpen(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetAdditionalLocations();
  }, [funeralHomeId]);

  useEffect(() => {
    if (addMoreLabel === 'Add Additional Location') {
      handleGetAdditionalLocations();
    }
  }, [addMoreLabel]);

  return (
    <div className="view-funeral-homes-right-container">
      <AdditionalLocationsTable
        columnNames={columnNames}
        rowsData={rowsData}
        handleEditClick={handleEditClick}
        handleDeleteClick={handleDeleteClick}
        handleAddMoreLocation={handleAddMoreLocation}
        setLoading={setLoading}
        funeralHomeId={funeralHomeId}
        handleGetAdditionalLocations={handleGetAdditionalLocations}
        setAlertOpen={setAlertOpen}
        setAlertType={setAlertType}
        setAlertMessage={setAlertMessage}
        isEditProgress={isEditProgress}
        setIsEditProgress={setIsEditProgress}
      />
      <DeleteConfirmationPopup
        isOpen={isDeletePopupOpen}
        onClose={() => setDeletePopupOpen(!isDeletePopupOpen)}
        handleDelete={handleDelete}
        label="Additional Location"
      />
      <AlertComponent message={alertMessage} type={alertType} open={alertOpen} onClose={() => setAlertOpen(false)} />
    </div>
  );
};

export default AdditionalLocations;
