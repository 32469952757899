import React from "react";
import {
  Row
} from "reactstrap";
import {
  DirectoryAddressComponent,
  InputComponent,
  PhoneNumberComponent
} from "../../../../common/components/index";

const AgencyDetailsSection = (props: any) => {

    const {
        values,
        setFieldValue
    }= props;
    
    return (
        <div className="agencies-agencie-detail">
        <span className="agencies-agencie-detail-heading">Agency Details*</span>
        <div className="agencies-agencie-detail-container">
          <div className="agencies-agencie-detail-form">
            <Row xs={12} className="single-line-row-form-input heading-name-label">
              <InputComponent
                fieldName="name"
                label="Name of Agency"
                placeholder="Enter Name of Agency"
                optional = {false}
                isAlpha
              />
            </Row>
            <Row xs={12} className="single-line-row-form-input heading-name-label">
              <InputComponent
                fieldName="authorizedRepresentative"
                label="Authorized Representative"
                placeholder="Enter Authorized Representative"
                optional = {true}
                isAlpha
              />
            </Row>
            <Row  xs={12} className="single-line-row-form-input sub-name-label">
              <DirectoryAddressComponent
                addressLabel="mailingAddress"
                streetFieldName="mailingAddress.street"
                zipCodeFieldName="mailingAddress.zipcode"
                setCity={(city)=>setFieldValue('mailingAddress.mailingAddressCity', city)}   
                setState={(state)=>setFieldValue('mailingAddress.mailingAddressState', state)} 
                selectedState={values?.mailingAddress?.selectedMailingAddressState}
                selectedCity={values?.mailingAddress?.selectedMailingAddressCity}
                setSelectedCity = {(city)=>setFieldValue('mailingAddress.selectedMailingAddressCity', city)} 
                setSelectedState = {(state)=>setFieldValue('mailingAddress.selectedMailingAddressState', state)}
                cityFieldName="mailingAddress.selectedMailingAddressCity"
                stateFieldName="mailingAddress.selectedMailingAddressState"
                optional = {false}
              />
            </Row>
            <Row xs={12} className="single-line-row-form-input sub-name-label">
                <PhoneNumberComponent
                  label="Work Phone Number"
                  fieldName="workPhone.phoneNumber"
                  countryCodeFieldName="workPhone.countryCode"
                  placeholder="Enter Work Phone Number"
                  optional={false}/>
            </Row>
            <Row xs={12} className="single-line-row-form-input sub-name-label">
                <PhoneNumberComponent
                  label="Cell Phone Number"
                  fieldName="cellPhone.phoneNumber"
                  countryCodeFieldName="cellPhone.countryCode"
                  placeholder="Enter Cell Phone Number"
                  optional={true}/>
            </Row>
            <Row  xs={12} className="single-multi-line-row-form-input sub-name-label">
              <InputComponent
                fieldName="emailAddress"
                label="Email Address"
                placeholder="Enter Email Address"
                optional = {false}
              />
            </Row>
          </div>
        </div>
      </div>
    );
};

export default AgencyDetailsSection;