import { splitPhoneNumber } from "../../../utils";

export const getInitialValues =(editData?: any )=>{
    return  {
        name: editData?.name || "",
        physicalAddress: {
            id: editData?.physicalAddress?.id,
            street: editData?.physicalAddress?.street || "",
            city: editData?.physicalAddress?.City?.name || "",
            state: editData?.physicalAddress?.State?.name || "",
            zipcode: editData?.physicalAddress?.zipcode || "",
            selectedPhysicalAddressCity: editData.physicalAddress.City,
            selectedPhysicalAddressState: editData.physicalAddress.State,
            physicalAddressCity: null,
            physicalAddressState: null,
        },
        mailingAddress: {
            id: editData?.mailingAddress?.id,
            street: editData?.mailingAddress?.street || "",
            city: editData?.mailingAddress?.City?.name || "",
            state: editData?.mailingAddress?.State?.name || "",
            zipcode: editData?.mailingAddress?.zipcode || "",
            selectedMailingAddressCity: editData.mailingAddress.City, 
            selectedMailingAddressState: editData.mailingAddress.State,
            mailingAddressCity: null,
            mailingAddressState: null,
        },
        ownerName: editData?.owner_name || "",
        phoneNumber: splitPhoneNumber(editData?.contact_no),
        emailAddress: editData?.email || "",
        faxNumber: editData?.fax_number || "",
        taxId: editData?.tax_id || "",
    }
}