/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useMemo } from 'react';
import { Formik, Form, Field, ErrorMessage, FieldProps } from 'formik';
import * as Yup from 'yup';
import './ProfilePage.scss';
import { updateUser, uploadProfileImageApi } from '../../api/profile';
import CameraIcon from '../../assets/icons/camera.png';
import ProfileImage from '../../assets/icons/person-fill.svg';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import AlertComponent from '../../common/components/Alert/Alert';
import { refreshUserData, useUser } from '../../common/hooks/useUser';
import { useSetLoading } from '../../common/hooks/appearance';
import Country from '../../common/components/autocomplete/Country';
import BreadcrumbComponent from '../../common/components/breadcrumb-component/breadcrumb-component';
import { concatenatePhoneNumber, splitPhoneNumber } from '../directory/utils';
import { checkUserUpdate } from '../../common/routines/user-profile';
import Select from 'react-select';
import Flags from 'react-world-flags';
import { get } from 'lodash';
import { useNavigate } from 'react-router-dom';
interface LocationOption {
  id: number;
  name: string;
  State?: any;
  Country?: any;
  Cities?: LocationOption[];
}
interface UserValues {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: {
    countryCode: string;
    phoneNumber: string;
  };
  address_line_1: string;
  address_line_2: string;
  postal_code: string;
}
const countryCodes = [
  { code: '+1', flag: 'US' }, // United States
  { code: '+91', flag: 'IN' }, // India
  { code: '+44', flag: 'GB' }, // United Kingdom
];

const ProfilePage: React.FC = () => {
  const [initialValues, setInitialValues] = useState<UserValues>({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: {
      countryCode: '+1',
      phoneNumber: '',
    },
    address_line_1: '',
    address_line_2: '',
    postal_code: '',
  });
  const [userId, setUserId] = useState<number | null>(null);
  const [cityId, setCityId] = useState<number | null>(null);
  const [stateId, setStateId] = useState<number | null>(null);
  const [countryId, setCountryId] = useState<number | null>(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState<'success' | 'error' | 'info' | 'warning'>('success');

  const [selectedCity, setSelectedCity] = useState<LocationOption | null>(null);
  const [selectedState, setSelectedState] = useState<LocationOption | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<LocationOption | null>(null);

  const [profileImage, setProfileImage] = useState<string | null>(ProfileImage);

  const [loading, setLoading] = useState(false);
  const user = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    setUserId(user?.id);
    setInitialValues({
      first_name: user?.first_name || '',
      last_name: user?.last_name || '',
      email: user?.email || '',
      phone_number: user?.phone_number ? splitPhoneNumber(user?.phone_number) : { countryCode: '+1', phoneNumber: '' },
      address_line_1: user?.address_line_1 || '',
      address_line_2: user?.address_line_2 || '',
      postal_code: user?.postal_code || '',
    });
    setSelectedCity(user?.City ? { name: user.City.name, id: user.City.id } : null);
    setSelectedState(user?.State ? { name: user.State.name, id: user.State.id } : null);
    setSelectedCountry(user?.Country ? { name: user.Country.name, id: user.Country.id } : null);
    setCityId(user?.city_id);
    setStateId(user?.state_id);
    setCountryId(user?.country_id);
    setProfileImage(user?.image_url || ProfileImage);
  }, [user]);

  const handleFileUpload = async (files: FileList | null) => {
    if (!files || files.length === 0) return;
    setLoading(true);

    const file = files[0];
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await uploadProfileImageApi(file);
      if (response.data.success) {
        const imageUrl = response.data.data.imageUrl;
        setProfileImage(imageUrl);
        setAlertMessage('Profile image uploaded successfully.');
        setAlertType('success');
        setTimeout(() => {
          setLoading(false);
          window.location.reload();
        }, 1000);
        setAlertOpen(true);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error: any) {
      setAlertMessage(error.response.data?.message);
      setAlertType('error');
      setLoading(false);
      setAlertOpen(true);
    }
  };

  const breadcrumbItems = [{ label: 'Profile', path: '/profile', active: true }];

  const validationSchema = Yup.object({
    first_name: Yup.string()
      .matches(/^[A-Za-z ´',-.()!&Åë]+$/, 'First name can only contain letters and special characters')
      .required('First name is required'),
    last_name: Yup.string()
      .matches(/^[A-Za-z ´',-.()!&Åë]+$/, 'First name can only contain letters and special characters')
      .required('Last name is required'),
    phone_number: Yup.object({
      countryCode: Yup.string()
        .matches(/^\+\d{1,4}$/)
        .required('Country code is required'),
      phoneNumber: Yup.string()
        .matches(/^\d{10}$/, 'Phone Number is invalid')
        .required('Phone Number is required'),
    }),
  });

  const onSubmit = (values: UserValues) => {
    setLoading(true);
    const modifiedValues = {
      ...values,
      phone_number: concatenatePhoneNumber(values.phone_number),
    };
    const updatedFields: Record<string, any> = {};
    for (const key in modifiedValues) {
      if (modifiedValues[key as keyof UserValues] !== initialValues[key as keyof UserValues]) {
        updatedFields[key as keyof UserValues] = modifiedValues[key as keyof UserValues];
      }
    }
    if (cityId !== user?.City?.id) {
      updatedFields['city_id'] = cityId;
    }
    if (stateId !== user?.State?.id) {
      updatedFields['state_id'] = stateId;
    }
    if (countryId !== user?.Country?.id) {
      updatedFields['country_id'] = countryId;
    }

    updateUser(userId, updatedFields)
      .then(() => {
        setAlertMessage('User details updated successfully!');
        setAlertType('success');
        checkUserUpdate();
        refreshUserData();
        setTimeout(() => navigate(-1), 2000);
      })
      .catch((error) => {
        setAlertMessage(`${error.response.data.details}`);
        setAlertType('error');
        setTimeout(() => setLoading(false), 1000);
        setAlertOpen(true);
      })
      .finally(() => {
        setTimeout(() => setLoading(false), 1000);
        setTimeout(() => setAlertOpen(true), 1000);
      });
  };

  useEffect(() => {
    refreshUserData();
  }, []);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const customCountryCodeOptions = countryCodes.map((item) => ({
    value: item.code,
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Flags code={item.flag} style={{ width: 20, height: 14, marginRight: 8 }} />
        {item.code}
      </div>
    ),
  }));
  const handlePhoneNumberInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!/^\d*$/.test(value)) {
      event.target.value = value.replace(/\D/g, '');
    } else if (value.length > 10) {
      event.target.value = value.slice(0, 10);
    }
  };
  const showLoading = useMemo(() => loading, [loading]);
  useSetLoading(showLoading);
  return (
    <div>
      <BreadcrumbComponent breadcrumbLink={breadcrumbItems} />

      <div className="profile-page">
        <div className="profile-header">
          <div className="profile-picture-container">
            <img src={`${profileImage}`} alt="Profile" className="profile-picture" />
            <div className="camera-icon-overlay" onClick={() => document.getElementById('fileInput')?.click()}>
              <img src={CameraIcon} alt="Camera Icon" className="camera-icon" />
            </div>
            <Input
              type="file"
              id="fileInput"
              accept="image/*"
              onChange={(e) => handleFileUpload(e.target.files)}
              style={{ display: 'none' }}
            />
          </div>

          <div className="profile-name">
            <h2>Full Name</h2>
            <h1>{`${initialValues.first_name} ${initialValues.last_name}`}</h1>
          </div>
        </div>

        <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ touched, errors }) => (
            <div className="profile-form">
              <Form>
                <Row className="profile-row">
                  <Col xs="6">
                    <FormGroup className="form-group">
                      <Label className="custom-label">First Name</Label>
                      <Field
                        type="text"
                        name="first_name"
                        placeholder="First Name"
                        className={`custom-input ${touched.first_name && errors.first_name ? 'is-invalid' : ''}`}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                          e.target.value = e.target.value.replace(/[^A-Za-z `',-.()!Åë]/g, '');
                        }}
                        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                          if (!/^[A-Za-z `',-.()!Åë]$/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <ErrorMessage name="first_name" component="div" className="invalid-feedback" />
                    </FormGroup>
                  </Col>
                  <Col xs="6">
                    <FormGroup className="form-group">
                      <Label className="custom-label">Last Name</Label>
                      <Field
                        type="text"
                        name="last_name"
                        placeholder="Last Name"
                        className={`custom-input ${touched.last_name && errors.last_name ? 'is-invalid' : ''}`}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                          e.target.value = e.target.value.replace(/[^A-Za-z `',-.()!Åë]/g, '');
                        }}
                        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                          if (!/^[A-Za-z `',-.()!Åë]$/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <ErrorMessage name="last_name" component="div" className="invalid-feedback" />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="profile-row">
                  <Col xs="6">
                    <FormGroup className="form-group">
                      <Label className="custom-label">Email</Label>
                      <Field type="email" name="email" className="custom-input" disabled />
                    </FormGroup>
                  </Col>
                  <Col xs="6">
                    <FormGroup className="form-group">
                      <Label className="custom-label">Phone Number</Label>
                      <div className="phone-number-row">
                        <div className="phone-number-field">
                          <Field
                            name="phone_number.countryCode"
                            className={`custom-input ${touched.phone_number && errors.phone_number?.countryCode ? 'is-invalid' : ''}`}
                          >
                            {({ form }: FieldProps) => {
                              const countryCodeValue = get(form.values, 'phone_number.countryCode');
                              return (
                                <Select
                                  options={customCountryCodeOptions}
                                  className="custom-country-code-dropdown"
                                  classNamePrefix="custom-country-code-dropdown"
                                  onChange={(selectedOption) => {
                                    form.setFieldValue('phone_number.countryCode', selectedOption?.value);
                                  }}
                                  value={customCountryCodeOptions.find((option) => option.value === countryCodeValue)}
                                />
                              );
                            }}
                          </Field>
                          <ErrorMessage name="phone_number.countryCode" component="div" className="invalid-feedback" />
                        </div>
                        <div className="phone-number-field">
                          <Field
                            name="phone_number.phoneNumber"
                            className={`custom-input ${touched.phone_number && errors.phone_number?.phoneNumber ? 'is-invalid' : ''}`}
                          >
                            {({ field }: FieldProps) => (
                              <Input
                                {...field}
                                type="text"
                                id="phone_number.phoneNumber"
                                placeholder="Phone Number"
                                className="custom-phone-input form-control"
                                onInput={handlePhoneNumberInput}
                              />
                            )}
                          </Field>
                          <ErrorMessage name="phone_number.phoneNumber" component="div" className="invalid-feedback" />
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Country
                  cityId={cityId}
                  stateId={stateId}
                  countryId={countryId}
                  setCityId={setCityId}
                  setStateId={setStateId}
                  setCountryId={setCountryId}
                  selectedCity={selectedCity}
                  setSelectedCity={setSelectedCity}
                  selectedState={selectedState}
                  setSelectedState={setSelectedState}
                  selectedCountry={selectedCountry}
                  setSelectedCountry={setSelectedCountry}
                  addressLine1FieldName="address_line_1"
                  addressLine2FieldName="address_line_2"
                  postalCodeFieldName="postal_code"
                />
                <Button type="button" className={'cancel-button'} onClick={() => navigate(-1)}>
                  Cancel
                </Button>
                <Button type="submit" className="save-button">
                  Save Changes
                </Button>
              </Form>
              <div>
                <AlertComponent message={alertMessage} type={alertType} open={alertOpen} onClose={handleAlertClose} />
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ProfilePage;
