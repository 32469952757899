/* eslint-disable  @typescript-eslint/no-explicit-any */
import { useRef, useMemo, useCallback } from 'react';
import { Formik, ErrorMessage } from 'formik';
import debounce from 'lodash/debounce';
import { Autocomplete, TextField } from '@mui/material';
import { addUpdateCompaniesDetails, getAutoFillDirectories } from '../../../../../../../../api/funeralhomes';
import { InputComponent } from '../../../../../../../../common/components/index';
import { validationSchema } from './companies-table.validation';
import { CompaniesTableCellFormProps } from './companies-table-props';
import { formatAddress } from '../companies.utils';
import tickIcons from '../../../../../../../../assets/icons/tickIcons.svg';
import crossIcons from '../../../../../../../../assets/icons/crossIcons.svg';

const CompaniesTableCellForm: React.FC<CompaniesTableCellFormProps> = ({
  initialValues,
  colIndex,
  handleEditClick,
  setIsEditProgress,
  setLoading,
  funeralHomeId,
  handleGetCompaniesDetails,
  setAlertOpen,
  setAlertType,
  setAlertMessage,
}) => {
  const formRef: any = useRef();

  const handleCompanyChange = (event: any, newValue: any, setFieldValue: any) => {
    if (newValue) {
      setFieldValue(`selectedCompany`, { id: newValue.id, name: newValue.name });
      setFieldValue(`companyName`, newValue?.name || '-');
      setFieldValue(`companyPhysicalAddress`, formatAddress(newValue?.physicalAddress) || '-');
      setFieldValue(`companyMailingAddress`, formatAddress(newValue?.physicalAddress) || '-');
      setFieldValue(`contactNumbersClaims`, newValue?.contact_numbers_claims || '-');
      setFieldValue(`contactNumbersAgents`, newValue?.contact_numbers_agents || '-');
      setFieldValue(`contactNames`, newValue?.contact_names || '-');
      setFieldValue(`contactDepartments`, newValue?.contact_departments || '-');
    } else {
      setFieldValue(`selectedCompany`, null);
      setFieldValue(`companyName`, '');
      setFieldValue(`companyPhysicalAddress`, '');
      setFieldValue(`companyMailingAddress`, '');
      setFieldValue(`contactNumbersClaims`, '');
      setFieldValue(`contactNumbersAgents`, '');
      setFieldValue(`contactNames`, '');
      setFieldValue(`contactDepartments`, '');
    }
  };

  const handleCompanyFocus = (setFieldValue: any) => setFieldValue(`companyFocusedField`, 'companyFocusedField');

  const fetchCompanyOptions = useCallback(async (value: string, setFieldValue?: any) => {
    try {
      if (value.length < 3) return;
      const response = await getAutoFillDirectories('companies', value);
      const data: any = response.data.data;
      setFieldValue('companyOptions', data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);

  const debouncedFetchCompanyOptions = useMemo(() => debounce(fetchCompanyOptions, 500), [fetchCompanyOptions]);

  const onSubmit = async (value: any) => {
    console.log(value);
    const payloadFormatted = { companyId: value?.selectedCompany?.id };
    try {
      setLoading(true);
      await addUpdateCompaniesDetails(funeralHomeId, value?.id, payloadFormatted);
      setLoading(false);
      handleGetCompaniesDetails();
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message
        ? error?.response?.data?.message
        : (typeof error === 'object' && error !== null && 'response' in error && (error as any).response?.data?.details?.[0]) ||
          (error instanceof Error ? error.message : 'An unexpected error occurred.');
      setAlertMessage(errorMessage);
      setAlertType('error');
      setAlertOpen(true);
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    formRef?.current?.handleSubmit();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={formRef}
      isValidating={true}
      setSubmitting={true}
      isSubmitting={true}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => (
        <tr>
          <td key={colIndex} className={`${'companies-table-sticky-column'}`}>
            <img
              className={`edit-form-icon`}
              src={tickIcons}
              alt="Edit Icon"
              onClick={() => {
                handleSubmit();
              }}
            />
            <img
              className={`edit-form-icon`}
              src={crossIcons}
              alt="Edit Icon"
              onClick={() => {
                setIsEditProgress('');
                handleEditClick?.(values?.id);
              }}
            />
          </td>
          <td key={colIndex} className={`${'companies-table-sticky-column'}`}>
            <div className={`companies-table-column-component`}>
              <Autocomplete
                options={values?.companyOptions}
                getOptionLabel={(option) => option?.name || ''}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event: any, newValue: any) => handleCompanyChange(event, newValue, setFieldValue)}
                onInputChange={(event, value) => {
                  if (values?.companyFocusedField === 'companyFocusedField') {
                    debouncedFetchCompanyOptions(value, setFieldValue);
                  }
                }}
                onFocus={() => handleCompanyFocus(setFieldValue)}
                renderInput={(params) => <TextField {...params} placeholder="Select Company" className="autocomplete-text-field" />}
                value={values?.selectedCompany || null}
                className="autocomplete-div"
              />
              <ErrorMessage name={`selectedCompany`} component="div" className="error-message" />
            </div>
          </td>
          <td key={colIndex} className={``}>
            <div className={`companies-table-column-component`}>
              <InputComponent fieldName="companyPhysicalAddress" placeholder="Enter Physical Address" disabled={true} isAlpha />
            </div>
          </td>
          <td key={colIndex}>
            <div className={`companies-table-column-component`}>
              <InputComponent fieldName="companyMailingAddress" placeholder="Enter Mailing Address" disabled={true} isAlpha />
            </div>
          </td>
          <td key={colIndex}>
            <div className={`companies-table-column-component`}>
              <InputComponent fieldName="contactNumbersClaims" placeholder="Enter Numbers Claims" disabled={true} isAlpha />
            </div>
          </td>
          <td key={colIndex}>
            <div className={`companies-table-column-component`}>
              <InputComponent fieldName="contactNumbersAgents" placeholder="Enter Numbers Agents" disabled={true} isAlpha />
            </div>
          </td>
          <td key={colIndex}>
            <div className={`companies-table-column-component`}>
              <InputComponent fieldName="contactNames" placeholder="Enter contact names" disabled={true} isAlpha />
            </div>
          </td>
          <td key={colIndex}>
            <div className={`companies-table-column-component`}>
              <InputComponent fieldName="contactDepartments" placeholder="Enter contact departments" disabled={true} isAlpha />
            </div>
          </td>
        </tr>
      )}
    </Formik>
  );
};
export default CompaniesTableCellForm;
