const notificationConstants = {
  COMMON_SETTINGS: {
    animationIn: ["animated", "fadeIn"],
    container: "top-right",
    animationOut: ["animated", "fadeOut"],
  },
  dismiss: {
    showIcon: true,
    duration: 5000,
  },
  SUCCESS: {
    title: "Successful",
    type: "success",
  },
  ERROR: {
    title: "Error",
    type: "danger",
  },
  WARNING: {
    title: "Warning",
    type: "warning",
  },
  GENERIC_ERROR_NOTIFICATION: {
    title: "Error",
    message: "Something went wrong. Please retry in sometime.",
    type: "danger",
  },
};

export default notificationConstants;