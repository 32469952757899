import React, { useState } from "react";
import "./details-section.scss";
import EditDetailsSection from "./edit-details-section";
import ViewDetailsSection from "./view-details-section";

const DetailsSection: any = (props: any) =>{
  const { editDetailsInViewMode, handleEditDetailsSectionPopUp, setFieldValue, validateForm, values, isSubmitting, viewData } = props;

  return (
    <div className="details-section">
      {editDetailsInViewMode ? (
          <EditDetailsSection
            handleEditDetailsSectionPopUp = {handleEditDetailsSectionPopUp}
            editDetailsInViewMode={editDetailsInViewMode} 
            setFieldValue={setFieldValue} 
            validateForm={validateForm}  
            values={values}
            isSubmitting= {isSubmitting}
          />
        ):(
          <ViewDetailsSection
            handleEditDetailsSectionPopUp = {handleEditDetailsSectionPopUp}
            viewData={viewData}
          />
        )
      }
    </div>
  );
};

export default DetailsSection;