import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { getLeadsBySource, getDayWiseLeads, getDashboardOverview, getDirectoryAnalytics } from '../../api/dashboard';
import BreadcrumbComponent from '../../common/components/breadcrumb-component/breadcrumb-component';
import { useSetLoading } from '../../common/hooks/appearance';
//import downArrow from "../../assets/icons/downArrow.svg";
import graphIcon from '../../assets/icons/graphIcon.svg';
import graphDownIcon from '../../assets/icons/Arrowdown.svg';
import './dashboard.scss';
import { IntlProvider, useIntl } from 'react-intl';
import { Tooltip } from '@mui/material';

const contractStatusData = [
  { value: 39, label: 'New 39%', color: '#EF5DA8' },
  { value: 22, label: 'Pending 22%', color: '#5D5FEF' },
  { value: 39, label: 'Completed 39%', color: '#F57C00' },
];

const marginPayload = { bottom: 0, left: 0, right: 200, top: 0 };

const seriesPayload = {
  innerRadius: 45,
  outerRadius: 80,
  paddingAngle: 1,
  cornerRadius: 4,
};

const size = {
  height: 200,
};

interface LeadSourceData {
  value: number;
  label: string;
}
interface DayWiseLeadsData {
  totalLeadsData: number[];
  newLeadsData: number[];
  leadsLabels: string[];
}
interface LeadsOverviewData {
  totalUsers?: number;
  noOfInternalLeads?: number;
  noOfExternalLeads?: number;
  newLeads?: number;
  newLeadsPercentageChange?: number;
  totalLeads?: number;
}

interface DirectoryData {
  value: number;
  label: string;
}

interface DayWiseLead {
  total_leads: number;
  new_leads: number;
  day_of_week: string;
}

const DashBoardContent: React.FC = () => {
  const [leadsBySourceData, setLeadsBySourceData] = useState<LeadSourceData[]>([]);
  const [dayWiseLeadsData, setDayWiseLeadsData] = useState<DayWiseLeadsData>({
    totalLeadsData: [],
    newLeadsData: [],
    leadsLabels: ['Monday', 'Tuesday', 'Wednes', 'Thursd', 'Friday', 'Saturday', 'Sunday'],
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [leadsOverviewData, setLeadsOverviewData] = useState<LeadsOverviewData>({});
  const [directoryData, setDirectoryData] = useState<DirectoryData[]>([]);
  const intl = useIntl();

  const crumbMapping = [{ label: 'Dashboard', link: '/dashboard' }];

  const fetchLeadsBySource = async () => {
    setLoading(true);
    try {
      const response = await getLeadsBySource();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const formatResponse = response?.data?.data?.map((data: any) => {
        return {
          value: data?.percentage,
          label: `${data?.source} ${data?.percentage || '0'} %`,
        };
      });
      setLeadsBySourceData(formatResponse);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const fetchDayWiseLeads = async () => {
    setLoading(true);
    try {
      const response = await getDayWiseLeads();
      let totalLeadsInfo: number[] = [];
      let newLeadsInfo: number[] = [];
      let leadsLabelsInfo: string[] = [];
      response?.data?.data?.forEach((data: DayWiseLead) => {
        totalLeadsInfo = [...totalLeadsInfo, data?.total_leads];
        newLeadsInfo = [...newLeadsInfo, data?.new_leads];
        leadsLabelsInfo = [...leadsLabelsInfo, data?.day_of_week];
      });
      setDayWiseLeadsData({ totalLeadsData: totalLeadsInfo, newLeadsData: newLeadsInfo, leadsLabels: leadsLabelsInfo });
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const fetchLeadsOverview = async () => {
    setLoading(true);
    try {
      const response = await getDashboardOverview();
      setLeadsOverviewData(response?.data?.data);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const fetchDirectoryAnalytics = async () => {
    setLoading(true);
    try {
      const response = await getDirectoryAnalytics();
      const labels: { [key: string]: string } = {
        funeralHomes: 'Funeral Homes',
        agencies: 'Agencies',
        agents: 'Agents',
        companies: 'Companies',
        regionalReps: 'Regional Reps',
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const formatResponse = Object.entries(response?.data?.data || {}).map(([key, data]: [string, any]) => {
        const label: string = labels[key] || key;
        return {
          value: data,
          label: `${label} ${data || '0'} %`,
        };
      });
      setDirectoryData(formatResponse);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLeadsBySource();
    fetchDayWiseLeads();
    fetchLeadsOverview();
    fetchDirectoryAnalytics();
    const updateTextElements = document.getElementsByTagName('text') as HTMLCollectionOf<SVGTextElement>;
    for (let i = 0; i < updateTextElements.length; i++) {
      updateTextElements[i].setAttribute('dominant-baseline', '');
    }

    const updateTspanElements = document.getElementsByTagName('tspan') as HTMLCollectionOf<SVGTSpanElement>;
    for (let i = 0; i < updateTspanElements.length; i++) {
      updateTspanElements[i].setAttribute('dominant-baseline', '');
    }
  }, []);

  const showLoading = useMemo(() => loading, [loading]);
  useSetLoading(showLoading);

  const formatNumber = (value: number) => {
    return intl.formatNumber(value, { notation: 'compact', compactDisplay: 'short' });
  };

  return (
    <Container>
      <BreadcrumbComponent breadcrumbLink={crumbMapping} />
      <div className="dashboard">
        {/* <img src={dashboardImages} className={"dashboard-img"} alt="down-arrow-icon"/> */}
        <div className="dashboard-container">
          {/* <div className="dashboard-header-text-day-filter">
                        <div className="dashboard-header-text">
                            Overview
                        </div>
                        <div className="dashboard-header-day-filter-text-icons">
                            <div className="dashboard-header-day-filter-text">Today</div>
                            <div className="dashboard-header-day-filter-icons">
                                <img src={downArrow}  alt="down-arrow-icon"/>
                            </div>
                        </div>
                    </div> */}
          <div className="dashboard-chart-container-one">
            <div className="dashboard-chart-container-one-icon-text">
              <Link to="/home/user/user">
                <div className="dashboard-chart-container-one-icon-text-inner">
                  <div className="dashboard-chart-container-one-icon-text-header">Number of Users</div>
                  <div className="dashboard-chart-container-one-icon-sub-text">
                    <div className="dashboard-chart-container-one-icon-sub-text-left">{leadsOverviewData.totalUsers ?? '-'}</div>
                    {/* <div className="dashboard-chart-container-one-icon-sub-text-right">
                                        <div className="dashboard-chart-container-one-icon-sub-text-right-icon">
                                            <img src={graphIcon}  alt="up-arrow-icon"/>
                                        </div>
                                        <div className="dashboard-chart-container-one-icon-sub-text-right-text">
                                            0%
                                        </div>
                                    </div> */}
                  </div>
                </div>
              </Link>
            </div>
            <div className="dashboard-chart-container-one-icon-text">
              <Link to="/home/leads">
                <div className="dashboard-chart-container-one-icon-text-inner">
                  <div className="dashboard-chart-container-one-icon-text-header">Lead Type</div>
                  <div className="dashboard-chart-container-one-icon-sub-text">
                    <div className="dashboard-chart-container-one-icon-text-header">Internal/External</div>
                    <div className="dashboard-chart-container-one-icon-sub-text-left">
                      <Tooltip
                        title={`${leadsOverviewData.noOfInternalLeads}/${leadsOverviewData.noOfExternalLeads}`}
                        placement="top"
                        arrow
                      >
                        <span>
                          {formatNumber(leadsOverviewData.noOfInternalLeads ?? 0)}/
                          {formatNumber(leadsOverviewData.noOfExternalLeads ?? 0)}{' '}
                        </span>
                      </Tooltip>
                    </div>
                    {/* <div className="dashboard-chart-container-one-icon-sub-text-right">
                                        <div className="dashboard-chart-container-one-icon-sub-text-right-icon">
                                            <img src={graphIcon}  alt="up-arrow-icon"/>
                                        </div>
                                        <div className="dashboard-chart-container-one-icon-sub-text-right-text">
                                            0%
                                        </div>
                                    </div> */}
                  </div>
                </div>
              </Link>
            </div>
            <div className="dashboard-chart-container-one-icon-text">
              <Link to="/home/leads/new" state={{ createdToday: true }}>
                <div className="dashboard-chart-container-one-icon-text-inner">
                  <div className="dashboard-chart-container-one-icon-text-header">New Leads Today</div>
                  <div className="dashboard-chart-container-one-icon-sub-text">
                    <div className="dashboard-chart-container-one-icon-sub-text-left">{leadsOverviewData.newLeads ?? '-'}</div>
                    <div className="dashboard-chart-container-one-icon-sub-text-right">
                      <div className="dashboard-chart-container-one-icon-sub-text-right-icon">
                        <img
                          src={
                            leadsOverviewData.newLeadsPercentageChange !== undefined && leadsOverviewData.newLeadsPercentageChange >= 0
                              ? graphIcon
                              : graphDownIcon
                          }
                          alt="graph-arrow-icon"
                        />
                      </div>
                      <div className="dashboard-chart-container-one-icon-sub-text-right-text">
                        {leadsOverviewData.newLeadsPercentageChange ? `${leadsOverviewData.newLeadsPercentageChange}%` : '-'}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="dashboard-chart-container-one-icon-text">
              <Link to="/home/leads">
                <div className="dashboard-chart-container-one-icon-text-inner">
                  <div className="dashboard-chart-container-one-icon-text-header">Total Leads</div>
                  <div className="dashboard-chart-container-one-icon-sub-text">
                    <div className="dashboard-chart-container-one-icon-sub-text-left">{leadsOverviewData.totalLeads ?? '-'}</div>
                    {/* <div className="dashboard-chart-container-one-icon-sub-text-right">
                                        <div className="dashboard-chart-container-one-icon-sub-text-right-icon">
                                            <img src={graphIcon}  alt="up-arrow-icon"/>
                                        </div>
                                        <div className="dashboard-chart-container-one-icon-sub-text-right-text">
                                            +11.02%
                                        </div>
                                    </div> */}
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="dashboard-chart-container-two">
            <div className="dashboard-chart-container-two-left">
              <div className="dashboard-chart-container-two-left-heading">Leads By Source</div>
              <div className="dashboard-chart-container-two-left-chart">
                <PieChart
                  series={[
                    {
                      ...seriesPayload,
                      data: leadsBySourceData,
                      valueFormatter: () => {
                        return ``;
                      },
                    },
                  ]}
                  margin={marginPayload}
                  {...size}
                />
              </div>
            </div>
            <div className="dashboard-chart-container-two-right">
              <div className="dashboard-chart-container-two-right-heading">Leads</div>
              <div className="dashboard-chart-container-two-right-chart">
                <BarChart
                  height={200}
                  series={[
                    { data: dayWiseLeadsData?.totalLeadsData, label: 'Total Leads', id: 'pvId', stack: 'total' },
                    { data: dayWiseLeadsData?.newLeadsData, label: 'New Leads', id: 'uvId', stack: 'total' },
                  ]}
                  xAxis={[
                    {
                      data: dayWiseLeadsData?.leadsLabels,
                      scaleType: 'band',
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      valueFormatter: (day: any, context: any) => {
                        if (context?.location === 'tick') {
                          return `${day.slice(0, 3)}`;
                        }
                        return `${day}`;
                      },
                    },
                  ]}
                  grid={{ horizontal: true }}
                />
              </div>
            </div>
          </div>
          <div className="dashboard-chart-container-three">
            <div className="dashboard-chart-container-three-left">
              <div className="coming-soon-tag">Coming Soon</div>
              <div className="dashboard-chart-container-three-left-heading">Contract Status</div>
              <div className="dashboard-chart-container-three-left-chart">
                <PieChart
                  series={[
                    {
                      ...seriesPayload,
                      data: contractStatusData,
                      valueFormatter: () => {
                        return ``;
                      },
                    },
                  ]}
                  margin={marginPayload}
                  {...size}
                />
              </div>
            </div>
            <div className="dashboard-chart-container-three-right">
              <div className="dashboard-chart-container-three-right-heading">Directory</div>
              <div className="dashboard-chart-container-three-right-chart">
                <PieChart
                  series={[
                    {
                      ...seriesPayload,
                      data: directoryData,
                      valueFormatter: () => {
                        return ``;
                      },
                    },
                  ]}
                  margin={marginPayload}
                  {...size}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

const DashBoard: React.FC = () => {
  return (
    <IntlProvider locale="en-US">
      <DashBoardContent />
    </IntlProvider>
  );
};

export default DashBoard;
