import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadcrumbComponent from "../../../common/components/breadcrumb-component/breadcrumb-component";
import { Container } from "reactstrap";
import SearchBar from "../../../common/components/search/search-bar";
import _ from "lodash";
import TableComponent from "../../../common/components/table-component/table-component";
import { useSetLoading } from "../../../common/hooks/appearance";
import "./contracts-templates.scss";
import AddOrEditContractTemplates from "./add-or-edit-contracts-templates";
//import ViewContractsTemplate from "./view-contracts-templates";
import { getContractTemplates } from "../../../api/contracttemplates";
import PaginationComponent from "../../../common/components/pagination-component/pagination-component";
import PositionedMenu from "../../../common/components/positioned-menu/positioned-menu";
import editIcon from "../../../assets/icons/edit.svg";
import viewIcon from "../../../assets/icons/viewIcon.svg";
import trashIcon from "../../../assets/icons/trashIcon.svg";
import DeleteConfirmationPopup from "../../directory/delete-confirmation-popup/delete-confirmation-popup";
import { removeContractTemplate } from "../../../api/contracttemplates";
import ViewTemplate from "./view-template";
import moment from "moment";

const ContractsTemplates: React.FC = () => {
  const [data, setData] = useState<any[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [limit] = useState<number>(15);
  const [searchText, setSearchText] = useState<any>(null);
  const [isReset, setIsReset] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>("created_at");
  const [sortDirection, setSortDirection] = useState<string>("desc");
  const [columnName, setCloumnName] = useState<string>("");
  const [addEditState, setAddEditState] = useState<{
    isOpen: boolean;
    mode: "add" | "edit" | null;
    selectedId: number | null;
  }>({
    isOpen: false,
    mode: null,
    selectedId: null,
  });
  const [viewState, setViewState] = useState<{
    viewOpen: boolean;
    selectedId: number | null;
  }>({
    viewOpen: false,
    selectedId: null,
  });
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOptions, setMenuOptions] = useState<any[]>([]);
  const [deletePopupState, setDeletePopupState] = useState<{
    isOpen: boolean;
    selectedId: number | null;
  }>({
    isOpen: false,
    selectedId: null,
  });

  let crumbMapping = [
    { label: "Contracts", link: "/home/contracts/contracts-templates" },
    {
      label: "Contracts Templates",
      link: "/home/contracts/contracts-templates",
    },
  ];

  const columnNames: string[] = [
    "Actions",
    "Template ID",
    "Template Name",
    "Version",
    "Category",
    "Last Modified",
    "Download",
  ];

 const formatDate = (dateString: string): string => {
  const date = moment(dateString);
  const month = String(date.month() + 1).padStart(2, "0");
  const day = String(date.date()).padStart(2, "0");
  const year = date.year();
  let hours = date.hours();
  const minutes = String(date.minutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12 || 12;
  
    const formattedTime = `${hours}:${minutes} ${ampm}`;
    return `${year}-${month}-${day}, ${formattedTime}`;
  };

  const formattedData = useMemo(() => {
    if (Array.isArray(data)) {
      return data.map((item) => ({
        id: item.id,
        "Template ID": item.template_id,
        "Template Name": item.template_name,
        Version: item.version.toFixed(1),
        Category: item["directory-category"].name,
        "Last Modified": formatDate(item.updated_at) || "-",
        Download: "DOWNLOAD",
        file_url: item.file_url,
      }));
    } else {
      return [];
    }
  }, [data]);

  const mapColumnNameToField = (columnName: string): string => {
    const mapping: Record<string, string> = {
      "Template ID": "template_id",
      "Template Name": "template_name",
      Version: "version",
      Category: "category",
      "Last Modified": "updated_by"
    };

    return mapping[columnName] || columnName;
  };

  const handleSort = (columnName: string) => {
    const mappedField = mapColumnNameToField(columnName);
    if (sortBy === mappedField) {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortBy(mappedField);
      setCloumnName(columnName);
      setSortDirection("asc");
    }
  };

  const handleSearch = _.debounce((text: string) => {
    // setIsLoading(true);
    setSearchText(text);
  }, 500);

  const toggleAddEdit = (mode: "add" | "edit" | null, id?: number) => {
    setAddEditState((prevState) => ({
      isOpen: !prevState.isOpen,
      mode: !prevState.isOpen ? mode : null,
      selectedId: !prevState.isOpen ? id ?? null : null,
    }));
  };

  const toggleView = (id: number) => {
    setViewState((prevState) => ({
      viewOpen: !prevState.viewOpen,
      selectedId: id,
    }));
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    rowId: number
  ) => {
    setMenuOptions([
      {
        label: "Edit Template",
        onClick: () => {
          toggleAddEdit("edit", rowId);
          handleMenuClose();
        },
        icon: editIcon,
      },
      {
        label: "View Template",
        onClick: () => {
          toggleView(rowId);
          handleMenuClose();
        },
        icon: viewIcon,
      },
      {
        label: "Delete Template",
        onClick: () => {
          setDeletePopupState((prevState) => ({
            isOpen: !prevState.isOpen,
            selectedId: rowId,
          }));
          handleMenuClose();
        },
        icon: trashIcon,
      },
    ]);
    setMenuAnchorEl(event.currentTarget);
  };

  const fetchContractsTemplates =useCallback( async (page: number) => {
    setIsLoading(true);
    try {
      const res = await getContractTemplates(
        page,
        limit,
        sortBy,
        sortDirection,
        searchText
      );
      setData(res.data.data.data);
      setTotalRecords(res.data.data.totalRecords);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  },[limit, sortBy, sortDirection, searchText] );

  const handleDelete = async (deleteId: number | null) => {
    try {
      setIsLoading(true);
      if (deleteId === null) {
        return;
      }
      const response = await removeContractTemplate(deleteId);
      if (response.data.success) {
        fetchContractsTemplates(page);
      }
      setDeletePopupState((prevState) => ({ ...prevState, isOpen: false }));
      setIsLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    fetchContractsTemplates(page);
  }, [page,searchText, sortBy, sortDirection,fetchContractsTemplates]);

  const showLoading = useMemo(() => isLoading, [isLoading]);
  useSetLoading(showLoading);

  return (
    <Container>
      <BreadcrumbComponent breadcrumbLink={crumbMapping} />
      <SearchBar
        placeholder={`Search by template Id, template name, category`}
        toggleAddEdit={toggleAddEdit}
        onSearch={handleSearch}
        module="ContractTemplates"
        isReset={isReset}
      />
      {formattedData.length > 0 ? (
        <>
          <div className={"contracts-templates-table"}>
            <TableComponent
              columnNames={columnNames}
              toggleAddEdit={toggleAddEdit}
              contracts={true}
              rowsData={formattedData}
              handleSort={handleSort}
              sortedColumn={columnName}
              sortDirection={sortDirection}
              onMenuClick={handleMenuOpen}
            />
          </div>
          {totalRecords > 0 && (
            <PaginationComponent
              totalRecords={totalRecords}
              pageSize={limit}
              currentPage={page}
              onPageChange={(
                event: React.ChangeEvent<unknown>,
                value: number
              ) => {
                setPage(value);
              }}
            />
          )}
        </>
      ) : (
        !isLoading && (
          <div className="no-results">
            <p>No results found</p>
          </div>
        )
      )}
      {addEditState.isOpen && (
        <AddOrEditContractTemplates
          isOpen={addEditState.isOpen}
          toggle={() => {
            toggleAddEdit(null);
          }}
          mode={addEditState.mode}
          editData={data.find((item) => item.id === addEditState.selectedId)}
          page={page}
          refreshData={()=>{
            setSearchText(null);
            setPage(1);
            setIsReset(!isReset);
            fetchContractsTemplates(page);
          }}
        />
      )}
      {viewState?.viewOpen && (
        <ViewTemplate
          isOpen={viewState.viewOpen}
          viewData={data.find((item) => item.id === viewState.selectedId)}
          toggle={toggleView}
        />
      )}
      <PositionedMenu
        menuOptions={menuOptions}
        anchorEl={menuAnchorEl}
        onClose={handleMenuClose}
      />
      <DeleteConfirmationPopup
        isOpen={deletePopupState.isOpen}
        deleteId={deletePopupState.selectedId}
        onClose={() =>
          setDeletePopupState((prevState) => ({
            ...prevState,
            isOpen: false,
          }))
        }
        handleDelete={handleDelete}
        label="Contract Template"
      />
    </Container>
  );
};
export default ContractsTemplates;
