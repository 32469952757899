import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ProfileContainer.scss";
import ProfileImage from "../../../app/assets/icons/person-fill.svg";
import { getUser } from "../../api/profile";
import { fetchUserRoles } from "../../api/user";
import store from '../../store';

const Profile: React.FC = () => {
  const [user, setUser] = useState<any>(null);
  const [role, setRole] = useState<any>([]);
  const [userRole, setUserRole] = useState<any>(null);

  const navigate = useNavigate();
  const handleProfileClick = () => {
    navigate("profile");
  };
  const isUserUpdated = store?.getState()?.profile?.isUserUpdate;
  useEffect(() => {
      getUser()
        .then((response) => {
          const sharedUserData = response?.data?.userDetails;
          setUser(sharedUserData);
        })
        .catch((error) => console.error("Error fetching user data:", error));

        fetchUserRoles()
        .then((response) => {
          const sharedRolesData = response.data.data;
          setRole(sharedRolesData);
        })
        .catch((error) => console.error("Error fetching user roles:", error));
  }, [isUserUpdated]);

  useEffect(() => {
    if(user?.user_role_id && role.length){
      const userRoles = role?.find((role: any) => role.id === user?.user_role_id)?.name || "Role not found";
      setUserRole(userRoles);
    }
  }, [user, role]);

  return (
    <>
    {user ? 
    <div
      className="user-card"
      onClick={handleProfileClick}
      style={{ cursor: "pointer" }}
    >
      <img 
        src={user?.image_url ? user.image_url : ProfileImage} 
        alt="avatar" 
        className="user-avatar" 
      />
      <div className="user-info">
        <h2 className="user-name">
          {user?.first_name}{" "} {user?.last_name}
        </h2>
        <p className="user-role">{userRole || "-"}</p>
      </div>
    </div>:<></>}
    </>
  );
};

export default Profile;
