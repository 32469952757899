import axios from ".";
import { AddUserType } from "../features/user-management/user-management/user-props";

export const fetchUsersList = () => {
  return axios({
    method: "GET",
    url: "/s3/users/with-files",
  });
};


export const fetchUsersDetails = (params: Record<string, any>, filters?: any) => {
  return axios({
    method: "GET",
    url: "/user/list",
    params: {...params, ...filters},
  });
};
export const exportUsersData=({searchText, sortBy, sortDirection, fromDate, toDate}:{searchText?:string; sortBy?: string; sortDirection?: string; fromDate?: string; toDate?: string}, filters?:any)=>{
  return axios({
    method:'GET',
    url:`user/export`,
    params: {
      searchText: searchText,
      sortBy: sortBy,
      sortDirection: sortDirection,
      fromDate: fromDate,
      toDate: toDate,
      ...filters
    },
    responseType: 'blob',
  })
}

export const addUser = (data: AddUserType) => {
  return axios({
      method: 'POST',
      url: `/user`,
      data
  })
}

export const editUser = (userId: number, data: AddUserType) => {
  return axios({
    method: 'PUT',
    url: `/user/${userId}`,
    data
  })
}

export const deleteUser = (userId: number) => {
  return axios({
    method: 'DELETE',
    url: `/user/${userId}`
  })
}

export const fetchUserRoles = () => {
  return axios({
    method: "GET",
    url: "/user-roles",
  });
};

export const fetchModulesOfUploads = () => {
  return axios({
    method: "GET",
    url: "/s3/users/modules",
  });
};