import React from "react";
import "./delete-confirmation-popup.scss";
import { DeleteConfirmationPopupProps } from "./delete-confirmation-props";
import trashIcon from '../../../assets/icons/trashIcon.svg';

const DeleteConfirmationPopup: React.FC<DeleteConfirmationPopupProps> = ({
  isOpen,
  onClose,
  handleDelete,
  deleteId,
  label
}) => {
  if (!isOpen) return null;

  return (
    <div className="delete-popup-overlay">
      <div className="delete-popup">
        <div className="delete-popup-image">
          <div className="trashIcon-background">
          <img src={trashIcon} alt="trash-icon"/>
          </div>
        </div>
        <h4 className="delete-title">Delete {label}</h4>
        <p className="delete-message">
          Are you sure you want to delete this entry? This action cannot be
          undone.
        </p>
        <div className="delete-buttons">
          <button className="cancel-button" onClick={onClose}>
            Cancel
          </button>
          <button
            className="delete-button"
            onClick={()=> handleDelete(deleteId)}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationPopup;
