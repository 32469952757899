import React, { Fragment } from 'react';
import Root from './Root';
import './index.css';
import { render } from 'react-dom';
import { history } from './app/store/configureStore';
import store from './app/store';
import reportWebVitals from './reportWebVitals';
import setupAxios from './app/config/axios';
import 'react-notifications-component/dist/theme.css';

setupAxios();

const AppContainer =  Fragment;

render(
    <AppContainer>
      <Root store={store} history={history}/>
    </AppContainer>,
    document.getElementById('root')
);

reportWebVitals();
