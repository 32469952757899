
import  { useState , useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Welcome from '../welcome/welcome';
import Login from '../login/login';
import Signup from '../signup/signup';
import ForgotPassword from '../forgot-password/forgot-password'; // New ForgotPassword Component
import styles from '../welcome/welcome.module.scss';

function LandingPage(props: any) {
  const [isSignup, setIsSignup] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) {
      console.log('token',token)
      setIsForgotPassword(true);
      setIsSignup(false); // Hide signup form
    }
  }, [searchParams]);

  const handleToggleSignup = () => {
    setIsSignup(!isSignup);
    setIsForgotPassword(false); // Reset forgot password state when toggling to signup
  };

  const handleForgotPassword = () => {
    setIsForgotPassword(true);
    setIsSignup(false); // Hide signup when showing forgot password
  };

  return (
    <div className={styles.container}>
      <Welcome />
      {isForgotPassword ? (
        <ForgotPassword onBackToLogin={() => setIsForgotPassword(false)} /> // Show ForgotPassword Component
      ) : isSignup ? (
        <Signup isLoggedIn={props.isLoggedIn} onToggleSignup={handleToggleSignup} />
      ) : (
        <Login
          isLoggedIn={props.isLoggedIn}
          onToggleSignup={handleToggleSignup}
          onForgotPassword={handleForgotPassword} // Pass down to trigger ForgotPassword
        />
      )}
    </div>
  );
}

export default LandingPage;
