import React from "react";
import BreadcrumbComponent from "../../../common/components/breadcrumb-component/breadcrumb-component";

const Parameters:React.FC =()=> {
    let crumbMapping = [
        { label: 'Settings', link: '/home/settings/parameters' },
        { label: 'Parameters', link: '/home/settings/parameters' },
    ];
    return(
        <div>
            <BreadcrumbComponent breadcrumbLink={crumbMapping}/>
        </div>
    )
}
export default Parameters;