import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';
import _ from 'lodash';
import SearchBar from '../../common/components/search/search-bar';
import BreadcrumbComponent from '../../common/components/breadcrumb-component/breadcrumb-component';
import TableComponent from '../../common/components/table-component/table-component';
import PaginationComponent from '../../common/components/pagination-component/pagination-component';
import AddOrEditLeads from './add-or-edit-leads';
import { exportLeadsData, getLeads } from '../../api/leads';
import { useSetLoading } from '../../common/hooks/appearance';
import ViewLead from './view-lead';
import AlertComponent from '../../common/components/Alert/Alert';
import FilterLeads from './filter-leads';
import './leads.scss';

const Leads: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [searchText, setSearchText] = useState<any>(null);
  const [isReset, setIsReset] = useState<boolean>(false);
  const [limit] = useState<number>(15);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [viewState, setViewState] = useState<{
    viewOpen: boolean;
    selectedId: number | null;
  }>({
    viewOpen: false,
    selectedId: null,
  });
  const [addEditState, setAddEditState] = useState<{
    isOpen: boolean;
    mode: 'add' | 'edit' | null;
    selectedId: number | null;
  }>({
    isOpen: false,
    mode: null,
    selectedId: null,
  });
  const [sortBy, setSortBy] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<string>('desc');
  const [columnName, setCloumnName] = useState<string>('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState<'success' | 'error' | 'info' | 'warning'>('success');

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [appliedFilters, setAppliedFilters] = useState<any>({});
  const location = useLocation();

  const toggleAddEdit = (mode: 'add' | 'edit' | null, id?: number) => {
    setAddEditState((prevState) => ({
      isOpen: !prevState.isOpen,
      mode: !prevState.isOpen ? mode : null,
      selectedId: !prevState.isOpen ? (id ?? null) : null,
    }));
  };

  const toggleView = (id: number) => {
    setViewState((prevState) => ({
      viewOpen: !prevState.viewOpen,
      selectedId: id,
    }));
  };

  const toggleFilters = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const columnNames: string[] = [
    'Edit',
    'Full Name',
    'Status',
    'Email ID',
    'Phone Number',
    'Address',
    'Lead Source',
    'Lead Type',
    'Company',
    'Website URL',
    'Created Date and Time',
  ];
  const crumbMapping = useMemo(() => {
    const paths = location.pathname.split('/').filter(Boolean);
    const breadcrumb = [];
    if (paths[1] === 'leads') {
      breadcrumb.push({ label: 'Leads', link: '/home/leads' });
    }
    if (paths[2] === 'new') {
      breadcrumb.push({ label: 'New Leads Today', link: '/leads/new' });
    }
    return breadcrumb;
  }, [location.pathname]);

  const fetchLeads = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (page: number, searchText = '', filters?: any) => {
      setIsLoading(true);
      try {
        const offset = (page - 1) * limit;
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const res = await getLeads(
          {
            offset,
            limit,
            searchText,
            sortBy,
            sortDirection,
            createdToday: location?.state?.createdToday,
            timezone,
          },
          filters,
        );
        setData(res.data.data.data);
        setTotalRecords(res.data.data.totalRecords);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    },
    [limit, sortBy, sortDirection, location?.state?.createdToday],
  );

  useEffect(() => {
    if (searchText !== null) {
      setPage(1);
      fetchLeads(1, searchText, appliedFilters);
    }
  }, [searchText, appliedFilters, fetchLeads]);

  useEffect(() => {
    fetchLeads(page, searchText, appliedFilters);
  }, [page, searchText, sortBy, sortDirection, appliedFilters, fetchLeads]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatAddress = (address: any) => {
    const street = address?.street || '';
    const city = address?.City?.name || '';
    const state = address?.State?.name || '';
    const zipcode = address?.zipcode || '';
    return [street, city, state, zipcode].filter(Boolean).join(', ');
  };

  const formattedTableData = useMemo(() => {
    if (Array.isArray(data)) {
      return data.map((item) => ({
        id: item.id,
        Edit: '',
        'Full Name': item.full_name || '-',
        'Email ID': item.email || '-',
        'Phone Number': item.phone_no || '-',
        Address: formatAddress(item.Address) || '-',
        'Lead Source': item.LeadSource.source || '-',
        'Lead Type': item.lead_type || '-',
        Status: item.status || '-',
        Company: item.company || '-',
        'Website URL': item.website_url || '-',
        'Created Date and Time': item.created_at,
      }));
    } else {
      return [];
    }
  }, [data]);

  const showLoading = useMemo(() => isLoading, [isLoading]);
  useSetLoading(showLoading);

  const mapColumnNameToField = (columnName: string): string => {
    const mapping: Record<string, string> = {
      'Full Name': 'full_name',
      'Email ID': 'email',
      'Phone Number': 'phone_no',
      'Lead Source': 'lead_source.source',
      'Lead Type': 'lead_type',
      Status: 'status',
      Company: 'company',
      'Website URL': 'website_url',
      'Created Date and Time': 'created_at',
    };

    return mapping[columnName] || columnName;
  };

  const handleSort = (columnName: string) => {
    const mappedField = mapColumnNameToField(columnName);
    if (sortBy === mappedField) {
      setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortBy(mappedField);
      setCloumnName(columnName);
      setSortDirection('asc');
    }
  };
  const handleSearch = _.debounce((text: string) => {
    // setIsLoading(true);
    setSearchText(text);
  }, 500);
  const handleDownload = async () => {
    setIsLoading(true);
    try {
      await exportLeadsData(
        {
          searchText: searchText,
          sortBy: sortBy,
          sortDirection: sortDirection,
          createdToday: location?.state?.createdToday,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        appliedFilters,
      );
      // const blob = new Blob([response.data], { type: "application/vnd.ms-excel" });
      // const link = document.createElement('a');
      // link.href = URL.createObjectURL(blob);
      // link.download = 'regional_reps_data.xlsx';
      // link.click();
      setTimeout(() => setIsLoading(false), 1000);
      setAlertMessage('Sent Downloadable link to Email , Please check');
      setAlertType('success');
      setAlertOpen(true);
    } catch (error) {
      setAlertMessage('Failed to download RegionalReps data');
      setAlertType('error');
      setAlertOpen(true);
      //setError('Failed to download file');
      setIsLoading(false);
      console.error(error);
    }
  };
  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  return (
    <Container>
      <AlertComponent message={alertMessage} type={alertType} open={alertOpen} onClose={handleAlertClose} />
      <BreadcrumbComponent breadcrumbLink={crumbMapping} />
      <SearchBar
        placeholder={`Search by name, address, email etc..`}
        toggleAddEdit={toggleAddEdit}
        module="Leads"
        onSearch={handleSearch}
        isReset={isReset}
        onDownload={handleDownload}
        toggleFilter={toggleFilters}
        appliedFilters={appliedFilters}
      />
      {formattedTableData.length > 0 ? (
        <>
          <div className={'leads-table'}>
            <TableComponent
              columnNames={columnNames}
              toggleAddEdit={toggleAddEdit}
              rowsData={formattedTableData}
              handleViewClick={toggleView}
              handleSort={handleSort}
              sortedColumn={columnName}
              sortDirection={sortDirection}
            />
          </div>
          {totalRecords > 0 && (
            <PaginationComponent
              totalRecords={totalRecords}
              pageSize={limit}
              currentPage={page}
              onPageChange={(event: React.ChangeEvent<unknown>, value: number) => {
                setPage(value);
              }}
            />
          )}
        </>
      ) : (
        <div className="no-results">
          <p>No results found</p>
        </div>
      )}
      {addEditState?.isOpen && (
        <AddOrEditLeads
          isOpen={addEditState.isOpen}
          toggle={() => {
            toggleAddEdit(null);
          }}
          mode={addEditState.mode}
          editData={data.find((item) => item.id === addEditState.selectedId)}
          fetchLeads={() => {
            setSearchText(null);
            setPage(1);
            setIsReset(!isReset);
            fetchLeads(page);
          }}
          page={page}
        />
      )}
      {viewState.viewOpen && (
        <ViewLead
          isOpen={viewState.viewOpen}
          viewData={data.find((item) => item.id === viewState.selectedId)}
          toggle={toggleView}
          fetchLeads={fetchLeads}
          page={page}
        />
      )}
      {isFilterOpen && (
        <FilterLeads
          isOpen={isFilterOpen}
          toggleFilter={toggleFilters}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
        />
      )}
    </Container>
  );
};
export default Leads;
