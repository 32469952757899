import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container } from "reactstrap";
import BreadcrumbComponent from "../../../common/components/breadcrumb-component/breadcrumb-component";
import PaginationComponent from "../../../common/components/pagination-component/pagination-component";
import SearchBar from "../../../common/components/search/search-bar";
import TableComponent from "../../../common/components/table-component/table-component";
import "./agents.scss";
import AddOrEditAgents from "./add-or-edit-agents";
import { useSetLoading } from "../../../common/hooks/appearance";
import ViewAgents from "./view-agents";
import { exportAgentData,  getAgentDetails, getAgentstList } from "../../../api/agents";
import _ from "lodash";
import AlertComponent from "../../../common/components/Alert/Alert";
import moment from "moment";
import FilterAgents from "./filter-agents";

export interface StateAppointed {
  id: number;
  state_id: number;
  agent_id: number;
  created_by: number;
  created_at: string;
  states_appointed_in: {
    id: number;
    name: string;
  };
}
export interface FuneralHomeAssociated {
  id: number;
  funeral_home_id: number;
  agent_id: number;
  created_by: number;
  created_at: string;
  funeral_home_associated: {
    id: number;
    name: string;
  };
}

const Agents: React.FC = () => {
  const columnNames: string[] = [
    "Edit",
    "Agent Name",
    "Entity Name",
    "Mailing Address",
    "Home Address",
    "Home Phone",
    "Work Phone",
    "Cell Phone",
    "Email Address",
    "Gender",
    "Agency",
    "Companies Contracted With",
    "Bank Routing Number",
    "Bank Account Number",
    "Checking or Savings",
    "Commission Payment Frequency",
    "Bank Name",
    "Social Security Number",
    "EIN",
    "Date Of Birth",
    "States Licensed In",
    "License Expiration (each state)",
    "AML Certification (each company)",
    "Reserve Balance (each company)",
    "Agency Number (each company)",
    "Special Instructions",
    "Commission Level",
    "Regional Rep",
    "States Appointed",
    "Agent Number",
    "Upline Agents",
    "Funeral Home Associated With",
    "Beneficiary",
  ];
  let crumbMapping = [
    { label: "Directory", link: "/home/directory/funeral-homes" },
    { label: "Agents", link: "/home/directory/agents" },
  ];

  const [addOrEditOpen, setAddOrEditOpen] = useState<boolean>(false);
  const [addOrEditOpenMode, setEditOpenMode] = useState<"add" | "edit" | null>(
    null
  );
  const [editData, setEditData] = useState<any>(null);
  const [viewOpen, setViewOpen] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize] = useState<number>(15);
  const [sortBy, setSortBy] = useState<string>("created_at");
  const [sortDirection, setSortDirection] = useState<string>("desc");
  const [columnName, setCloumnName] = useState<string>("");
  const [searchText, setSearchText] = useState<any>(null);
  const [isReset, setIsReset] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<
    "success" | "error" | "info" | "warning"
  >("success");

  const handleSearch = _.debounce((text: string) => {
    setSearchText(text)
  }, 500);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<any>({});

  const toggleAddEdit = (modeType: any) => {
    const newState = !addOrEditOpen;
    setAddOrEditOpen(newState);
    setEditOpenMode(newState ? modeType : "");
    if (modeType !== "edit") {
      setEditData(null);
    }
  };

  const toggleView = () => {
    setViewOpen(!viewOpen);
  };

  const toggleFilters = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleEditClick = async (agentId: number) => {
    try {
      setLoading(true);
      const getAPIDetails = await getAgentDetails(agentId);
      setEditData(getAPIDetails.data.data[0]);
      setTimeout(() => setLoading(false), 1000);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleViewClick = async (regionalRepId: number) => {
    try {
      setLoading(true);
      const getAPIDetails = await getAgentDetails(regionalRepId);
      setEditData(getAPIDetails?.data?.data[0]);
      setViewOpen(!viewOpen);
      setTimeout(() => setLoading(false), 1000);
    } catch (error) {
      setLoading(false);
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const fetchAgents =useCallback( async (page = 1, search = "", filters?:any) => {
    setLoading(true);
    const offset = (page - 1) * pageSize;
    try {
      const response = await getAgentstList(
        offset,
        pageSize,
        search,
        sortBy,
        sortDirection, filters
      );
      setData(response.data.data.data);
      setTotalRecords(response.data.data.totalRecords);
      setTimeout(() => setLoading(false), 1000);
    } catch (error) {
      setError("Failed to fetch Agents");
      setLoading(false);
    }
  },[pageSize,sortBy,sortDirection]);

  useEffect(() => {
    if(searchText !== null){
      setCurrentPage(1);
      fetchAgents(1, searchText, appliedFilters);
    }
  }, [searchText,fetchAgents,appliedFilters]);

  useEffect(() => {
    fetchAgents(currentPage, searchText, appliedFilters);
  }, [currentPage,searchText, sortBy, sortDirection, appliedFilters,fetchAgents]);

  const formatAddress = (address: any) => {
    const street = address?.street || "N/A";
    const city = address?.City?.name || "N/A";
    const state = address?.State?.name || "N/A";
    const zipcode = address?.zipcode || "N/A";
    return `${street}, ${city}, ${state}, ${zipcode}`;
  };

  const formatDate = (dateString: string): string => {
    const date = moment(dateString);
    const month = String(date.month() + 1).padStart(2, "0");
    const day = String(date.date()).padStart(2, "0");
    const year = date.year();
    return `${month}-${day}-${year}`;
  };

  const formattedData = useMemo(() => {
    if (Array.isArray(data)) {
      return data.map((item) => ({
        id: item.id,
        "Agent Name": item.name || "-",
        "Entity Name": item.entity_name || "-",
        "Mailing Address": formatAddress(item.mailingAddress) || "-",
        "Home Address": formatAddress(item.homeAddress) || "-",
        "Home Phone": item.home_phone || "-",
        "Work Phone": item.work_phone || "-",
        "Cell Phone": item.cell_phone || "-",
        "Email Address": item.email_address || "-",
        Gender: item.gender || "-",
        Agency: item.agency || "-",
        "Companies Contracted With": item.companies_contracted_with || "-",
        "Bank Routing Number": item.bank_routing_number || "-",
        "Bank Account Number": item.bank_account_number || "-",
        "Checking or Savings": item.account_type || "-",
        "Commission Payment Frequency": item.commission_payment_frequency || "-",
        "Bank Name": item.bank_name || "-",
        "Social Security Number": item.social_security_number || "-",
        EIN: item.ein || "-",
        "Date Of Birth": item?.date_of_birth ? formatDate(item?.date_of_birth) : "-",
        "States Licensed In": item?.states_licensed?.name || "-",
        "License Expiration (each state)": item?.license_expiration ? formatDate(item?.license_expiration) : "-",
        "AML Certification (each company)": item.aml_certification || "-",
        "Reserve Balance (each company)": item.reserve_balance || "-",
        "Agency Number (each company)": item.agency_number || "-",
        "Special Instructions": item.special_instruction || "-",
        "Commission Level": item.commissionLevel?.commission_name || "-",
        "Regional Rep": item.regionalRep?.name || "-",
        "States Appointed": item.agent_states_appointed
          .map((state: StateAppointed) => state.states_appointed_in.name)
          .join(", ") || "-",
        "Agent Number": item.agent_number || "-",
        "Upline Agents": item.uplineAgent?.name || "-",
        "Funeral Home Associated With": item.agent_funeral_home_associated
          .map(
            (state: FuneralHomeAssociated) => state.funeral_home_associated.name
          )
          .join(", ") || "-",
        Beneficiary: item.beneficiary || "-",
      }));
    } else {
      return [];
    }
  }, [data]);
  const showLoading = useMemo(() => loading, [loading]);
  useSetLoading(showLoading);

  if (error) {
    return <div>{error}</div>;
  }

  const mapColumnNameToField = (columnName: string): string => {
    const mapping: Record<string, string> = {
      "Agent Name": "name",
      "Entity Name": "entity_name",
      "Mailing Address": "mail_address.street",
      "Home Address": "home_addess.street",
      "Home Phone": "home_phone",
      "Work Phone": "work_phone",
      "Cell Phone": "cell_phone",
      "Email Address": "email_address",
      Gender: "gender",
      Agency: "agency",
      "Companies Contracted With": "companies_contracted_with",
      "Bank Routing Number": "bank_routing_number",
      "Bank Account Number": "bank_account_number",
      "Checking or Savings": "account_type",
      "Commission Payment Frequency": "commission_payment_frequency",
      "Bank Name": "bank_name",
      "Social Security Number": "social_security_number",
      EIN: "ein",
      "Date Of Birth": "data_of_birth",
      "States Licensed In": "states_licensed_in",
      "License Expiration (each state)": "license_expiration",
      "AML Certification (each company)": "aml_certification",
      "Reserve Balance (each company)": "reserve_balance",
      "Agency Number (each company)": "agency_number",
      "Special Instructions": "special_instruction",
      "Commission Level": "commission_level",
      "Regional Rep": "regional_rep_id",
      "States Appointed": "agent_states_appointed",
      "Agent Number": "agent_number",
      "Upline Agents": "upline_agent",
      "Funeral Home Associated With": "agent_funeral_home_associated.funeral_home_id",
      Beneficiary: "beneficiary",
    };

    return mapping[columnName] || columnName;
  };

  const handleSort = (columnName: string) => {
    const mappedField = mapColumnNameToField(columnName);
    if (sortBy === mappedField) {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortBy(mappedField);
      setCloumnName(columnName);
      setSortDirection("asc");
    }
  };
  const handleDownload = async () => {
    setLoading(true);
    try {
     await exportAgentData({searchText:searchText, sortBy:sortBy, sortDirection:sortDirection}, appliedFilters); 
      // const blob = new Blob([response.data], { type: "application/vnd.ms-excel" });
      // const link = document.createElement('a');
      // link.href = URL.createObjectURL(blob);
      // link.download = 'regional_reps_data.xlsx'; 
      // link.click();
      setTimeout(() => setLoading(false), 1000);
      setAlertMessage("Sent Downloadable link to Email , Please check");
      setAlertType("success");
      setAlertOpen(true);
    } catch (error) {
      setAlertMessage("Failed to download RegionalReps data");
      setAlertType("error");
      setAlertOpen(true);
      setError('Failed to download file');
      setLoading(false);
    }
  };
  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <Container>
       <AlertComponent
        message={alertMessage}
        type={alertType}
        open={alertOpen}
        onClose={handleAlertClose}
      />
      <BreadcrumbComponent breadcrumbLink={crumbMapping} />
      <SearchBar
        placeholder={`Search by name, address, email etc..`}
        toggleAddEdit={toggleAddEdit}
        onSearch={handleSearch}
        isReset={isReset}
        module={"Agents"}
        onDownload={handleDownload}
        toggleFilter={toggleFilters}
        appliedFilters={appliedFilters}
      />
      {formattedData.length > 0 ? (
        <>
          <div className={"agents-table"}>
            <TableComponent
              columnNames={columnNames}
              toggleAddEdit={toggleAddEdit}
              rowsData={formattedData}
              handleEditClick={handleEditClick}
              handleViewClick={handleViewClick}
              handleSort={handleSort}
              sortedColumn={columnName}
              sortDirection={sortDirection}
            />
          </div>
          <PaginationComponent
            totalRecords={totalRecords}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </>
      ) : (
        !loading && (
          <div className="no-results">
            <p>No results found</p>
          </div>
        )
      )}
      {addOrEditOpen ? (
        <AddOrEditAgents
          isOpen={addOrEditOpen}
          toggle={() => {
            toggleAddEdit("");
          }}
          editData={editData}
          mode={addOrEditOpenMode}
          refreshData={()=>{
            setSearchText(null);
            setCurrentPage(1);
            setIsReset(!isReset);
            fetchAgents();
          }}    
        />
      ) : (
        ""
      )}
      {viewOpen && (
        <ViewAgents
          isOpen={viewOpen}
          viewData={editData}
          toggleView={toggleView}
        />
      )}
      {isFilterOpen && (
        <FilterAgents
          isOpen={isFilterOpen}
          toggleFilter={toggleFilters}
          appliedFilters={appliedFilters}
          setAppliedFilters = {setAppliedFilters}
        />
      )}
    </Container>
  );
};
export default Agents;
