import React, { useState, useRef, useEffect } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Formik, Form,  ErrorMessage  } from "formik";
import {
  Button,
} from "reactstrap";
import "./add-new-funeral-homes.scss";
import { tabName } from "./add-new-funeral-homes.constant";
import { AddNewFuneralHomesProps } from "./add-new-funeral-homes.props";
import { validationSchema } from "./add-new-funeral-homes.validation";
import { getInitialValues } from "./add-new-funeral-homes.initialValues";
import { getFuneralHomesPayloadData } from "./add-or-edit-funeral-homes.utils";
import { addFuneralHomesDetails } from '../../../../api/funeralhomes';
import FuneralHomeDetails from "./add-new-funeral-homes-tabs/funeral-home-details/funeral-home-details";
import AlertComponent from "../../../../common/components/Alert/Alert";
import CustomTabPanel from "../../../../common/components/custom-tab-panel/custom-tab-panel";

const AddNewFuneralHomes: React.FC<AddNewFuneralHomesProps> = ({funeralHomeId, setLoading,  handleAddNewClose}) =>{
  const formRef: any = useRef();
  const [tabIndex, setTabIndex] = useState(0);
  const [initialValues, setInitialValues] = useState({});
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<"success" | "error" | "info" | "warning">("success");

  useEffect(() => {
    setInitialValues(getInitialValues(null, ""));
  },[]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const onSubmit = async (values: any, { resetForm }: any) => {
    setLoading(true);
    const payloadData = getFuneralHomesPayloadData(values, null);
    try {
        const response = await addFuneralHomesDetails(payloadData);
        resetForm();
        setLoading(false);
        handleAddNewClose();
    } catch (error) {
      setAlertMessage("Failed to update funeral home.");
      setAlertType("error");
      setAlertOpen(true);
      setLoading(false);
    }
  };

  return (
    <div className="add-new-funeral-homes">
      <AlertComponent
          message={alertMessage}
          type={alertType}
          open={alertOpen}
          onClose={()=>setAlertOpen(false)}
        />
      <Box sx={{ bgcolor: 'background.paper' }}>
        <Formik 
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            innerRef={formRef}
            isValidating={true}
            setSubmitting={true}
            isSubmitting={true}
            onSubmit={onSubmit}>
            {({isSubmitting, errors, touched, values, setFieldValue, validateForm})=> (
              <Form className="add-new-funeral-homes-container">
                <div className="view-funeral-homes-bottom-container-tab-name">
                  <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                  >
                    {tabName?.map((data:any, index: any)=>
                      <Tab label={data} key={index} />
                    )}
                  </Tabs>
                </div>
                <CustomTabPanel value={tabIndex} index={0}>
                  <FuneralHomeDetails setLoading={setLoading} values={values} setFieldValue={setFieldValue} validateForm={validateForm}  />
                </CustomTabPanel>
                <div className="funeral-homes-form-button-container">
                  <div className="edit-details-section-button-container">
                    <button type="button"
                      className="cancel-edit-details"
                      onClick={()=> handleAddNewClose()}
                    >
                      Close
                    </button>
                    <button type="submit" className="save-edit-details" disabled={isSubmitting}>Save</button>
                  </div>
                </div>
              </Form>
            )}
        </Formik>
          
      </Box>
    </div>
  );
};

export default AddNewFuneralHomes;