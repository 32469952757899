import React from "react";
import { useField, FieldAttributes } from "formik";
import './checkbox.scss'
import { Checkbox } from "@mui/material";

interface SingleSelectCheckboxProps extends FieldAttributes<any> {
  label: string;
}

const CustomCheckbox: React.FC<SingleSelectCheckboxProps> = ({
  label,
  value,
  name,
  ...props
}) => {
  const [field, , helpers] = useField({ name });

  const handleChange = () => {
    helpers.setValue(field.value === value ? "" : value);
  };

  return (
    <div style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
      <label style={{ marginRight: "15px" }}>{label}</label>
      <Checkbox
        checked={field.value === value}
        onChange={handleChange}
        style={{color:"#887952"}}
        size="small"
        {...props}
      />
    </div>
  );
};

export default CustomCheckbox;