/* eslint-disable  @typescript-eslint/no-explicit-any */
import Select, { SingleValue, MultiValue } from 'react-select';

interface Option {
  label: string;
  value: any;
  id?: number;
}

interface SelectDropdownComponentProps {
  placeholder?: string;
  options?: Option[];
  value?: SingleValue<Option> | MultiValue<Option>;
  onChange?: (selected: SingleValue<Option> | MultiValue<Option>) => void;
  isMulti?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  customStyles?: any;
  isDisabled?: boolean;
  menuPosition?: any;
}

const SelectDropdownComponent: React.FC<SelectDropdownComponentProps> = ({
  placeholder = 'Select ...',
  options = [],
  value,
  onChange,
  isMulti = false,
  isSearchable = false,
  isClearable = false,
  customStyles,
  isDisabled = false,
  menuPosition,
}) => {
  return (
    <Select
      placeholder={placeholder}
      options={options}
      value={value}
      onChange={onChange}
      isMulti={isMulti}
      isClearable={isClearable}
      isSearchable={isSearchable}
      styles={customStyles}
      isDisabled={isDisabled}
      menuPosition={menuPosition || 'absolute'}
    />
  );
};

export default SelectDropdownComponent;
