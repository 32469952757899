export const formatAddress = (address: any) => {
    const street = address?.street || "";
    const city = address?.City?.name || "";
    const state = address?.State?.name || "";
    const zipcode = address?.zipcode || "";
    if (!street && !city && !state && !zipcode) {
      return "";
    }
    return `${street}, ${city}, ${state}, ${zipcode}`;
  };
