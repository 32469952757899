import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Container } from "reactstrap";
import BreadcrumbComponent from "../../../common/components/breadcrumb-component/breadcrumb-component";
import PaginationComponent from "../../../common/components/pagination-component/pagination-component";
import SearchBar from "../../../common/components/search/search-bar";
import TableComponent from "../../../common/components/table-component/table-component";
import AddOrEditCompanies from "./add-or-edit-companies";
import "./companies.scss";
import { useSetLoading } from "../../../common/hooks/appearance";
import {
  exportCompaniesList,
  getCompaniesList,
  getCompanyDetails,
} from "../../../api/directories/companies";
import ViewCompanies from "./view-companies";
import _ from "lodash";
import AlertComponent from "../../../common/components/Alert/Alert";
import FilterCompanies from "./filter-companies";

const Companies: React.FC = () => {
  const [addOrEditOpen, setEditOpen] = useState<boolean>(false);
  const [addOrEditOpenMode, setEditOpenMode] = useState<"add" | "edit" | null>(
    null
  );
  const [viewOpen, setViewOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<any>(null);
  const [searchText, setSearchText] = useState<any>(null);
  const [isReset, setIsReset] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>("created_at");
  const [sortDirection, setSortDirection] = useState<string>("desc");
  const [columnName, setCloumnName] = useState<string>("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<
    "success" | "error" | "info" | "warning"
  >("success");
  const handleSearch = _.debounce((text: string) => {
    // setLoading(true);
    setSearchText(text);
  }, 500);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<any>({});

  const toggleAddEdit = async (modeType: any) => {
    const newState = !addOrEditOpen;
    setEditOpen(newState);
    setEditOpenMode(newState ? modeType : "");
  };
  const toggleView = () => {
    setViewOpen(!viewOpen);
  };
  const toggleFilters = () => {
    setIsFilterOpen(!isFilterOpen);
  };
  
  const handleViewClick = async (companyId: number) => {
    try {
      setLoading(true);
      const getAPIDetails = await getCompanyDetails(companyId);
      setEditData(getAPIDetails.data.data[0]);
      setViewOpen(!viewOpen);
      setTimeout(() => setLoading(false), 1000);
    } catch (error) {
      setLoading(false);
      console.error("Error while fetching company details:", error);
    }
  };
  const handleEditClick = async (companyId: number) => {
    try {
      setLoading(true);
      const getAPIDetails = await getCompanyDetails(companyId);
      setEditData(getAPIDetails.data.data[0]);
      setTimeout(() => setLoading(false), 1000);
    } catch (error) {
      setLoading(false);
      console.error("Error while fetching company details:", error);
    }
  };
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize] = useState<number>(15);
  const columnNames: string[] = [
    "Edit",
    "Company Name",
    "Company Physical Address",
    "Company Mailing Address",
    "Contact Numbers Claims",
    "Contact Numbers Agents",
    "Contact Names",
    "Contact Departments",
  ];

  const crumbMapping = [
    { label: "Directory", link: "/home/directory/funeral-homes" },
    { label: "Companies", link: "/home/directory/companies" },
  ];
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };
  const fetchCompanies =useCallback( async (page = 1, search = "", filters?: any) => {
    setLoading(true);
    const offset = (page - 1) * pageSize;
    try {
      const response = await getCompaniesList(
        offset,
        pageSize,
        search,
        sortBy,
        sortDirection,
        filters
      );
      setData(response.data.data.data);
      setTotalRecords(response.data.data.totalRecords);
      setTimeout(() => setLoading(false), 1000);
    } catch {
      setError("Failed to fetch companies");
      setLoading(false);
    }
  },[pageSize, sortBy, sortDirection]);
  useEffect(() => {
    if(searchText !== null){
      setCurrentPage(1);
      fetchCompanies(1, searchText, appliedFilters);
    }
  }, [searchText,fetchCompanies,appliedFilters]);

  useEffect(() => {
    fetchCompanies(currentPage, searchText, appliedFilters);
  }, [currentPage, searchText,sortBy, sortDirection, appliedFilters,fetchCompanies]);


  const formatAddress = (address: any) => {
    const street = address?.street || "";
    const city = address?.City?.name || "";
    const state = address?.State?.name || "";
    const zipcode = address?.zipcode || "";
    if (!street && !city && !state && !zipcode) {
      return "";
    }
    return `${street}, ${city}, ${state}, ${zipcode}`;
  };

  const formattedData = useMemo(() => {
    if (Array.isArray(data)) {
      return data.map((item) => ({
        id: item.id,
        "Company Name": item.name || "-",
        "Company Physical Address": formatAddress(item.physicalAddress) || "-",
        "Company Mailing Address": formatAddress(item.mailingAddress) || "-",
        "Contact Numbers Claims": item.contact_numbers_claims || "-",
        "Contact Numbers Agents": item.contact_numbers_agents || "-",
        "Contact Names": item.contact_names || "-",
        "Contact Departments": item.contact_departments || "-",
      }));
    } else {
      return [];
    }
  }, [data]);

  const showLoading = useMemo(() => loading, [loading]);
  useSetLoading(showLoading);
  if (error) {
    return <div>{error}</div>;
  }

  const mapColumnNameToField = (columnName: string): string => {
    const mapping: Record<string, string> = {
      "Company Name": "name",
      "Company Physical Address": "physicalAddress.street",
      "Company Mailing Address": "mailingAddress.street",
      "Contact Numbers Claims": "contact_numbers_claims",
      "Contact Numbers Agents": "contact_numbers_agents",
      "Contact Names": "contact_names",
      "Contact Departments": "contact_departments",
    };

    return mapping[columnName] || columnName;
  };

  const handleSort = (columnName: string) => {
    const mappedField = mapColumnNameToField(columnName);
    if (sortBy === mappedField) {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortBy(mappedField);
      setCloumnName(columnName);
      setSortDirection("asc");
    }
  };
  const handleDownload = async () => {
    setLoading(true);
    try {
      await exportCompaniesList({searchText: searchText, sortBy: sortBy, sortDirection: sortDirection},appliedFilters);
      // const blob = new Blob([response.data], {
      //   type: "application/vnd.ms-excel",
      // });
      // const link = document.createElement("a");
      // link.href = URL.createObjectURL(blob);
      // link.download = "companies_data.xlsx";
      // link.click();
      setTimeout(() => setLoading(false), 1000);
      setAlertMessage("Sent Downloadable link to Email , Please check");
      setAlertType("success");
      setAlertOpen(true);
    } catch (error) {
      setAlertMessage("Failed to download Companies data");
      setAlertType("error");
      setAlertOpen(true);
      setError("Failed to download file");
      setLoading(false);
    }
  };
  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <Container>
      <AlertComponent
        message={alertMessage}
        type={alertType}
        open={alertOpen}
        onClose={handleAlertClose}
      />
      <BreadcrumbComponent breadcrumbLink={crumbMapping} />
      <SearchBar
        placeholder="Search by name, address etc.."
        toggleAddEdit={toggleAddEdit}
        onSearch={handleSearch}
        module="Companies"
        onDownload={handleDownload}
        isReset={isReset}
        toggleFilter={toggleFilters}
        appliedFilters={appliedFilters}
      />
      {formattedData.length > 0 ? (
        <>
          <div className="companies-table">
            <TableComponent
              columnNames={columnNames}
              toggleAddEdit={toggleAddEdit}
              rowsData={formattedData}
              handleEditClick={handleEditClick}
              handleViewClick={handleViewClick}
              handleSort={handleSort}
              sortedColumn={columnName}
              sortDirection={sortDirection}
            />
          </div>
          <PaginationComponent
            totalRecords={totalRecords}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </>
      ) : (
        !loading && (
          <div className="no-results">
            <p>No results found</p>
          </div>
        )
      )}
      {addOrEditOpen ? (
        !loading ? (
          <AddOrEditCompanies
            isOpen={addOrEditOpen}
            toggle={() => {
              toggleAddEdit("");
            }}
            mode={addOrEditOpenMode}
            editData={editData}
            refreshData={()=>{
              setSearchText(null);
              setCurrentPage(1);
              setIsReset(!isReset);
              fetchCompanies();
            }} 
          />
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {viewOpen && (
        <ViewCompanies
          isOpen={viewOpen}
          viewData={editData}
          toggleView={toggleView}
        />
      )}
      {isFilterOpen && (
        <FilterCompanies
          isOpen={isFilterOpen}
          toggleFilter={toggleFilters}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
        />
      )}
    </Container>
  );
};

export default Companies;
