import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { SearchBarProps } from './search-bar-props';
import { Button, Row } from 'reactstrap';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import '../search/search-bar.scss';
import filterIcon from '../../../assets/icons/filterIcon.svg';
import exportIcon from '../../../assets/icons/newExportIcon.svg';
import downloadIcon from '../../../assets/icons/importIcon.svg';
import exportHoverIcon from '../../../assets/icons/exportHover.svg';
import downloadHoverIcon from '../../../assets/icons/importHover.svg';
import { uploadFile } from '../../../api/upload-file';
import Divider from '@mui/material/Divider';
import withPermissions from '../with-permissions/with-permissions';
import { useSetLoading } from '../../hooks/appearance';
import AlertComponent from '../Alert/Alert';
import ImportModal from '../import-modal-component/ImportModal';
import appliedFiltersIcon from '../../../assets/icons/appliedFiltersIcon.svg';
import Tooltip from '@mui/material/Tooltip';

const SearchBar: React.FC<SearchBarProps> = ({
  placeholder = '',
  toggleAddEdit,
  onSearch,
  onDownload,
  fromUser = false,
  module,
  permissions,
  isReset,
  toggleFilter,
  appliedFilters,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [searchText, setSearchText] = useState<any>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState<'success' | 'error' | 'info' | 'warning'>('success');
  const [isHoveringImport, setIsHoveringImport] = useState<boolean>(false);
  const [isHoveringExport, setIsHoveringExport] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      handleUpload(event.target.files[0]);
    }
  };

  useEffect(() => {
    setSearchText('');
  }, [isReset]);

  const handleUpload = async (file: File) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      const moduleName = module ?? '';
      formData.append('file', file);
      formData.append('moduleName', moduleName);
      await uploadFile(formData);
      setIsLoading(false);
      setAlertMessage('Uploaded successfully');
      setAlertType('success');
      setAlertOpen(true);
      toggleModal();
    } catch (error) {
      setIsLoading(false);
      setAlertMessage('Upload failed');
      setAlertType('error');
      setAlertOpen(true);
      toggleModal();
      console.error(error);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.trim().length > 0 || value === '') {
      setSearchText(value);
    }
    if (!value.trim()) {
      onSearch(value);
    }
  };

  const handleSearch = useCallback(() => {
    if (searchText.trim() === '!') {
      setAlertMessage('Search cannot be performed with just "!"');
      setAlertType('info');
      setAlertOpen(true);
      return;
    }

    const value = searchText.trim();
    if (value) {
      onSearch(value);
    }
  }, [searchText, onSearch]);

  const getButtonLabel = () => {
    if (fromUser) {
      return 'Add User';
    } else if (module === 'ContractSignatures') {
      return 'Add Signature';
    } else if (module === 'ContractTemplates') {
      return 'Add Template';
    } else {
      return 'Add';
    }
  };
  const handleSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && searchText?.trim()) {
      handleSearch();
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  const showLoading = useMemo(() => isLoading, [isLoading]);
  useSetLoading(showLoading);

  return (
    <>
      <AlertComponent message={alertMessage} type={alertType} open={alertOpen} onClose={handleAlertClose} />
      <Row className={'row-search-bar-filter'}>
        <div className={'search-bar-filter'}>
          <div className={'search-bar-input-text'}>
            <InputLabel shrink htmlFor="bootstrap-input">
              Search
            </InputLabel>
            <FormControl variant="outlined">
              <OutlinedInput
                id="outlined-adornment-password"
                type={'search'}
                placeholder={placeholder}
                onChange={handleSearchChange}
                onKeyDown={handleSearchKeyDown}
                value={searchText}
                autoComplete="off"
                endAdornment={
                  <InputAdornment position="end">
                    <Divider orientation="vertical" flexItem className={'search-icon-divider'} />
                    <SearchIcon onClick={handleSearch} className={'search-icon'} titleAccess="Click to Search" />
                  </InputAdornment>
                }
                label={''}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                    borderWidth: '1px',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                    borderWidth: '1px',
                  },
                }}
              />
            </FormControl>
          </div>
          <div className={'search-bar-div'}>
            {module === 'ContractSignatures' || module === 'ContractTemplates' || module === 'SavedReports' ? (
              <div></div>
            ) : (
              <div className={'search-bar-side-div'}>
                <img
                  className={'search-icon'}
                  src={Object.keys(appliedFilters).length > 0 ? appliedFiltersIcon : filterIcon}
                  alt="Filter Icon"
                  style={{ cursor: 'pointer' }}
                  onClick={toggleFilter}
                />
                {permissions?.IMPORT && (
                  <Tooltip title="Import" arrow>
                    <label htmlFor="file-upload">
                      <img
                        className="search-icon"
                        src={isHoveringImport ? exportHoverIcon : exportIcon}
                        alt="Import Icon"
                        style={{ cursor: 'pointer' }}
                        onClick={toggleModal}
                        onMouseEnter={() => setIsHoveringImport(true)}
                        onMouseLeave={() => setIsHoveringImport(false)}
                      />
                    </label>
                  </Tooltip>
                )}

                {permissions?.EXPORT && (
                  <Tooltip title="Export" arrow>
                    <img
                      className="search-icon"
                      src={isHoveringExport ? downloadHoverIcon : downloadIcon}
                      alt="Export Icon"
                      onClick={onDownload}
                      style={{ cursor: 'pointer' }}
                      onMouseEnter={() => setIsHoveringExport(true)}
                      onMouseLeave={() => setIsHoveringExport(false)}
                    />
                  </Tooltip>
                )}
              </div>
            )}
            {module === 'SavedReports' ? (
              <div></div>
            ) : (
              <Button className={'btn'} onClick={() => toggleAddEdit('add')}>
                {getButtonLabel()}
              </Button>
            )}
          </div>
        </div>
      </Row>
      <ImportModal openModal={openModal} toggleModal={toggleModal} handleFileChange={handleFileChange} module={module} />
    </>
  );
};

export default withPermissions(SearchBar, { modules: ['Import', 'Export'] });
