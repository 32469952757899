/* eslint-disable  @typescript-eslint/no-explicit-any */
import { concatenatePhoneNumber } from '../../../../../utils';

export const formatAddress = (address: any) => {
  const street = address?.street || '';
  const city = address?.City?.name || '';
  const state = address?.State?.name || '';
  const zipcode = address?.zipcode || '';
  if (!street && !city && !state && !zipcode) {
    return '';
  }
  return `${street}, ${city}, ${state}, ${zipcode}`;
};

export const formatPayload = (value: any) => {
  return {
    fullName: value?.fullName,
    email: value?.email,
    physicalAddress: {
      id: value?.physicalAddress?.id,
      street: value?.physicalAddress?.street,
      city: {
        id: value?.physicalAddress?.selectedPhysicalAddressCity?.id,
        name: value?.physicalAddress?.selectedPhysicalAddressCity?.name,
      },
      state: {
        id: value?.physicalAddress?.selectedPhysicalAddressState?.id,
        name: value?.physicalAddress?.selectedPhysicalAddressState?.name,
      },
      zipcode: value?.physicalAddress?.zipcode,
    },
    mailingAddress: {
      id: value?.mailingAddress?.id,
      street: value?.mailingAddress?.street,
      city: {
        id: value?.mailingAddress?.selectedMailingAddressCity?.id,
        name: value?.mailingAddress?.selectedMailingAddressCity?.name,
      },
      state: {
        id: value?.mailingAddress?.selectedMailingAddressState?.id,
        name: value?.mailingAddress?.selectedMailingAddressState?.name,
      },
      zipcode: value?.mailingAddress?.zipcode,
    },
    contactDepartment: value?.contactDepartments,
    contactNumber: concatenatePhoneNumber(value?.contactNumber),
  };
};
