import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import closeIcon from "../../assets/icons/closeIcon.svg";
import { ViewLeadProps } from "./leads-item-props";
import "./view-lead.scss";
import { useEffect } from "react";
import { updateLeadStatus } from "../../api/leads";

const ViewLead: React.FC<ViewLeadProps> = ({ isOpen, toggle, viewData, fetchLeads,page }) => {
  useEffect(() => {
    const fetchData = async () => {
      if (viewData.status === "New") {
        await updateLeadStatus(viewData.id);
      }
    };
    fetchData();
  }, [viewData.status, viewData.id]);
  const formatAddress = (address: any) => {
    const street = address?.street || "";
    const city = address?.City?.name || "";
    const state = address?.State?.name || "";
    const zipcode = address?.zipcode || "";
    return [street, city, state, zipcode].filter(Boolean).join(", ");
  };
  return (
    <div className="add-or-edit-offcanvas">
      <Offcanvas isOpen={isOpen} direction="end" className="offcanvas">
        <OffcanvasHeader className="offcanvas-header">
          <div className="header-div">
            <h2 className="mb-0">Lead Details</h2>
            <button
              onClick={() => {
                toggle(viewData.id);
                if (viewData.status === "New") {
                  fetchLeads(page);
                }
              }}
            >
              <img src={closeIcon} alt="Close" />
            </button>
          </div>
        </OffcanvasHeader>
        <OffcanvasBody className="offcanvas-body">
          <div className="view-form">
            <div className="form-group">
              <label>Full name</label>
              <div className="data-value scrollable-text">{viewData?.full_name || "-"}</div>
            </div>
            <div className="form-group">
              <label>Email</label>
              <div className="data-value">{viewData?.email || "-"}</div>
            </div>
            <div className="form-group">
              <label>Phone Number</label>
              <div className="data-value">{viewData?.phone_no || "-"}</div>
            </div>
            <div className="form-group">
              <label>Address</label>
              <div className="data-value">
                {formatAddress(viewData?.Address) || "-"}
              </div>
            </div>
            <div className="form-group">
              <label>Lead Source</label>
              <div className="data-value">{viewData?.LeadSource?.source || "-"}</div>
            </div>
            <div className="form-group">
              <label>Lead Type</label>
              <div className="data-value">{viewData?.lead_type || "-"}</div>
            </div>
            <div className="form-group">
              <label>Status</label>
              <div className="data-value">{viewData?.status || "-"}</div>
            </div>
            <div className="form-group">
              <label>Company</label>
              <div className="data-value">{viewData?.company || "-"}</div>
            </div>
            <div className="form-group">
              <label>Website URL</label>
              <div className="data-value">{viewData?.website_url || "-"}</div>
            </div>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </div>
  );
};

export default ViewLead;
