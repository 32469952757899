import React, {useCallback, useMemo} from "react";
import {
  Label,
  Col,
  FormGroup 
} from "reactstrap";
import { ErrorMessage } from 'formik';
import debounce from "lodash/debounce";
import { Autocomplete, TextField } from "@mui/material";
import {
  InputComponent,
  DatePicker
} from "../../../../common/components/index";
import { getRegionalRepList } from '../../../../api/agencies';
import { LocationOption } from "./add-or-edit-agencies.utils";
import { getAutoFillLocation } from "../../../../api/profile";
import { useShouldDisplayField } from "../../../../common/hooks/useShouldDisplayField";
import { getAutoFillAgent } from "../../../../api/agents";
import { getCustomDobStyle } from "../../agents/add-or-edit-agents.utils";

const OtherSection = (props: any) => {

    const { 
        values,
        setFieldValue,
        handleAddOtherDetails,
        handleRemoveOtherDetails,
    } = props;

    const fetchStateOptions =useCallback( async (value: string, index?: any) => {
        try {
          if (value.length < 3) return;
          const response = await getAutoFillLocation("state", value);
          const data = response.data.data;
          const locationData = data as LocationOption[];
          setFieldValue( index >=0 ?  `agencyLicenseDetails[${index}].stateOptions` : 'stateAppointedOptions', locationData)
        } catch (error) {
          console.error("Error fetching data:", error);
        }
    },[setFieldValue]);

    const fetchRegionalReps =useCallback( async (searchText: string) => {
        try {
        if (searchText.length < 3) return;
          const response = await getRegionalRepList("regionalRep" ,searchText);
          setFieldValue( 'regionalRepIdOptions', response?.data?.data);
        } catch (error) {
        }
      },[setFieldValue]);
    const fetchCommissionLevel =useCallback( async (searchText: string) => {
      try {
        if (searchText.length < 3) return;
        const response = await getAutoFillAgent(
          "commissionDetails",
          searchText
        );
        if (response?.data?.data) {
          setFieldValue("commissionLevelOptions", response.data.data);
        } else {
          setFieldValue("commissionLevelOptions", []);
        }
      } catch (error) {
        console.error("Error fetching commission level:", error);
      }
    },[setFieldValue]);

    const debouncedFetchOptions = useMemo(() => debounce(fetchStateOptions, 500), [fetchStateOptions]);
    const debouncedFetchRegionalRepsOptions = useMemo(() => debounce(fetchRegionalReps, 500), [fetchRegionalReps]);
    const debouncedCommissionLevelOptions = useMemo(() => debounce(fetchCommissionLevel, 500), [fetchCommissionLevel]);

    // const debouncedFetchOptions = useCallback(debounce(fetchStateOptions, 500), []);

    // const debouncedFetchRegionalRepsOptions = useCallback(debounce(fetchRegionalReps, 500), []);
    // const debouncedCommissionLevelOptions = useCallback(debounce(fetchCommissionLevel, 500), []);

    const handleFocus = (field: string, index?: any) => setFieldValue(index >=0 ? `agencyLicenseDetails[${index}].focusedField` : `stateAppointedFocusedField`, field);

    const handleRegionalRepsFocus = (field: string, index?: any) => setFieldValue('regionalRepIdFocusedField', field);
    const handleCommissionLevelFocus = (field: string, index?: any) => setFieldValue('commissionLevelField', field);

    const handleStateChange = (event: any, newValue: any, index?: any) => {
        if( index >= 0 ){
            if (newValue) {
                setFieldValue(`agencyLicenseDetails[${index}].selectedState`, {id: newValue.id, name: newValue.name});
                setFieldValue(`agencyLicenseDetails[${index}].stateLicensedIn`, {id: newValue.id, name: newValue.name});
            } else {
                setFieldValue(`agencyLicenseDetails[${index}].selectedState`, null);
                setFieldValue(`agencyLicenseDetails[${index}].stateLicensedIn`, null);
            }
        }else{
            if (newValue) {
                const savedStates = values?.agencyStatesAppointedIn || [];
                const unselectedStates = savedStates?.filter(
                    (savedState: any) => !newValue.some((selectedState: any) => selectedState.id === savedState.id)
                );
                const newDeletedStates = unselectedStates?.map((state: any) => ({
                    id: state.editID,
                    stateId: state.id,
                    isDeleted: true,
                }));
                const updatedDeletedStates = [
                    ...(values?.deletedAgencyStatesAppointed || []), 
                    ...newDeletedStates,
                ];
                const uniqueDeletedStates = Array.from(
                    new Map(updatedDeletedStates?.map((state: any) => [state.id, state])).values()
                );
                setFieldValue('deletedAgencyStatesAppointed', uniqueDeletedStates);
                setFieldValue(`stateAppointedSelectedState`, newValue);
                setFieldValue(`agencyStatesAppointedIn`, newValue);
            } else {
                setFieldValue(`stateAppointedSelectedState`, null);
                setFieldValue(`agencyStatesAppointedIn`, null);
            }
        }
    };
    
    const handleRegionalRepChange = (event: any, newValue: any) => {
            if (newValue) {
                setFieldValue(`regionalRepId`, newValue.id);
                setFieldValue(`regionalRepIdSelectedState`, {id: newValue.id, name: newValue.name});
            } else {
                setFieldValue(`regionalRepId`, null);
                setFieldValue(`regionalRepIdSelectedState`, null);
            }
    };
    const handleCommissionLevelChange = (event: any, newValue: any) => {
            if (newValue) {
                setFieldValue(`commissionLevel`, newValue.id);
                setFieldValue(`commissionLevelSelected`, {id: newValue.id, commission_name: newValue.commission_name});
            } else {
                setFieldValue(`commissionLevel`, null);
                setFieldValue(`commissionLevelSelected`, null);
            }
    };
    const shouldDisplayField = useShouldDisplayField();


    return (
      <>
        <div className="agencies-other-details">
          <div className="agencies-other-details-container">
            <div className="agencies-other-details-sub-container">
              {values?.agencyLicenseDetails?.map((data: any, index: any) => (
                <div className="agencies-other-details-container-form">
                  {values?.agencyLicenseDetails?.length > 1 ? (
                    <div className="agencies-other-details-remove-btn-container">
                      <div
                        className="agencies-other-details-remove-btn"
                        onClick={() => {
                          handleRemoveOtherDetails(
                            values,
                            setFieldValue,
                            index
                          );
                        }}
                      >
                        <i className="fa fa-regular fa-trash"></i>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="agencies-other-details-single-input-label-box">
                    <FormGroup className="add-or-edit-form-group">
                      <Label className="custom-form-label">
                        State Licensed In *
                      </Label>
                      <Autocomplete
                        options={
                          values?.agencyLicenseDetails[index]?.stateOptions
                        }
                        getOptionLabel={(option) => option?.name || ""}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(
                          event: any,
                          newValue: LocationOption | null
                        ) => handleStateChange(event, newValue, index)}
                        onInputChange={(event, value) => {
                          if (
                            values?.agencyLicenseDetails[index]
                              ?.focusedField === `state ${index}`
                          ) {
                            debouncedFetchOptions(value, index);
                          }
                        }}
                        onFocus={() => handleFocus(`state ${index}`, index)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select State"
                            className="autocomplete-text-field"
                          />
                        )}
                        value={
                          values?.agencyLicenseDetails[index]?.selectedState ||
                          null
                        }
                        className="autocomplete-div"
                      />
                      <ErrorMessage
                        name={`agencyLicenseDetails[${index}].stateLicensedIn`}
                        component="div"
                        className="error-message"
                      />
                    </FormGroup>
                  </div>
                  <div className="agencies-other-details-single-input-label-box">
                    <FormGroup className="add-or-edit-form-group">
                      <Label className="custom-form-label">
                        License Expiration
                      </Label>
                      <DatePicker
                        placeholder="Select License Expiration"
                        value={data?.licenseExpiration}
                        minDate={new Date()}
                        onChange={(selected) => {
                          setFieldValue(
                            `agencyLicenseDetails[${index}].licenseExpiration`,
                            selected
                          );
                        }}
                        customStyles={getCustomDobStyle()}
                      />
                      <ErrorMessage
                        name={`agencyLicenseDetails[${index}].licenseExpiration`}
                        component="div"
                        className="error-message"
                      />
                    </FormGroup>
                  </div>
                  <div className="agencies-other-details-single-input-label-box">
                    <FormGroup className="add-or-edit-form-group">
                      <InputComponent
                        fieldName={`agencyLicenseDetails[${index}].amlCertification`}
                        placeholder="Enter AML Certification"
                        label="AML Certification"
                        optional={true}
                      />
                      <ErrorMessage
                        name={`agencyLicenseDetails[${index}].amlCertification`}
                        component="div"
                        className="error-message"
                      />
                    </FormGroup>
                  </div>
                  <div className="agencies-other-details-single-input-label-box">
                    <InputComponent
                      fieldName={`agencyLicenseDetails[${index}].reserveBalance`}
                      label="Reserve Balance"
                      placeholder="Enter Reserve Balance"
                      optional={true}
                    />
                  </div>
                  <div className="agencies-other-details-single-input-label-box">
                    <InputComponent
                      fieldName={`agencyLicenseDetails[${index}].agencyNumber`}
                      label="Agency Number"
                      placeholder="Enter Agency Number"
                      optional={true}
                    />
                  </div>
                </div>
              ))}
              <div className="agencies-other-details-add-btn-container">
                <div
                  className="agencies-other-details-add-btn"
                  onClick={() => {
                    handleAddOtherDetails(values, setFieldValue);
                  }}
                >
                  + Add More
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="agencies-single-input-label-box">
          <Col className="multi-line-row-form-input-single">
            <InputComponent
              fieldName="specialInstructions"
              label="Special Instructions"
              placeholder="Enter Special Instructions"
              inputIsTextArea={true}
            />
          </Col>
        </div>

        <div className="agencies-single-input-label-box">
          <Col className="multi-line-row-form-input-single">
            <FormGroup className="add-or-edit-form-group">
              <Label
                htmlFor={"funeralHomeContactNumber"}
                className="custom-form-label"
              >
                Commission Level<span>*</span>
              </Label>
              <Autocomplete
                options={values?.commissionLevelOptions || []}
                getOptionLabel={(option) => option?.commission_name || ""}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={handleCommissionLevelChange}
                onInputChange={(event, value) => {
                  if (values?.commissionLevelField === `commissionDetails`) {
                    debouncedCommissionLevelOptions(value);
                  }
                }}
                onFocus={() => handleCommissionLevelFocus(`commissionDetails`)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Commission Level"
                    className="autocomplete-text-field"
                  />
                )}
                value={values?.commissionLevelSelected || null}
                className="autocomplete-div"
              />
              <ErrorMessage
                name={"commissionLevel"}
                component="div"
                className="error-message"
              />
            </FormGroup>
          </Col>
        </div>

        <div className="agencies-single-input-label-box">
          <Col className="multi-line-row-form-input-single">
            <FormGroup className="add-or-edit-form-group">
              <Label
                htmlFor={"funeralHomeContactNumber"}
                className="custom-form-label"
              >
                Regional Rep
              </Label>
              <Autocomplete
                options={values?.regionalRepIdOptions}
                getOptionLabel={(option) => option?.name || ""}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={handleRegionalRepChange}
                onInputChange={(event, value) => {
                  if (values?.regionalRepIdFocusedField === `regionalRep`) {
                    debouncedFetchRegionalRepsOptions(value);
                  }
                }}
                onFocus={() => handleRegionalRepsFocus(`regionalRep`)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Regional Rep"
                    className="autocomplete-text-field"
                  />
                )}
                value={values?.regionalRepIdSelectedState || null}
                className="autocomplete-div"
              />
              <ErrorMessage
                name={"regionalRepId"}
                component="div"
                className="error-message"
              />
            </FormGroup>
          </Col>
        </div>

        <div className="agencies-single-input-label-box">
          <Col className="multi-line-row-form-input-single">
            <FormGroup className="add-or-edit-form-group">
              <Label className="custom-form-label">
                States Appointed/Active in
              </Label>
              <Autocomplete
                multiple
                filterSelectedOptions
                options={values?.stateAppointedOptions}
                getOptionLabel={(option) => option?.name || ""}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={handleStateChange}
                onInputChange={(event, value) => {
                  if (
                    values?.stateAppointedFocusedField === `State Appointed`
                  ) {
                    debouncedFetchOptions(value);
                  }
                }}
                onFocus={() => handleFocus(`State Appointed`)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select State Appointed"
                    className="autocomplete-text-field"
                  />
                )}
                value={values?.agencyStatesAppointedIn || null}
                className="autocomplete-div"
              />
              <ErrorMessage
                name={`agencyStatesAppointedIn`}
                component="div"
                className="error-message"
              />
            </FormGroup>
          </Col>
        </div>
        <div className="agencies-single-input-label-box">
          <Col className="multi-line-row-form-input-single">
            {shouldDisplayField && (
              <InputComponent
                fieldName="note"
                label="Note(admins only)"
                placeholder="Enter Note"
                inputIsTextArea={true}
                optional={true}
              />
            )}
          </Col>
        </div>
      </>
    );
};

export default OtherSection;