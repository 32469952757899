import React from 'react';
import BreadcrumbComponent from '../../common/components/breadcrumb-component/breadcrumb-component';
import { useSetLoading } from '../../common/hooks/appearance';
import './terms-of-services.scss';

const TermsOfService: React.FC = () => {
  const crumbMapping = [{ label: 'Terms Of Services', link: '/terms-of-service' }];

  useSetLoading(false);

  return (
    <div className="terms-of-service">
      <BreadcrumbComponent breadcrumbLink={crumbMapping} />
      <div className="terms-of-service-container">
        <article id="post-604" className="post-604 page type-page status-publish hentry">
          <div className="entry-content">
            <p>
              <strong>Introduction</strong>&nbsp;
            </p>
            <p>
              AFP (“we,” “our,” or “us”) is committed to protecting your privacy. This Privacy Policy outlines our practices concerning data
              collection, usage, and protection when you use our website and services. We comply with applicable privacy laws, including
              GDPR and CCPA.&nbsp;
            </p>
            <p>
              <strong>Types of Information Collected</strong>&nbsp;
            </p>
            <p>
              We collect personal information, such as your name, email address, mailing address, phone number, and payment details, when
              you interact with our website or services. Additionally, we may collect non-personal information automatically, such as IP
              addresses, device types, and browsing patterns.&nbsp;
            </p>
            <p>
              <strong>How We Use Your Information</strong>&nbsp;
            </p>
            <p>We use personal information to:&nbsp;</p>
            <ul className="wp-block-list">
              <li> Process transactions and deliver services&nbsp;</li>
              <li> Communicate about orders, updates, and services&nbsp;</li>
              <li> Enhance user experience and website performance&nbsp;</li>
            </ul>
            <p>
              <strong>Information Provided or Collected via Services</strong>&nbsp;
            </p>
            <p>
              We collect data when offering services on behalf of customers. This may include personal information related to preneed
              funeral arrangements or other relevant services.&nbsp;
            </p>
            <p>
              <strong>Cookies and Automatically Collected Information</strong>&nbsp;
            </p>
            <p>
              We use cookies and tracking technologies to gather information automatically, which helps improve our website’s functionality.
              We use session and persistent cookies for website optimization and analytics. You can control cookies through your browser
              settings.&nbsp;
            </p>
            <p>
              <strong>Disclosure of Collected Information</strong>&nbsp;
            </p>
            <p>
              We do not sell personal data. We may share your information with trusted third-party service providers for operational
              purposes, such as payment processing, website management, or marketing services. These parties agree to keep your information
              confidential. We may also disclose data to comply with legal requirements or protect our rights.&nbsp;
            </p>
            <p>
              <strong>Do Not Track</strong>&nbsp;
            </p>
            <p>We do not currently respond to Do Not Track signals.&nbsp;</p>
            <p>
              <strong>Children’s Privacy</strong>&nbsp;
            </p>
            <p>
              Our website and services are not directed at children under 18. We do not knowingly collect personal information from minors.
              If we discover that personal information from children has been collected, we will take steps to delete it.&nbsp;
            </p>
            <p>
              <strong>Transfer and Storage of Information</strong>&nbsp;
            </p>
            <p>
              Your information may be transferred to and processed in countries outside of your residence. We take appropriate safeguards to
              ensure your data remains protected.&nbsp;
            </p>
            <p>
              <strong>Data Security</strong>&nbsp;
            </p>
            <p>
              We implement industry-standard security measures, such as encryption and secure servers, to protect personal information.
              However, no system is entirely secure, and we cannot guarantee full security.&nbsp;
            </p>
            <p>
              <strong>Retention of Information</strong>&nbsp;
            </p>
            <p>
              We retain your personal information only for as long as necessary to fulfill the purposes for which it was collected or to
              comply with legal requirements. Afterward, the data will be securely deleted or anonymized.&nbsp;
            </p>
            <p>
              <strong>Your Rights</strong>&nbsp;
            </p>
            <p>Depending on your location, you may have the right to:&nbsp;</p>
            <ul className="wp-block-list">
              <li>Access, correct, or delete your personal data&nbsp;</li>
              <li>Object to or restrict data processing&nbsp;</li>
              <li>Receive a copy of your data in a structured format&nbsp;</li>
              <li>Withdraw consent at any time&nbsp;</li>
            </ul>
            <p>To exercise these rights, please contact us.&nbsp;</p>
            <p>
              <strong>Third-Party Links</strong>&nbsp;
            </p>
            <p>
              Our website may include links to third-party websites. We are not responsible for the content or privacy practices of these
              external sites.&nbsp;
            </p>
            <p>
              <strong>Updates to the Privacy Policy</strong>&nbsp;
            </p>
            <p>
              We may update this policy periodically. If significant changes occur, we will notify users via our website or other
              communication channels.&nbsp;
            </p>
            <p>
              <strong>Contact Us</strong>&nbsp;
            </p>
            <p>For questions or concerns about this Privacy Policy, please contact us at&nbsp;&nbsp;</p>
            <p>Email:&nbsp;info@afphorizon.com</p>
            <p>Phone Number: 480-300-4719&nbsp;</p>
          </div>
        </article>
      </div>
    </div>
  );
};
export default TermsOfService;
