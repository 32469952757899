import { createNamespacer, createReducer } from '../utils/reducers';

const initialState = {
  isUserUpdate: false,
};

const namespacer = createNamespacer('PROFILE');

const handlers = {
  [namespacer('SET_USER_UPDATE')]: (state:any, action:any) => {        
    return {
      ...state,
      isUserUpdate: action?.payload?.value,
    };
  }
};

const reducer = createReducer(initialState, handlers, ['PROFILE']);

export default reducer;