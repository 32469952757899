import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Button } from "reactstrap";
import Logo from "../../assets/icons/bg_logo.svg";
import { useSetLoading } from '../../common/hooks/appearance';
import StopwatchDisplay from '../../common/components/stop-watch-display/stop-watch-display';
import * as signupAPI from '../../api/signup';
import "./email-verification.scss";

const EmailVerifications:React.FC =()=> {
    const [message, setMessage] = useState<any | null>(null);
    const [step, setStep] = useState<'invalid' | 'valid'>('valid');
    const [email, setEmail] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [stopwatchValue, setStopwatchValue] = useState(0);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const toggleStopwatchValue = useCallback((message: any, types: any) => {
      setIsLoading(false);
      setMessage({message: message, types: types});
      const stopwatchTimer = setInterval(() => {
        setStopwatchValue((prevState) => prevState + 10);
      }, 10);
      setTimeout(() => {
        clearInterval(stopwatchTimer);
        setStopwatchValue(0);
        navigate("/");
      }, 10000);
    },[navigate]);
  
    const handleValidationToken =useCallback( async (payload: any) => {
      setIsLoading(true);
      try {
        const response: any = await signupAPI?.verifyEmail({token: payload});
        toggleStopwatchValue(response?.data?.message,'success' )
      } catch (error: any) {
        setIsLoading(false);
        if(error?.response?.data?.message === "Your verification token has expired."){
            setStep("invalid")
            setEmail(error?.response?.data?.resendVerificationEmail);
            setMessage({message: error?.response?.data?.message, types: "error"});
        }else{
            toggleStopwatchValue(error?.response?.data?.message, 'error');
        }
      }
    }, [toggleStopwatchValue]);
  
    useEffect(() => {
      const tokenFromUrl = searchParams.get('token');
      if (tokenFromUrl) {
        handleValidationToken(tokenFromUrl);
      }
    }, [searchParams,handleValidationToken]);
  
  

    const handleResendEmail = async (payload: any) => {
        setIsLoading(true);
        try {
          const response: any = await signupAPI?.resendVerificationEmail(email);
          setStep("valid");
          toggleStopwatchValue(response?.data?.message,'success' );
        } catch (error: any) {
            setIsLoading(false);
            setStep("valid");
            toggleStopwatchValue(error?.response?.data?.message, 'error');
        }
    };
  
    const showLoading = useMemo(() => isLoading, [isLoading]);
    useSetLoading(showLoading);
  
    return (
      <div className="email-verification">
        <div className={`email-verification-container`}>
          <h2 className={`email-verification-heading`}><img className={`email-verification-heading-icon`} src={Logo} alt="AFP Logo" /></h2>
          <h2 className={`email-verification-heading`}>Verify Email</h2>
            {step === 'valid' ? 
                <div className="timer-error-msg-container">
                    <div className={`${message?.types === 'error' ? `email-verification-error-message` : `email-verification-success-message`}`}>
                        {message?.message}
                    </div>
                    <StopwatchDisplay value={stopwatchValue}/>
                </div>:
                <div className="email-verification-expired-container">
                    <div className={`${message?.types === 'error' ? `email-verification-error-message` : `email-verification-success-message`}`}>
                        {message?.message}
                    </div>
                    <div className="email-verification-expired-btn">
                        <Button className="btn" onClick={handleResendEmail}>
                            Resend Verification Email
                        </Button>
                    </div>
                </div>
            }
        </div>
      </div>
    );
  };
  
export default EmailVerifications;