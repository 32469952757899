import React, {  useState } from "react";
import phone from 'phone';
import { Offcanvas, OffcanvasBody, OffcanvasHeader, Button } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import closeIcon from "../../../assets/icons/closeIcon.svg";
import InputComponent from "../../../common/components/input-component/input-component";
import "../add-or-edit-directories.scss";
import { AddOrEditComponentProps } from "../types/add-or-edit-props";
import DeleteConfirmationPopup from "../delete-confirmation-popup/delete-confirmation-popup";
import {
  addCompany,
  deleteCompany,
  editCompany,
} from "../../../api/directories/companies";
import PhoneNumberComponent from "../../../common/components/phone-number-component/phone-number-component";
import { concatenatePhoneNumber, splitPhoneNumber } from "../utils";
import DirectoryAddressComponent from "../../../common/components/directory-address-component/directory-address-component";
import AlertComponent from "../../../common/components/Alert/Alert";
import { useShouldDisplayField } from "../../../common/hooks/useShouldDisplayField";

const AddOrEditCompanies: React.FC<AddOrEditComponentProps> = ({
  isOpen,
  toggle,
  mode,
  editData,
  refreshData,
}) => {
  // const [physicalAddressCity, setPhysicalAddressCity] = useState<{
  //   id: number;
  //   name: string;
  // } | null>(null);
  // const [physicalAddressState, setPhysicalAddressState] = useState<{
  //   id: number;
  //   name: string;
  // } | null>(null);
  // const [mailingAddressCity, setMailingAddressCity] = useState<{
  //   id: number;
  //   name: string;
  // } | null>(null);
  // const [mailingAddressState, setMailingAddressState] = useState<{
  //   id: number;
  //   name: string;
  // } | null>(null);
  // const [selectedPhysicalAddressCity, setSelectedPhysicalAddressCity] =
  //   useState<{ id: number; name: string } | null>(null);
  // const [selectedPhysicalAddressState, setSelectedPhysicalAddressState] =
  //   useState<{ id: number; name: string } | null>(null);
  // const [selectedMailingAddressCity, setSelectedMailingAddressCity] = useState<{
  //   id: number;
  //   name: string;
  // } | null>(null);
  // const [selectedMailingAddressState, setSelectedMailingAddressState] =
  //   useState<{ id: number; name: string } | null>(null);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<
    "success" | "error" | "info" | "warning"
  >("success");

  const [isDeletePopupOpen, setDeletePopupOpen] = useState<boolean>(false);
  const [deleteCompanyId, setDeleteCompanyId] = useState<number | null>(null);

  //const [isLoading, setIsLoading] = useState(false);
  const shouldDisplayField = useShouldDisplayField();

  const handleDelete = async (deleteCompanyId: number | null) => {
   // setIsLoading(true);
    try {
      if (deleteCompanyId === null) {
        console.error("No company ID provided for deletion");
        return;
      }
      const response = await deleteCompany(deleteCompanyId);
      //setIsLoading(false);
      if (response.data.success && refreshData) {
        refreshData();
      }
      setDeletePopupOpen(false);
      toggle();
    } catch (error) {
      console.error("Error while deleting company:", error);
      const errorMessage =
        (typeof error === "object" &&
          error !== null &&
          "response" in error &&
          (error as any).response?.data?.details?.[0]) ||
        (error instanceof Error
          ? error.message
          : "An unexpected error occurred.");
      setAlertMessage(errorMessage);
      setAlertType("error");
      setAlertOpen(true);
      //setIsLoading(false);
    }
  };

  const initialValues =
    editData && mode === "edit"
      ? {
          companyName: editData.name,
          physicalAddress: {
            id: editData.physicalAddress.id,
            street: editData.physicalAddress.street || "",
            zipcode: editData.physicalAddress.zipcode || "",
            state: editData?.physicalAddress?.State?.name || "",
            city: editData?.physicalAddress?.City?.name || "",
            selectedPhysicalAddressCity: editData.physicalAddress.City, 
            selectedPhysicalAddressState: editData.physicalAddress.State,
            physicalAddressCity: null,
            physicalAddressState: null,
          },
          mailingAddress: {
            id: editData.mailingAddress.id,
            street: editData.mailingAddress.street || "",
            zipcode: editData.mailingAddress.zipcode || "",
            state: editData?.mailingAddress?.State?.name || "",
            city: editData?.mailingAddress?.City?.name || "",
            selectedMailingAddressCity: editData.mailingAddress.City, 
            selectedMailingAddressState: editData.mailingAddress.State,
            mailingAddressCity: null,
            mailingAddressState: null,
          },
          contactNumberClaims: splitPhoneNumber(
            editData.contact_numbers_claims
          ),
          contactNumberAgents: splitPhoneNumber(
            editData.contact_numbers_agents
          ),
          contactNames: editData.contact_names || "",
          contactDepartments: editData.contact_departments || "",
          note: editData?.note || "",
        }
      : {
          companyName: "",
          physicalAddress: {
            street: "",
            city: "",
            state: "",
            zipcode: "",
            selectedPhysicalAddressCity: null,
            selectedPhysicalAddressState: null,
            physicalAddressCity: null,
            physicalAddressState: null,
          },
          mailingAddress: {
            street: "",
            city: "",
            state: "",
            zipcode: "",
            selectedMailingAddressCity: null,
            selectedMailingAddressState: null,
            mailingAddressCity: null,
            mailingAddressState: null,
          },
          contactNumberClaims: {
            countryCode: "+1",
            phoneNumber: "",
          },
          contactNumberAgents: {
            countryCode: "+1",
            phoneNumber: "",
          },
          contactNames: "",
          contactDepartments: "",
        };

  const validationSchema = Yup.object({
    companyName: Yup.string()
      .matches(/^[A-Za-z ´',-.()!&Åë]+$/, 'Company Name can only contain alphabets, spaces, and special characters')
      .max(200, "Company Name can't be longer than 200 characters.")
      .required("Mandatory Field*"),
    physicalAddress: Yup.object({
      street: Yup.string()
        .required("Mandatory Field*")
        .min(3, "Street name must be at least 3 characters.")
        .max(200, "Street name can't be longer than 200 characters."),
      selectedPhysicalAddressCity: Yup.object().required("Mandatory Field*"),
      selectedPhysicalAddressState: Yup.object().required("Mandatory Field*"),
      zipcode: Yup.string()
        .required("Mandatory Field*")
        .matches(/^\d{5}$/, "Invalid zipcode format"),
    }),
    mailingAddress: Yup.object({
      street: Yup.string()
        .required("Mandatory Field*")
        .min(3, "Street name must be at least 3 characters.")
        .max(200, "Street name can't be longer than 200 characters."),
      selectedMailingAddressCity: Yup.object().required("Mandatory Field*"),
      selectedMailingAddressState: Yup.object().required("Mandatory Field*"),
      zipcode: Yup.string()
        .required("Mandatory Field*")
        .matches(/^\d{5}$/, "Invalid zipcode format"),
    }),
    contactNumberClaims: Yup.object({
      countryCode: Yup.string().matches(/^\+\d{1,4}$/),
      phoneNumber: Yup.string()
        .matches(/^\d{10}$/, `Number format is invalid`)
        .test('is-valid-phone', `Invalid number`, (value, allValue) => {
          if (!value) return true;
          const { isValid } = phone(`${allValue?.parent?.countryCode || ''}-${value}`);
          return isValid;
      }),
    }),
    contactNumberAgents: Yup.object({
      countryCode: Yup.string().matches(/^\+\d{1,4}$/),
      phoneNumber: Yup.string()
        .matches(/^\d{10}$/, `Number format is invalid`)
        .test('is-valid-phone', `Invalid number`, (value, allValue) => {
          if (!value) return true;
          const { isValid } = phone(`${allValue?.parent?.countryCode || ''}-${value}`);
          return isValid;
      }),
    }),
    contactNames: Yup.string()
      .matches(/^[A-Za-z ´',-.()!&Åë]+$/, 'Contact Name can only contain alphabets, spaces, and special characters')
      .max(200, "Contact Name can't be longer than 200 characters.")
      .required("Mandatory Field*"),
    contactDepartments: Yup.string()
      .matches(/^[A-Za-z ´',-.()!&Åë]+$/, 'Contact Departments can only contain alphabets, spaces, and special characters')
      .max(200, "Contact Departments can't be longer than 200 characters.")
,
  });
  // useEffect(() => {
  //   if (editData) {
  //     setSelectedPhysicalAddressCity(editData.physicalAddress.City);
  //     setSelectedPhysicalAddressState(editData.physicalAddress.State);
  //     setSelectedMailingAddressCity(editData.mailingAddress.City);
  //     setSelectedMailingAddressState(editData.mailingAddress.State);
  //   }
  // }, [editData]);
  const onSubmit = async (values: typeof initialValues, { resetForm }: any) => {
    //setIsLoading(true);
    const companyData = {
      name: values.companyName,
      physicalAddress: {
        id: mode === "edit" ? editData?.physicalAddress.id : undefined,
        street: values.physicalAddress.street,
        city: {
          id:
            values?.physicalAddress?.selectedPhysicalAddressCity?.id ||
            editData?.physicalAddress.City.id,
          name:
            values?.physicalAddress?.selectedPhysicalAddressCity?.name ||
            editData?.physicalAddress.City.name,
        },
        state: {
          id:
            values?.physicalAddress?.selectedPhysicalAddressState?.id ||
            editData?.physicalAddress.State.id,
          name:
            values?.physicalAddress?.selectedPhysicalAddressState?.name ||
            editData?.physicalAddress.State.name,
        },
        zipcode: values.physicalAddress.zipcode,
      },
      mailingAddress: {
        id: mode === "edit" ? editData?.mailingAddress.id : undefined,
        street: values.mailingAddress.street,
        city: {
          id:
            values?.mailingAddress?.selectedMailingAddressCity?.id || editData?.mailingAddress.City.id,
          name:
            values?.mailingAddress?.selectedMailingAddressCity?.name ||
            editData?.mailingAddress.City.name,
        },
        state: {
          id:
            values?.mailingAddress?.selectedMailingAddressState?.id ||
            editData?.mailingAddress.State.id,
          name:
            values?.mailingAddress?.selectedMailingAddressState?.name ||
            editData?.mailingAddress.State.name,
        },
        zipcode: values.mailingAddress.zipcode,
      },
      contactNumbersClaims: values.contactNumberClaims.phoneNumber
        ? concatenatePhoneNumber(values.contactNumberClaims)
        : null,
      contactNumbersAgents: values.contactNumberAgents.phoneNumber
        ? concatenatePhoneNumber(values.contactNumberAgents)
        : null,
      contactNames: values.contactNames,
      contactDepartments: values.contactDepartments || null,
      note: values?.note || null,
    };
    try {
      if (editData && mode === "edit") {
        const response = await editCompany(editData.id, companyData);
        //setIsLoading(false);
        if (response.data.success && refreshData) {
          refreshData();
        }
      } else {
        const response = await addCompany(companyData);
       // setIsLoading(false);
        if (response.data.success && refreshData) {
          refreshData();
        }
      }
      resetForm();
      toggle();
    } catch (error: unknown) {
      console.error("Error submitting form:", error);
      let errorMessage = "An unexpected error occurred."; 
      if (typeof error === "object" && error !== null) {
        if ("response" in error && (error as any).response?.data) {
          const responseData = (error as any).response.data;
          errorMessage = responseData?.message || errorMessage;
        } else if ("message" in error) {
          errorMessage = (error as Error).message;
        }
      }
      setAlertMessage(errorMessage);
      setAlertType("error");
      setAlertOpen(true);
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <>
      <AlertComponent
        message={alertMessage}
        type={alertType}
        open={alertOpen}
        onClose={handleAlertClose}
      />
      <div className="add-or-edit-offcanvas">
        <Offcanvas isOpen={isOpen} direction="end" className="offcanvas">
          <OffcanvasHeader className="offcanvas-header">
            <div className="header-div">
              <h2 className="mb-0">
                {mode === "edit" ? "Edit" : "Add"} Company
              </h2>
              <button onClick={toggle}>
                <img src={closeIcon} alt="Close" />
              </button>
            </div>
          </OffcanvasHeader>
          <OffcanvasBody className="offcanvas-body">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form className="add-or-edit-form">
                  <InputComponent
                    fieldName="companyName"
                    label="Company Name"
                    placeholder="Enter company name"
                    optional={false}
                    isAlpha
                  />
                  <DirectoryAddressComponent
                    addressLabel="physicalAddress"
                    streetFieldName="physicalAddress.street"
                    zipCodeFieldName="physicalAddress.zipcode"
                    setCity={(city)=>setFieldValue('physicalAddress.physicalAddressCity', city)}   
                    setState={(state)=>setFieldValue('physicalAddress.physicalAddressState', state)} 
                    selectedState={values?.physicalAddress?.selectedPhysicalAddressState}
                    selectedCity={values?.physicalAddress?.selectedPhysicalAddressCity}
                    setSelectedCity = {(city)=>setFieldValue('physicalAddress.selectedPhysicalAddressCity', city)} 
                    setSelectedState = {(state)=>setFieldValue('physicalAddress.selectedPhysicalAddressState', state)}
                    cityFieldName="physicalAddress.selectedPhysicalAddressCity"
                    stateFieldName="physicalAddress.selectedPhysicalAddressState"
                    optional={false}
                    // mode={mode}
                  />
                  <DirectoryAddressComponent
                    addressLabel="mailingAddress"
                    streetFieldName="mailingAddress.street"
                    zipCodeFieldName="mailingAddress.zipcode"
                    setCity={(city)=>setFieldValue('mailingAddress.mailingAddressCity', city)}   
                    setState={(state)=>setFieldValue('mailingAddress.mailingAddressState', state)} 
                    selectedState={values?.mailingAddress?.selectedMailingAddressState}
                    selectedCity={values?.mailingAddress?.selectedMailingAddressCity}
                    setSelectedCity = {(city)=>setFieldValue('mailingAddress.selectedMailingAddressCity', city)} 
                    setSelectedState = {(state)=>setFieldValue('mailingAddress.selectedMailingAddressState', state)}
                    cityFieldName="mailingAddress.selectedMailingAddressCity"
                    stateFieldName="mailingAddress.selectedMailingAddressState"
                    optional={false}
                    // mode={mode}
                  />
                  <PhoneNumberComponent
                    label="Contact Number Claims"
                    fieldName="contactNumberClaims.phoneNumber"
                    countryCodeFieldName="contactNumberClaims.countryCode"
                    placeholder="Enter contact number claims"
                  />
                  <PhoneNumberComponent
                    label="Contact Number Agents"
                    fieldName="contactNumberAgents.phoneNumber"
                    countryCodeFieldName="contactNumberAgents.countryCode"
                    placeholder="Enter contact number agents"
                  />
                  <InputComponent
                    fieldName="contactNames"
                    label="Contact Names"
                    placeholder="Enter contact names"
                    optional={false}
                    isAlpha
                  />
                  <InputComponent
                    fieldName="contactDepartments"
                    label="Contact Departments"
                    placeholder="Enter contact departments"
                    isAlpha
                  />
                  {shouldDisplayField && (
                    <InputComponent
                      fieldName="note"
                      label="Note(admins only)"
                      placeholder="Enter Note"
                      inputIsTextArea={true}
                      optional={true}
                    />
                  )}
                  <div className="form-button-container">
                    {editData && (
                      <Button
                        type="button"
                        className="delete-button"
                        onClick={() => {
                          setDeleteCompanyId(editData.id);
                          setDeletePopupOpen(true);
                        }}
                      >
                        Delete
                      </Button>
                    )}
                    <button
                      type="button"
                      className={`cancel-button${
                        mode === "edit" ? "-in-edit" : ""
                      }`}
                      onClick={toggle}
                    >
                      Cancel
                    </button>
                    <button
                      className={
                        mode === "edit" ? "save-button-changes" : "save-button"
                      }
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {mode === "edit" ? "Save Changes" : "Save"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </OffcanvasBody>
        </Offcanvas>
      </div>
      <DeleteConfirmationPopup
        isOpen={isDeletePopupOpen}
        onClose={() => setDeletePopupOpen(!isDeletePopupOpen)}
        handleDelete={handleDelete}
        deleteId={deleteCompanyId}
        label="Company"
      />
    </>
  );
};

export default AddOrEditCompanies;
