
export const selectDropDowncustomStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderColor: state.isFocused ? "#887952" : provided.borderColor,
      boxShadow: state.isFocused ? "0 0 0 1px #887952" : provided.boxShadow,
      backgroundColor: state.isFocused ? provided.backgroundColor : "#F7F7F7",
      "&:hover": {
        borderColor: "#887952",
      },
    }),
    option: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: state.isSelected ? "#887952" : "white",
      ":hover": {
        backgroundColor: state.isSelected ? "#887952" : "#EEEE",
      },
    }),
  };

// export const getCustomDobStyle = () => {
//     return {
//       width: '100%',
//       padding: '10px',
//       borderRadius: '5px',
//       outline: 'none',
//       fontSize: '15px',
//       textAlign: 'left',
//       transition: 'border-color 0.3s ease',
//         ':focus': {
//         border: '1px solid #887952',
//       },
//       backgroundColor: '#F7F7F7',
//     };
//   };

export const getCustomDobStyle = (styleVariant?: string) => {
  return {
    "& .MuiInputBase-root": { bgcolor: "#F7F7F7", borderRadius: "6px" },
    "& .MuiOutlinedInput-root": {
      width: styleVariant ? "578px": "546px",
      height: styleVariant ? "48px" : "60px",
      "& fieldset": { border: "none" },
      "&:hover fieldset": { border: "none" },
      "&.Mui-focused fieldset": { border: "none" },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px"
    },
    "& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected": {
      bgcolor: "#887952 !important",
      color: "#fff !important",
    },
  }
}

export interface AgentOption {
    id: number;
    name: string;
    timezone?: string;
  };

  export interface CommissionOption {
    id: number;
    commission_name: string;
  };