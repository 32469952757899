export const concatenatePhoneNumber = (phoneNumberFormatValues: any) => {
    const { countryCode, phoneNumber } = phoneNumberFormatValues;
    if (!countryCode && !phoneNumber) {
      return "";
    }
    return `${countryCode}-${phoneNumber}`;
}

export const splitPhoneNumber = (formatSentFromDB: string | null | undefined) => {
  if (!formatSentFromDB) {
    return {
      countryCode: "+1",
      phoneNumber: "",
    };
  }
  const contactNumber = formatSentFromDB;
  const contactNumberRegex = /^(\+\d{1,4})[-]?(\d{10})$/;
  const match = contactNumber ? contactNumber?.match(contactNumberRegex) : undefined;
  return match
    ? {
        countryCode: match[1], 
        phoneNumber: match[2], 
      }
    : {
        countryCode: "",
        phoneNumber: "",
      };
}

export const formateDateToMMDDYYYY = (date: Date) => {
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
};