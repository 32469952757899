import _ from 'lodash';
import { Store } from "react-notifications-component";
import { LOG_IN_AGAIN, SEQUELIZE_DB_ERROR } from '../constants/errors';
import notificationConstants from "../constants/notificationConstants";


let isNetworkNotificationDisplayed: any = 0;

// eslint-disable-next-line import/prefer-default-export
export const createFilterChangeHandler =
  (onChange: any) =>
  (name: any, path: any = 'target.value') =>
  (e: any) => {
    const newValue = _.get(e, path, e);

    onChange({
      [name]: newValue,
    });
  };

export const apiErrorHandler = async (errorRes: any, showWarning: any) => {
  // This specific error is being handled in global response interceptors.
  if (
    errorRes?.response?.data?.message === LOG_IN_AGAIN ||
    errorRes?.response?.data?.name === SEQUELIZE_DB_ERROR ||
    errorRes.response?.data?.message?.name === SEQUELIZE_DB_ERROR
  )
    return;

  if (errorRes.message === 'Network Error') {
    // Store.addNotification({
      // ...notificationConstants.COMMON_SETTINGS,
      // ...notificationConstants.NETWORK_ERROR_NOTIFICATION,
      // dismiss: {
      //   duration: 3000,
      // },
    // });
  } else {
    let error;
    if (
      _.get(errorRes, 'response.data.error.errors') &&
      _.get(errorRes, 'response.data.error.errors').length &&
      typeof _.get(errorRes, 'response.data') === 'object'
    ) {
      error = _.get(errorRes, 'response.data.error.errors[0].message', '');
    } else if (typeof _.get(errorRes, 'response.data') === 'object') {
      const errorForBlobType = await errorRes?.response?.data?.text?.();
      if (errorForBlobType) {
        error = JSON.parse(errorForBlobType)?.error;
      } else {
        error =
          _.get(errorRes, 'response.data.error.message', null) ||
          _.get(errorRes, 'response.data.message', null) ||
          _.get(errorRes, 'response.data.messages', null) ||
          _.get(errorRes, 'response.data.error', null) ||
          _.get(errorRes, 'response.data', null) ||
          _.get(errorRes, 'message');
      }
    } else {
      error = _.get(errorRes, 'response.statusText');
    }
    // Store.addNotification({
      // ...notificationConstants.COMMON_SETTINGS,
      // ...notificationConstants[showWarning ? 'WARNING' : 'ERROR'],
      // dismiss: {
      //   duration: 3000,
      // },
      // message: error,
    // });
  }
};

export const successHandler = (res: any) => {
  // Store.addNotification({
  //   ...notificationConstants.COMMON_SETTINGS,
  //   ...notificationConstants.SUCCESS,
  //   ...res.message,
  // });
};

export const warningHandler = (message: any, options: any = {}) => {
  Store.addNotification({
    ...notificationConstants.COMMON_SETTINGS,
    ...notificationConstants.WARNING,
    message,
    dismiss:{...notificationConstants.dismiss, ...options.dismiss},
    ...options,
  });
};

export const errorHandler = (message: any, options: any = {}) => {
  // Store.addNotification({
  //   ...notificationConstants.COMMON_SETTINGS,
  //   ...notificationConstants.ERROR,
  //   message,
  //   ...options,
  // });
};

export const networkErrorHandler = () => {
  if (!isNetworkNotificationDisplayed) {
    isNetworkNotificationDisplayed = true;

    // Store.addNotification({
    //   ...notificationConstants.COMMON_SETTINGS,
    //   ...notificationConstants.NETWORK_ERROR_NOTIFICATION,
    //   onRemoval: () => {
    //     isNetworkNotificationDisplayed = false;
    //   },
    // });
  }
};

