import axios from '.';

export const login = (data: any) => {
  return axios({
    method: 'POST',
    url: '/login',
    data
  });
};

export const getUploadTrackingRecords = (filters : { page?: number,limit?: number;  moduleName?:string, uploadedBy?:number, sortBy?: string, 
  sortDirection?: string  } = {}) => {
  return axios({
    method: "GET",
    url: "/s3/upload-tracking-records",
    params: filters,
  });
};