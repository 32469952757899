import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

interface AlertProps {
  message: string;
  type: "success" | "error" | "info" | "warning";
  open: boolean;
  onClose: () => void;
  autoHideDuration?: number;
}

const AlertComponent: React.FC<AlertProps> = ({
  message,
  type,
  open,
  onClose,
  autoHideDuration = 3000,
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert onClose={onClose} severity={type} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertComponent;
