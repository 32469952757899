/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import { FormGroup, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Input } from 'reactstrap';
import { Form, Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { InputComponent, SelectDropdownComponent } from '../../../common/components';
import closeIcon from '../../../assets/icons/closeIcon.svg';
import { selectDropDowncustomStyles } from '../../directory/agents/add-or-edit-agents.utils';
import { DropdownOption } from '../../leads-item/leads-item-props';
import { addContractTemplate, updateContractTemplate, instantFileUpdate, getCategories } from '../../../api/contracttemplates';
import { AddOrEditContractTemplateComponentProps } from './contracts-templates-props';
import { useSetLoading } from '../../../common/hooks/appearance';
import './contracts-templates.scss';

const AddOrEditContractTemplates: React.FC<AddOrEditContractTemplateComponentProps> = ({
  isOpen,
  mode,
  toggle,
  editData,
  refreshData,
  page,
}) => {
  const [categories, setCategories] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editFileState, setEditFileState] = useState<{
    newFileSelected: boolean;
    fileError: string | null;
  }>({
    newFileSelected: false,
    fileError: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await getCategories();
        const updatedCategories = res.data.data.filter((category: any) => category.id !== 6);
        setCategories(updatedCategories);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching user list:', error);
      }
    };

    fetchData();
  }, []);

  const showLoading = useMemo(() => isLoading, [isLoading]);
  useSetLoading(showLoading);

  const initialValues =
    editData && mode === 'edit'
      ? {
          templateName: editData.template_name,
          category: editData['directory-category'].name,
          templatePdfUrl: editData.file_url,
          file: editData.file_url ? new File([], editData.file_url.split('/').pop()?.split('-').pop() || 'Existing File') : null,
        }
      : {
          templateName: '',
          category: '',
          file: '',
          templatePdfUrl: '',
        };

  const validationSchema = Yup.object({
    templateName: Yup.string().required('Template name is required'),
    category: Yup.string().required('Category is required'),
    file:
      mode === 'add'
        ? Yup.mixed<File>()
            .required('Template File is required')
            .test('fileSize', 'PDF file size should be less than 5MB', (value) => {
              if (!value) return false;
              return value.size <= 5 * 1024 * 1024;
            })
            .test('fileFormat', 'File format must be PDF', (value) => {
              if (!value) return true;
              return value.type === 'application/pdf';
            })
            .test('fileNameLength', 'File name must be less than 200 characters', (value) => {
              if (!value || !value.name) return true;
              return value.name.length <= 200;
            })
        : Yup.mixed().notRequired(),
    templatePdfUrl:
      mode === 'edit'
        ? Yup.string().required('Template URL is required').max(255, 'Template URL must be less than 255 characters')
        : Yup.string().notRequired(),
  });

  const instantUpload = async (file: File | null, setFieldValue: any) => {
    if (!file) {
      return;
    }
    try {
      if (file.size > 5 * 1024 * 1024) {
        setEditFileState({
          newFileSelected: true,
          fileError: 'PDF file size should be less than 5MB',
        });
        return;
      }
      if (file.type !== 'application/pdf') {
        setEditFileState({
          newFileSelected: true,
          fileError: 'File format must be PDF',
        });
        return;
      }
      if (file.name.length > 200) {
        setEditFileState({
          newFileSelected: true,
          fileError: 'File name length must be less than 200 characters',
        });
        return;
      }
      const formData = new FormData();
      formData.append('file', file);
      setIsLoading(true);
      const response = await instantFileUpdate(editData?.id, formData);
      setIsLoading(false);
      if (response.data.success) {
        setFieldValue('templatePdfUrl', response.data.data.templatePdfUrl);
      } else {
        console.error('File upload failed:', response.data.errors);
      }
    } catch (error) {
      console.error('Unexpected error:', error);
    }
  };

  const onSubmit = async (values: any, { resetForm }: any) => {
    if (editFileState.fileError && mode === 'edit') {
      return;
    }
    const hasChanges =
      editData &&
      (editData.template_name !== values.templateName ||
        editData['directory-category']?.name !== values.category ||
        editData.file_url !== values.templatePdfUrl);
    if (mode === 'edit' && !hasChanges) {
      resetForm();
      toggle();
      return;
    }
    const formData = new FormData();
    formData.append('templateName', values.templateName);
    formData.append('category', values.category);
    if (editData && mode === 'edit') {
      formData.append('fileUrl', values.templatePdfUrl ? values.templatePdfUrl : editData?.templatePdfUrl);
    } else {
      formData.append('file', values.file);
    }
    try {
      setIsLoading(true);
      let response;
      if (mode === 'add') {
        response = await addContractTemplate(formData);
      } else if (mode === 'edit') {
        response = await updateContractTemplate(editData?.id, formData);
      }
      setIsLoading(false);
      if (response?.data.success && refreshData) {
        refreshData(page);
      } else if (response?.data.errors) {
        // setErrors(response.data.errors);
      }
    } catch (error: any) {
      setIsLoading(false);
      if (error.response && error.response.data && error.response.data.errors) {
        // setErrors(error.response.data.errors);
      } else {
        console.error('Unexpected error:', error);
      }
    }
    resetForm();
    toggle();
  };
  return (
    <>
      <div className="add-or-edit-offcanvas">
        <Offcanvas isOpen={isOpen} direction="end" className="offcanvas">
          <OffcanvasHeader className="offcanvas-header">
            <div className="header-div">
              <h2 className="mb-0">{mode === 'edit' ? 'Edit' : 'Add'} Contract Template</h2>
              <button onClick={toggle}>
                <img src={closeIcon} alt="Close" />
              </button>
            </div>
          </OffcanvasHeader>
          <OffcanvasBody className="offcanvas-body">
            <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize={true} onSubmit={onSubmit}>
              {({ isSubmitting, values, setFieldValue }) => (
                <Form className="add-or-edit-form">
                  <InputComponent
                    fieldName="templateName"
                    label="Template Name"
                    placeholder="Enter Template Name"
                    optional={false}
                    isAlpha
                  />
                  <FormGroup className="add-or-edit-form-group">
                    <Label htmlFor="category" className="custom-form-label">
                      Category <span>*</span>
                    </Label>
                    <SelectDropdownComponent
                      placeholder="Select Category"
                      options={categories.map((category) => ({
                        label: category.name,
                        value: category.name,
                      }))}
                      customStyles={selectDropDowncustomStyles}
                      value={values.category ? { label: values.category, value: values.category } : null}
                      onChange={(selected) => {
                        const selectedOption = selected as DropdownOption;
                        setFieldValue('category', selectedOption?.value);
                      }}
                    />
                    <ErrorMessage name="category" component="div" className="error-message" />
                  </FormGroup>
                  {mode === 'edit' && editData.file_url && (
                    <FormGroup className="add-or-edit-form-group view-existing-file">
                      <label className="custom-form-label">Existing Template File:</label>
                      <a href={editData.file_url} target="_blank" rel="noopener noreferrer" className="truncate" title={editData.file_Url}>
                        <p>{editData.file_url.split('/').pop()}</p>
                      </a>
                    </FormGroup>
                  )}
                  {mode === 'edit' && editData.file_url && (
                    <FormGroup className="add-or-edit-form-group">
                      <Label>
                        <Input
                          type="checkbox"
                          name="newFileSelected"
                          checked={editFileState.newFileSelected}
                          onChange={(e) => {
                            setEditFileState((prev) => ({
                              newFileSelected: !prev.newFileSelected,
                              fileError: null,
                            }));
                            if (!e.target.checked) {
                              setFieldValue('file', null);
                            }
                          }}
                        />
                        Choose a new file
                      </Label>
                    </FormGroup>
                  )}
                  {(mode === 'add' || (mode === 'edit' && editFileState.newFileSelected)) && (
                    <FormGroup className="add-or-edit-form-group">
                      <Label htmlFor="templatePdfUrl" className="custom-form-label">
                        {mode === 'edit' ? 'Choose New Template' : 'Template'} <span>*</span>
                      </Label>
                      <Input
                        type="file"
                        id="fileInput"
                        accept=".pdf"
                        name="file"
                        onChange={(event) => {
                          const { files } = event.currentTarget;
                          if (files && files[0]) {
                            if (mode === 'add') {
                              setFieldValue('file', files[0]);
                            } else if (mode === 'edit') {
                              instantUpload(files[0], setFieldValue);
                            }
                          }
                        }}
                      />
                      <ErrorMessage name="file" component="div" className="error-message" />
                      {editFileState.fileError && <div className="error-message">{editFileState.fileError}</div>}
                    </FormGroup>
                  )}
                  <div className="form-button-container">
                    <button className="cancel-button" onClick={toggle} type="button">
                      Cancel
                    </button>
                    <button className="save-button" type="submit" disabled={isSubmitting}>
                      Save
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </OffcanvasBody>
        </Offcanvas>
      </div>
    </>
  );
};

export default AddOrEditContractTemplates;
