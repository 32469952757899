import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { MenuItem } from '../types/menu-item';
import DashBoard from '../../dashboard/dashboard';
import SideBar from '../side-bar/side-bar';
import ContractsTemplates from '../../contracts/contracts-templates/contracts-templates';
import FuneralHomes from '../../directory/funeral-homes/funeral-homes';
import Agencies from '../../directory/agencies/agencies';
import Companies from '../../directory/companies/companies';
import RegionalReps from '../../directory/regional-reps/regional-reps';
import Policies from '../../directory/policies/policies';
import Agents from '../../directory/agents/agents';
import dashboardIcon from '../../../assets/icons/side-bar/dashboardIcon.svg';
import directoryIcon from '../../../assets/icons/side-bar/directoryIcon.svg';
import contractsNewIcons from '../../../assets/icons/side-bar/contractsNewIcons.svg';
import funeralServicesNewIcons from '../../../assets/icons/side-bar/funeralServicesNewIcons.svg';
import paymentsIcon from '../../../assets/icons/side-bar/paymentsIcon.svg';
import ordersNewIcons from '../../../assets/icons/side-bar/ordersNewIcons.svg';
import userManagementIcon from '../../../assets/icons/side-bar/userManagementIcon.svg';
import settingsIcon from '../../../assets/icons/side-bar/settingsIcon.svg';
import parametersIcon from '../../../assets/icons/side-bar/parametersIcon.svg';
import integrationsIcon from '../../../assets/icons/side-bar/integrationsIcon.svg';
import leadsNewIcons from '../../../assets/icons/side-bar/leadsNewIcons.svg';
import funeralHomesIcon from '../../../assets/icons/side-bar/funeralHomesIcon.svg';
import agenciesIcon from '../../../assets/icons/side-bar/agenciesIcon.svg';
import companiesIcon from '../../../assets/icons/side-bar/companiesIcon.svg';
import regionalRepsIcon from '../../../assets/icons/side-bar/regionalRepsIcon.svg';
import policiesIcon from '../../../assets/icons/side-bar/policiesIcon.svg';
import agentsIcon from '../../../assets/icons/side-bar/agentsIcon.svg';
import reportsIcon from '../../../assets/icons/side-bar/reportsNewIcons.svg';
import reportsNewIconsSecond from '../../../assets/icons/side-bar/reportsNewIconsSecond.svg';
import './main-layout.scss';
import FuneralServices from '../../funeral-services/funeral-services';
import Packages from '../../packages/packages';
import Guides from '../../guides/guides';
import Leads from '../../leads-item/leads';
import Payments from '../../payments/payments';
import Orders from '../../orders/orders';
import Parameters from '../../settings/parameters/parameters';
import Integrations from '../../settings/integrations/integrations';
import ProfileContainer from '../../Profile/ProfileContainer';
import ProfilePage from '../../Profile/ProfilePage';
import TermsOfService from '../../terms-of-services/terms-of-services';
import termsOfServiceIcon from '../../../assets/icons/side-bar/termsOfServiceIcon.svg';
import logoutIcon from '../../../assets/icons/side-bar/logoutIcon.svg';
import FileUploadTracking from '../../file-upload-tracking/file-upload-tracking';
import TrackUploadsIcon from '../../../assets/icons/side-bar/TrackUploadsIcon.svg';
import Roles from '../../user-management/roles/roles';
import rolesIcon from '../../../assets/icons/side-bar/rolesIcon.svg';
import guidesIcons from '../../../assets/icons/side-bar/guidesIcons.svg';
import User from '../../user-management/user-management/user';
import ContractsSignatures from '../../contracts/contracts-signature/contracts-signature';
import SavedReports from '../../reports/saved-reports/saved-reports';
import generateReportsIcon from '../../../assets/icons/side-bar/generateReportsIcon.svg';
import savedReportsIcon from '../../../assets/icons/side-bar/savedReportsIcon.svg';
import MODULES from '../../../constants/modules';
import GenerateReports from '../../reports/generate-reports/generate-reports';
import activeDashboardIcon from '../../../assets/icons/side-bar/activeDashboardIcon.svg';
import activeLeadsIcon from '../../../assets/icons/side-bar/activeLeadsIcon.svg';
import activeTermsOfServiceIcon from '../../../assets/icons/side-bar/activeTermsOfServiceIcon.svg';
import activeGuidesIcon from '../../../assets/icons/side-bar/activeGuidesIcons.svg';
import activeLogoutIcon from '../../../assets/icons/side-bar/activeLogoutIcon.svg';

const MainLayout: React.FC = () => {
  const isModuleEnabled = (moduleName: string): boolean => {
    const userData = localStorage.getItem('user');
    if (!userData) return false;

    try {
      const parsedUser = JSON.parse(userData);
      const permissions = parsedUser?.userRole?.permissions || [];
      const module = permissions.find((perm: { module: string; isEnabled: boolean }) => perm.module === moduleName);
      return module?.isEnabled || false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const navigate = useNavigate();
  const menuItems: MenuItem[] = [
    {
      label: 'Dashboard',
      icon: dashboardIcon,
      path: 'dashboard',
      component: <DashBoard />,
      classNames: '',
      moduleName: MODULES.DASHBOARD,
    },
    {
      label: 'Directory',
      icon: directoryIcon,
      path: 'directory',
      component: '',
      classNames: '',
      isOpen: true,
      moduleName: MODULES.DIRECTORY,
      subItems: [
        {
          label: 'Funeral Homes',
          icon: funeralHomesIcon,
          path: 'directory/funeral-homes',
          component: <FuneralHomes />,
          classNames: '',
        },
        {
          label: 'Agencies',
          icon: agenciesIcon,
          path: 'directory/agencies',
          component: <Agencies />,
          classNames: '',
        },
        {
          label: 'Agents',
          icon: agentsIcon,
          path: 'directory/agents',
          component: <Agents />,
          classNames: '',
        },
        {
          label: 'Regional Reps',
          icon: regionalRepsIcon,
          path: 'directory/regional-reps',
          component: <RegionalReps />,
          classNames: '',
        },
        {
          label: 'Companies',
          icon: companiesIcon,
          path: 'directory/companies',
          component: <Companies />,
          classNames: '',
        },
        {
          label: 'Policies - Coming Soon',
          icon: policiesIcon,
          path: 'directory/policies',
          component: <Policies />,
          classNames: '',
          disabled: true,
        },
      ],
    },
    {
      label: 'Contracts',
      icon: contractsNewIcons,
      path: 'contracts',
      component: '',
      classNames: '',
      isOpen: true,
      moduleName: MODULES.CONTRACTS,
      subItems: [
        // {
        //   label: "View Contracts",
        //   icon: funeralHomesIcon,
        //   path: "contracts/view-contracts",
        //   component: <ViewContracts />,
        //   classNames: "",
        // },
        {
          label: 'Contracts Templates',
          icon: agenciesIcon,
          path: 'contracts/contracts-templates',
          component: <ContractsTemplates />,
          classNames: '',
        },
        {
          label: 'Contracts Signature',
          icon: agenciesIcon,
          path: 'contracts/contracts-signature',
          component: <ContractsSignatures />,
          classNames: '',
        },
      ],
    },
    {
      label: 'Leads',
      icon: leadsNewIcons,
      path: 'leads',
      component: <Leads />,
      classNames: '',
      moduleName: MODULES.LEADS,
    },
    {
      label: 'Reports',
      icon: reportsNewIconsSecond,
      path: 'reports',
      component: '',
      classNames: '',
      isOpen: true,
      moduleName: MODULES.REPORTS,
      subItems: [
        {
          label: 'Saved Reports',
          icon: savedReportsIcon,
          path: 'reports/saved-reports',
          component: <SavedReports />,
          classNames: '',
        },
        {
          label: 'Generate Reports',
          icon: generateReportsIcon,
          path: 'reports/generate-reports',
          component: <GenerateReports />,
          classNames: '',
        },
      ],
    },
    {
      label: 'Users',
      icon: userManagementIcon,
      path: 'user',
      component: '',
      classNames: '',
      isOpen: true,
      moduleName: MODULES.USERS,
      subItems: [
        {
          label: 'Users',
          icon: userManagementIcon,
          path: 'user/user',
          component: <User />,
          classNames: '',
        },
        {
          label: 'Roles',
          icon: rolesIcon,
          path: 'user/role',
          component: <Roles />,
          classNames: '',
        },
      ],
    },
    {
      label: 'Settings',
      icon: settingsIcon,
      path: 'settings',
      component: '',
      classNames: 'side-bar-border-bottom',
      isOpen: true,
      moduleName: MODULES.SETTINGS,
      subItems: [
        {
          label: 'Parameters',
          icon: parametersIcon,
          path: 'settings/parameters',
          component: <Parameters />,
          classNames: '',
        },
        {
          label: 'Track Uploads',
          icon: TrackUploadsIcon,
          path: 'settings/trackuploads',
          component: <FileUploadTracking />,
          classNames: '',
          moduleName: MODULES.IMPORT,
        },
        {
          label: 'Integrations - Comin...',
          icon: integrationsIcon,
          path: 'settings/integrations',
          component: <Integrations />,
          classNames: '',
          disabled: true,
        },
      ],
    },
    {
      label: 'Coming Soon',
      icon: reportsNewIconsSecond,
      path: '',
      component: '',
      classNames: '',
      isOpen: true,
      moduleName: MODULES.COMING_SOON,
      subItems: [
        {
          label: 'Packages',
          icon: reportsIcon,
          path: 'packages',
          component: <Packages />,
          classNames: '',
          disabled: true,
        },
        {
          label: 'Funeral Services',
          icon: funeralServicesNewIcons,
          path: 'funeral-services',
          component: <FuneralServices />,
          classNames: '',
          disabled: true,
        },
        {
          label: 'Payments',
          icon: paymentsIcon,
          path: 'payments',
          component: <Payments />,
          classNames: '',
          disabled: true,
        },
        {
          label: 'Orders',
          icon: ordersNewIcons,
          path: 'orders',
          component: <Orders />,
          classNames: '',
          disabled: true,
        },
      ],
    },
  ];

  const iconMapping = {
    Dashboard: activeDashboardIcon,
    Leads: activeLeadsIcon,
  };

  const footerIconMapping = {
    Guides: activeGuidesIcon,
    'Terms Of Services': activeTermsOfServiceIcon,
    Logout: activeLogoutIcon,
  };

  const allowedModules = [MODULES.DASHBOARD, MODULES.COMING_SOON];
  const filteredMenuItems = menuItems
    .map((item) => {
      const isMainItemEnabled = item.moduleName && (allowedModules.includes(item.moduleName) || isModuleEnabled(item.moduleName));
      const enabledSubItems = item.subItems?.filter(
        (subItem) => subItem.moduleName && (allowedModules.includes(subItem.moduleName) || isModuleEnabled(subItem.moduleName)),
      );
      if (isMainItemEnabled || (enabledSubItems && enabledSubItems.length > 0)) {
        return {
          ...item,
          subItems: isMainItemEnabled ? item.subItems : enabledSubItems,
        };
      }
      return null;
    })
    .filter((item) => item !== null) as MenuItem[];

  const footerItem: MenuItem[] = [
    {
      label: 'Guides',
      icon: guidesIcons,
      path: 'guides',
      component: <Guides />,
      classNames: '',
    },
    {
      label: 'Terms Of Services',
      icon: termsOfServiceIcon,
      path: 'terms-of-service',
      component: <TermsOfService />,
      classNames: '',
    },
    {
      label: 'Logout',
      icon: logoutIcon,
      path: '#',
      component: '',
      classNames: '',
    },
  ];

  useEffect(() => {
    if (localStorage.getItem('currentUrl')) {
      navigate(`${localStorage.getItem('currentUrl')}`);
    }
  }, [navigate]);

  return (
    <div className={`main-layout`}>
      <SideBar navItemsInfo={filteredMenuItems} footerItems={footerItem} iconMapping={iconMapping} footerIconMapping={footerIconMapping} />
      <div className="content">
        <ProfileContainer />
        <Routes>
          {filteredMenuItems.map((item, index) => (
            <>
              <Route path={item.path} element={item.component} />
              {item.subItems &&
                item.subItems.map((subItem, subIndex) => (
                  <Route key={`${index}-${subIndex}`} path={subItem.path} element={subItem.component} />
                ))}
            </>
          ))}
          <Route path="/" element={<DashBoard />} />
          <Route path="/guides" element={<Guides />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/leads/new" element={<Leads />} />
        </Routes>
      </div>
    </div>
  );
};
export default MainLayout;
