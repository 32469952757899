import axios from '.';
export const verifyTemporaryPasswordAndExpiration = (data: any) => {
    return axios({
        method: 'POST',
        url: '/verify-temporary-password-expiration',
        data
    })
}

export const setPassword=(data:any)=>{
    return axios({
      method:'POST',
      url:'/set-password',
      data
    })
}